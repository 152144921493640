import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { paths } from '../../routes'
import { formatDate, getDate } from '../../utils/date-utils'
import { timeRegistrationDescription } from '../../utils/time-registration-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import TimeRegistrationModal from '../time-registration/TimeRegistrationModal'
import { TimeRegistrationMode } from '../types'

type Props = {
  mode: TimeRegistrationMode
  employee: Employee
  company: Company
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: List<SalaryType>
  leaveTypes: List<LeaveType>
  costCenters: List<CostCenter>
  departments: List<Department>
  projects: List<Project>

  addAlert: addAlertSignature
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  deleteTimeRegistration: (id: string, employeeID: string) => void
}

export default function TimeRegistrationCard(props: Props): ReactElement | null {
  const [editing, setEditing] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)

  const baseTitle =
    props.mode === 'Flex'
      ? 'dashboard.card_flex_time_registrations'
      : props.mode === 'Overtime'
      ? 'dashboard.card_overtime_registrations'
      : props.mode === 'Project Hours'
      ? 'dashboard.card_project_registrations'
      : 'dashboard.card_time_registrations'

  const setEditVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const { timeRegistrations, addAlert } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations?.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        addAlert('success', t(baseTitle + '.alert.success'), { timeout: 5 })
        setEditVisibility(false)
      }
    }
  }, [previousTimeRegistrations, timeRegistrations, addAlert, baseTitle])

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        props.deleteTimeRegistration(id, props.employee.id)
      }
    }
  }

  const today = getDate()
  const registrations = timeRegistrations.timeRegistrations
    .filter((reg) => reg.class === props.mode && isSameDay(getDate(reg.date), today))
    .map((reg) => ({
      ...reg,
      date: formatDate(reg.date),
      className: reg.approved ? 'registration-row-approved' : 'registration-row-unapproved',
      description: timeRegistrationDescription(reg, props.projects.toArray()),
    }))

  return (
    <div className="card-time-registration">
      <Headline>{t(baseTitle + '.title')}</Headline>
      <div className="registrations-list">
        {registrations.map((timeRegistration) => {
          return (
            <div key={timeRegistration.id} className="registration-row">
              <Card key={timeRegistration.id} className={timeRegistration.className}>
                <div className="registration-row-info" onClick={() => setEditVisibility(timeRegistration.id)}>
                  <div className="time-registration-date">{timeRegistration.date}</div>
                  <div className="time-registration-description">{timeRegistration.description}</div>
                </div>
                {!timeRegistration.immutable && (
                  <span onClick={remove(timeRegistration.id)} className="registration-row-delete" />
                )}
              </Card>
            </div>
          )
        })}
      </div>

      <Button className="dashboard-choice-register-button" type="primary" onClick={() => setEditVisibility(true)}>
        {t(baseTitle + '.register_new')}
      </Button>
      <Link
        to={
          '/' +
          (props.mode === 'Flex' || props.mode === 'Overtime'
            ? paths.TIME_BOX_REGISTRATION
            : props.mode === 'Project Hours'
            ? paths.PROJECT_REGISTRATION
            : paths.TIME_REGISTRATION)
        }
      >
        <Button className="dashboard-choice-page-button">{t(baseTitle + '.go_to_page')}</Button>
      </Link>

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <TimeRegistrationModal
          visible={editing !== false}
          locked={false}
          mode={props.mode}
          company={props.company}
          employee={props.employee}
          timeRegistrationID={typeof editing !== 'boolean' ? editing : undefined}
          timeRegistrations={props.timeRegistrations}
          salaryTypes={props.mode === 'Hours' ? props.salaryTypes : List()}
          leaveTypes={props.mode === 'Flex' || props.mode === 'Overtime' ? props.leaveTypes : List()}
          costCenters={props.mode === 'Project Hours' ? List() : props.costCenters}
          departments={props.mode === 'Project Hours' ? List() : props.departments}
          projects={props.mode === 'Project Hours' || props.mode === 'Hours' ? props.projects : List()}
          createTimeRegistration={props.createTimeRegistration}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>
    </div>
  )
}
