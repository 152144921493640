import Employee from '../model/employee'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchEmployees(): Promise<RequestResponse<Employee[]>> {
  return secureRequest('GET', url('/v2/employees'))
}

export function putEmployee(employee: Employee): Promise<RequestResponse<Employee>> {
  return secureRequest('PUT', url('/v2/employees/' + employee.id), {
    body: {
      nationalIDType: employee.nationalIDType,
      nationalID: employee.nationalID,
      name: employee.name,
      address: employee.address,
      postalCode: employee.postalCode,
      city: employee.city,
      country: employee.country,
      gender: employee.gender,
      birthDate: employee.birthDate,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      phoneNumberCountryCode: employee.phoneNumberCountryCode,
      transferDestinationType: employee.transferDestinationType,
      bankRegistrationNumber: employee.bankRegistrationNumber,
      bankAccountNumber: employee.bankAccountNumber,
      paySlipTransportEBoks: employee.paySlipTransportEBoks,
      paySlipTransportMitDK: employee.paySlipTransportMitDK,
      paySlipTransportEMail: employee.paySlipTransportEMail,
      paySlipTransportSMS: employee.paySlipTransportSMS,
      language: employee.language,
      affiliationType: employee.affiliationType,
      departmentID: employee.departmentID,
      employeeAppFrontPageChoice: employee.employeeAppFrontPageChoice,
    },
  })
}
