import { addMonths, isSameYear, startOfYear, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import Employee from '../../model/employee'
import LeaveBalance from '../../model/leaveBalance'
import LeaveType, { LeaveTypeName } from '../../model/leaveType'
import TimeRegistration, {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { DateFormat } from '../../model/types'
import { LeaveBalanceReducer } from '../../reducers/leaveBalances'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import CompanySetting from '../../types/company-setting'
import { formatAPIDate, formatDate, getDate, trimCurrentYear } from '../../utils/date-utils'
import { getPlatform } from '../../utils/device-utils'
import { formatDays } from '../../utils/number-utils'
import { capitalise } from '../../utils/string-utils'
import { leaveRegistrationDescription } from '../../utils/time-registration-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Button from '../elements/button'
import DumbLink from '../elements/DumbLink'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import NoContractMessage from '../widgets/NoContractMessage'
import PullToRefresh from '../widgets/PullToRefresh'
import LeaveCalculationExplanation from './LeaveCalculationExplanation'
import LeaveRegistrationModal from './LeaveRegistrationModal'

import './LeaveRegistration.css'

type Props = {
  employee: Employee
  company: Company
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: List<LeaveType>
  leaveBalances: LeaveBalanceReducer

  getTimeRegistrations: (
    employeeID: string,
    fromDate?: DateFormat,
    toDate?: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistrationBulk: (registration: TimeRegistrationBulkFields) => void
  createTimeRegistration: (registration: TimeRegistrationCreationFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (registrationID: string) => void
  getLeaveTypes: (companyID: string) => Promise<LeaveType[] | void>
  getLeaveBalances: (employeeID: string) => Promise<LeaveBalance[] | void>
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

export default function LeaveRegistrations(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [showExplanation, setShowExplanation] = useState(false)
  const [showCycles, setShowCycles] = useState(false)

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const { timeRegistrations } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    // Check for save callback
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      // Check for no error occurred
      if (!timeRegistrations.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const remove = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteTimeRegistration(id)
      }
    }
  }

  type TimeRegistrationRow = {
    id: string
    className: string
    date: string
    description: string
    approved: boolean
    immutable: boolean
  }

  const getLeaveRegistrationRows = () => {
    const approvedMeansImmutable = !props.company.autoApproveTimeRegistrationLeave
    const months: Record<string, TimeRegistrationRow[]> = {}
    props.timeRegistrations.timeRegistrations
      .filter(
        (timeRegistration) => timeRegistration.class === 'Leave' && !deleting.some((id) => id === timeRegistration.id)
      )
      .forEach((timeRegistration) => {
        let month = formatDate(getDate(timeRegistration.date), 'MMMM yyyy')
        month = month.substring(0, 1).toUpperCase() + month.substring(1)
        if (!months[month]) {
          months[month] = []
        }
        let className = 'registration-row-unapproved'
        let immutable = timeRegistration.immutable
        if (timeRegistration.approved) {
          className = 'registration-row-approved'
          if (approvedMeansImmutable) {
            immutable = true
          }
        }
        months[month].push({
          id: timeRegistration.id,
          className,
          date: trimCurrentYear(formatDate(timeRegistration.date)),
          description: leaveRegistrationDescription(timeRegistration, props.employee, props.leaveTypes.toArray()),
          approved: timeRegistration.approved,
          immutable,
        })
      })
    const timeRegistrations = []
    for (const month in months) {
      timeRegistrations.push({
        month,
        timeRegistrations: months[month],
      })
    }
    return timeRegistrations
  }

  const employee = props.employee
  if (!employee.activeContract) {
    return <NoContractMessage employee={employee} />
  }

  const leaveBalances = props.leaveBalances.leaveBalances
  const getLeaveTypeID = (names: LeaveTypeName[]) =>
    props.leaveTypes.find(
      (leaveType) =>
        names.indexOf(leaveType.name) !== -1 &&
        leaveBalances.some(
          (leaveBalance) =>
            leaveBalance.leaveTypeID === leaveType.id &&
            (leaveBalance.earned !== 0 ||
              !!props.employee.activeContract?.remuneration.leave.some((leave) => leave.typeID === leaveType.id))
        )
    )?.id
  const paidVacationLeaveTypeID = getLeaveTypeID(['DenmarkVacationPaid', 'DenmarkVacationAccrual'])
  const personalLeaveTypeID = getLeaveTypeID(['DenmarkPersonalDay', 'DenmarkPersonalTimeAccrual'])
  const optionalLeaveTypeID = getLeaveTypeID(['DenmarkOptionalVacation', 'DenmarkExtraVacationAccrual'])
  const showCycleButton = !props.company.settingsEnabled.some(
    (setting) => setting === CompanySetting.DISABLE_APP_DETAILED_VACATION_PREDICTION
  )

  const renderVacationLeaveBalance = (vacationLeaveTypeID?: string) => {
    if (!vacationLeaveTypeID) {
      return null
    }
    const leaveBalance = leaveBalances.find(
      (balance) => !!vacationLeaveTypeID && balance.leaveTypeID === vacationLeaveTypeID
    )
    if (!leaveBalance) {
      return null
    }
    const used = leaveBalance.used + leaveBalance.registered
    // use employee's vacationExcessLimit, and fall to company's if undefined.
    // employee.vacationExcessLimit == 0 is a legit scenario, i.e. disabling it for the employee.
    const vacationExcessLimit = props.employee.vacationExcessLimit ?? props.company.vacationExcessLimit
    return (
      <div key={leaveBalance.leaveTypeID} className={'leave-balances-group leave-balances-vacation'}>
        <Row className={vacationExcessLimit > 0.0 ? '' : 'leave-balances-total'}>
          <Col span={14}>
            {vacationExcessLimit > 0.0
              ? t('leave_registration.leave_balances_balance')
              : t('leave_registration.leave_balances_total')}
          </Col>
          <Col span={10} className={'leave-balances-days'}>
            {formatDays(leaveBalance.earned - used)}
          </Col>
        </Row>
        {vacationExcessLimit > 0.0 && (
          <>
            <Row>
              <Col span={14}>{t('leave_registration.leave_balances_excess')}</Col>
              <Col span={10} className={'leave-balances-days'}>
                {formatDays(vacationExcessLimit)}
              </Col>
            </Row>
            <Row className={'leave-balances-total'}>
              <Col span={14}>{t('leave_registration.leave_balances_total')}</Col>
              <Col span={10} className={'leave-balances-days'}>
                {formatDays(leaveBalance.earned - used + vacationExcessLimit)}
              </Col>
            </Row>
          </>
        )}
        {!showCycles && (
          <Row className={'leave-balances-future'}>
            <Col span={14}>{t('leave_registration.leave_balances_future')}</Col>
            <Col span={10} className={'leave-balances-days'}>
              {formatDays(leaveBalance.registeredFuture)}
            </Col>
          </Row>
        )}
        {leaveBalance.cycles.length > 0 && (
          <>
            {showCycles && (
              <>
                <Row>
                  <Col span={24} className={'leave-balances-header'}>
                    {t('leave_registration.cycle_header')}
                  </Col>
                </Row>
                <Row className={'leave-balances-table-header'}>
                  <Col span={14}>{t('leave_registration.cycle_header_expire')}</Col>
                  <Col span={10} className={'leave-balances-days'}>
                    {t('leave_registration.cycle_header_balance')}
                  </Col>
                </Row>
                {leaveBalance.cycles.map((cycle) => {
                  if (Math.max(cycle.balance, 0) === 0) {
                    return null
                  }
                  return (
                    <Row>
                      <Col span={14}>{formatDate(cycle.expireAt, 'do MMM yyyy')}</Col>
                      <Col span={10} className={'leave-balances-days'}>
                        {formatDays(cycle.balance, true)}
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col span={24} className={'leave-balances-header'}>
                    {t('leave_registration.futures_header')}
                  </Col>
                </Row>
                <Row className={'leave-balances-table-header'}>
                  <Col span={8}>{t('leave_registration.futures_header_date')}</Col>
                  <Col span={8} className={'leave-balances-days'}>
                    {t('leave_registration.futures_header_taken')}
                  </Col>
                  <Col span={8} className={'leave-balances-days'}>
                    {t('leave_registration.futures_header_balance')}
                  </Col>
                </Row>
                {leaveBalance.futures.map((future, i) => {
                  let format = 'MMMM yyyy'
                  if (
                    i > 0 &&
                    getDate(leaveBalance?.futures[i - 1].date).getFullYear() === getDate(future.date).getFullYear()
                  ) {
                    format = 'MMMM'
                  }
                  return (
                    <Row>
                      <Col span={8}>{capitalise(formatDate(future.date, format))}</Col>
                      <Col span={8} className={'leave-balances-days'}>
                        {future.taken > 0.0 && <>{formatDays(future.taken)}</>}
                      </Col>
                      <Col span={8} className={'leave-balances-days'}>
                        {formatDays(future.balance, true)}
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col span={24} className={'leave-balance-explanation-link'}>
                    <DumbLink onClick={() => setShowExplanation(true)}>
                      {t('leave_registration.future_calculation_show')}
                    </DumbLink>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className={'leave-balances-explanation'}>
                    <p>
                      {t('leave_registration.future_disclaimer')}
                      {vacationExcessLimit > 0 && <> {t('leave_registration.future_disclaimer_excess')}</>}
                      {props.company.automaticVacationTransferLimit > 0 && (
                        <> {t('leave_registration.future_disclaimer_transfer')}</>
                      )}
                    </p>
                  </Col>
                </Row>
              </>
            )}
            {!showCycles && (
              <>
                {getDate().getMonth() >= 8 && isSameYear(getDate(), getDate(leaveBalance.cycles[0].expireAt)) && (
                  <Row>
                    <Col span={14}>
                      {t('leave_registration.leave_balances_expiring', {
                        date: formatDate(leaveBalance.cycles[0].expireAt, 'do MMM yyyy'),
                      })}
                    </Col>
                    <Col span={10} className={'leave-balances-days'}>
                      {formatDays(Math.max(leaveBalance.cycles[0].balance, 0))}
                    </Col>
                  </Row>
                )}
              </>
            )}
            {showCycleButton && (
              <Row>
                <Col span={24}>
                  <Button
                    style={{ margin: '0 auto', display: 'block', marginTop: '5px' }}
                    onClick={() => setShowCycles((prev) => !prev)}
                  >
                    {showCycles ? t('leave_registration.cycles_hide') : t('leave_registration.cycles_show')}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    )
  }
  const renderOtherLeaveBalance = (leaveTypeID: string | undefined, className: string, text: string) => {
    const leaveBalance = leaveBalances.find((balance) => !!leaveTypeID && balance.leaveTypeID === leaveTypeID)
    if (!leaveBalance) {
      return null
    }
    const used = leaveBalance.used + leaveBalance.registered
    return (
      <div key={leaveBalance.leaveTypeID} className={'leave-balances-group ' + className}>
        <Row className={'leave-balances-total'}>
          <Col span={14}>{text}</Col>
          <Col span={10} className={'leave-balances-days'}>
            {formatDays(leaveBalance.earned + leaveBalance.complimentary - used)}
          </Col>
        </Row>
      </div>
    )
  }

  const locked = !props.employee.earliestMutableContract
  const leaveRegistrations = getLeaveRegistrationRows()
  return (
    <div className={'leave-registration registrations-list platform-' + getPlatform()}>
      <PullToRefresh
        onRefresh={() => {
          const employee = props.employee
          return Promise.all([
            props.getTimeRegistrations(
              employee.id,
              formatAPIDate(startOfYear(subYears(getDate(), 1))),
              formatAPIDate(addMonths(getDate(), 5))
            ),
            props.getLeaveTypes(props.company.id),
            props.getLeaveBalances(employee.id),
          ])
        }}
      >
        {(!!paidVacationLeaveTypeID || !!personalLeaveTypeID || !!optionalLeaveTypeID) && (
          <Card className={'leave-balances'}>
            {props.leaveBalances.loading && <LoadingOverlay />}
            <Subtitle>{t('leave_registration.leave_balances_title')}</Subtitle>
            {renderVacationLeaveBalance(paidVacationLeaveTypeID)}
            {renderOtherLeaveBalance(
              personalLeaveTypeID,
              'leave-balances-vacation-personal',
              t('leave_registration.personal_days_balance')
            )}
            {renderOtherLeaveBalance(
              optionalLeaveTypeID,
              'leave-balances-vacation-optional',
              t('leave_registration.additional_leave_balances_balance')
            )}
          </Card>
        )}
        {leaveRegistrations.map((row) => {
          return (
            <div key={row.month}>
              <div className="leave-registrations-header">{row.month}</div>
              {row.timeRegistrations.map((timeRegistration) => {
                return (
                  <div key={timeRegistration.id} className="registration-row">
                    <Card className={timeRegistration.className}>
                      <div className="registration-row-info" onClick={() => setEditVisibility(timeRegistration.id)}>
                        <div className="leave-registration-date">{timeRegistration.date}</div>
                        <div className="leave-registration-description">{timeRegistration.description}</div>
                      </div>
                      {!timeRegistration.immutable && !locked && (
                        <span onClick={remove(timeRegistration.id)} className="registration-row-delete" />
                      )}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {leaveRegistrations.length === 0 && (
          <div className="content-text">{t('leave_registration.no_leave_registrations_yet')}</div>
        )}
      </PullToRefresh>
      {!locked && (
        <DumbLink onClick={() => setEditVisibility(true)} className="leave-registrations-add">
          {t('leave_registration.register_leave')}
        </DumbLink>
      )}

      <Modal
        key={`form-${modalKey}`}
        visible={editing !== false}
        closable={true}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <LeaveRegistrationModal
          visible={editing !== false}
          locked={locked}
          company={props.company}
          employee={props.employee}
          timeRegistrationID={typeof editing === 'string' ? editing : undefined}
          timeRegistrations={props.timeRegistrations}
          leaveTypes={props.leaveTypes}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>

      <Modal
        key={`info`}
        visible={showExplanation}
        closable={true}
        onOk={() => setShowExplanation(false)}
        onCancel={() => setShowExplanation(false)}
        footer={null}
      >
        <LeaveCalculationExplanation
          company={props.company}
          employee={props.employee}
          closeModal={() => setShowExplanation(false)}
        />
      </Modal>
    </div>
  )
}
