import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Employee from '../model/employee'
import { setLanguage } from '../utils/cookie-utils'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmployeeReducer {
  loading: boolean
  loaded: boolean
  saving: boolean
  preferEmployeeID: string | undefined
  employee: Employee | undefined
  employees: List<Employee>
  error: Error | null
}

const Params = Record<EmployeeReducer>({
  loading: false,
  loaded: false,
  saving: false,
  preferEmployeeID: undefined,
  employee: undefined,
  employees: List<Employee>(),
  error: null,
})

export interface EmployeeAction extends ReducerAction {
  employeeID?: string
  employee?: Employee
  employees?: Employee[]
}

export default (
  state: Record<EmployeeReducer> = Params(),
  action: EmployeeAction = { type: '' }
): Record<EmployeeReducer> => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_LOADING:
      return new Params({
        loading: true,
        preferEmployeeID: action.employeeID || state.get('preferEmployeeID'),
      })
    case ActionTypes.EMPLOYEE_LOADED: {
      let employee: Employee | undefined
      if (action.employees) {
        employee = action.employees[0]
        if (action.employeeID) {
          employee = action.employees.find((employee) => employee.id === action.employeeID)
        } else if (state.get('preferEmployeeID')) {
          employee = action.employees.find((employee) => employee.id === state.get('preferEmployeeID'))
        }
        if (employee) {
          setLanguage(employee.language)
        }
      }
      return new Params({
        loaded: true,
        employee: employee,
        employees: List<Employee>(action.employees),
        preferEmployeeID: action.employeeID || state.get('preferEmployeeID'),
      })
    }
    case ActionTypes.EMPLOYEE_LOAD_FAILED:
      return new Params({
        error: action.error || null,
        preferEmployeeID: state.get('preferEmployeeID'),
      })
    case ActionTypes.EMPLOYEE_SELECTED: {
      if (!action.employeeID) {
        return state
      }
      const employee = state.get('employees').find((employee) => employee.id === action.employeeID)
      if (employee) {
        setLanguage(employee.language)
      }
      return state.set('preferEmployeeID', action.employeeID)
    }

    case ActionTypes.EMPLOYEE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_UPDATED: {
      if (!action.employee) {
        return state
      }
      if (action.employeeID === state.get('employee')?.id) {
        setLanguage(action.employee.language)
        state = state.set('employee', action.employee)
      }
      const idx = state.get('employees').findIndex((employee) => employee.id === action.employeeID)
      if (!idx) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('employees', state.get('employees').set(idx, action.employee))
    }
    case ActionTypes.EMPLOYEE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params()
    default:
      return state
  }
}
