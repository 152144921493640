import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SalaryType from '../model/salaryType'
import { ReducerAction } from '../utils/reducer-utils'

export interface SalaryTypeReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  salaryTypes: List<SalaryType>
  error: Error | null
}

const Params = Record<SalaryTypeReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  salaryTypes: List<SalaryType>(),
  error: null,
})

export interface SalaryTypeAction extends ReducerAction {
  salaryTypes?: SalaryType[]
}

export default (
  state: Record<SalaryTypeReducer> = Params(),
  action: SalaryTypeAction = { type: '' }
): Record<SalaryTypeReducer> => {
  switch (action.type) {
    case ActionTypes.SALARY_TYPES_LOADING:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.SALARY_TYPES_LOADED:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        salaryTypes: List<SalaryType>(action.salaryTypes),
      })
    case ActionTypes.SALARY_TYPES_LOAD_FAILED:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
