import React, { ReactElement } from 'react'

import { getPaySlips } from '../actions/pay-slips'
import PaySlipsComponent from '../components/pay-slips/PaySlips'
import PaySlip from '../model/paySlip'
import { CompanyReducer } from '../reducers/company'
import { DocumentReducer } from '../reducers/documents'
import { EmployeeReducer } from '../reducers/employee'
import { PaySlipReducer } from '../reducers/paySlips'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  company: CompanyReducer
  documents: DocumentReducer
  employee: EmployeeReducer
  paySlips: PaySlipReducer
}

type Actions = {
  getPaySlips: (employeeID: string) => Promise<PaySlip[] | void>
}

function PaySlips(props: Reducers & Actions): ReactElement | null {
  const employee = props.employee.employee
  const company = props.company.company
  if (!employee || !company) {
    return null // should not happen
  }

  return (
    <PaySlipsComponent
      companySettings={company.settingsEnabled}
      documents={props.documents.documents}
      employee={employee}
      paySlips={props.paySlips.paySlips}
      getPaySlips={props.getPaySlips}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    company: state.company,
    documents: state.documents,
    employee: state.employee,
    paySlips: state.paySlips,
  }),
  { getPaySlips: getPaySlips }
)(PaySlips)
