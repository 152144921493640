import React, { ReactElement, useEffect, useState } from 'react'

import { UserReducer } from '../../reducers/user'
import { BUILD_NUMBER, VERSION_NUMBER } from '../../release'
import MfaChannel from '../../types/mfa-channel'
import { getEmail, setEmail } from '../../utils/cookie-utils'
import { getDeviceName, isNative } from '../../utils/device-utils'
import { formatError, isChannelMFAError } from '../../utils/error-utils'
import { ChannelMFAError, RequestError, RequestResponse } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import LoginForm, { Fields } from './LoginForm'

import './Login.css'

interface Props {
  user: UserReducer

  createDeviceToken: (deviceName: string) => void
  login: (
    email: string,
    password: string,
    challengeID?: string,
    response?: string,
    recoveryCode?: string,
    trustDevice?: boolean
  ) => Promise<RequestResponse | ChannelMFAError>
}

interface State {
  email?: string
  password?: string
  channel?: MfaChannel
  challengeID?: string
  remember?: boolean
}

export default function Login(props: Props): ReactElement | null {
  const [state, setState] = useState<State>({})
  const [error, setError] = useState<Error | null>(null)

  const { user } = props

  useEffect(() => {
    let newError = user.error
    if (newError && newError instanceof RequestError && newError.type === 'Authorization') {
      newError = null
    }
    if (newError !== error) {
      setError(newError)
    }
  }, [user, error, setError])

  const handleSubmit = (values: Fields) => {
    const email = values.email || state.email
    const password = values.password || state.password
    if (!email || !password) {
      return // do nothing
    }
    props
      .login(email, password, state.challengeID, values.response, values.recoveryCode, values.trustDevice)
      .then(async (res) => {
        if (res && isChannelMFAError(res)) {
          const mfaError = res as ChannelMFAError
          setState({
            email: values.email,
            password: values.password,
            channel: mfaError.channel,
            challengeID: mfaError.mfaChallengeID,
            remember: values.remember,
          })
        } else {
          if (values.remember || state.remember) {
            if (isNative()) {
              props.createDeviceToken(getDeviceName())
            } else {
              setEmail(values.email || null, 14 * 24 * 60 * 60)
            }
          }
        }
      })
  }

  return (
    <div className="login">
      <Subtitle>{t('common.log_in')}</Subtitle>
      <p>{t('login.no_account_contact_employer')}</p>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <LoginForm email={getEmail()} channel={state.channel} remember={!!getEmail()} onSubmit={handleSubmit} />
      <div className="release-info">
        v{VERSION_NUMBER}-{BUILD_NUMBER}
      </div>
      {user.loggingIn && <LoadingOverlay />}
    </div>
  )
}
