import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ExpenseCategory from '../model/expenseCategory'
import { ReducerAction } from '../utils/reducer-utils'

export interface ExpenseCategoryReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  expenseCategories: List<ExpenseCategory>
  error: Error | null
}

const Params = Record<ExpenseCategoryReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  expenseCategories: List<ExpenseCategory>(),
  error: null,
})

export interface ExpenseCategoryAction extends ReducerAction {
  expenseCategories?: ExpenseCategory[]
}

const comparator = (a: ExpenseCategory, b: ExpenseCategory) => {
  const diff = a.order - b.order
  if (diff !== 0) {
    return diff
  }
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<ExpenseCategoryReducer> = Params(),
  action: ExpenseCategoryAction = { type: '' }
): Record<ExpenseCategoryReducer> => {
  switch (action.type) {
    case ActionTypes.EXPENSE_CATEGORY_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.EXPENSE_CATEGORY_LOADED:
    case ActionTypes.EXPENSE_CATEGORY_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.EXPENSE_CATEGORY_LOADED_PARTIAL,
        expenseCategories: state.get('expenseCategories').concat(List(action.expenseCategories)).sort(comparator),
      })
    case ActionTypes.EXPENSE_CATEGORY_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
