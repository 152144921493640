import omit from 'omit.js'
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

import { getAntdStrings } from '../../../locale'
import { DateFormat } from '../../../model/types'
import { t } from '../../../utils/translation-utils'
import Icon from '../icon'
import createCustomHeader from './CustomHeader'

// The CustomInput is moved outside the main component, as it solves the issue of the key presses
// being stolen by the main date picker component.  (Why?  Don't ask.)
type CustomInputProps = {
  calendarOpen: boolean
  placeholder?: string
  clearIcon: React.ReactNode
  onCalendarOpen: () => void
}

const CustomInput = React.forwardRef(
  (props: CustomInputProps & { value?: DateFormat; onClick?: () => void }, ref: React.Ref<HTMLDivElement>) => (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick()
          props.onCalendarOpen()
        }
      }}
      className={`react-datepicker-input-wrapper`}
      ref={ref}
    >
      <input {...omit(props, ['calendarOpen', 'placeholder', 'clearIcon', 'onCalendarOpen'])} type="text" />
      {props.clearIcon}
      <span className={`picker-icon`} />
    </div>
  )
)

type Props = {
  value?: Date
  defaultValue?: Date
  onChange?: (value: Date | null) => void
  onStateChange?: (open: boolean) => void
  format?: string
  allowClear?: boolean
  placeholder?: string
  disabled?: boolean
  onOk?: () => void
  style?: CSSProperties
  tabIndex?: number
  includeDateRange?: [Date, Date] // from -> to
}

export default function DatePicker(props: Props): ReactElement | null {
  type State = {
    value?: Date
  }
  const [state, setState] = useState<State>(() => {
    const value = props.value || props.defaultValue
    return {
      value,
    }
  })
  const [calendarOpen, setCalendarOpen] = useState(false)

  const { value } = props

  useEffect(() => {
    if (value) {
      setState({
        value: value,
      })
    }
  }, [value])

  const { format = 'do MMMM yyyy' } = props

  const handleClose = () => {
    setCalendarOpen(false)
    if (props.onStateChange) {
      props.onStateChange(false)
    }
  }

  const handleChange = (value: Date | null) => {
    if (!('value' in props)) {
      setState({
        value: value || undefined,
      })
    }
    if (props.onChange) {
      props.onChange(value)
    }
    handleClose()
  }

  const { allowClear = true } = props

  const antdStrings = getAntdStrings()

  const placeholder = 'placeholder' in props ? props.placeholder : antdStrings.DatePicker.lang.placeholder

  const clearSelection = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleChange(null)
  }

  const clearIcon =
    !props.disabled && allowClear && value ? (
      <Icon type="cross-circle" className={`picker-clear`} onClick={clearSelection} />
    ) : null

  const refCustomInput = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>

  return (
    <span style={props.style} className={`react-datepicker-container`}>
      <ReactDatePicker
        onChange={handleChange}
        onCalendarClose={() => {
          handleClose()
        }}
        onClickOutside={() => {
          handleClose()
        }}
        open={calendarOpen}
        selected={state.value}
        tabIndex={props.tabIndex}
        customInput={
          <CustomInput
            calendarOpen={calendarOpen}
            onCalendarOpen={() => {
              setCalendarOpen(true)
              if (props.onStateChange) {
                props.onStateChange(true)
              }
            }}
            clearIcon={clearIcon}
            placeholder={placeholder}
            ref={refCustomInput}
          />
        }
        renderCustomHeader={(params) =>
          createCustomHeader({
            showTwoMonths: false,
            ...params,
          })
        }
        disabled={props.disabled}
        locale={'da'}
        dateFormat={format}
        showWeekNumbers
        fixedHeight
        todayButton={t('date_picker.today')}
        disabledKeyboardNavigation
        includeDateIntervals={
          props.includeDateRange ? [{ start: props.includeDateRange[0], end: props.includeDateRange[1] }] : undefined
        }
      />
    </span>
  )
}
