import classNames from 'classnames'
import React, { Children, Component } from 'react'

import Grid from './Grid'

import './style/css'

class Card extends Component {
  isContainGrid() {
    let containGrid
    Children.forEach(this.props.children, (element) => {
      if (element && element.type && element.type === Grid) {
        containGrid = true
      }
    })
    return containGrid
  }
  render() {
    const {
      prefixCls = 'ant-card',
      className,
      extra,
      bodyStyle,
      noHovering,
      title,
      loading,
      bordered = true,
      ...others
    } = this.props
    let children = this.props.children

    const classString = classNames(prefixCls, className, {
      [`${prefixCls}-loading`]: loading,
      [`${prefixCls}-bordered`]: bordered,
      [`${prefixCls}-no-hovering`]: noHovering,
      [`${prefixCls}-wider-padding`]: false,
      [`${prefixCls}-padding-transition`]: this.updateWiderPaddingCalled,
      [`${prefixCls}-contain-grid`]: this.isContainGrid(),
    })

    if (loading) {
      children = (
        <div className={`${prefixCls}-loading-content`}>
          <p className={`${prefixCls}-loading-block`} style={{ width: '94%' }} />
          <p>
            <span className={`${prefixCls}-loading-block`} style={{ width: '28%' }} />
            <span className={`${prefixCls}-loading-block`} style={{ width: '62%' }} />
          </p>
          <p>
            <span className={`${prefixCls}-loading-block`} style={{ width: '22%' }} />
            <span className={`${prefixCls}-loading-block`} style={{ width: '66%' }} />
          </p>
          <p>
            <span className={`${prefixCls}-loading-block`} style={{ width: '56%' }} />
            <span className={`${prefixCls}-loading-block`} style={{ width: '39%' }} />
          </p>
          <p>
            <span className={`${prefixCls}-loading-block`} style={{ width: '21%' }} />
            <span className={`${prefixCls}-loading-block`} style={{ width: '15%' }} />
            <span className={`${prefixCls}-loading-block`} style={{ width: '40%' }} />
          </p>
        </div>
      )
    }

    let head
    if (title || extra) {
      head = (
        <div className={`${prefixCls}-head`}>
          {title ? <div className={`${prefixCls}-head-title`}>{title}</div> : null}
          {extra ? <div className={`${prefixCls}-extra`}>{extra}</div> : null}
        </div>
      )
    }

    return (
      <div {...others} className={classString}>
        {head}
        <div className={`${prefixCls}-body`} style={bodyStyle}>
          {children}
        </div>
      </div>
    )
  }
}

Card.Grid = Grid

export default Card
