import React, { ReactElement } from 'react'

import { formatDate } from './date-utils'
import { logWarning } from './log-utils'
import { ChannelMFAError, RequestError, RequestResponse } from './request-utils'
import { t } from './translation-utils'

export function isRequestError(e: Error | RequestResponse): boolean {
  if (e instanceof RequestError) {
    return true
  }
  throw e
}

export function isChannelMFAError(e: Error | RequestResponse): boolean {
  return e instanceof ChannelMFAError
}

export function formatError(e: RequestError | Error | string): string | ReactElement {
  if (e instanceof RequestError) {
    switch (e.type) {
      case 'CompanyAlreadyExists':
        return t('error_message.type.company_already_exists')
      case 'CompanyFeatureMissing':
        return t('error_message.type.company_feature_missing')
      case 'EmailAlreadyExists':
        return t('error_message.type.email_already_exists')
      case 'EmailTokenExpired':
        return t('error_message.type.email_token_expired')
      case 'InternalServerError':
        return t('error_message.type.internal_server_error')
      case 'WrongPassword':
        return t('error_message.type.wrong_password')
      default:
        switch (e.message) {
          case 'Accounting API key invalid':
            return t('error_message.message.accounting_api_key_invalid')
          case 'Email Token Not Found':
            return t('error_message.message.email_token_not_found')
          case 'Employee Contract Invalid':
            return t('error_message.message.employee_contract_invalid')
          case 'Employee Contract Not Found':
            return t('error_message.message.employee_contract_not_found')
          case 'Employee Employed In Company':
            return t('error_message.message.employee_employed_in_company')
          case 'Employee Image Format Invalid':
            return t('error_message.message.employee_image_format_invalid')
          case 'Employee Immutable':
            return t('error_message.message.employee_immutable')
          case 'Employee Time Registration Excess Hours Pr Day':
            return t('error_message.message.employee_time_registration_excess_hours_pr_day')
          case 'Employment Number Is Not Unique':
            return t('error_message.message.employment_number_is_not_unique')
          case 'Import Could Not Log In':
            return t('error_message.message.import_could_not_log_in')
          case 'MFA Wrong Response':
            return t('error_message.message.mfa_wrong_response')
          case 'Production Unit Already Exists':
            return t('error_message.message.production_unit_already_exists')
          case 'User Not Found':
            return t('error_message.message.user_not_found')
          case 'Workflow Cannot Be Cancelled':
            return t('error_message.message.workflow_cannot_be_cancelled')
          case 'Reimbursement Voucher Unsupported File Format':
            return t('error_message.message.unsupported_file_format')
          case 'Employee Has Pending Swipe':
            return t('error_message.message.has_pending_swipe')
          case 'Employee Time Registration Already Exists':
            return t('error_message.message.time_registration_already_exists')
          case 'Device Token Not Found':
            return t('error_message.message.device_token_not_found')
          case 'Password must be at least 8 characters':
            return t('error_message.message.password_must_be_at_least_8_characters')
          case 'Password must not be listed in public data breach':
            return t('error_message.message.password_must_not_be_listed_in_public_data_breach')
          case 'Login throttled':
            if (e.details && e.details.length > 0) {
              return t('error_message.message.login_throttled.details', {
                time: formatDate(e.details[0].replace('Next try: ', ''), 'HH:mm'),
              })
            }
            return t('error_message.message.login_throttled')
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          case 'Employee Time Registration Invalid':
            if (e.details.length > 0) {
              switch (e.details[0]) {
                case 'Leave registration cannot be on vacation day':
                  return t('error_message.message.leave_registration_cannot_be_vacation_day')
              }
            }
          // fallthrough
          default:
            if (process.env.NODE_ENV !== 'test') {
              logWarning('Missing error handling: ' + e.message + ' (' + e.type + ')')
            }
            return t('error_message.type.internal_server_error')
        }
    }
  }
  if (e instanceof Error) {
    return e.message
  }
  return (
    <div>
      {e.split('\n').map((line, i) => {
        return <div key={i}>{line}</div>
      })}
    </div>
  )
}

export function formatValidationErrors(): string {
  return t('validation.enter_the_required_fields_correctly')
}
