import { registerPlugin, WebPlugin } from '@capacitor/core'

export class SwipeBackWeb extends WebPlugin {
  constructor() {
    super({
      name: 'SwipeBack',
      platforms: ['web'],
    })
  }

  enable() {
    return
  }
  disable() {
    return
  }
}

const SwipeBack = new SwipeBackWeb()

export { SwipeBack }

registerPlugin('SwipeBack', SwipeBack)
