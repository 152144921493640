import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import {
  createCarAllowances,
  deleteCarAllowance,
  getCarAllowances,
  updateCarAllowance,
} from '../actions/car-allowances'
import CarAllowanceDisabledComponent from '../components/car-allowance/CarAllowanceDisabled'
import CarAllowanceComponent from '../components/car-allowance/CarAllowances'
import CarAllowance, { CarAllowanceMutableFields } from '../model/carAllowance'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CarAllowanceReducer } from '../reducers/carAllowances'
import { CompanyReducer } from '../reducers/company'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employee'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  carAllowances: CarAllowanceReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCarAllowances: (employeeID: string, fromDate?: DateFormat, toDate?: DateFormat) => Promise<CarAllowance[] | void>
  createCarAllowances: (carAllowances: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  deleteCarAllowance: (carAllowance: CarAllowance) => void
}

function CarAllowanceTab(props: Reducers & Actions & RouteProps): ReactElement | null {
  const employee = props.employee.employee
  if (
    !employee ||
    (employee.activeContract && employee.activeContract.carAllowanceRegistrationMethodType === 'Coarse') ||
    !props.company.company
  ) {
    return <CarAllowanceDisabledComponent />
  }
  return (
    <CarAllowanceComponent
      showHistory={props.location.pathname.indexOf('/history') !== -1}
      employee={employee}
      company={props.company.company}
      costCenters={props.costCenters.costCenters}
      departments={props.departments.departments}
      carAllowances={props.carAllowances}
      getCarAllowances={props.getCarAllowances}
      createCarAllowances={props.createCarAllowances}
      updateCarAllowance={props.updateCarAllowance}
      deleteCarAllowance={props.deleteCarAllowance}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    employee: state.employee,
    carAllowances: state.carAllowances,
    costCenters: state.costCenters,
    departments: state.departments,
  }),
  {
    getCarAllowances: getCarAllowances,
    createCarAllowances: createCarAllowances,
    updateCarAllowance: updateCarAllowance,
    deleteCarAllowance: deleteCarAllowance,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(CarAllowanceTab)
