import React from 'react'

import { delOneTimePay, fetchOneTimePays, postOneTimePay, putOneTimePay } from '../api/one-time-pays'
import ActionTypes from '../constants/action-types'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { OneTimePayAction } from '../reducers/oneTimePays'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingOneTimePays(employeeID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAYS_LOADING,
    employeeID,
  }
}
function loadedOneTimePays(employeeID: string, oneTimePays: OneTimePay[], partial = false): OneTimePayAction {
  return {
    type: partial ? ActionTypes.ONE_TIME_PAYS_LOADED_PARTIAL : ActionTypes.ONE_TIME_PAYS_LOADED,
    employeeID,
    oneTimePays,
  }
}
function failedLoadingOneTimePays(employeeID: string, error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAYS_LOAD_FAILED,
    employeeID,
    error,
  }
}

function creatingOneTimePays(employeeID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAYS_CREATING,
    employeeID,
  }
}
export function createdOneTimePay(employeeID: string, oneTimePay: OneTimePay): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAYS_CREATED,
    employeeID,
    oneTimePay,
  }
}
function failedCreatingOneTimePays(employeeID: string, error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAYS_CREATE_FAILED,
    error,
  }
}

function updatingOneTimePay(employeeID: string, oneTimePayID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_UPDATING,
    employeeID,
    oneTimePayID,
  }
}
export function updatedOneTimePay(employeeID: string, oneTimePay: OneTimePay): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_UPDATED,
    employeeID,
    oneTimePayID: oneTimePay.id,
    oneTimePay,
  }
}
function failedUpdatingOneTimePay(employeeID: string, oneTimePayID: string, error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_UPDATE_FAILED,
    error,
    employeeID,
    oneTimePayID,
  }
}

function deletingOneTimePay(oneTimePayID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_DELETING,
    oneTimePayID,
  }
}
export function deletedOneTimePay(oneTimePayID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_DELETED,
    oneTimePayID,
  }
}
function failedDeletingOneTimePay(oneTimePayID: string, error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_DELETE_FAILED,
    error,
    oneTimePayID,
  }
}

export function getOneTimePays(employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay[] | void> => {
    if (!offset) {
      dispatch(loadingOneTimePays(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchOneTimePays(employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedOneTimePays(employeeID, data)),
          (data) => dispatch(loadedOneTimePays(employeeID, data, true)),
          (offset) => dispatch(getOneTimePays(employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingOneTimePays(employeeID, e))
        }
      })
  }
}

export function createOneTimePay(oneTimePay: OneTimePayCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay | void> => {
    dispatch(creatingOneTimePays(oneTimePay.employeeID))
    return postOneTimePay(oneTimePay)
      .then((res) => {
        dispatch(createdOneTimePay(oneTimePay.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingOneTimePays(oneTimePay.employeeID, e))
        }
      })
  }
}

export function updateOneTimePay(oneTimePay: OneTimePayMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay | void> => {
    dispatch(updatingOneTimePay(oneTimePay.employeeID, oneTimePay.id))
    return putOneTimePay(oneTimePay)
      .then((res) => {
        dispatch(updatedOneTimePay(res.data.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingOneTimePay(oneTimePay.employeeID, oneTimePay.id, e))
        }
      })
  }
}

export function deleteOneTimePay(oneTimePayID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingOneTimePay(oneTimePayID))
    return delOneTimePay(oneTimePayID)
      .then(() => {
        dispatch(deletedOneTimePay(oneTimePayID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingOneTimePay(oneTimePayID, e))
        }
      })
  }
}
