import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Employee from '../../model/employee'
import EmployeeEmergencyContact from '../../model/employeeEmergencyContact'
import { DateFormat, Language } from '../../model/types'
import { EmployeeReducer } from '../../reducers/employee'
import CompanySetting from '../../types/company-setting'
import CountryCode from '../../types/country-code'
import Gender from '../../types/gender'
import TransferDestinationType from '../../types/transfer-destination-type'
import { getLanguage } from '../../utils/cookie-utils'
import { hasBirthDate, hasCountry, hasGender, hasNationalID } from '../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatCountryCode, formatGender } from '../../utils/format-utils'
import { url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import { getCityFromPostalCode, getPhonePrefixes, normalizeNationalID, trimSpaces } from '../../utils/validation-utils'
import Checkbox from '../antd/checkbox'
import Form from '../antd/form'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Switch from '../antd/switch'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  companyFeatures: List<CompanyFeature>
  company: Company
  employeeReducer: EmployeeReducer
  employee: Employee
  employeeEmergencyContact?: EmployeeEmergencyContact
}

type Fields = {
  nationalID: string
  name: string
  address: string
  postalCode: string
  city: string
  country?: string
  birthDate?: DateFormat
  gender?: Gender
  phoneNumberCountryCode?: string
  phoneNumber?: string
  paySlipTransportNationalInbox: boolean
  paySlipTransportNationalInboxSelected: 'EBoks' | 'MitDK' | 'None'
  paySlipTransportEMail: boolean
  paySlipTransportSMS: boolean
  email?: string
  language: Language
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  emergencyContactName?: string
  emergencyContactRelation?: string
  emergencyContactPhoneNumberCountryCode?: string
  emergencyContactPhoneNumber?: string
  emergencyContactEmail?: string
}

export type ProfileResult = {
  nationalID: string
  name: string
  address: string
  postalCode: string
  city: string
  country?: string
  birthDate?: DateFormat
  gender?: Gender
  phoneNumberCountryCode?: string
  phoneNumber?: string
  paySlipTransportEBoks: boolean
  paySlipTransportMitDK: boolean
  paySlipTransportEMail: boolean
  paySlipTransportSMS: boolean
  email?: string
  language: Language
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  emergencyContactName?: string
  emergencyContactRelation?: string
  emergencyContactPhoneNumberCountryCode?: string
  emergencyContactPhoneNumber?: string
  emergencyContactEmail?: string
  transferDestinationType?: TransferDestinationType
}

function ProfileForm(props: Props & FormComponentProps<Fields, ProfileResult>): ReactElement | null {
  const [includeHolidays, setIncludeHolidays] = useState(false)

  const employee = props.employee

  const isFinal = employee.onboardingState === 'Final'
  const allowUpdateBanking =
    props.company.settingsEnabled.some((setting) => setting === CompanySetting.ALLOW_APP_EMPLOYEE_UPDATE_BANKING) ||
    !isFinal

  const hasEmployeeCalendarFeature = props.companyFeatures.some(
    (feature) => feature.featureType === 'Employee Calendar'
  )
  const hasEmployeeEmergencyContactsFeature = props.companyFeatures.some(
    (feature) => feature.featureType === 'Employee Emergency Contacts'
  )
  const allowsEBoks = props.company.settingsEnabled.some(
    (setting) => setting === CompanySetting.PAY_SLIP_TRANSPORT_DEFAULT_EBOKS
  )
  const allowsMitDK = props.company.settingsEnabled.some(
    (setting) => setting === CompanySetting.PAY_SLIP_TRANSPORT_DEFAULT_MITDK
  )
  let showDigitalPost = allowsEBoks || allowsMitDK

  const { decorateField, getFieldValue, getFieldError } = props

  let currentDigitalPostVersion
  switch (getFieldValue('paySlipTransportNationalInboxSelected')) {
    case 'MitDK':
      showDigitalPost = true
      currentDigitalPostVersion = 'mit.dk'
      break
    case 'EBoks':
      showDigitalPost = true
      currentDigitalPostVersion = 'e-Boks'
      break
    default:
      currentDigitalPostVersion = allowsEBoks ? 'e-Boks' : 'mit.dk'
      break
  }

  return (
    <div>
      {props.getFormError()}
      {hasNationalID(employee.nationalIDType) &&
        decorateField('nationalID', {
          placeholder: t('profile.cpr_number'),
          validate: (val) => {
            if (!val) {
              return t('validation.cpr_number_is_required')
            }
            if (!val.match(/^([0-9]{10}|[0-9-]{11})$/) || val === '000000-0000') {
              return t('validation.cpr_number_is_invalid')
            }
            return null
          },
        })(<Input tabIndex={1} disabled={isFinal} />)}
      {decorateField('name', {
        placeholder: t('common.name'),
        validate: (val) => (!val ? t('validation.name_is_required') : null),
      })(<Input tabIndex={2} />)}
      {decorateField('address', {
        placeholder: t('common.address'),
        validate: (val) => (!val ? t('validation.address_is_required') : null),
      })(<Input tabIndex={3} />)}
      <Row style={{ marginBottom: '-10px' }}>
        <Col span={8}>
          {decorateField('postalCode', {
            placeholder: t('common.post_code'),
            validate: (val) => {
              if (!val) {
                return t('validation.post_code_is_required')
              }
              if (isFinal && !hasCountry(employee.nationalIDType) && !getCityFromPostalCode(val)) {
                return t('validation.post_code_is_invalid')
              }
              return null
            },
          })(<Input tabIndex={4} />)}
        </Col>
        <Col span={16}>
          {decorateField('city', {
            placeholder: t('common.city'),
            validate: (val) => (!val ? t('validation.city_is_required') : null),
          })(<Input tabIndex={5} />)}
        </Col>
      </Row>
      {hasCountry(employee.nationalIDType) &&
        decorateField('country', {
          title: t('common.country'),
          placeholder: t('common.select_country'),
          validate: (val) => {
            if (!val) {
              return t('validation.country_must_be_chosen')
            }
            if (val === CountryCode.GB) {
              return t('validation.country_cannot_be_gb')
            }
            return null
          },
        })(
          <Select
            tabIndex={6}
            dropdownMatchSelectWidth={false}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }}
          >
            {Object.values(CountryCode)
              .filter((code) => code !== CountryCode.DK)
              .sort((a, b) => formatCountryCode(a).localeCompare(formatCountryCode(b), getLanguage()))
              .map((code) => {
                return (
                  <Select.Option key={code} value={code}>
                    {formatCountryCode(code)}
                  </Select.Option>
                )
              })}
          </Select>
        )}
      {hasBirthDate(employee.nationalIDType) &&
        decorateField('birthDate', {
          placeholder: t('common.birth_date'),
          validate: (val) => {
            if (!val) {
              return t('validation.birth_date_is_required')
            }
            if (!val.match(/^([0-9]{4}-[0-9]{2}-[0-9]{2})$/)) {
              return t('validation.birth_date_is_invalid')
            }
            return null
          },
        })(<Input tabIndex={7} />)}
      {hasGender(employee.nationalIDType) &&
        decorateField('gender', {
          title: t('common.gender'),
          placeholder: t('common.select_gender'),
          validate: (val) => (!val ? t('validation.gender_is_required') : null),
        })(
          <Select tabIndex={8} dropdownMatchSelectWidth={false}>
            <Select.Option value={Gender.MALE}>{formatGender(Gender.MALE)}</Select.Option>
            <Select.Option value={Gender.FEMALE}>{formatGender(Gender.FEMALE)}</Select.Option>
            <Select.Option value={Gender.UNKNOWN}>{formatGender(Gender.UNKNOWN)}</Select.Option>
          </Select>
        )}
      <Form.Item
        validateStatus={getFieldError('phoneNumberCountryCode') || getFieldError('phoneNumber') ? 'error' : 'success'}
      >
        <label htmlFor="phoneNumber" title={t('common.phone_number')}>
          {getFieldError('phoneNumberCountryCode') || getFieldError('phoneNumber') || t('common.phone_number')}
        </label>
        <Input.Group compact className="country-code-phone-group">
          {decorateField('phoneNumberCountryCode', {
            placeholder: t('common.country_code'),
            validate: (val) => {
              if (!val) {
                return t('validation.country_code_is_required')
              }
              if (getPhonePrefixes().indexOf(val) === -1) {
                return t('validation.country_code_is_invalid')
              }
              return null
            },
            skipWrapper: true,
            skipLabel: true,
          })(
            <Select tabIndex={9} dropdownMatchSelectWidth={false}>
              {getPhonePrefixes().map((prefix) => {
                return (
                  <Select.Option key={prefix} value={prefix}>
                    +{prefix}
                  </Select.Option>
                )
              })}
            </Select>
          )}
          {decorateField('phoneNumber', {
            placeholder: t('common.phone_number'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.match(/^[0-9]+$/)) {
                return t('validation.phone_number_is_invalid')
              }
              if (getFieldValue('phoneNumberCountryCode') === '45' && !val.match(/^[0-9]{8}$/)) {
                return t('validation.phone_number_is_invalid')
              }
              return null
            },
            skipWrapper: true,
            skipLabel: true,
          })(<Input tabIndex={10} inputMode="numeric" pattern="[0-9]*" />)}
        </Input.Group>
      </Form.Item>
      {decorateField('email', {
        placeholder: t('common.email'),
        validate: (val) => {
          if (!val) {
            return null
          }
          if (!val.match(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,10})$/i)) {
            return t('validation.email_is_invalid')
          }
          return null
        },
      })(<Input tabIndex={11} disabled />)}
      <Row>
        <Col span={24}>
          <label>{t('profile.language')}:</label>
        </Col>
        <Col span={24}>
          <div className="ant-switch-wrapper">
            {decorateField('language', {
              skipWrapper: true,
              skipLabel: true,
            })(
              <RadioGroup>
                <Radio value={'da'}>{t('profile.language_da')}</Radio>
                <Radio value={'en'}>{t('profile.language_en')}</Radio>
              </RadioGroup>
            )}
          </div>
        </Col>
      </Row>

      <Subtitle style={{ marginTop: 30, marginBottom: 10 }}>{t('common.bank_information')}</Subtitle>
      <Row>
        <Col span={8}>
          {decorateField('bankRegistrationNumber', {
            placeholder: t('common.reg_number'),
            validate: (val) => {
              if (!val) {
                return null
              }
              val = val.toString()
              if (val && !val.match(/^[0-9]{4}$/)) {
                return t('validation.reg_number_is_invalid')
              }
              return null
            },
          })(<Input tabIndex={12} inputMode="numeric" pattern="[0-9]*" disabled={!allowUpdateBanking} />)}
        </Col>
        <Col span={16}>
          {decorateField('bankAccountNumber', {
            placeholder: t('common.account_number'),
            validate: (val) => {
              if (!val) {
                return null
              }
              val = val.toString()
              if (!val.match(/^[0-9]{4,10}$/)) {
                return t('validation.account_number_is_invalid')
              }
              return null
            },
          })(<Input tabIndex={13} inputMode="numeric" pattern="[0-9]*" disabled={!allowUpdateBanking} />)}
        </Col>
      </Row>

      {hasEmployeeEmergencyContactsFeature && (
        <div>
          <Subtitle style={{ marginTop: 30, marginBottom: 10 }}>{t('profile.next_of_kin')}</Subtitle>
          {decorateField('emergencyContactName', {
            placeholder: t('common.name'),
          })(<Input tabIndex={14} />)}
          {decorateField('emergencyContactRelation', {
            placeholder: t('profile.relation'),
          })(<Input tabIndex={15} />)}
          <Form.Item
            validateStatus={
              getFieldError('emergencyContactPhoneNumberCountryCode') || getFieldError('emergencyContactPhoneNumber')
                ? 'error'
                : 'success'
            }
          >
            <label htmlFor="emergencyContactPhoneNumber" title={t('common.phone_number')}>
              {getFieldError('emergencyContactPhoneNumberCountryCode') ||
                getFieldError('emergencyContactPhoneNumber') ||
                t('common.phone_number')}
            </label>
            <Input.Group compact className="country-code-phone-group">
              {decorateField('emergencyContactPhoneNumberCountryCode', {
                placeholder: t('common.country_code'),
                validate: (val) => {
                  if (!val) {
                    return t('validation.country_code_is_required')
                  }
                  if (getPhonePrefixes().indexOf(val) === -1) {
                    return t('validation.country_code_is_invalid')
                  }
                  return null
                },
                skipWrapper: true,
                skipLabel: true,
              })(
                <Select tabIndex={9} dropdownMatchSelectWidth={false}>
                  {getPhonePrefixes().map((prefix) => {
                    return (
                      <Select.Option key={prefix} value={prefix}>
                        +{prefix}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
              {decorateField('emergencyContactPhoneNumber', {
                placeholder: t('common.phone_number'),
                validate: (val) => {
                  if (!val) {
                    return null
                  }
                  if (!val.match(/^[0-9]+$/)) {
                    return t('validation.phone_number_is_invalid')
                  }
                  if (getFieldValue('emergencyContactPhoneNumberCountryCode') === '45' && !val.match(/^[0-9]{8}$/)) {
                    return t('validation.phone_number_is_invalid')
                  }
                  return null
                },
                skipWrapper: true,
                skipLabel: true,
              })(<Input tabIndex={16} inputMode="numeric" pattern="[0-9]*" />)}
            </Input.Group>
          </Form.Item>
          {decorateField('emergencyContactEmail', {
            placeholder: t('common.email'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.match(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,10})$/i)) {
                return t('validation.email_is_invalid')
              }
              return null
            },
          })(<Input tabIndex={17} />)}
        </div>
      )}

      <Subtitle style={{ marginTop: 30, marginBottom: 10 }}>{t('profile.notifications')}</Subtitle>
      {hasEmployeeCalendarFeature && (
        <Form.Item>
          <label>{t('profile.link_to_subscribe_to_leave_calendar')}:</label>
          <Input
            value={url('/v2/employeeICalendar/' + employee.id, {
              key: employee.calendarFeedKey,
              includeHolidays: includeHolidays,
            })}
            readOnly
          />
          <Checkbox onChange={(e: any) => setIncludeHolidays(e.target.checked)} style={{ marginTop: '5px' }}>
            {t('profile.calendar_include_holidays')}
          </Checkbox>
        </Form.Item>
      )}
      <Row>
        <Col span={24}>
          <label>{t('profile.payslips_are_sent_to')}:</label>
        </Col>
        {showDigitalPost && (
          <Col span={24}>
            <div className="ant-switch-wrapper">
              {decorateField('paySlipTransportNationalInbox', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">
                {t('profile.sent_to_digital_post', { version: currentDigitalPostVersion })}
              </span>
            </div>
          </Col>
        )}
        {!!getFieldValue('email') && (
          <Col span={24}>
            <div className="ant-switch-wrapper">
              {decorateField('paySlipTransportEMail', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('profile.sent_by_email')}</span>
            </div>
          </Col>
        )}
        {!!getFieldValue('phoneNumber') && (
          <Col span={24}>
            <div className="ant-switch-wrapper">
              {decorateField('paySlipTransportSMS', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('profile.sent_by_sms')}</span>
            </div>
          </Col>
        )}
      </Row>
      <Form.Item>
        {props.getFormError()}
        <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={30}>
          {t('common.save_changes')}
        </Button>
      </Form.Item>
      {props.employeeReducer.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ProfileResult>({
  mapPropsToFields: (props) => {
    const employee = props.employee
    const fields: Fields = {
      nationalID: normalizeNationalID(employee.nationalID),
      name: employee.name,
      address: employee.address,
      postalCode: employee.postalCode,
      city: employee.city,
      country: employee.country,
      birthDate: employee.birthDate,
      gender: employee.gender,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      phoneNumberCountryCode:
        !employee.phoneNumberCountryCode || employee.phoneNumberCountryCode === ''
          ? '45'
          : employee.phoneNumberCountryCode,
      bankRegistrationNumber: employee.bankRegistrationNumber,
      bankAccountNumber: employee.bankAccountNumber,
      emergencyContactPhoneNumberCountryCode: '45',
      paySlipTransportNationalInbox: employee.paySlipTransportEBoks || employee.paySlipTransportMitDK,
      paySlipTransportNationalInboxSelected: employee.paySlipTransportEBoks
        ? 'EBoks'
        : employee.paySlipTransportMitDK
        ? 'MitDK'
        : 'None',
      paySlipTransportEMail: employee.paySlipTransportEMail,
      paySlipTransportSMS: employee.paySlipTransportSMS,
      language: getLanguage(),
    }
    if (props.employeeEmergencyContact) {
      const emergencyContact = props.employeeEmergencyContact
      fields.emergencyContactName = emergencyContact.name
      fields.emergencyContactEmail = emergencyContact.email
      fields.emergencyContactPhoneNumberCountryCode = emergencyContact.phoneNumberCountryCode
      fields.emergencyContactPhoneNumber = emergencyContact.phoneNumber
      fields.emergencyContactRelation = emergencyContact.relation
    }
    return fields
  },
  onChange: (key, val) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'nationalID':
        values.nationalID = normalizeNationalID(val as string)
        break
      case 'postalCode': {
        values[key] = val
        const city = getCityFromPostalCode(val as string)
        if (city) {
          values.city = city
        }
        break
      }
      case 'phoneNumber': {
        const str = trimSpaces(val as string)
        if (!str) {
          values.paySlipTransportSMS = false
        }
        values.phoneNumber = str
        break
      }
      case 'emergencyContactPhoneNumber':
        values.emergencyContactPhoneNumber = trimSpaces(val as string)
        break
      case 'email':
        if (!val) {
          values['paySlipTransportEMail'] = false
        }
        values[key] = val
        break
      case 'paySlipTransportNationalInbox':
        if (!val) {
          values['paySlipTransportNationalInboxSelected'] = 'None'
        }
        values[key] = val
        break
      default:
        values[key] = val
        break
    }
    return values
  },
  onSubmit: (values, props) => {
    const allowsEBoks = props.company.settingsEnabled.some(
      (setting) => setting === CompanySetting.PAY_SLIP_TRANSPORT_DEFAULT_EBOKS
    )
    const allowsMitDK = props.company.settingsEnabled.some(
      (setting) => setting === CompanySetting.PAY_SLIP_TRANSPORT_DEFAULT_MITDK
    )
    const result: ProfileResult = {
      nationalID: values.nationalID.replace(/-/g, ''),
      name: values.name,
      address: values.address,
      postalCode: values.postalCode,
      city: values.city,
      country: values.country,
      birthDate: values.birthDate,
      gender: values.gender,
      phoneNumberCountryCode: values.phoneNumberCountryCode,
      phoneNumber: values.phoneNumber,
      paySlipTransportEBoks: values.paySlipTransportNationalInbox && allowsEBoks,
      paySlipTransportMitDK: values.paySlipTransportNationalInbox && allowsMitDK,
      paySlipTransportEMail: values.paySlipTransportEMail,
      paySlipTransportSMS: values.paySlipTransportSMS,
      email: values.email,
      language: values.language,
      bankRegistrationNumber: values.bankRegistrationNumber || undefined,
      bankAccountNumber: values.bankAccountNumber || undefined,
      emergencyContactName: values.emergencyContactName,
      emergencyContactRelation: values.emergencyContactRelation,
      emergencyContactPhoneNumberCountryCode: values.emergencyContactPhoneNumberCountryCode,
      emergencyContactPhoneNumber: values.emergencyContactPhoneNumber,
      emergencyContactEmail: values.emergencyContactEmail,
    }
    if (!result.bankRegistrationNumber || !result.bankAccountNumber) {
      result.transferDestinationType = TransferDestinationType.NONE
    }
    return result
  },
})(ProfileForm)
