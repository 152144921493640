import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Department from '../model/department'
import { ReducerAction } from '../utils/reducer-utils'

export interface DepartmentReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  departments: List<Department>
  error: Error | null
}

const Params = Record<DepartmentReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  departments: List<Department>(),
  error: null,
})

export interface DepartmentAction extends ReducerAction {
  departments?: Department[]
}

const comparator = (a: Department, b: Department) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DepartmentReducer> = Params(),
  action: DepartmentAction = { type: '' }
): Record<DepartmentReducer> => {
  switch (action.type) {
    case ActionTypes.DEPARTMENT_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.DEPARTMENT_LOADED:
    case ActionTypes.DEPARTMENT_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.DEPARTMENT_LOADED_PARTIAL,
        departments: state.get('departments').concat(List(action.departments)).sort(comparator),
      })
    case ActionTypes.DEPARTMENT_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
