import PropTypes from 'prop-types'
import React from 'react'

import Radio from './radio'

class RadioButton extends React.Component {
  render() {
    const radioProps = { ...this.props }
    if (this.context.radioGroup) {
      radioProps.onChange = this.context.radioGroup.onChange
      radioProps.checked = this.props.value === this.context.radioGroup.value
      radioProps.disabled = this.props.disabled || this.context.radioGroup.disabled
    }

    return <Radio {...radioProps} />
  }
}

RadioButton.defaultProps = {
  prefixCls: 'ant-radio-button',
}

RadioButton.contextTypes = {
  radioGroup: PropTypes.any,
}

export default RadioButton
