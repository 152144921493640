import { DateTimeFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

interface Clock {
  now: DateTimeFormat
}

export function getClock(): Promise<RequestResponse<Clock>> {
  return secureRequest('GET', url('/v2/clock'))
}
