import Contract from '../model/contract'
import Employee from '../model/employee'
import LeaveType from '../model/leaveType'
import SalaryType from '../model/salaryType'
import AffiliationType from '../types/affiliation-type'
import EmployeePayType from '../types/employee-pay-type'
import EmploymentType from '../types/employment-type'
import NationalIDType from '../types/national-id-type'
import RemunerationType from '../types/remuneration-type'
import SalaryClass from '../types/salary-class'

export function hasNationalID(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case NationalIDType.DK_CPR:
    case NationalIDType.DK_CVR:
    case NationalIDType.DK_FOREIGN:
      return true
    default:
      return false
  }
}
export function hasCountry(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case NationalIDType.DK_CPR:
    case NationalIDType.DK_CVR:
      return false
    default:
      return true
  }
}
export function hasBirthDate(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case NationalIDType.DK_CPR:
    case NationalIDType.DK_CVR:
      return false
    default:
      return true
  }
}
export function hasGender(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case NationalIDType.DK_CPR:
    case NationalIDType.DK_CVR:
      return false
    default:
      return true
  }
}

export function isHourlyPaid(employee: Employee): boolean {
  return !!employee.activeContract && employee.activeContract.remunerationType === RemunerationType.HOURLY
}

export function isSalaried(employee: Employee): boolean {
  return !!employee.activeContract && employee.activeContract.remunerationType === RemunerationType.SALARIED
}

export function hasDetailedCarAllowance(employee: Employee): boolean {
  return !!employee.activeContract && employee.activeContract.carAllowanceRegistrationMethodType === 'Detailed'
}

export function hasFlex(employee: Employee, leaveTypes: LeaveType[]): boolean {
  return (
    !!employee.activeContract &&
    employee.activeContract.remuneration.leave.some((leave) =>
      leaveTypes.some((type) => type.id === leave.typeID && type.name === 'DenmarkFlexTime')
    )
  )
}

export function hasOvertime(employee: Employee, leaveTypes: LeaveType[]): boolean {
  return (
    !!employee.activeContract &&
    employee.activeContract.remuneration.leave.some((leave) =>
      leaveTypes.some((type) => type.id === leave.typeID && type.name === 'DenmarkOvertime')
    )
  )
}

export function hasSalaryRegistration(employee: Employee, salaryTypes: SalaryType[]): boolean {
  return !!employee?.activeContract?.remuneration.salary.some((salary) =>
    salaryTypes.some((type) => type.id === salary.salaryTypeID && type.class === SalaryClass.SUPPLEMENT_VARIED)
  )
}

function getEmployeePayType(contract: Contract): EmployeePayType {
  let payType = EmployeePayType.SALARIED
  if (!contract) {
    payType = EmployeePayType.COMMISSIONED
  } else {
    switch (contract.remunerationType) {
      case RemunerationType.HOURLY:
        payType = EmployeePayType.HOURLY_PAID
        break
      case RemunerationType.SALARIED:
        payType = EmployeePayType.SALARIED
        break
      case RemunerationType.COMMISSIONED:
      default:
        payType = EmployeePayType.COMMISSIONED
        break
    }
  }
  return payType
}

export function isEmployeeType(employee: Employee, employmentType: EmploymentType): boolean {
  if (employee.affiliationType === AffiliationType.FREELANCER) {
    // if freelancer, then it is either that or none of them
    return employmentType === EmploymentType.FREELANCER
  }
  const contract = employee.activeContract
  if (!contract) {
    return false
  }

  const payType = getEmployeePayType(contract)
  switch (employmentType) {
    case EmploymentType.FULL_TIME:
      return payType === EmployeePayType.SALARIED && contract.weeklyHours > 30
    case EmploymentType.PART_TIME:
      return payType === EmployeePayType.SALARIED && contract.weeklyHours <= 30
    case EmploymentType.FIXED:
      return payType === EmployeePayType.SALARIED
    case EmploymentType.HOURLY:
      return payType === EmployeePayType.HOURLY_PAID
    case EmploymentType.COMMISSIONED:
      return payType === EmployeePayType.COMMISSIONED
    default:
      return false
  }
}
