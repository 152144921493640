export default {
  CHANGE_ACTIVE_COMPANY: 'CHANGE_ACTIVE_COMPANY',
  ALERT_ADDED: 'ALERT_ADDED',
  ALERT_REMOVED: 'ALERT_REMOVED',
  CAR_ALLOWANCES_LOADING: 'CAR_ALLOWANCES_LOADING',
  CAR_ALLOWANCES_LOADED: 'CAR_ALLOWANCES_LOADED',
  CAR_ALLOWANCES_LOADED_PARTIAL: 'CAR_ALLOWANCES_LOADED_PARTIAL',
  CAR_ALLOWANCES_LOAD_FAILED: 'CAR_ALLOWANCES_LOAD_FAILED',
  CAR_ALLOWANCES_CREATING: 'CAR_ALLOWANCES_CREATING',
  CAR_ALLOWANCES_CREATED: 'CAR_ALLOWANCES_CREATED',
  CAR_ALLOWANCES_CREATE_FAILED: 'CAR_ALLOWANCES_CREATE_FAILED',
  CAR_ALLOWANCE_APPROVING: 'CAR_ALLOWANCE_APPROVING',
  CAR_ALLOWANCE_APPROVED: 'CAR_ALLOWANCE_APPROVED',
  CAR_ALLOWANCE_APPROVE_FAILED: 'CAR_ALLOWANCE_APPROVE_FAILED',
  CAR_ALLOWANCE_UPDATING: 'CAR_ALLOWANCE_UPDATING',
  CAR_ALLOWANCE_UPDATED: 'CAR_ALLOWANCE_UPDATED',
  CAR_ALLOWANCE_UPDATE_FAILED: 'CAR_ALLOWANCE_UPDATE_FAILED',
  CAR_ALLOWANCE_DELETING: 'CAR_ALLOWANCE_DELETING',
  CAR_ALLOWANCE_DELETED: 'CAR_ALLOWANCE_DELETED',
  CAR_ALLOWANCE_DELETE_FAILED: 'CAR_ALLOWANCE_DELETE_FAILED',
  COMPANY_LOADING: 'COMPANY_LOADING',
  COMPANY_LOADED: 'COMPANY_LOADED',
  COMPANY_LOAD_FAILED: 'COMPANY_LOAD_FAILED',
  COMPANY_FEATURES_LOADING: 'COMPANY_FEATURES_LOADING',
  COMPANY_FEATURES_LOADED: 'COMPANY_FEATURES_LOADED',
  COMPANY_FEATURES_LOAD_FAILED: 'COMPANY_FEATURES_LOAD_FAILED',
  DOCUMENT_LOADING: 'DOCUMENT_LOADING',
  DOCUMENT_LOADED: 'DOCUMENT_LOADED',
  DOCUMENT_LOADED_PARTIAL: 'DOCUMENT_LOADED_PARTIAL',
  DOCUMENT_LOAD_FAILED: 'DOCUMENT_LOAD_FAILED',
  DOCUMENT_ADDING: 'DOCUMENT_ADDING',
  DOCUMENT_ADDED: 'DOCUMENT_ADDED',
  DOCUMENT_ADD_FAILED: 'DOCUMENT_ADD_FAILED',
  DOCUMENT_UPDATING: 'DOCUMENT_UPDATING',
  DOCUMENT_UPDATED: 'DOCUMENT_UPDATED',
  DOCUMENT_UPDATE_FAILED: 'DOCUMENT_UPDATE_FAILED',
  DOCUMENT_DELETING: 'DOCUMENT_DELETING',
  DOCUMENT_DELETED: 'DOCUMENT_DELETED',
  DOCUMENT_DELETE_FAILED: 'DOCUMENT_DELETE_FAILED',
  DOCUMENT_CATEGORY_LOADING: 'DOCUMENT_CATEGORY_LOADING',
  DOCUMENT_CATEGORY_LOADED: 'DOCUMENT_CATEGORY_LOADED',
  DOCUMENT_CATEGORY_LOADED_PARTIAL: 'DOCUMENT_CATEGORY_LOADED_PARTIAL',
  DOCUMENT_CATEGORY_LOAD_FAILED: 'DOCUMENT_CATEGORY_LOAD_FAILED',
  EMPLOYEE_LOADING: 'EMPLOYEE_LOADING',
  EMPLOYEE_LOADED: 'EMPLOYEE_LOADED',
  EMPLOYEE_LOAD_FAILED: 'EMPLOYEE_LOAD_FAILED',
  EMPLOYEE_SELECTED: 'EMPLOYEE_SELECTED',
  EMPLOYEE_UPDATING: 'EMPLOYEE_UPDATING',
  EMPLOYEE_UPDATED: 'EMPLOYEE_UPDATED',
  EMPLOYEE_UPDATE_FAILED: 'EMPLOYEE_UPDATE_FAILED',
  LEAVE_BALANCES_LOADING: 'LEAVE_BALANCES_LOADING',
  LEAVE_BALANCES_LOADED: 'LEAVE_BALANCES_LOADED',
  LEAVE_BALANCES_LOAD_FAILED: 'LEAVE_BALANCES_LOAD_FAILED',
  LEAVE_BALANCE_DIRTY: 'LEAVE_BALANCE_DIRTY',
  EMPLOYEE_EMERGENCY_CONTACT_LOADING: 'EMPLOYEE_EMERGENCY_CONTACT_LOADING',
  EMPLOYEE_EMERGENCY_CONTACT_LOADED: 'EMPLOYEE_EMERGENCY_CONTACT_LOADED',
  EMPLOYEE_EMERGENCY_CONTACT_LOAD_FAILED: 'EMPLOYEE_EMERGENCY_CONTACT_LOAD_FAILED',
  EMPLOYEE_EMERGENCY_CONTACT_UPDATING: 'EMPLOYEE_EMERGENCY_CONTACT_UPDATING',
  EMPLOYEE_EMERGENCY_CONTACT_UPDATED: 'EMPLOYEE_EMERGENCY_CONTACT_UPDATED',
  EMPLOYEE_EMERGENCY_CONTACT_UPDATE_FAILED: 'EMPLOYEE_EMERGENCY_CONTACT_UPDATE_FAILED',
  EMPLOYEE_EMERGENCY_CONTACT_DELETING: 'EMPLOYEE_EMERGENCY_CONTACT_DELETING',
  EMPLOYEE_EMERGENCY_CONTACT_DELETED: 'EMPLOYEE_EMERGENCY_CONTACT_DELETED',
  EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED: 'EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED',
  EXPENSE_CATEGORY_LOADING: 'EXPENSE_CATEGORY_LOADING',
  EXPENSE_CATEGORY_LOADED: 'EXPENSE_CATEGORY_LOADED',
  EXPENSE_CATEGORY_LOADED_PARTIAL: 'EXPENSE_CATEGORY_LOADED_PARTIAL',
  EXPENSE_CATEGORY_LOAD_FAILED: 'EXPENSE_CATEGORY_LOAD_FAILED',
  COST_CENTER_LOADING: 'COST_CENTER_LOADING',
  COST_CENTER_LOADED: 'COST_CENTER_LOADED',
  COST_CENTER_LOADED_PARTIAL: 'COST_CENTER_LOADED_PARTIAL',
  COST_CENTER_LOAD_FAILED: 'COST_CENTER_LOAD_FAILED',
  DEPARTMENT_LOADING: 'DEPARTMENT_LOADING',
  DEPARTMENT_LOADED: 'DEPARTMENT_LOADED',
  DEPARTMENT_LOADED_PARTIAL: 'DEPARTMENT_LOADED_PARTIAL',
  DEPARTMENT_LOAD_FAILED: 'DEPARTMENT_LOAD_FAILED',
  LEAVE_TYPES_LOADING: 'LEAVE_TYPES_LOADING',
  LEAVE_TYPES_LOADED: 'LEAVE_TYPES_LOADED',
  LEAVE_TYPES_LOAD_FAILED: 'LEAVE_TYPES_LOAD_FAILED',
  PROJECT_LOADING: 'PROJECT_LOADING',
  PROJECT_LOADED: 'PROJECT_LOADED',
  PROJECT_LOADED_PARTIAL: 'PROJECT_LOADED_PARTIAL',
  PROJECT_LOAD_FAILED: 'PROJECT_LOAD_FAILED',
  ONE_TIME_PAYS_LOADING: 'ONE_TIME_PAYS_LOADING',
  ONE_TIME_PAYS_LOADED: 'ONE_TIME_PAYS_LOADED',
  ONE_TIME_PAYS_LOADED_PARTIAL: 'ONE_TIME_PAYS_LOADED_PARTIAL',
  ONE_TIME_PAYS_LOAD_FAILED: 'ONE_TIME_PAYS_LOAD_FAILED',
  ONE_TIME_PAYS_CREATING: 'ONE_TIME_PAYS_CREATING',
  ONE_TIME_PAYS_CREATED: 'ONE_TIME_PAYS_CREATED',
  ONE_TIME_PAYS_CREATE_FAILED: 'ONE_TIME_PAYS_CREATE_FAILED',
  ONE_TIME_PAY_APPROVING: 'ONE_TIME_PAY_APPROVING',
  ONE_TIME_PAY_APPROVED: 'ONE_TIME_PAY_APPROVED',
  ONE_TIME_PAY_APPROVE_FAILED: 'ONE_TIME_PAY_APPROVE_FAILED',
  ONE_TIME_PAY_UPDATING: 'ONE_TIME_PAY_UPDATING',
  ONE_TIME_PAY_UPDATED: 'ONE_TIME_PAY_UPDATED',
  ONE_TIME_PAY_UPDATE_FAILED: 'ONE_TIME_PAY_UPDATE_FAILED',
  ONE_TIME_PAY_DELETING: 'ONE_TIME_PAY_DELETING',
  ONE_TIME_PAY_DELETED: 'ONE_TIME_PAY_DELETED',
  ONE_TIME_PAY_DELETE_FAILED: 'ONE_TIME_PAY_DELETE_FAILED',
  PAY_SLIPS_LOADING: 'PAY_SLIPS_LOADING',
  PAY_SLIPS_LOADED: 'PAY_SLIPS_LOADED',
  PAY_SLIPS_LOADED_PARTIAL: 'PAY_SLIPS_LOADED_PARTIAL',
  PAY_SLIPS_LOAD_FAILED: 'PAY_SLIPS_LOAD_FAILED',
  PAY_SLIP_ADDED: 'PAY_SLIP_ADDED',
  PAY_SLIP_UPDATED: 'PAY_SLIP_UPDATED',
  PAY_SLIP_DELETED: 'PAY_SLIP_DELETED',
  REIMBURSEMENT_VOUCHERS_LOADING: 'REIMBURSEMENT_VOUCHERS_LOADING',
  REIMBURSEMENT_VOUCHERS_LOADED: 'REIMBURSEMENT_VOUCHERS_LOADED',
  REIMBURSEMENT_VOUCHERS_LOADED_PARTIAL: 'REIMBURSEMENT_VOUCHERS_LOADED_PARTIAL',
  REIMBURSEMENT_VOUCHERS_LOAD_FAILED: 'REIMBURSEMENT_VOUCHERS_LOAD_FAILED',
  REIMBURSEMENT_VOUCHERS_CREATING: 'REIMBURSEMENT_VOUCHERS_CREATING',
  REIMBURSEMENT_VOUCHERS_CREATED: 'REIMBURSEMENT_VOUCHERS_CREATED',
  REIMBURSEMENT_VOUCHERS_CREATE_FAILED: 'REIMBURSEMENT_VOUCHERS_CREATE_FAILED',
  REIMBURSEMENT_VOUCHERS_CREATE_CANCELLED: 'REIMBURSEMENT_VOUCHERS_CREATE_CANCELLED',
  REIMBURSEMENT_VOUCHER_APPROVING: 'REIMBURSEMENT_VOUCHER_APPROVING',
  REIMBURSEMENT_VOUCHER_APPROVED: 'REIMBURSEMENT_VOUCHER_APPROVED',
  REIMBURSEMENT_VOUCHER_APPROVE_FAILED: 'REIMBURSEMENT_VOUCHER_APPROVE_FAILED',
  REIMBURSEMENT_VOUCHER_UPDATING: 'REIMBURSEMENT_VOUCHER_UPDATING',
  REIMBURSEMENT_VOUCHER_UPDATED: 'REIMBURSEMENT_VOUCHER_UPDATED',
  REIMBURSEMENT_VOUCHER_UPDATE_FAILED: 'REIMBURSEMENT_VOUCHER_UPDATE_FAILED',
  REIMBURSEMENT_VOUCHER_DELETING: 'REIMBURSEMENT_VOUCHER_DELETING',
  REIMBURSEMENT_VOUCHER_DELETED: 'REIMBURSEMENT_VOUCHER_DELETED',
  REIMBURSEMENT_VOUCHER_DELETE_FAILED: 'REIMBURSEMENT_VOUCHER_DELETE_FAILED',
  SALARY_PERIODS_LOADING: 'SALARY_PERIODS_LOADING',
  SALARY_PERIODS_LOADED: 'SALARY_PERIODS_LOADED',
  SALARY_PERIODS_LOAD_FAILED: 'SALARY_PERIODS_LOAD_FAILED',
  SALARY_TYPES_LOADING: 'SALARY_TYPES_LOADING',
  SALARY_TYPES_LOADED: 'SALARY_TYPES_LOADED',
  SALARY_TYPES_LOAD_FAILED: 'SALARY_TYPES_LOAD_FAILED',
  SWIPE_STATUS_LOADING: 'SWIPE_STATUS_LOADING',
  SWIPE_STATUS_LOADED: 'SWIPE_STATUS_LOADED',
  SWIPE_STATUS_LOAD_FAILED: 'SWIPE_STATUS_LOAD_FAILED',
  SWIPE_LOADING: 'SWIPE_LOADING',
  SWIPE_LOADED: 'SWIPE_LOADED',
  SWIPE_LOADED_PARTIAL: 'SWIPE_LOADED_PARTIAL',
  SWIPE_LOAD_FAILED: 'SWIPE_LOAD_FAILED',
  SWIPE_ADDING: 'SWIPE_ADDING',
  SWIPE_ADDED: 'SWIPE_ADDED',
  SWIPE_ADD_FAILED: 'SWIPE_ADD_FAILED',
  SWIPE_UPDATED: 'SWIPE_UPDATED',
  SWIPE_DELETING: 'SWIPE_DELETING',
  SWIPE_DELETED: 'SWIPE_DELETED',
  SWIPE_DELETE_FAILED: 'SWIPE_DELETE_FAILED',
  TIME_REGISTRATIONS_LOADING: 'TIME_REGISTRATIONS_LOADING',
  TIME_REGISTRATIONS_LOADED: 'TIME_REGISTRATIONS_LOADED',
  TIME_REGISTRATIONS_LOADED_PARTIAL: 'TIME_REGISTRATIONS_LOADED_PARTIAL',
  TIME_REGISTRATIONS_LOAD_FAILED: 'TIME_REGISTRATIONS_LOAD_FAILED',
  TIME_REGISTRATIONS_CREATING: 'TIME_REGISTRATIONS_CREATING',
  TIME_REGISTRATIONS_CREATED: 'TIME_REGISTRATIONS_CREATED',
  TIME_REGISTRATIONS_CREATE_FAILED: 'TIME_REGISTRATIONS_CREATE_FAILED',
  TIME_REGISTRATIONS_UPDATING: 'TIME_REGISTRATIONS_UPDATING',
  TIME_REGISTRATIONS_UPDATED: 'TIME_REGISTRATIONS_UPDATED',
  TIME_REGISTRATIONS_UPDATE_FAILED: 'TIME_REGISTRATIONS_UPDATE_FAILED',
  TIME_REGISTRATIONS_DELETING: 'TIME_REGISTRATIONS_DELETING',
  TIME_REGISTRATIONS_DELETED: 'TIME_REGISTRATIONS_DELETED',
  TIME_REGISTRATIONS_DELETE_FAILED: 'TIME_REGISTRATIONS_DELETE_FAILED',
  USER_CREATING_DEVICE_TOKEN: 'USER_CREATING_DEVICE_TOKEN',
  USER_CREATED_DEVICE_TOKEN: 'USER_CREATED_DEVICE_TOKEN',
  USER_CREATE_DEVICE_TOKEN_FAILED: 'USER_CREATE_DEVICE_TOKEN_FAILED',
  USER_REGISTERING: 'USER_REGISTERING',
  USER_REGISTERED: 'USER_REGISTERED',
  USER_REGISTERING_FAILED: 'USER_REGISTERING_FAILED',
  USER_LOGGING_IN: 'USER_LOGGING_IN',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
  USER_LOGGING_OUT: 'USER_LOGGING_OUT',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_LOGOUT_FAILED: 'USER_LOGOUT_FAILED',
  USER_LOADING: 'USER_LOADING',
  USER_LOADED: 'USER_LOADED',
  USER_LOAD_FAILED: 'USER_LOAD_FAILED',
  USER_REQUESTING_PASSWORD: 'USER_REQUESTING_PASSWORD',
  USER_REQUESTED_PASSWORD: 'USER_REQUESTED_PASSWORD',
  USER_REQUEST_PASSWORD_FAILED: 'USER_REQUEST_PASSWORD_FAILED',
  USER_RESETTING_PASSWORD: 'USER_RESETTING_PASSWORD',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_RESET_PASSWORD_FAILED: 'USER_RESET_PASSWORD_FAILED',
  SALARY_REGISTRATIONS_LOADING: 'SALARY_REGISTRATIONS_LOADING',
  SALARY_REGISTRATIONS_LOADED: 'SALARY_REGISTRATIONS_LOADED',
  SALARY_REGISTRATIONS_LOADED_PARTIAL: 'SALARY_REGISTRATIONS_LOADED_PARTIAL',
  SALARY_REGISTRATIONS_LOAD_FAILED: 'SALARY_REGISTRATIONS_LOAD_FAILED',
  SALARY_REGISTRATIONS_CREATING: 'SALARY_REGISTRATIONS_CREATING',
  SALARY_REGISTRATIONS_CREATED: 'SALARY_REGISTRATIONS_CREATED',
  SALARY_REGISTRATIONS_CREATE_FAILED: 'SALARY_REGISTRATIONS_CREATE_FAILED',
  SALARY_REGISTRATIONS_UPDATING: 'SALARY_REGISTRATIONS_UPDATING',
  SALARY_REGISTRATIONS_UPDATED: 'SALARY_REGISTRATIONS_UPDATED',
  SALARY_REGISTRATIONS_UPDATE_FAILED: 'SALARY_REGISTRATIONS_UPDATE_FAILED',
  SALARY_REGISTRATIONS_DELETING: 'SALARY_REGISTRATIONS_DELETING',
  SALARY_REGISTRATIONS_DELETED: 'SALARY_REGISTRATIONS_DELETED',
  SALARY_REGISTRATIONS_DELETE_FAILED: 'SALARY_REGISTRATIONS_DELETE_FAILED',
  CONTRACTS_LOADING: 'CONTRACTS_LOADING',
  CONTRACTS_LOADED: 'CONTRACTS_LOADED',
  CONTRACTS_LOADED_PARTIAL: 'CONTRACTS_LOADED_PARTIAL',
  CONTRACTS_LOAD_FAILED: 'CONTRACTS_LOAD_FAILED',
  TIME_REGISTRATION_TEMPLATE_LOADING: 'TIME_REGISTRATION_TEMPLATE_LOADING',
  TIME_REGISTRATION_TEMPLATE_LOADED: 'TIME_REGISTRATION_TEMPLATE_LOADED',
  TIME_REGISTRATION_TEMPLATE_LOAD_FAILED: 'TIME_REGISTRATION_TEMPLATE_LOAD_FAILED',
  TIME_REGISTRATION_TEMPLATE_SAVING: 'TIME_REGISTRATION_TEMPLATE_SAVING',
  TIME_REGISTRATION_TEMPLATE_SAVED: 'TIME_REGISTRATION_TEMPLATE_SAVED',
  TIME_REGISTRATION_TEMPLATE_SAVE_FAILED: 'TIME_REGISTRATION_TEMPLATE_SAVE_FAILED',
}
