import React from 'react'

import Spin from '../antd/spin'

import './Landing.css'

export default function Landing() {
  return (
    <div className="landing">
      <Spin size="large" />
    </div>
  )
}
