import React, { ReactElement } from 'react'

import { createDeviceToken, login } from '../actions/user'
import LoginComponent from '../components/login/Login'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { ChannelMFAError, RequestResponse } from '../utils/request-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  createDeviceToken: (deviceName: string) => void
  login: (
    email: string,
    password: string,
    challengeID?: string,
    response?: string,
    recoveryCode?: string,
    trustDevice?: boolean
  ) => Promise<RequestResponse | ChannelMFAError>
}

function Login(props: Reducers & Actions): ReactElement | null {
  return <LoginComponent user={props.user} createDeviceToken={props.createDeviceToken} login={props.login} />
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    user: state.user,
  }),
  {
    createDeviceToken: createDeviceToken,
    login: login,
  }
)(Login)
