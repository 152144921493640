import { Language } from '../model/types'
import { getLanguage } from '../utils/cookie-utils'
import daAntdStrings from './da_DK.antd'
import daStrings from './da_DK.strings'
import enAntdStrings from './en_GB.antd'
import enStrings from './en_GB.strings'

type lang = {
  strings: Record<string, string>
  antd: Record<string, unknown>
}

const langs: Record<Language, lang> = {
  da: { strings: daStrings, antd: daAntdStrings },
  en: { strings: enStrings, antd: enAntdStrings },
}
export function getStrings(): Record<string, string> {
  const lang = langs[getLanguage()]
  if (!lang) {
    return daStrings
  }
  return lang.strings
}

export function getAntdStrings(): Record<string, any> {
  const lang = langs[getLanguage()]
  if (!lang) {
    return daAntdStrings
  }
  return lang.antd
}
