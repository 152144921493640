import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { SwipeStatus } from '../model/swipe'
import { ReducerAction } from '../utils/reducer-utils'

export interface SwipeStatusReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  available: number
  availableAutomatic: number
  canSwipeNow: boolean
  instantPayCost: number | null
  willChargeFee: boolean
  swipeFeeCost: number | undefined
  swipeFeeCostType: 'Fixed' | 'Percentage' | undefined
  swipeFeeCostCap: number | undefined
  swipeFeeCostFrequency: 'Each' | 'Period' | undefined
  error: Error | null
}

const Params = Record<SwipeStatusReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  available: 0,
  availableAutomatic: 0,
  canSwipeNow: false,
  instantPayCost: null,
  willChargeFee: false,
  swipeFeeCost: undefined,
  swipeFeeCostType: undefined,
  swipeFeeCostCap: undefined,
  swipeFeeCostFrequency: undefined,
  error: null,
})

export interface SwipeStatusAction extends ReducerAction {
  swipe?: SwipeStatus
}

export default (
  state: Record<SwipeStatusReducer> = Params(),
  action: SwipeStatusAction = { type: '' }
): Record<SwipeStatusReducer> => {
  switch (action.type) {
    case ActionTypes.SWIPE_STATUS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.SWIPE_STATUS_LOADED:
      if (!action.swipe) {
        return state
      }
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        available: action.swipe.available,
        availableAutomatic: action.swipe.availableAutomatic,
        canSwipeNow: action.swipe.canSwipeNow,
        instantPayCost: action.swipe.instantPayCost,
        willChargeFee: action.swipe.willChargeFee,
        swipeFeeCost: action.swipe.swipeFeeCost,
        swipeFeeCostType: action.swipe.swipeFeeCostType,
        swipeFeeCostCap: action.swipe.swipeFeeCostCap,
        swipeFeeCostFrequency: action.swipe.swipeFeeCostFrequency,
      })
    case ActionTypes.SWIPE_STATUS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error || null,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
