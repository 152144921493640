import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SalaryRegistration from '../model/salaryRegistration'
import { DateFormat } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

export interface SalaryRegistrationReducer {
  companyID: string | undefined
  employeeID: string | undefined
  fromDate: string | undefined
  toDate: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  salaryRegistrations: List<SalaryRegistration>
  error: Error | null
}

const Params = Record<SalaryRegistrationReducer>({
  companyID: undefined,
  employeeID: undefined,
  fromDate: undefined,
  toDate: undefined,
  loading: false,
  loaded: false,
  saving: false,
  salaryRegistrations: List<SalaryRegistration>(),
  error: null,
})

export interface SalaryRegistrationAction extends ReducerAction {
  employeeID?: string
  fromDate?: DateFormat
  toDate?: DateFormat
  registrationID?: string
  registrations?: SalaryRegistration[]
  registration?: SalaryRegistration
}

const comparator = (a: SalaryRegistration, b: SalaryRegistration) => {
  return b.date.localeCompare(a.date, 'da-dk')
}

export default (
  state: Record<SalaryRegistrationReducer> = Params(),
  action: SalaryRegistrationAction = { type: '' }
): Record<SalaryRegistrationReducer> => {
  const idx = state.get('salaryRegistrations').findIndex((item) => item.id === action.registrationID)

  switch (action.type) {
    case ActionTypes.SALARY_REGISTRATIONS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        loading: true,
      })
    case ActionTypes.SALARY_REGISTRATIONS_LOADED:
    case ActionTypes.SALARY_REGISTRATIONS_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        loaded: true,
        loading: action.type === ActionTypes.SALARY_REGISTRATIONS_LOADED_PARTIAL,
        salaryRegistrations: state.get('salaryRegistrations').concat(List(action.registrations)).sort(comparator),
      })
    case ActionTypes.SALARY_REGISTRATIONS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error || null,
      })

    case ActionTypes.SALARY_REGISTRATIONS_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATIONS_CREATED:
      if (!action.registration) {
        return state
      }
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('salaryRegistrations', state.get('salaryRegistrations').set(idx, action.registration).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('salaryRegistrations', state.get('salaryRegistrations').push(action.registration).sort(comparator))
    case ActionTypes.SALARY_REGISTRATIONS_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_REGISTRATIONS_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATIONS_UPDATED:
      if (!action.registration) {
        return state
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('salaryRegistrations', state.get('salaryRegistrations').set(idx, action.registration).sort(comparator))
    case ActionTypes.SALARY_REGISTRATIONS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_REGISTRATIONS_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATIONS_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('salaryRegistrations', state.get('salaryRegistrations').delete(idx))
    case ActionTypes.SALARY_REGISTRATIONS_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
