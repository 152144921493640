import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import { t } from '../../utils/translation-utils'
import Headline from '../elements/Headline'
import CardChoiceSwitcherModalForm, { CardChoiceResult } from './CardChoiceSwitcherModalForm'

type Props = {
  employee: Employee
  company: Company
  projects: List<Project>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  companyFeatures: List<CompanyFeature>

  updateEmployee: (employee: Employee) => void
  onClose: () => void
}

export default function CardChoiceSwitcherModal(props: Props): ReactElement | null {
  const handleSubmit = (values: CardChoiceResult) => {
    props.updateEmployee({ ...props.employee, ...values })
    props.onClose()
  }

  return (
    <div>
      <Headline>{t('dashboard.choice_card.switcher.title')}</Headline>
      <p>{t('dashboard.choice_card.switcher.intro')}</p>
      <CardChoiceSwitcherModalForm
        employee={props.employee}
        company={props.company}
        projects={props.projects}
        leaveTypes={props.leaveTypes}
        salaryTypes={props.salaryTypes}
        companyFeatures={props.companyFeatures}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
