import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { approveState } from '../../utils/approve-utils'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import Alert from '../elements/alert'
import { TimeRegistrationEditMode } from '../types'
import TimeRegistrationModalForm, { ResultFields } from './TimeRegistrationModalForm'

type Props = {
  visible: boolean
  locked: boolean
  mode: TimeRegistrationEditMode
  company: Company
  employee: Employee
  timeRegistrationID?: string
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: List<SalaryType>
  leaveTypes: List<LeaveType>
  costCenters: List<CostCenter>
  departments: List<Department>
  projects: List<Project>
  includeDateRange?: [Date, Date]
  suggestedDate?: Date

  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
}

export default function TimeRegistrationModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { timeRegistrations, visible } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, timeRegistrations.error, error, setError)
  }, [timeRegistrations, error, visible, setError])

  const handleSubmit = (values: ResultFields) => {
    if (props.locked) {
      return
    }
    const timeRegistration: TimeRegistrationCreationFields = {
      employeeID: props.employee.id,
      class: props.mode,
      salaryTypeID: values.salaryTypeID,
      projectID: values.projectID,
      costCenterID: values.costCenterID,
      date: values.date,
      start: values.start,
      end: values.end,
      hours: values.hours,
      note: values.note,
    }
    if (props.mode === 'Flex' || props.mode === 'Overtime') {
      const leaveType = props.leaveTypes.find(
        (type) => type.name === (props.mode === 'Flex' ? 'DenmarkFlexTime' : 'DenmarkOvertime')
      )
      if (!leaveType) {
        return
      }
      timeRegistration.leaveTypeID = leaveType.id
      timeRegistration.salaryTypeID = undefined
      timeRegistration.minutes = values.hours * 60
      timeRegistration.hours = undefined
    }
    if (props.timeRegistrationID) {
      props.updateTimeRegistration({ ...timeRegistration, id: props.timeRegistrationID })
    } else {
      props.createTimeRegistration({ ...timeRegistration, approved: approveState(props.employee) })
    }
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TimeRegistrationModalForm
        locked={props.locked}
        timeRegistrationID={props.timeRegistrationID}
        mode={props.mode}
        company={props.company}
        employee={props.employee}
        timeRegistrations={props.timeRegistrations}
        salaryTypes={props.salaryTypes}
        costCenters={props.costCenters}
        departments={props.departments}
        projects={props.projects}
        includeDateRange={props.includeDateRange}
        suggestedDate={props.suggestedDate}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
