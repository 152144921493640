import React, { ReactElement, useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getLeaveBalances } from '../actions/leave-balances'
import { getLeaveTypes } from '../actions/leave-types'
import {
  createTimeRegistration,
  createTimeRegistrationBulk,
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import LeaveRegistrationComponent from '../components/leave-registration/LeaveRegistrations'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LeaveBalance from '../model/leaveBalance'
import LeaveType from '../model/leaveType'
import TimeRegistration, {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { EmployeeReducer } from '../reducers/employee'
import { LeaveBalanceReducer } from '../reducers/leaveBalances'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { paths } from '../routes'
import CompanySetting from '../types/company-setting'
import { isHourlyPaid } from '../utils/employee-utils'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: LeaveTypeReducer
  leaveBalances: LeaveBalanceReducer
}

type Actions = {
  getTimeRegistrations: (
    employeeID: string,
    fromDate?: DateFormat,
    toDate?: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistrationBulk: (registration: TimeRegistrationBulkFields) => void
  createTimeRegistration: (registration: TimeRegistrationCreationFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (registrationID: string) => void
  getLeaveTypes: (companyID: string) => Promise<LeaveType[] | void>
  getLeaveBalances: (employeeID: string) => Promise<LeaveBalance[] | void>
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

function LeaveRegistrationTab(props: Reducers & Actions): ReactElement | null {
  const company = props.company.company
  const employee = props.employee.employee
  useEffectOnce(() => {
    if (!company || !employee) {
      return
    }
    if (
      isHourlyPaid(employee) &&
      !company.settingsEnabled.some((setting) => setting === CompanySetting.ENABLE_APP_HOURLY_LEAVE_REGISTRATION)
    ) {
      jsBrowserHistory.push('/' + paths.TIME_REGISTRATION)
    }
  })

  const { leaveBalances, getLeaveBalances } = props

  useEffect(() => {
    if (!employee) {
      return
    }
    if (leaveBalances.dirty) {
      getLeaveBalances(employee.id)
    }
  }, [leaveBalances, getLeaveBalances, employee])

  if (!company || !employee) {
    return null
  }

  return (
    <LeaveRegistrationComponent
      employee={employee}
      company={company}
      timeRegistrations={props.timeRegistrations}
      leaveTypes={props.leaveTypes.leaveTypes}
      leaveBalances={props.leaveBalances}
      getTimeRegistrations={props.getTimeRegistrations}
      createTimeRegistration={props.createTimeRegistration}
      createTimeRegistrationBulk={props.createTimeRegistrationBulk}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      getLeaveTypes={props.getLeaveTypes}
      getLeaveBalances={props.getLeaveBalances}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    employee: state.employee,
    timeRegistrations: state.timeRegistrations,
    leaveTypes: state.leaveTypes,
    leaveBalances: state.leaveBalances,
  }),
  {
    getTimeRegistrations: getTimeRegistrations,
    createTimeRegistration: createTimeRegistration,
    createTimeRegistrationBulk: createTimeRegistrationBulk,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    getLeaveTypes: getLeaveTypes,
    getLeaveBalances: getLeaveBalances,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(LeaveRegistrationTab)
