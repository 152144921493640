import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import ReimbursementVoucher, {
  ReimbursementVoucherMutableDataFields,
  ReimbursementVoucherMutableFields,
} from '../../model/reimbursementVoucher'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import CostCenterAccounting from '../../types/cost-center-accounting'
import ReimbursementVoucherApprovalState from '../../types/reimbursement-voucher-approval-state'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import Alert from '../elements/alert'
import ReimbursementVouchersModalForm, { ResultFields } from './ReimbursementVouchersModalForm'

interface Props {
  visible: boolean
  editable: boolean
  waitForScan: boolean
  immediatePayEnabled: boolean
  waitForCreation: boolean
  employee: Employee
  reimbursementVoucherID?: string
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenters: List<CostCenter>
  costCenterAccounting: CostCenterAccounting

  updateReimbursementVoucher: (voucher: ReimbursementVoucherMutableFields) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (
    voucher: ReimbursementVoucherMutableDataFields
  ) => Promise<ReimbursementVoucher | void>
  readyReimbursementVouchers: (ids: string[]) => void
  closeModal: () => void
}

export default function ReimbursementVouchersModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, reimbursementVouchers } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, reimbursementVouchers.error, error, setError)
  }, [visible, reimbursementVouchers, error, setError])

  const handleSubmit = (values: ResultFields) => {
    if (!props.editable) {
      return
    }
    if (props.waitForCreation || !props.reimbursementVoucherID) {
      return
    }
    props
      .updateReimbursementVoucherFields({
        id: props.reimbursementVoucherID,
        amount: values.amount,
        currency: values.currency,
        receiptDate: values.receiptDate,
      })
      .then((res) => {
        if (res) {
          props
            .updateReimbursementVoucher({
              id: props.reimbursementVoucherID || '',
              disburseAmount: values.disburseAmount,
              disburseable: values.disburseable,
              expenseCategoryID: values.expenseCategoryID,
              costCenterID: values.costCenterID,
              note: values.note,
              cardNote: values.cardNote,
              immediatePay: values.immediatePay,
            })
            .then((res) => {
              if (!res) {
                return
              }
              if (values.ready && res.approvalState === ReimbursementVoucherApprovalState.DRAFT) {
                props.readyReimbursementVouchers([res.id])
              } else {
                props.closeModal()
              }
            })
        }
      })
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ReimbursementVouchersModalForm
        waitForScan={props.waitForScan}
        waitForCreation={props.waitForCreation}
        immediatePayEnabled={props.immediatePayEnabled}
        reimbursementVoucherID={props.reimbursementVoucherID}
        employee={props.employee}
        expenseCategories={props.expenseCategories}
        reimbursementVouchers={props.reimbursementVouchers}
        costCenters={props.costCenters}
        costCenterAccounting={props.costCenterAccounting}
        onSubmit={handleSubmit}
        editable={props.editable}
      />
    </div>
  )
}
