import { Preferences } from '@capacitor/preferences'
import Cookies from 'js-cookie'

import { DateTimeFormat, Language } from '../model/types'
import { isWeb } from './device-utils'
import { getDefaultLanguage } from './language-utils'

const ACCESS_TOKEN = 'salaryEmployee'
const MFA_DEVICE_TOKEN = 'employeeDeviceToken'
const LANGUAGE = 'employeeLanguage'
const CLOCK = 'sallyClock'
const REMEMBER_EMAIL = 'employeeEmail'
const DEVICE_TOKEN = 'salaryDeviceToken'
const ACTIVE_EMPLOYEE = 'employeeID'

function hasCookies() {
  return isWeb()
}

const cookies: Record<string, string | null> = {}
async function loadCookies() {
  if (hasCookies()) {
    return
  }
  const keys = [ACCESS_TOKEN, MFA_DEVICE_TOKEN, LANGUAGE, CLOCK, REMEMBER_EMAIL, DEVICE_TOKEN, ACTIVE_EMPLOYEE]
  const values = await Promise.all(keys.map((key) => Preferences.get({ key })))
  keys.forEach((key, i) => {
    cookies[key] = values[i].value
  })
}
const cookiesReady = loadCookies()
export function areCookiesReady() {
  return cookiesReady
}

export function getAccessToken(): string | undefined {
  if (!hasCookies()) {
    return cookies[ACCESS_TOKEN] || undefined
  }
  return Cookies.get(ACCESS_TOKEN)
}
export function setAccessToken(accessToken: string | null, expiration?: number) {
  if (!hasCookies()) {
    cookies[ACCESS_TOKEN] = accessToken
    Preferences.set({ key: ACCESS_TOKEN, value: accessToken || '' })
    return
  }
  if (!expiration) {
    expiration = Infinity
  }
  if (!accessToken) {
    Cookies.remove(ACCESS_TOKEN)
  } else {
    Cookies.set(ACCESS_TOKEN, accessToken, { expires: expiration })
  }
}

function randomString(length: number, chars: string) {
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export function getMfaDeviceToken(): string | undefined {
  if (!hasCookies()) {
    return cookies[MFA_DEVICE_TOKEN] || undefined
  }
  return Cookies.get(MFA_DEVICE_TOKEN)
}
export function createMfaDeviceToken() {
  const mfaDeviceToken = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
  if (!hasCookies()) {
    cookies[MFA_DEVICE_TOKEN] = mfaDeviceToken
    Preferences.set({ key: MFA_DEVICE_TOKEN, value: mfaDeviceToken })
  } else {
    Cookies.set(MFA_DEVICE_TOKEN, mfaDeviceToken)
  }
  return mfaDeviceToken
}

export function getLanguage(): Language {
  let locale
  if (!hasCookies()) {
    locale = cookies[LANGUAGE]
  } else {
    locale = Cookies.get(LANGUAGE)
  }
  if (!locale) {
    locale = getDefaultLanguage()
  }
  return locale as Language
}
export function setLanguage(language: Language | null, expiration?: number) {
  if (!hasCookies()) {
    cookies[LANGUAGE] = language
    Preferences.set({ key: LANGUAGE, value: language || '' })
    return
  }
  if (!expiration) {
    expiration = Infinity
  }
  if (!language) {
    Cookies.remove(LANGUAGE)
  } else {
    Cookies.set(LANGUAGE, language, { expires: expiration })
  }
}

export function getClock(): DateTimeFormat | undefined {
  if (!hasCookies()) {
    return cookies[CLOCK] || undefined
  }
  return Cookies.get(CLOCK)
}
export function setClock(clock: DateTimeFormat | null, expiration?: number) {
  if (!hasCookies()) {
    cookies[CLOCK] = clock
    Preferences.set({ key: CLOCK, value: clock || '' })
    return
  }
  if (!expiration) {
    expiration = Infinity
  }
  if (!clock) {
    Cookies.remove(CLOCK)
  } else {
    Cookies.set(CLOCK, clock, { expires: expiration })
  }
}

export function getEmail(): string | undefined {
  if (!hasCookies()) {
    return cookies[REMEMBER_EMAIL] || undefined
  }
  return Cookies.get(REMEMBER_EMAIL)
}
export function setEmail(email: string | null, expiration?: number) {
  if (!hasCookies()) {
    cookies[REMEMBER_EMAIL] = email
    Preferences.set({ key: REMEMBER_EMAIL, value: email || '' })
    return
  }
  if (!expiration) {
    expiration = Infinity
  }
  if (!email) {
    Cookies.remove(REMEMBER_EMAIL)
  } else {
    Cookies.set(REMEMBER_EMAIL, email, { expires: expiration })
  }
}

export function getDeviceToken(): string | undefined {
  if (!hasCookies()) {
    return cookies[DEVICE_TOKEN] || undefined
  }
  return Cookies.get(DEVICE_TOKEN)
}
export function setDeviceToken(token: string | null, expiration?: number) {
  if (!hasCookies()) {
    cookies[DEVICE_TOKEN] = token
    Preferences.set({ key: DEVICE_TOKEN, value: token || '' })
    return
  }
  if (!expiration) {
    expiration = Infinity
  }
  if (!token) {
    Cookies.remove(DEVICE_TOKEN)
  } else {
    Cookies.set(DEVICE_TOKEN, token, { expires: expiration })
  }
}

export function getActiveEmployee(): string | undefined {
  return Cookies.get(ACTIVE_EMPLOYEE)
}
export function setActiveEmployee(activeEmployee: string | null, expiration?: number) {
  if (!expiration) {
    expiration = Infinity
  }
  if (!activeEmployee) {
    Cookies.remove(ACTIVE_EMPLOYEE)
  } else {
    Cookies.set(ACTIVE_EMPLOYEE, activeEmployee, { expires: expiration })
  }
}
