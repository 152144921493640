import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import { t } from '../../utils/translation-utils'

import '../dashboard/Dashboard.css'

type Props = {
  employee: Employee
}

export default function NoContractMessage(props: Props): ReactElement | null {
  if (!props.employee.activeContract) {
    return (
      <div className="dashboard">
        <div className="content-text">{t('widgets.your_information_is_awaiting_approval_from_your_employer')}</div>
      </div>
    )
  }

  return null
}
