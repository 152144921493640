import React from 'react'

import { formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'

type Props = {
  summaryHours: number
}

export default function WorkHoursSummary(props: Props) {
  return (
    <div className="work-week-summary">
      {tx('work_hours_registration_tab.week.summary', {
        summary: (
          <span>
            {t('work_hours_registration_tab.week.summary.count', {
              count: props.summaryHours,
              number: formatNumber(props.summaryHours, 2),
            })}
          </span>
        ),
      })}
    </div>
  )
}
