import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import CompanySetting from '../../types/company-setting'
import CostCenterAccounting from '../../types/cost-center-accounting'
import { isWeb } from '../../utils/device-utils'
import { decorateFieldSignature, getFieldValueSignature } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Col from './grid/col'
import Row from './grid/row'

export const NO_COST_CENTER = 'NoCostCenter'

type RegistrationCostCenterFields = {
  costCenterID?: string
}

type Props<Fields extends RegistrationCostCenterFields> = {
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>
  tabIndex: number

  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
}

export default function RegistrationCostCenter<Fields extends RegistrationCostCenterFields>(
  props: Props<Fields>
): ReactElement | null {
  type basicCostCenter = {
    id: string
    name: string
  }

  const getCostCenters = (departmentAccounting: boolean): basicCostCenter[] => {
    if (!departmentAccounting) {
      return props.costCenters
        .filter((costCenter) => costCenter.active || costCenter.id === props.getFieldValue('costCenterID'))
        .toArray()
        .map((costCenter) => ({
          id: costCenter.id,
          name: costCenter.name,
        }))
    }
    return props.departments
      .filter(
        (department) =>
          !!department.costCenterID &&
          (department.active || department.costCenterID === props.getFieldValue('costCenterID'))
      )
      .toArray()
      .map((department) => ({
        id: department.costCenterID!,
        name: department.name,
      }))
  }

  const hasCostCenters =
    props.costCenters.size > 0 &&
    props.company.settingsEnabled.some((setting) => setting === CompanySetting.DEPARTMENT_SELECT_TIME_REGISTRATION)
  const costCenterAccounting =
    hasCostCenters && props.company.costCenterAccounting === CostCenterAccounting.COST_CENTERS
  const departmentAccounting = hasCostCenters && props.company.costCenterAccounting === CostCenterAccounting.DEPARTMENTS

  if (!costCenterAccounting && !departmentAccounting) {
    return null
  }

  return (
    <Row>
      <Col span={24}>
        {props.decorateField('costCenterID', {
          placeholder: departmentAccounting ? t('common.department') : t('common.cost_center'),
        })(
          <Select
            dropdownMatchSelectWidth={false}
            showSearch={isWeb()}
            filterOption={(inputValue: string, option: ReactElement) => {
              return option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
            tabIndex={props.tabIndex}
          >
            <Select.Option key={NO_COST_CENTER} value={NO_COST_CENTER}>
              <i>{departmentAccounting ? t('common.no_department') : t('common.no_cost_center')}</i>
            </Select.Option>
            {getCostCenters(departmentAccounting)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((costCenter) => {
                return (
                  <Select.Option key={costCenter.id} value={costCenter.id}>
                    {costCenter.name}
                  </Select.Option>
                )
              })}
          </Select>
        )}
      </Col>
    </Row>
  )
}
