import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { CarAllowanceMutableFields } from '../../model/carAllowance'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { paths } from '../../routes'
import { formatDate, getDate } from '../../utils/date-utils'
import { formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import CarAllowanceModal from '../car-allowance/CarAllowanceModal'
import Button from '../elements/button'
import Headline from '../elements/Headline'

type Props = {
  employee: Employee
  carAllowances: CarAllowanceReducer
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>

  addAlert: addAlertSignature
  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
}

export default function CarAllowanceCard(props: Props): ReactElement | null {
  const [newRegistration, setNewRegistration] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const setNewVisibility = (toggle: boolean) => {
    setModalKey((prev) => prev + 1)
    setNewRegistration(toggle)
  }

  const { carAllowances, addAlert } = props
  const previousCarAllowances = usePrevious(carAllowances)

  useEffect(() => {
    if (previousCarAllowances?.saving && !carAllowances.saving) {
      if (!carAllowances.error) {
        addAlert('success', t('dashboard.card_car_allowance.alert.success'), { timeout: 5 })
        setNewVisibility(false)
      }
    }
  })

  const today = getDate()
  const registrations = carAllowances.carAllowances.filter((reg) => isSameDay(getDate(reg.date), today))
  const totalKilometres = registrations.reduce((km, reg) => {
    return km + reg.kilometers
  }, 0)
  const registrationCount = registrations.size

  return (
    <div className="card-car-allowance">
      <Headline>{t('dashboard.card_car_allowance.title')}</Headline>
      <p>
        {tx('dashboard.card_car_allowance.summary.line_1', {
          day: <strong>{formatDate(today)}</strong>,
        })}
        <br />
        {tx('dashboard.card_car_allowance.summary.line_2', {
          kilometres: (
            <strong>
              {t('dashboard.card_car_allowance.summary.km', { number: formatNumber(totalKilometres, 2) })}
            </strong>
          ),
          number: (
            <strong>
              {t('dashboard.card_car_allowance.summary.count', {
                count: registrationCount,
                number: formatNumber(registrationCount),
              })}
            </strong>
          ),
        })}
      </p>
      <Button className="dashboard-choice-register-button" type="primary" onClick={() => setNewVisibility(true)}>
        {t('dashboard.card_car_allowance.register_new')}
      </Button>
      <Link to={'/' + paths.CAR_ALLOWANCE}>
        <Button className="dashboard-choice-page-button">{t('dashboard.card_car_allowance.go_to_page')}</Button>
      </Link>

      <Modal
        key={modalKey}
        visible={newRegistration}
        onOk={() => setNewVisibility(false)}
        onCancel={() => setNewVisibility(false)}
        footer={null}
      >
        <CarAllowanceModal
          visible={newRegistration}
          locked={false}
          employee={props.employee}
          carAllowances={props.carAllowances}
          company={props.company}
          costCenters={props.costCenters}
          departments={props.departments}
          createCarAllowances={props.createCarAllowances}
          updateCarAllowance={props.updateCarAllowance}
        />
      </Modal>
    </div>
  )
}
