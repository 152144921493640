import { combineReducers } from 'redux'

import alerts, { AlertReducer } from './alerts'
import carAllowances, { CarAllowanceReducer } from './carAllowances'
import company, { CompanyReducer } from './company'
import companyFeatures, { CompanyFeatureReducer } from './companyFeatures'
import contracts, { ContractReducer } from './contracts'
import costCenters, { CostCenterReducer } from './costCenters'
import departments, { DepartmentReducer } from './departments'
import documentCategories, { DocumentCategoryReducer } from './documentCategories'
import documents, { DocumentReducer } from './documents'
import employee, { EmployeeReducer } from './employee'
import employeeEmergencyContacts, { EmployeeEmergencyContactReducer } from './employeeEmergencyContacts'
import expenseCategories, { ExpenseCategoryReducer } from './expenseCategories'
import leaveBalances, { LeaveBalanceReducer } from './leaveBalances'
import leaveTypes, { LeaveTypeReducer } from './leaveTypes'
import oneTimePays, { OneTimePayReducer } from './oneTimePays'
import paySlips, { PaySlipReducer } from './paySlips'
import projects, { ProjectReducer } from './projects'
import reimbursementVouchers, { ReimbursementVoucherReducer } from './reimbursementVouchers'
import salaryPeriods, { SalaryPeriodReducer } from './salaryPeriods'
import salaryRegistrations, { SalaryRegistrationReducer } from './salaryRegistrations'
import salaryTypes, { SalaryTypeReducer } from './salaryTypes'
import swipes, { SwipeReducer } from './swipes'
import swipeStatus, { SwipeStatusReducer } from './swipeStatus'
import timeRegistrations, { TimeRegistrationReducer } from './timeRegistrations'
import timeRegistrationTemplates, { TimeRegistrationTemplateReducer } from './timeRegistrationTemplates'
import user, { UserReducer } from './user'

export interface AllReducers {
  alerts: AlertReducer
  carAllowances: CarAllowanceReducer
  company: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  contracts: ContractReducer
  costCenters: CostCenterReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  employee: EmployeeReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  expenseCategories: ExpenseCategoryReducer
  departments: DepartmentReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: LeaveTypeReducer
  oneTimePays: OneTimePayReducer
  paySlips: PaySlipReducer
  projects: ProjectReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryPeriods: SalaryPeriodReducer
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: SalaryTypeReducer
  swipes: SwipeReducer
  swipeStatus: SwipeStatusReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  user: UserReducer
}

export default combineReducers({
  alerts,
  carAllowances,
  company,
  companyFeatures,
  documents,
  documentCategories,
  employee,
  employeeEmergencyContacts,
  expenseCategories,
  contracts,
  costCenters,
  departments,
  leaveBalances,
  leaveTypes,
  oneTimePays,
  paySlips,
  projects,
  reimbursementVouchers,
  salaryPeriods,
  salaryRegistrations,
  salaryTypes,
  swipes,
  swipeStatus,
  timeRegistrations,
  timeRegistrationTemplates,
  user,
})
