import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import PaySlip from '../../model/paySlip'
import { paths } from '../../routes'
import { formatDate, trimCurrentYear } from '../../utils/date-utils'
import { secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import DumbLink from '../elements/DumbLink'
import Subtitle from '../elements/Subtitle'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

import './Preview.css'

type Props = {
  paySlipID: string
  paySlips: List<PaySlip>
}

export default function Preview(props: Props): ReactElement | null {
  useEffectOnce(() => {
    const paySlip = props.paySlips.find((paySlip) => paySlip.id === props.paySlipID)
    if (!paySlip) {
      jsBrowserHistory.push('/' + paths.PAY_SLIPS)
    }
  })

  const handleClose = () => {
    jsBrowserHistory.goBack()
  }

  const getTitle = () => {
    const paySlip = props.paySlips.find((paySlip) => paySlip.id === props.paySlipID)
    if (!paySlip) {
      return ''
    }
    return (
      t('payslip_preview.payslip_for') +
      ' ' +
      trimCurrentYear(formatDate(paySlip.salaryPeriod.start)) +
      ' - ' +
      trimCurrentYear(formatDate(paySlip.salaryPeriod.end))
    )
  }

  return (
    <div className="preview">
      <LoadingOverlay />
      <div className="preview-container">
        <iframe title={getTitle()} src={secureUrl('/v2/paySlipsPDF/' + props.paySlipID, { disposition: 'inline' })} />
      </div>
      <div className="preview-header">
        <Subtitle>{getTitle()}</Subtitle>
      </div>
      <div className="preview-footer">
        <Link
          to={secureUrl('/v2/paySlipsPDF/' + props.paySlipID)}
          className="preview-download"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('common.save')}
        </Link>
        <DumbLink onClick={handleClose} className="preview-close">
          {t('common.close')}
        </DumbLink>
      </div>
    </div>
  )
}
