import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import OneTimePay from '../model/oneTimePay'
import { ReducerAction } from '../utils/reducer-utils'
import { RequestError } from '../utils/request-utils'

export interface OneTimePayReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  oneTimePays: List<OneTimePay>
  error: Error | null
}

const Params = Record<OneTimePayReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  oneTimePays: List(),
  error: null,
})

export interface OneTimePayAction extends ReducerAction {
  employeeID?: string
  oneTimePays?: OneTimePay[]
  oneTimePay?: OneTimePay
  oneTimePayID?: string
}

const comparator = (a: OneTimePay, b: OneTimePay) => {
  return b.dispositionDate.localeCompare(a.dispositionDate, 'da-dk')
}

export default (
  state: Record<OneTimePayReducer> = Params(),
  action: OneTimePayAction = { type: '' }
): Record<OneTimePayReducer> => {
  const idx = state.get('oneTimePays').findIndex((item) => item.id === action.oneTimePayID)

  switch (action.type) {
    case ActionTypes.ONE_TIME_PAYS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.ONE_TIME_PAYS_LOADED:
    case ActionTypes.ONE_TIME_PAYS_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        loading: action.type === ActionTypes.ONE_TIME_PAYS_LOADED_PARTIAL,
        oneTimePays: state.get('oneTimePays').concat(List(action.oneTimePays)).sort(comparator),
      })
    case ActionTypes.ONE_TIME_PAYS_LOAD_FAILED:
      if (action.error && (action.error as unknown as RequestError).message === 'Access Token Not Privileged') {
        return Params({
          companyID: action.companyID || state.get('companyID'),
          employeeID: action.employeeID,
          loaded: true,
        })
      }
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        error: action.error,
      })

    case ActionTypes.ONE_TIME_PAYS_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAYS_CREATED: {
      if (!action.oneTimePay) {
        return state
      }
      const index = state.get('oneTimePays').findIndex((item) => item.id === action.oneTimePay?.id)
      if (index !== -1) {
        return state
          .set('saving', false)
          .set('oneTimePays', state.get('oneTimePays').set(index, action.oneTimePay).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('oneTimePays', state.get('oneTimePays').push(action.oneTimePay).sort(comparator))
    }
    case ActionTypes.ONE_TIME_PAYS_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_UPDATED:
      if (!action.oneTimePay) {
        return state
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('oneTimePays', state.get('oneTimePays').set(idx, action.oneTimePay).sort(comparator))
    case ActionTypes.ONE_TIME_PAY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('oneTimePays', state.get('oneTimePays').delete(idx))
    case ActionTypes.ONE_TIME_PAY_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
