import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Company from '../model/company'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  company: Company | undefined
  error: Error | null
}

const Params = Record<CompanyReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  company: undefined,
  error: null,
})

export interface CompanyAction extends ReducerAction {
  company?: Company
}

export default (
  state: Record<CompanyReducer> = Params(),
  action: CompanyAction = { type: '' }
): Record<CompanyReducer> => {
  switch (action.type) {
    case ActionTypes.COMPANY_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.COMPANY_LOADED:
      return Params({
        loaded: true,
        companyID: action.company?.id,
        company: action.company,
      })
    case ActionTypes.COMPANY_LOAD_FAILED:
      return Params({
        error: action.error || null,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
