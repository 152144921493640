import React from 'react'

import { fetchLeaveBalances } from '../api/leave-balances'
import ActionTypes from '../constants/action-types'
import LeaveBalance from '../model/leaveBalance'
import { LeaveBalanceAction } from '../reducers/leaveBalances'
import { isRequestError } from '../utils/error-utils'

function loadingLeaveBalances(employeeID: string): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCES_LOADING,
    employeeID,
  }
}
function loadedLeaveBalances(employeeID: string, leaveBalances: LeaveBalance[]): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCES_LOADED,
    employeeID,
    leaveBalances,
  }
}
function failedLoadingLeaveBalances(employeeID: string, error: Error): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCES_LOAD_FAILED,
    error,
    employeeID,
  }
}

export function markLeaveBalanceDirty(employeeID: string): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCE_DIRTY,
    employeeID,
  }
}

export function getLeaveBalances(employeeID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loadingLeaveBalances(employeeID))
    return fetchLeaveBalances(employeeID)
      .then((res) => {
        dispatch(loadedLeaveBalances(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingLeaveBalances(employeeID, e))
        }
      })
  }
}
