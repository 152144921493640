import React, { ReactElement } from 'react'

import { resetPassword } from '../actions/user'
import ResetPasswordComponent from '../components/reset-password/ResetPassword'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  resetPassword: (token: string, password: string) => Promise<boolean | void>
}

function ResetPassword(props: Reducers & Actions & RouteProps): ReactElement | null {
  if (!props.params.splat) {
    jsBrowserHistory.push('/')
    return null
  }
  return <ResetPasswordComponent token={props.params.splat} user={props.user} resetPassword={props.resetPassword} />
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    resetPassword: resetPassword,
  }
)(ResetPassword)
