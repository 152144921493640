import { Browser } from '@capacitor/browser'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import Company from '../../model/company'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import DocumentCategory from '../../model/documentCategory'
import Employee from '../../model/employee'
import { DocumentReducer } from '../../reducers/documents'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import CompanySetting from '../../types/company-setting'
import { FileChangeEvent, HandleFileEventError } from '../../utils/antd-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { formatDate } from '../../utils/date-utils'
import { isWeb } from '../../utils/device-utils'
import { formatError } from '../../utils/error-utils'
import { RequestError, secureUrl, url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import UploadDragger from '../antd/upload/Dragger'
import Alert from '../elements/alert'
import Button from '../elements/button'
import LoadingOverlay from '../widgets/LoadingOverlay'
import PullToRefresh from '../widgets/PullToRefresh'
import DocumentModal from './DocumentModal'

import './Documents.css'

type Props = {
  company: Company
  employee: Employee
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
  user: UserReducer

  getDocuments: (employeeID: string) => Promise<Document[] | void>
  deleteDocument: (documentID: string) => void
  addDocument: (employeeID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (employeeID: string, document: DocumentMutableFields) => Promise<Document | void>
}

export default function Documents(props: Props): ReactElement | null {
  type CreationDocumentState = {
    modalKey: number
    editing: string | boolean
    fileID?: string
  }
  const [documentState, setDocumentState] = useState<CreationDocumentState>({
    modalKey: 1,
    editing: false,
  })
  const [deleting, setDeleting] = useState<string[]>([])
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<RequestError>()

  const setEditVisibility = (id: string | boolean, fileID?: string) => {
    // Increment modalKey to create a new component
    setDocumentState((prev) => ({
      modalKey: prev.modalKey + 1,
      editing: id,
      fileID: fileID,
    }))
  }

  const { documents } = props
  const previousDocuments = usePrevious(documents)
  useEffect(() => {
    if (previousDocuments && previousDocuments.saving && !documents.saving) {
      if (!documents.error) {
        setEditVisibility(false)
      }
    }
  }, [previousDocuments, documents])

  const getDocuments = () => {
    return props.documents.documents
      .filter((document) => !deleting.some((id) => id === document.id))
      .map((document) => {
        return {
          id: document.id,
          uri: '/v2/documentDownload/' + document.id + '?disposition=attachment',
          name: document.name,
          canEdit: !!props.user.user?.id && document.userID === props.user.user.id,
          createdAt: formatDate(document.createdAt),
        }
      })
  }

  const handleDocument = (documentID: string) => {
    return (e: React.MouseEvent) => {
      if (!isWeb()) {
        e.preventDefault()

        Browser.open({ url: secureUrl('/v2/documentDownload/' + documentID + '?disposition=attachment') })
      }
    }
  }

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('documents.deletion_confirmation'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteDocument(id)
      }
    }
  }

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setError(undefined)
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        setEditVisibility(true, e.file.response.data?.id)
        break
      case 'error': {
        setUploading(false)
        setError(HandleFileEventError(e.file.response))
        break
      }
      default:
        break
    }
  }

  return (
    <div className="documents">
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <PullToRefresh onRefresh={() => props.getDocuments(props.employee.id)}>
        <div className="btn-group">
          <Link to={'/' + paths.PAY_SLIPS}>
            <Button size="large">{t('documents.payslips')}</Button>
          </Link>
          <Link to={'/' + paths.DOCUMENTS}>
            <Button size="large" className="ant-btn-secondary">
              {t('documents.documents')}
            </Button>
          </Link>
        </div>

        {getDocuments().map((document) => {
          if (document.canEdit) {
            return (
              <Card key={document.id}>
                <div onClick={() => setEditVisibility(document.id)}>
                  <div className="document-amount">{document.name}</div>
                  <div className="document-period">
                    {t('documents.added')} {document.createdAt}
                  </div>
                </div>
                <span onClick={remove(document.id)} className="registration-row-delete" />
                <a
                  key={document.id}
                  href={secureUrl('/v2/documentDownload/' + document.id + '?disposition=attachment')}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleDocument(document.id)}
                  className="view-document"
                >
                  {''}
                </a>
              </Card>
            )
          }
          return (
            <a
              key={document.id}
              href={secureUrl('/v2/documentDownload/' + document.id + '?disposition=attachment')}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleDocument(document.id)}
            >
              <Card>
                <div>
                  <div className="document-amount">{document.name}</div>
                  <div className="document-period">
                    {t('documents.added')} {document.createdAt}
                  </div>
                </div>
              </Card>
            </a>
          )
        })}
        {props.documents.documents.size === 0 && <div className="content-text">{t('documents.no_documents_yet')}</div>}
        {props.company.settingsEnabled.some(
          (setting) => setting === CompanySetting.ALLOW_EMPLOYEE_TO_UPLOAD_DOCUMENTS
        ) && (
          <UploadDragger
            name={'fileData'}
            action={url('v2/stagedFiles')}
            headers={{ authorization: getAccessToken() }}
            showUploadList={false}
            onChange={handleFileUpload}
          >
            <div className="documents-add">{t('documents.upload_new')}</div>
          </UploadDragger>
        )}
      </PullToRefresh>

      <Modal
        key={documentState.modalKey}
        visible={documentState.editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <DocumentModal
          visible={documentState.editing !== false}
          company={props.company}
          employee={props.employee}
          documentID={typeof documentState.editing !== 'boolean' ? documentState.editing : undefined}
          fileID={documentState.fileID}
          documents={props.documents}
          documentCategories={props.documentCategories}
          addDocument={props.addDocument}
          updateDocument={props.updateDocument}
        />
      </Modal>

      {uploading && <LoadingOverlay />}
    </div>
  )
}
