import React, { ReactElement } from 'react'

import PreviewComponent from '../components/pay-slip-preview/Preview'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { PaySlipReducer } from '../reducers/paySlips'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  paySlips: PaySlipReducer
}

function PaySlipPreview(props: Reducers & RouteProps): ReactElement | null {
  if (!props.params.splat) {
    jsBrowserHistory.push('/')
    return null
  }
  return <PreviewComponent paySlipID={props.params.splat} paySlips={props.paySlips.paySlips} />
}
export default connectToReducer<Reducers, any, RouteProps>(
  (state) => ({
    paySlips: state.paySlips,
  }),
  {}
)(PaySlipPreview)
