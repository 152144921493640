import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CarAllowance from '../model/carAllowance'
import { DateFormat } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

export interface CarAllowanceReducer {
  companyID: string | undefined
  employeeID: string | undefined
  fromDate: string | undefined
  toDate: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  carAllowances: List<CarAllowance>
  error: Error | null
}

const Params = Record<CarAllowanceReducer>({
  companyID: undefined,
  employeeID: undefined,
  fromDate: undefined,
  toDate: undefined,
  loading: false,
  loaded: false,
  saving: false,
  carAllowances: List<CarAllowance>(),
  error: null,
})

export interface CarAllowanceAction extends ReducerAction {
  employeeID?: string
  carAllowanceID?: string
  carAllowances?: CarAllowance[]
  carAllowance?: CarAllowance
  fromDate?: DateFormat
  toDate?: DateFormat
}

const comparator = (a: CarAllowance, b: CarAllowance) => {
  return b.date.localeCompare(a.date, 'da-dk')
}

export default (
  state: Record<CarAllowanceReducer> = Params(),
  action: CarAllowanceAction = { type: '' }
): Record<CarAllowanceReducer> => {
  const idx = state.get('carAllowances').findIndex((item) => item.id === action.carAllowanceID)

  switch (action.type) {
    case ActionTypes.CAR_ALLOWANCES_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
        employeeID: action.employeeID || state.get('employeeID'),
      })
    case ActionTypes.CAR_ALLOWANCES_LOADED:
    case ActionTypes.CAR_ALLOWANCES_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        loading: action.type == ActionTypes.CAR_ALLOWANCES_LOADED_PARTIAL,
        employeeID: action.employeeID || state.get('employeeID'),
        fromDate: action.fromDate,
        toDate: action.toDate,
        carAllowances: state.get('carAllowances').concat(List(action.carAllowances)).sort(comparator),
      })
    case ActionTypes.CAR_ALLOWANCES_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        error: action.error || null,
      })

    case ActionTypes.CAR_ALLOWANCES_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCES_CREATED: {
      if (!action.carAllowances) {
        return state
      }
      let carAllowances = state.get('carAllowances')
      action.carAllowances.forEach((carAllowance) => {
        const index = carAllowances.findIndex((item) => item.id === carAllowance.id)
        if (index !== -1) {
          carAllowances = carAllowances.set(index, carAllowance)
        } else {
          carAllowances = carAllowances.push(carAllowance)
        }
      })
      return state.set('saving', false).set('carAllowances', carAllowances.sort(comparator))
    }
    case ActionTypes.CAR_ALLOWANCES_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CAR_ALLOWANCE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCE_UPDATED:
      if (idx === -1 || !action.carAllowance) {
        return state
      }
      return state
        .set('saving', false)
        .set('carAllowances', state.get('carAllowances').set(idx, action.carAllowance).sort(comparator))
    case ActionTypes.CAR_ALLOWANCE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CAR_ALLOWANCE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCE_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('carAllowances', state.get('carAllowances').delete(idx))
    case ActionTypes.CAR_ALLOWANCE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
