import React, { ReactElement, useEffect, useState } from 'react'

import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import message from '../antd/message'
import Alert from '../elements/alert'
import Subtitle from '../elements/Subtitle'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ResetPasswordForm, { ResetPasswordResult } from './ResetPasswordForm'

import './ResetPassword.css'

type Props = {
  token: string
  user: UserReducer

  resetPassword: (token: string, password: string) => Promise<boolean | void>
}

export default function ResetPassword(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    regularComponentDidUpdate(props.user.error, error, setError)
  }, [props.user, error])

  const handleSubmit = (values: ResetPasswordResult) => {
    props.resetPassword(props.token, values.password).then(() => {
      message.success(t('reset_password.your_password_has_been_reset'), 5)
      jsBrowserHistory.push('/' + paths.LOGIN)
    })
  }

  return (
    <div className="reset-password">
      <Subtitle>{t('reset_password.create_new_password')}</Subtitle>
      <p>&nbsp;</p>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ResetPasswordForm onSubmit={handleSubmit} />
      {props.user.resettingPassword && <LoadingOverlay />}
    </div>
  )
}
