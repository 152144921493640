import { addYears, startOfYear, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { SalaryDefinition } from '../../model/remuneration'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../../model/salaryRegistration'
import SalaryType from '../../model/salaryType'
import { DateFormat } from '../../model/types'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { paths } from '../../routes'
import SettledState from '../../types/settled-state'
import { formatAPIDate, formatDate, getDate, trimCurrentYear } from '../../utils/date-utils'
import { getPlatform } from '../../utils/device-utils'
import { formatCurrency, formatDisplayNumber, formatInputNumber } from '../../utils/number-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import DumbLink from '../elements/DumbLink'
import PullToRefresh from '../widgets/PullToRefresh'
import SalaryRegistrationModal from './SalaryRegistrationModal'

import './SalaryRegistration.css'

type Props = {
  company: Company
  employee: Employee
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>
  costCenters: List<CostCenter>
  departments: List<Department>

  showHistory: boolean

  deleteSalaryRegistration: (id: string) => void
  getSalaryTypes: (companyID: string) => Promise<SalaryType[] | void>
  getSalaryRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<SalaryRegistration[] | void>
  createSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
  updateSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
}

export default function SalaryRegistrationTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const setEditVisibility = useCallback(
    (id: string | boolean) => {
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setEditing(id)
    },
    [setModalKey, setEditing]
  )

  const { salaryRegistrations } = props
  const previousSalaryRegistrations = usePrevious(salaryRegistrations)

  useEffect(() => {
    if (previousSalaryRegistrations && previousSalaryRegistrations.saving && !salaryRegistrations.saving) {
      if (!salaryRegistrations.error) {
        setEditVisibility(false)
      }
    }
  }, [previousSalaryRegistrations, salaryRegistrations, setEditVisibility])

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteSalaryRegistration(id)
      }
    }
  }

  const getUnsettledQuantity = (): string => {
    const quantity = salaryRegistrations.salaryRegistrations.reduce((quantity, salaryRegistration) => {
      if (salaryRegistration.state === SettledState.PENDING && salaryRegistration.quantity) {
        return quantity + salaryRegistration.quantity
      }
      return quantity
    }, 0)
    return formatInputNumber(quantity)
  }

  const getUnsettledAmount = (): string => {
    const contract = props.employee.earliestMutableContract
    const amount = salaryRegistrations.salaryRegistrations.reduce((amount, salaryRegistration) => {
      if (contract) {
        const salaryRow = contract.remuneration.salary.find(
          (salary) => salary.salaryTypeID === salaryRegistration.salaryTypeID
        )
        if (salaryRow) {
          amount += salaryRow.rate * salaryRegistration.quantity
        }
      }
      return amount
    }, 0)
    return formatCurrency(amount)
  }

  type SalaryRegistrationRow = {
    id: string
    month: string
    className: string
    date: string
    description: string
    approved: boolean
    immutable: boolean
  }

  type SalaryRegistrationRowBox = {
    month: string
    salaryRegistrations: SalaryRegistrationRow[]
  }

  const getSalaryRegistrations = (): SalaryRegistrationRowBox[] => {
    const contract = props.employee.earliestMutableContract
    return salaryRegistrations.salaryRegistrations
      .filter(
        (salaryRegistration) =>
          (salaryRegistration.state !== SettledState.PENDING) === props.showHistory &&
          !deleting.some((id) => id === salaryRegistration.id)
      )
      .map((salaryRegistration) => {
        let month = formatDate(getDate(salaryRegistration.date), 'MMMM yyyy')
        month = month[0].toUpperCase() + month.substring(1)
        let description = formatDisplayNumber(salaryRegistration.quantity, 2)
        let date = trimCurrentYear(formatDate(salaryRegistration.date))
        if (contract) {
          const salaryRow = contract.remuneration.salary.find(
            (salary: SalaryDefinition) => salaryRegistration.salaryTypeID === salary.salaryTypeID
          )
          if (salaryRow) {
            date += ' (' + translateGroupTitle(salaryRow) + ')'
            description +=
              ' ' +
              t('salary_registration.of') +
              ' ' +
              formatCurrency(salaryRow.rate, 2) +
              ' (' +
              formatCurrency(salaryRegistration.quantity * salaryRow.rate, 2) +
              ')'
          }
        }
        let className = 'registration-row-unapproved'
        if (salaryRegistration.approved) {
          className = 'registration-row-approved'
        }
        return {
          id: salaryRegistration.id,
          month,
          className,
          date,
          description,
          approved: salaryRegistration.approved,
          immutable: salaryRegistration.immutable,
        }
      })
      .reduce((boxes: SalaryRegistrationRowBox[], row: SalaryRegistrationRow) => {
        if (boxes.length === 0) {
          return [{ month: row.month, salaryRegistrations: [row] }]
        }
        if (boxes[boxes.length - 1].month !== row.month) {
          return [...boxes, { month: row.month, salaryRegistrations: [row] }]
        }
        const last = boxes[boxes.length - 1]
        last.salaryRegistrations.push(row)
        boxes[boxes.length - 1] = last
        return boxes
      }, [])
  }

  const registrations = getSalaryRegistrations()
  return (
    <div className={'salary-registration registrations-list platform-' + getPlatform()}>
      <PullToRefresh
        onRefresh={() => {
          const employee = props.employee
          const companyID = employee.companyID
          return Promise.all([
            props.getSalaryTypes(companyID),
            props.getSalaryRegistrations(
              employee.id,
              formatAPIDate(startOfYear(subYears(getDate(), 1))),
              formatAPIDate(addYears(getDate(), 1))
            ),
          ])
        }}
      >
        {!props.showHistory && (
          <div className="salary-registration-summary">
            {t('salary_registration.awaiting_payout')}: {getUnsettledQuantity()} ({getUnsettledAmount()})
          </div>
        )}
        {props.showHistory ? (
          <Link to={'/' + paths.SALARY_REGISTRATION} className="salary-registration-history">
            {t('common.go_back')}
          </Link>
        ) : (
          <Link to={'/' + paths.SALARY_REGISTRATION + '/' + paths.HISTORY} className="salary-registration-history">
            {t('common.show_history')}
          </Link>
        )}
        {registrations.map((row) => {
          return (
            <div key={row.month}>
              {row.salaryRegistrations.map((salaryRegistration) => {
                return (
                  <div className="registration-row">
                    <Card key={salaryRegistration.id} className={salaryRegistration.className}>
                      <div className="registration-row-info" onClick={() => setEditVisibility(salaryRegistration.id)}>
                        <div className="salary-registration-date">{salaryRegistration.date}</div>
                        <div className="salary-registration-description">{salaryRegistration.description}</div>
                      </div>
                      {!salaryRegistration.immutable && (
                        <span onClick={remove(salaryRegistration.id)} className="registration-row-delete" />
                      )}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {registrations.length === 0 && (
          <div className="content-text">
            {props.showHistory
              ? t('salary_registration.no_historic_salary_registrations')
              : t('salary_registration.no_salary_registrations_yet')}
          </div>
        )}
      </PullToRefresh>
      {!props.showHistory && !!props.employee.earliestMutableContract && (
        <DumbLink onClick={() => setEditVisibility(true)} className="salary-registrations-add">
          {t('salary_registration.register_salary')}
        </DumbLink>
      )}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <SalaryRegistrationModal
          visible={editing !== false}
          locked={!props.employee.earliestMutableContract}
          employee={props.employee}
          company={props.company}
          costCenters={props.costCenters}
          departments={props.departments}
          salaryRegistrationID={typeof editing !== 'boolean' ? editing : undefined}
          salaryRegistrations={props.salaryRegistrations}
          salaryTypes={props.salaryTypes}
          createSalaryRegistration={props.createSalaryRegistration}
          updateSalaryRegistration={props.updateSalaryRegistration}
        />
      </Modal>
    </div>
  )
}
