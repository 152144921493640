import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Project from '../model/project'
import { ReducerAction } from '../utils/reducer-utils'

export interface ProjectReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  projects: List<Project>
  error: Error | null
}

const Params = Record<ProjectReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  projects: List<Project>(),
  error: null,
})

export interface ProjectAction extends ReducerAction {
  projects?: Project[]
}

const comparator = (a: Project, b: Project) => {
  if (a.parentID === b.parentID) {
    return a.order - b.order
  }
  if (a.parentID && b.parentID) {
    return 1
  }
  if (a.parentID) {
    if (a.parentID === b.id) {
      return 1
    } else {
      return -1
    }
  } else {
    if (b.parentID === a.id) {
      return -1
    } else {
      return 1
    }
  }
}

export default (
  state: Record<ProjectReducer> = Params(),
  action: ProjectAction = { type: '' }
): Record<ProjectReducer> => {
  switch (action.type) {
    case ActionTypes.PROJECT_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.PROJECT_LOADED:
    case ActionTypes.PROJECT_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.PROJECT_LOADED_PARTIAL,
        projects: state.get('projects').concat(List(action.projects)).sort(comparator),
      })
    case ActionTypes.PROJECT_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
