import PropTypes from 'prop-types'
import Dialog from 'rc-dialog'
import addEventListener from 'rc-util/lib/Dom/addEventListener'
import React from 'react'

import Button from '../../elements/button'

let mousePosition
let mousePositionEventBinded

class Modal extends React.Component {
  handleCancel = (e) => {
    const onCancel = this.props.onCancel
    if (onCancel) {
      onCancel(e)
    }
  }

  handleOk = (e) => {
    const onOk = this.props.onOk
    if (onOk) {
      onOk(e)
    }
  }

  componentDidMount() {
    if (mousePositionEventBinded) {
      return
    }
    addEventListener(document.documentElement, 'click', (e) => {
      mousePosition = {
        x: e.pageX,
        y: e.pageY,
      }
      setTimeout(() => (mousePosition = null), 100)
    })
    mousePositionEventBinded = true
  }

  render() {
    let { okText, okType, cancelText, confirmLoading, footer, visible } = this.props

    if (this.context.antLocale && this.context.antLocale.Modal) {
      okText = okText || this.context.antLocale.Modal.okText
      cancelText = cancelText || this.context.antLocale.Modal.cancelText
    }

    const defaultFooter = [
      <Button key="cancel" size="large" onClick={this.handleCancel}>
        {cancelText || '取消'}
      </Button>,
      <Button key="confirm" type={okType} size="large" loading={confirmLoading} onClick={this.handleOk}>
        {okText || '确定'}
      </Button>,
    ]

    return (
      <Dialog
        {...this.props}
        footer={footer === undefined ? defaultFooter : footer}
        visible={visible}
        mousePosition={mousePosition}
        onClose={this.handleCancel}
      />
    )
  }
}

Modal.defaultProps = {
  prefixCls: 'ant-modal',
  width: 520,
  transitionName: 'zoom',
  maskTransitionName: 'fade',
  confirmLoading: false,
  visible: false,
  okType: 'primary',
}

Modal.propTypes = {
  prefixCls: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.node,
  cancelText: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  confirmLoading: PropTypes.bool,
  visible: PropTypes.bool,
  align: PropTypes.object,
  footer: PropTypes.node,
  title: PropTypes.node,
  closable: PropTypes.bool,
}

Modal.contextTypes = {
  antLocale: PropTypes.object,
}

export default Modal
