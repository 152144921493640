import Document, { DocumentCreationFields, DocumentMutableFields } from '../model/document'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDocuments(
  employeeID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<Document[]>> {
  return secureRequest('GET', url('/v2/documents', { employeeID, limit, offset }))
}

export function postDocument(document: DocumentCreationFields): Promise<RequestResponse<Document>> {
  return secureRequest('POST', url('/v2/documents'), {
    body: {
      companyID: document.companyID,
      employeeID: document.employeeID,
      fileID: document.fileID,
      documentCategoryID: document.documentCategoryID,
      name: document.name,
      visibleForEmployee: document.visibleForEmployee,
    },
  })
}

export function putDocument(document: DocumentMutableFields): Promise<RequestResponse<Document>> {
  return secureRequest('PUT', url('/v2/documents/' + document.id), {
    body: {
      employeeID: document.employeeID,
      documentCategoryID: document.documentCategoryID,
      name: document.name,
      visibleForEmployee: document.visibleForEmployee,
      archived: document.archived,
    },
  })
}

export function delDocument(id: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/documents/' + id))
}
