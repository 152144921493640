export function handleErrorUpdate(error?: Error | null): Error | null {
  switch (error) {
    case undefined:
    case null:
      error = null
      break
    default:
      // Received error already set
      break
  }
  return error
}

export function visibleComponentDidUpdate(
  visible: boolean,
  error: Error | null,
  stateError: Error | null,
  setError: (_: Error | null) => void
): void {
  let nextError = handleErrorUpdate(error)
  if (!visible) {
    nextError = stateError // set to state error to prevent redrawing
  }
  // prevent updating if not necessary and check that props are still valid
  if (stateError !== nextError) {
    setError(nextError)
  }
}

export function regularComponentDidUpdate(
  error: Error | null,
  stateError: Error | null,
  setError: (_: Error | null) => void
): boolean {
  const nextError = handleErrorUpdate(error)
  // prevent updating if not necessary and check that props are still valid
  if (stateError !== nextError) {
    setError(nextError)
    return true
  }
  return false
}
