import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Subtitle from '../elements/Subtitle'

import './ReimbursementVouchers.css'

export default function ReimbursementDisabled(): ReactElement | null {
  return (
    <div className="reimbursement">
      <Subtitle>{t('reimbursement_vouchers.title')}</Subtitle>
      <p>{t('reimbursement_vouchers.disabled')}</p>
    </div>
  )
}
