import Contract from '../model/contract'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchContracts(
  employeeID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<Contract[]>> {
  return secureRequest('GET', url('/v2/employeeContracts', { employeeID, limit, offset }))
}
