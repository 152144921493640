import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import DocumentCategory from '../model/documentCategory'
import { ReducerAction } from '../utils/reducer-utils'

export interface DocumentCategoryReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  documentCategories: List<DocumentCategory>
  error: Error | null
}

const Params = Record<DocumentCategoryReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  documentCategories: List(),
  error: null,
})

export interface DocumentCategoryAction extends ReducerAction {
  documentCategories?: DocumentCategory[]
}

const comparator = (a: DocumentCategory, b: DocumentCategory) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DocumentCategoryReducer> = Params(),
  action: DocumentCategoryAction = { type: '' }
): Record<DocumentCategoryReducer> => {
  switch (action.type) {
    case ActionTypes.DOCUMENT_CATEGORY_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.DOCUMENT_CATEGORY_LOADED:
    case ActionTypes.DOCUMENT_CATEGORY_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.DOCUMENT_CATEGORY_LOADED_PARTIAL,
        documentCategories: state.get('documentCategories').concat(List(action.documentCategories)).sort(comparator),
      })
    case ActionTypes.DOCUMENT_CATEGORY_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error || null,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
