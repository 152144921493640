import React from 'react'
import { IndexRoute, Route, RoutePropLocation, RouteProps } from 'react-router'

import App from './App'
import CarAllowance from './controllers/CarAllowanceTab'
import Dashboard from './controllers/Dashboard'
import Documents from './controllers/Documents'
import Fees from './controllers/Fees'
import LeaveRegistration from './controllers/LeaveRegistrationTab'
import LeaveStatistics from './controllers/LeaveStatistics'
import Login from './controllers/Login'
import PaySlipPreview from './controllers/PaySlipPreview'
import PaySlips from './controllers/PaySlips'
import Profile from './controllers/Profile'
import ProjectRegistrations from './controllers/ProjectRegistrations'
import Register from './controllers/Register'
import ReimbursementVouchers from './controllers/ReimbursementVouchers'
import RequestPassword from './controllers/RequestPassword'
import ResetPassword from './controllers/ResetPassword'
import SalaryRegistration from './controllers/SalaryRegistrationTab'
import Swipe from './controllers/Swipe'
import TimeBoxRegistration from './controllers/TimeBoxRegistrationTab'
import TimeRegistration from './controllers/TimeRegistrationTab'
import WorkHoursRegistrations from './controllers/WorkHoursRegistrations'
import { getAccessToken } from './utils/cookie-utils'

export const paths = {
  CAR_ALLOWANCE: 'car-allowance',
  DOCUMENTS: 'documents',
  LEAVE_REGISTRATION: 'leave',
  PAY_SLIPS: 'pay-slips',
  PROFILE: 'profile',
  REIMBURSEMENT_VOUCHERS: 'reimbursement-vouchers',
  TIME_REGISTRATION: 'time-registration',
  TIME_BOX_REGISTRATION: 'time-box-registration',
  SALARY_REGISTRATION: 'salary-registration',
  SWIPE: 'swipe',
  FEES: 'fees',
  PROJECT_REGISTRATION: 'project-registration',
  WORK_HOURS_REGISTRATION: 'work-hours-registration',
  LEAVE_STATISTICS: 'leave-statistics',

  LOGIN: 'login',
  REGISTER: 'register',
  SIGNUP: 'signup',
  REQUEST_PASSWORD: 'request-password',
  RESET_PASSWORD: 'reset-password',

  HISTORY: 'history',
}

function requireUnauthorized(nextState: RouteProps, replace: (state: Partial<RoutePropLocation>) => void) {
  if (getAccessToken()) {
    replace({
      pathname: nextState.location.query.ref || '/',
    })
  }
}
function requireAuthorized(nextState: RouteProps, replace: (state: Partial<RoutePropLocation>) => void) {
  if (!getAccessToken()) {
    replace({
      pathname: '/' + paths.LOGIN,
      query: { ref: nextState.location.pathname + nextState.location.search },
    })
  }
}

export default (
  <Route path="/" component={App}>
    <IndexRoute component={Dashboard} onEnter={requireAuthorized} />

    <Route path={paths.TIME_REGISTRATION} component={TimeRegistration} onEnter={requireAuthorized} />
    <Route
      path={paths.TIME_REGISTRATION + '/' + paths.HISTORY}
      component={TimeRegistration}
      onEnter={requireAuthorized}
    />
    <Route path={paths.TIME_BOX_REGISTRATION} component={TimeBoxRegistration} onEnter={requireAuthorized} />
    <Route
      path={paths.TIME_BOX_REGISTRATION + '/' + paths.HISTORY}
      component={TimeBoxRegistration}
      onEnter={requireAuthorized}
    />
    <Route path={paths.SALARY_REGISTRATION} component={SalaryRegistration} onEnter={requireAuthorized} />
    <Route
      path={paths.SALARY_REGISTRATION + '/' + paths.HISTORY}
      component={SalaryRegistration}
      onEnter={requireAuthorized}
    />
    <Route path={paths.LEAVE_REGISTRATION} component={LeaveRegistration} onEnter={requireAuthorized} />
    <Route path={paths.CAR_ALLOWANCE} component={CarAllowance} onEnter={requireAuthorized} />
    <Route path={paths.CAR_ALLOWANCE + '/' + paths.HISTORY} component={CarAllowance} onEnter={requireAuthorized} />
    <Route path={paths.DOCUMENTS} component={Documents} onEnter={requireAuthorized} />
    <Route path={paths.REIMBURSEMENT_VOUCHERS} component={ReimbursementVouchers} onEnter={requireAuthorized} />
    <Route path={paths.PAY_SLIPS} component={PaySlips} onEnter={requireAuthorized} />
    <Route path={paths.PAY_SLIPS + '/*'} component={PaySlipPreview} onEnter={requireAuthorized} />
    <Route path={paths.PROFILE} component={Profile} onEnter={requireAuthorized} />
    <Route path={paths.SWIPE} component={Swipe} onEnter={requireAuthorized} />
    <Route path={paths.SWIPE + '/*'} component={Swipe} onEnter={requireAuthorized} />
    <Route path={paths.FEES} component={Fees} onEnter={requireAuthorized} />
    <Route path={paths.PROJECT_REGISTRATION} component={ProjectRegistrations} onEnter={requireAuthorized} />
    <Route
      path={paths.PROJECT_REGISTRATION + '/' + paths.HISTORY}
      component={ProjectRegistrations}
      onEnter={requireAuthorized}
    />
    <Route path={paths.WORK_HOURS_REGISTRATION} component={WorkHoursRegistrations} onEnter={requireAuthorized} />
    <Route
      path={paths.WORK_HOURS_REGISTRATION + '/' + paths.HISTORY}
      component={WorkHoursRegistrations}
      onEnter={requireAuthorized}
    />
    <Route path={paths.LEAVE_STATISTICS} component={LeaveStatistics} onEnter={requireAuthorized} />

    <Route path={paths.LOGIN} component={Login} onEnter={requireUnauthorized} />
    <Route path={paths.REGISTER + '/*'} component={Register} onEnter={requireUnauthorized} />
    <Route path={paths.SIGNUP + '/*'} component={Register} onEnter={requireUnauthorized} />

    <Route path={paths.REQUEST_PASSWORD} component={RequestPassword} onEnter={requireUnauthorized} />
    <Route path={paths.RESET_PASSWORD + '/*'} component={ResetPassword} onEnter={requireUnauthorized} />

    {/*<Route path="*" component={PageNotFound} />*/}
  </Route>
)
