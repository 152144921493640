import { Browser } from '@capacitor/browser'
import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import Document from '../../model/document'
import Employee from '../../model/employee'
import PaySlip from '../../model/paySlip'
import { paths } from '../../routes'
import CompanySetting from '../../types/company-setting'
import { formatDate, getDate, trimCurrentYear } from '../../utils/date-utils'
import { isWeb } from '../../utils/device-utils'
import { formatCurrency } from '../../utils/number-utils'
import { secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Button from '../elements/button'
import NoContractMessage from '../widgets/NoContractMessage'
import PullToRefresh from '../widgets/PullToRefresh'

import './PaySlips.css'

type Props = {
  companySettings: CompanySetting[]
  documents: List<Document>
  employee: Employee
  paySlips: List<PaySlip>

  getPaySlips: (employeeID: string) => Promise<PaySlip[] | void>
}

export default function PaySlips(props: Props): ReactElement | null {
  type PaySlipRow = {
    id: string
    uri: string
    start: string
    end: string
    payCheck: string
  }

  type PaySlipYear = number | 'Future'

  type PaySlipContainer = {
    year: PaySlipYear
    paySlips: PaySlipRow[]
  }

  const getPaySlips = (): PaySlipContainer[] => {
    const paySlips: PaySlipContainer[] = []
    props.paySlips
      .filter((paySlip) => !(!paySlip.settled && paySlip.termination)) // don't show payslips that are not settled and termination
      .reduce((years: Map<PaySlipYear, PaySlipRow[]>, paySlip) => {
        const year: PaySlipYear = !paySlip.settled ? 'Future' : getDate(paySlip.dispositionDate).getFullYear()
        if (
          year === 'Future' &&
          props.companySettings.some((setting) => setting === CompanySetting.DISABLE_APP_UPCOMING_PAY_SLIP)
        ) {
          return years // skip future payslips when the feature is disabled
        }
        years.set(year, [
          ...(years.get(year) || []),
          {
            id: paySlip.id,
            uri: '/v2/paySlipsPDF/' + paySlip.id,
            start: trimCurrentYear(formatDate(paySlip.salaryPeriod.start)),
            end: trimCurrentYear(formatDate(paySlip.salaryPeriod.end)),
            payCheck: formatCurrency(paySlip.payCheck, 2),
          },
        ])
        return years
      }, new Map<PaySlipYear, PaySlipRow[]>())
      .forEach((list, year) => {
        paySlips.push({
          year: year,
          paySlips: list,
        })
      })
    return paySlips.sort((a, b) => {
      if (a.year === 'Future') {
        return -1
      }
      if (b.year === 'Future') {
        return 1
      }
      return b.year - a.year
    })
  }

  const handleDocument = (paySlipId: string) => {
    return (e: React.MouseEvent) => {
      if (!isWeb()) {
        e.preventDefault()

        Browser.open({ url: secureUrl('/v2/paySlipsPDF/' + paySlipId, { disposition: 'inline' }) })
      }
    }
  }

  const employee = props.employee
  if (!employee.activeContract) {
    return <NoContractMessage employee={employee} />
  }

  const showDocumentsButton =
    props.documents.size > 0 ||
    props.companySettings.some((setting) => setting === CompanySetting.ALLOW_EMPLOYEE_TO_UPLOAD_DOCUMENTS)
  const paySlips = getPaySlips()

  return (
    <div className="pay-slips">
      <PullToRefresh onRefresh={() => props.getPaySlips(props.employee.id)}>
        {showDocumentsButton && (
          <div className="btn-group">
            <Link to={'/' + paths.PAY_SLIPS}>
              <Button size="large" className="ant-btn-secondary">
                {t('documents.payslips')}
              </Button>
            </Link>
            <Link to={'/' + paths.DOCUMENTS}>
              <Button size="large">{t('documents.documents')}</Button>
            </Link>
          </div>
        )}

        {paySlips.some((row) => row.year !== 'Future') && (
          <Link
            to={secureUrl('/v2/paySlipsPDF?employeeID=' + props.employee.id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="ant-btn-secondary" size="large" style={{ margin: '0 auto 25px', display: 'block' }}>
              {t('payslips.download_all')}
            </Button>
          </Link>
        )}

        {paySlips.map((row) => {
          return (
            <div key={row.year}>
              <div className="pay-slips-header">{row.year === 'Future' ? t('payslips.future_payslips') : row.year}</div>
              {row.year === 'Future' && <div className="pay-slips-note">{t('payslips.future_payslips_note')}</div>}
              {row.paySlips.map((paySlip) => {
                return (
                  <Link
                    key={paySlip.id}
                    to={'/' + paths.PAY_SLIPS + '/' + paySlip.id}
                    onClick={handleDocument(paySlip.id)}
                  >
                    <Card>
                      <div className="pay-slip-amount">{paySlip.payCheck}</div>
                      <div className="pay-slip-period">
                        <div>{paySlip.start}</div>
                        <div>{paySlip.end}</div>
                      </div>
                    </Card>
                  </Link>
                )
              })}
            </div>
          )
        })}
        {paySlips.length === 0 && <div className="content-text">{t('payslips.no_payslips_yet')}</div>}
      </PullToRefresh>
    </div>
  )
}
