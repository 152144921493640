import React, { ReactElement } from 'react'

import Subtitle from '../elements/Subtitle'

import './CarAllowance.css'

export default function CarAllowanceDisabled(): ReactElement | null {
  return (
    <div className="time-registration">
      <Subtitle>Kørselsregnskab</Subtitle>
      <p>
        Detaljeret kørselsregnskab er ikke slået til. Kontakt din arbejdsgiver for at begynde at registrere kørsel selv.
      </p>
    </div>
  )
}
