import CostCenter from '../model/costCenter'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCostCenters(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<CostCenter[]>> {
  return secureRequest('GET', url('/v2/costCenters', { companyID, limit, offset }))
}
