import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../../model/salaryRegistration'
import SalaryType from '../../model/salaryType'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { approveState } from '../../utils/approve-utils'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import Alert from '../elements/alert'
import SalaryRegistrationModalForm, { ResultFields } from './SalaryRegistrationModalForm'

type Props = {
  visible: boolean
  locked: boolean
  employee: Employee
  salaryRegistrationID?: string
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>

  createSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
  updateSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
}

export default function SalaryRegistrationModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { salaryRegistrations, visible } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, salaryRegistrations.error, error, setError)
  }, [salaryRegistrations, error, visible, setError])

  const handleSubmit = (values: ResultFields) => {
    if (props.locked) {
      return
    }
    const salaryRegistration: SalaryRegistrationMutableFields = {
      employeeID: props.employee.id,
      salaryTypeID: values.salaryTypeID,
      date: values.date,
      quantity: values.quantity,
      note: values.note,
      costCenterID: values.costCenterID,
    }
    if (props.salaryRegistrationID) {
      salaryRegistration.id = props.salaryRegistrationID
      props.updateSalaryRegistration(salaryRegistration)
    } else {
      props.createSalaryRegistration({ ...salaryRegistration, approved: approveState(props.employee) })
    }
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <SalaryRegistrationModalForm
        locked={props.locked}
        salaryRegistrationID={props.salaryRegistrationID}
        employee={props.employee}
        salaryRegistrations={props.salaryRegistrations}
        salaryTypes={props.salaryTypes}
        company={props.company}
        costCenters={props.costCenters}
        departments={props.departments}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
