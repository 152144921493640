import { addDays, format, isAfter, isBefore, isSameDay, isSameSecond, parse, startOfDay, startOfWeek } from 'date-fns'
import { da, enGB } from 'date-fns/locale'

import { Day } from '../model/types'
import { DateFormat, DateTimeFormat } from '../model/types'
import { getClock } from './cookie-utils'
import { dayToNumber } from './day-utils'
import { getCurrentLocale, getDefaultLanguage } from './language-utils'

export function utcToCopenhagenTime(date: DateFormat | DateTimeFormat | Date): Date {
  // NOTE: This function can be a little slow, so avoid calling it too much.
  // trick to change the time zone from UTC to Europe/Copenhagen
  // if a Z is missing from the end of the datestamp, we add it to ensure it is interpreted as UTC
  // we then use toLocaleString to convert it to en-US, which new Date() can read
  // because toLocaleString takes timeZone as an option
  // taken from here: https://stackoverflow.com/questions/10087819/convert-date-to-another-timezone-in-javascript
  return new Date(
    (date instanceof Date ? date : new Date(date + (date[date.length - 1] !== 'Z' ? 'Z' : ''))).toLocaleString(
      'en-US',
      { timeZone: 'Europe/Copenhagen' }
    )
  )
}

function utc(date: DateFormat | DateTimeFormat | Date, isDate = false): Date {
  if (date instanceof Date) {
    return utcToCopenhagenTime(date)
  }
  // if it is a date, then just interpret it as is, we don't care about time zones
  if (isDate) {
    return parse(date, 'yyyy-MM-dd', new Date())
  }
  return utcToCopenhagenTime(date)
}

/**
 * A cache of strings to Date.  This is only for situations when getDate() is called with a string parameter, i.e.
 * a date/date-time format.  As these strings will _always_ yield the same Date in the same session, this should
 * reduce parsing times for certain situations where a lot of Date comparisons are needed.
 */
const dateCache = new Map<string, Date>()

type timeLocale = 'da' | 'en-gb'
function handleTimeLocale(): timeLocale {
  let fullLocale = getCurrentLocale()
  if (!fullLocale) {
    fullLocale = getDefaultLanguage()
  }
  let locale: timeLocale = 'da'
  switch (fullLocale) {
    case 'en':
    case 'en-gb':
    case 'en-GB':
      locale = 'en-gb'
      break
  }
  return locale
}

let currentLocale: timeLocale = handleTimeLocale()
function handleCurrentLocale(): Locale {
  const locale = handleTimeLocale()
  if (locale !== currentLocale) {
    currentLocale = locale
  }
  let fnsLocale = da
  switch (locale) {
    case 'da':
      fnsLocale = da
      break
    case 'en-gb':
      fnsLocale = enGB
      break
  }
  return fnsLocale
}

export function getDate(date?: DateFormat | DateTimeFormat | Date): Date {
  if (!date) {
    const clock = getClock()
    if (clock) {
      return utc(clock)
    }
    return utc(new Date().toISOString())
  }
  if (date instanceof Date) {
    return date
  }
  if (dateCache.has(date)) {
    return dateCache.get(date)! // since we just checked with "has", we know it's set
  }
  const v = utc(date, date.indexOf('T') === -1)
  dateCache.set(date, v) // yes, even if it is invalid, we save it, because the same string won't become more valid later
  return v
}

export function buildDate(year: number, month: number, day?: number): Date {
  if (!day) {
    return new Date(year, month)
  }
  return new Date(year, month, day)
}

export function isValidDate(d: any): boolean {
  return d instanceof Date && !isNaN(d.getTime())
}

export function formatDate(date: Date | DateFormat, dateFormat = 'do MMMM yyyy', locale?: Locale): string {
  if (!locale) {
    locale = handleCurrentLocale()
  }
  if (date instanceof Date && isValidDate(date)) {
    return format(date, dateFormat, { locale })
  }
  return formatDate(getDate(date), dateFormat, locale)
}

/**
 * Helper function to create a shortform version of the date (i.e. months at max 3 letters)
 * @param date
 */
export function formatShortDate(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, 'do MMM yyyy')
}

/**
 * Helper function to also display the time after the date.
 * @param date
 */
export function formatDateTime(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, 'do MMMM yyyy HH:mm')
}

export function formatShortDateTime(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, 'do MMM yyyy HH:mm')
}

export function formatAPIDate(date: Date | DateFormat): DateFormat {
  return formatDate(date, 'yyyy-MM-dd', enGB)
}

type timeGranularity = 'day' | 'second'

function isSame(date: Date, dateToCompare: Date, granularity: timeGranularity = 'day'): boolean {
  switch (granularity) {
    case 'day':
      return isSameDay(date, dateToCompare)
    case 'second':
    default:
      return isSameSecond(date, dateToCompare)
  }
}

/**
 * Determines whether the `date` is before `dateToCompare`
 * @param {Date | DateFormat | DateTimeFormat} date Our start
 * @param {Date | DateFormat | DateTimeFormat} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day or second level
 */
export function isTimeBefore(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  switch (granularity) {
    case 'day':
      return isBefore(startOfDay(date), startOfDay(dateToCompare))
    case 'second':
    default:
      return isBefore(date, dateToCompare)
  }
}

/**
 * Determines whether the `date` is the same as or before `dateToCompare`
 * @param {Date | DateFormat | DateTimeFormat} date Our start
 * @param {Date | DateFormat | DateTimeFormat} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day, hour or second level
 */
export function isSameOrBefore(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  if (isSame(date, dateToCompare, granularity)) {
    return true
  }
  return isTimeBefore(date, dateToCompare, granularity)
}

/**
 * Determines whether the `date` is after `dateToCompare`
 * @param {Date | DateFormat | DateTimeFormat} date Our start
 * @param {Date | DateFormat | DateTimeFormat} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day or second level
 */
export function isTimeAfter(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  switch (granularity) {
    case 'day':
      return isAfter(startOfDay(date), startOfDay(dateToCompare))
    case 'second':
    default:
      return isAfter(date, dateToCompare)
  }
}

/**
 * Determines whether the `date` is the same as or after `dateToCompare`
 * @param {Date} date Our start
 * @param {Date} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day or second level
 */
export function isSameOrAfter(date: Date, dateToCompare: Date, granularity: timeGranularity = 'day'): boolean {
  if (isSame(date, dateToCompare, granularity)) {
    return true
  }
  return isTimeAfter(date, dateToCompare, granularity)
}

/**
 * Determines whether `date` is between `dateFrom` and `dateTo`
 * @param {Date} date Our start
 * @param {Date} dateFrom Our earliest point of the time span
 * @param {Date} dateTo Our latest point of the time span
 * @param [granularity=day] Whether to compare on day, hour or second level
 * @param [inclusive=true] Whether the date can match the end points
 */
export function isTimeBetween(
  date: Date,
  dateFrom: Date | DateFormat | DateTimeFormat,
  dateTo: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day',
  inclusive = true
): boolean {
  dateFrom = dateFrom instanceof Date ? dateFrom : getDate(dateFrom)
  dateTo = dateTo instanceof Date ? dateTo : getDate(dateTo)
  if (inclusive) {
    return isSameOrAfter(date, dateFrom, granularity) && isSameOrBefore(date, dateTo, granularity)
  }
  return isTimeAfter(date, dateFrom, granularity) && isTimeBefore(date, dateTo, granularity)
}

export function getDateForWeekDay(dayInWeek: Date, day: Day): Date {
  const weekStart = startOfWeek(dayInWeek, { weekStartsOn: 1 })
  let n = dayToNumber(day) - 1 // so Monday is 0, and Sunday is -1
  if (n === -1) {
    n = 6
  }
  return addDays(weekStart, n)
}

export function trimCurrentYear(str: string, year?: string): string {
  if (!year) {
    year = getDate().getFullYear().toString()
  }
  return str.replace(year, ' ').replace(/  +/, ' ').trim()
}

export function formatHours(num: number) {
  num = num || 0
  let hours = Math.floor(num)
  let minutes = Math.round(num * 60 - hours * 60)
  if (minutes === 60) {
    hours += 1
    minutes = 0
  }
  return '' + hours + ':' + (minutes < 10 ? '0' : '') + minutes
}
