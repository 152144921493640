import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchOneTimePays(
  employeeID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<OneTimePay[]>> {
  return secureRequest('GET', url('/v2/oneTimePays', { employeeID, limit, offset }))
}

export function delOneTimePay(oneTimePayID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/oneTimePays/' + oneTimePayID))
}

export function postOneTimePay(oneTimePay: OneTimePayCreationFields): Promise<RequestResponse<OneTimePay>> {
  return secureRequest('POST', url('/v2/oneTimePays'), {
    body: {
      employeeID: oneTimePay.employeeID,
      title: oneTimePay.title,
      dispositionDate: oneTimePay.dispositionDate,
      amount: oneTimePay.amount,
      type: oneTimePay.type,
      category: oneTimePay.category,
      isProvision: oneTimePay.isProvision,
      costCenterID: oneTimePay.costCenterID,
      approved: oneTimePay.approved,
    },
  })
}

export function putOneTimePay(oneTimePay: OneTimePayMutableFields): Promise<RequestResponse<OneTimePay>> {
  return secureRequest('PUT', url('/v2/oneTimePays/' + oneTimePay.id), {
    body: {
      title: oneTimePay.title,
      dispositionDate: oneTimePay.dispositionDate,
      amount: oneTimePay.amount,
      type: oneTimePay.type,
      category: oneTimePay.category,
      isProvision: oneTimePay.isProvision,
      costCenterID: oneTimePay.costCenterID,
    },
  })
}
