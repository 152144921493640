import React from 'react'

import { fetchDepartments } from '../api/departments'
import ActionTypes from '../constants/action-types'
import Department from '../model/department'
import { DepartmentAction } from '../reducers/departments'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingDepartments(companyID: string): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_LOADING,
    companyID,
  }
}
function loadedDepartments(companyID: string, departments: Department[], partial = false): DepartmentAction {
  return {
    type: partial ? ActionTypes.DEPARTMENT_LOADED_PARTIAL : ActionTypes.DEPARTMENT_LOADED,
    departments,
    companyID,
  }
}
function failedLoadingDepartments(companyID: string, error: Error): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getDepartments(companyID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingDepartments(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchDepartments(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedDepartments(companyID, data)),
          (data) => dispatch(loadedDepartments(companyID, data, true)),
          (offset) => dispatch(getDepartments(companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDepartments(companyID, e))
        }
      })
  }
}
