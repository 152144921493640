import { ReactElement } from 'react'

import { AllReducers } from '../reducers'
import { connectToReducerInternal } from './reducer-utils-old'

export interface ReducerAction {
  type: string
  companyID?: string
  error?: Error
}

export type getStateSignature = () => AllReducers

// connectToReducer wraps the internal connectToReducer, still in JavaScript, but makes it typed
export function connectToReducer<
  Reducers extends Partial<AllReducers>,
  Actions extends Record<string, (...a: any) => any>,
  AdditionalProps extends Record<string, unknown> = Record<string, unknown>
>(
  mapStateToProps: (state: AllReducers) => Reducers,
  actions: {
    [Key in keyof Actions]: (
      ...params: Parameters<Actions[Key]>
    ) => (dispatch: React.Dispatch<any>, getState?: getStateSignature) => ReturnType<Actions[Key]>
  }
): (
  el: (props: Reducers & Actions & AdditionalProps) => ReactElement | null
) => (props: AdditionalProps) => ReactElement | null {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return connectToReducerInternal(mapStateToProps, actions)
}
