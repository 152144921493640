import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import TimeRegistration from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

export interface TimeRegistrationReducer {
  companyID: string | undefined
  employeeID: string | undefined
  fromDate: string | undefined
  toDate: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  timeRegistrations: List<TimeRegistration>
  error: Error | null
}

const Params = Record<TimeRegistrationReducer>({
  companyID: undefined,
  employeeID: undefined,
  fromDate: undefined,
  toDate: undefined,
  loading: false,
  loaded: false,
  saving: false,
  timeRegistrations: List<TimeRegistration>(),
  error: null,
})

export interface TimeRegistrationAction extends ReducerAction {
  employeeID?: string
  fromDate?: DateFormat
  toDate?: DateFormat
  registrationID?: string
  registrations?: TimeRegistration[]
  registration?: TimeRegistration
}

const comparator = (a: TimeRegistration, b: TimeRegistration) => {
  return b.date.localeCompare(a.date, 'da-dk')
}

export default (
  state: Record<TimeRegistrationReducer> = Params(),
  action: TimeRegistrationAction = { type: '' }
): Record<TimeRegistrationReducer> => {
  const idx = state.get('timeRegistrations').findIndex((item) => item.id === action.registrationID)

  switch (action.type) {
    case ActionTypes.TIME_REGISTRATIONS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        loading: true,
      })
    case ActionTypes.TIME_REGISTRATIONS_LOADED:
    case ActionTypes.TIME_REGISTRATIONS_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        loaded: true,
        loading: action.type === ActionTypes.TIME_REGISTRATIONS_LOADED_PARTIAL,
        timeRegistrations: state.get('timeRegistrations').concat(List(action.registrations)).sort(comparator),
      })
    case ActionTypes.TIME_REGISTRATIONS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error || null,
      })

    case ActionTypes.TIME_REGISTRATIONS_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATIONS_CREATED:
      if (!action.registration) {
        return state
      }
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('timeRegistrations', state.get('timeRegistrations').set(idx, action.registration).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('timeRegistrations', state.get('timeRegistrations').push(action.registration).sort(comparator))
    case ActionTypes.TIME_REGISTRATIONS_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATIONS_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATIONS_UPDATED:
      if (!action.registration) {
        return state
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('timeRegistrations', state.get('timeRegistrations').set(idx, action.registration).sort(comparator))
    case ActionTypes.TIME_REGISTRATIONS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATIONS_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATIONS_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('timeRegistrations', state.get('timeRegistrations').delete(idx))
    case ActionTypes.TIME_REGISTRATIONS_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
