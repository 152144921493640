import React, { ReactElement } from 'react'

import { requestPassword } from '../actions/user'
import RequestPasswordComponent from '../components/request-password/RequestPassword'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  requestPassword: (email: string) => Promise<boolean | void>
}

function RequestPassword(props: Reducers & Actions): ReactElement | null {
  return <RequestPasswordComponent user={props.user} requestPassword={props.requestPassword} />
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    user: state.user,
  }),
  {
    requestPassword: requestPassword,
  }
)(RequestPassword)
