import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { deleteTimeRegistrationTemplate, saveTimeRegistrationTemplates } from '../actions/time-registration-templates'
import {
  createTimeRegistration,
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import TimeRegistrationDisabled from '../components/time-registration/TimeRegistrationDisabled'
import WorkHoursRegistrationTabComponent from '../components/work-hours-registration/WorkHoursRegistrationTab'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { ContractReducer } from '../reducers/contracts'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employee'
import { ProjectReducer } from '../reducers/projects'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../reducers/timeRegistrationTemplates'
import { showWorkHours } from '../utils/footer-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  employee: EmployeeReducer
  company: CompanyReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  contracts: ContractReducer
  projects: ProjectReducer
  departments: DepartmentReducer
  costCenters: CostCenterReducer
  companyFeatures: CompanyFeatureReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteTimeRegistration: (id: string, employeeID: string) => void
  getTimeRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (employeeID: string) => Promise<boolean | void>
}

function WorkHoursRegistrations(props: Reducers & Actions & RouteProps): ReactElement | null {
  const employee = props.employee.employee
  const company = props.company.company

  if (!company || !employee || !showWorkHours(employee, company)) {
    return <TimeRegistrationDisabled mode="Work Hours" />
  }

  return (
    <WorkHoursRegistrationTabComponent
      templateMode={false}
      alerts={props.alerts}
      company={company}
      contracts={props.contracts.contracts}
      employee={employee}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      timeRegistrations={props.timeRegistrations}
      timeRegistrationTemplates={props.timeRegistrationTemplates}
      projects={props.projects.projects}
      departments={props.departments.departments}
      costCenters={props.costCenters.costCenters}
      companyFeatures={props.companyFeatures.companyFeatures}
      getTimeRegistrations={props.getTimeRegistrations}
      createTimeRegistration={props.createTimeRegistration}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
      deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    employee: state.employee,
    company: state.company,
    timeRegistrations: state.timeRegistrations,
    timeRegistrationTemplates: state.timeRegistrationTemplates,
    contracts: state.contracts,
    projects: state.projects,
    departments: state.departments,
    costCenters: state.costCenters,
    companyFeatures: state.companyFeatures,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getTimeRegistrations: getTimeRegistrations,
    createTimeRegistration: createTimeRegistration,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    saveTimeRegistrationTemplates: saveTimeRegistrationTemplates,
    deleteTimeRegistrationTemplate: deleteTimeRegistrationTemplate,
  }
)(WorkHoursRegistrations)
