import SalaryRegistration, { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSalaryRegistrations(
  employeeID: string,
  fromDate: DateFormat,
  toDate: DateFormat,
  limit: number,
  offset: number
): Promise<RequestResponse<SalaryRegistration[]>> {
  return secureRequest('GET', url('/v2/salaryRegistrations', { employeeID, from: fromDate, to: toDate, limit, offset }))
}

export function postSalaryRegistration(
  employeeID: string,
  salaryRegistration: SalaryRegistrationMutableFields
): Promise<RequestResponse<SalaryRegistration>> {
  return secureRequest('POST', url('/v2/salaryRegistrations'), {
    body: {
      employeeID: salaryRegistration.employeeID,
      salaryTypeID: salaryRegistration.salaryTypeID,
      date: salaryRegistration.date,
      quantity: salaryRegistration.quantity,
      note: salaryRegistration.note,
      costCenterID: salaryRegistration.costCenterID,
      approved: salaryRegistration.approved,
    },
  })
}

export function putSalaryRegistration(
  salaryRegistrationID: string,
  salaryRegistration: SalaryRegistrationMutableFields
): Promise<RequestResponse<SalaryRegistration>> {
  return secureRequest('PUT', url('/v2/salaryRegistrations/' + salaryRegistrationID), {
    body: {
      employeeID: salaryRegistration.employeeID,
      salaryTypeID: salaryRegistration.salaryTypeID,
      date: salaryRegistration.date,
      quantity: salaryRegistration.quantity,
      note: salaryRegistration.note,
      costCenterID: salaryRegistration.costCenterID,
    },
  })
}

export function delSalaryRegistration(salaryRegistrationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/salaryRegistrations/' + salaryRegistrationID))
}
