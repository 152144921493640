import React from 'react'

import {
  delTimeRegistrationTemplates,
  fetchTimeRegistrationTemplates,
  postTimeRegistrationTemplates,
} from '../api/time-registration-templates'
import ActionTypes from '../constants/action-types'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { TimeRegistrationTemplateAction } from '../reducers/timeRegistrationTemplates'
import { isRequestError } from '../utils/error-utils'

function loadingTimeRegistrationTemplates(employeeID: string): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_LOADING,
    employeeID,
  }
}
function loadedTimeRegistrationTemplates(
  employeeID: string,
  templates: TimeRegistrationTemplate[]
): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_LOADED,
    employeeID,
    templates,
  }
}
function failedLoadingTimeRegistrationTemplates(employeeID: string, error: Error): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_LOAD_FAILED,
    employeeID,
    error,
  }
}

function savingTimeRegistrationTemplates(employeeID: string): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVING,
    employeeID,
  }
}
function savedTimeRegistrationTemplates(
  employeeID: string,
  template: TimeRegistrationTemplate | null
): TimeRegistrationTemplateAction {
  if (template === null) {
    return {
      type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVED,
      employeeID,
      templateDeleted: true,
    }
  }
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVED,
    employeeID,
    template,
  }
}
function failedSavingTimeRegistrationTemplates(employeeID: string, error: Error): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVE_FAILED,
    employeeID,
    error,
  }
}

export function getTimeRegistrationTemplates(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistrationTemplate[] | void> => {
    dispatch(loadingTimeRegistrationTemplates(employeeID))
    return fetchTimeRegistrationTemplates(employeeID)
      .then((res) => {
        dispatch(loadedTimeRegistrationTemplates(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(loadedTimeRegistrationTemplates(employeeID, []))
          } else {
            dispatch(failedLoadingTimeRegistrationTemplates(employeeID, e))
          }
        }
      })
  }
}

export function saveTimeRegistrationTemplates(template: TimeRegistrationTemplateCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistrationTemplate | void> => {
    const employeeID = template.employeeID
    dispatch(savingTimeRegistrationTemplates(employeeID))
    return postTimeRegistrationTemplates(template)
      .then((res) => {
        dispatch(savedTimeRegistrationTemplates(employeeID!, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSavingTimeRegistrationTemplates(employeeID!, e))
        }
      })
  }
}

export function deleteTimeRegistrationTemplate(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(savingTimeRegistrationTemplates(employeeID))
    return delTimeRegistrationTemplates(employeeID)
      .then(() => {
        dispatch(savedTimeRegistrationTemplates(employeeID!, null))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSavingTimeRegistrationTemplates(employeeID!, e))
        }
      })
  }
}
