import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Input from '../elements/input'

type Fields = {
  company: string
  email: string
  password?: string
}

export type RegisterResult = {
  password: string
}

type Props = {
  company: string
  email: string
}

function RegisterForm(props: Props & FormComponentProps<Fields, RegisterResult>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {decorateField('company', {
        placeholder: 'Virksomhed',
      })(<Input disabled />)}
      {decorateField('email', {
        placeholder: t('common.email'),
      })(<Input disabled />)}
      {decorateField('password', {
        placeholder: t('common.password'),
        validate: (val) => {
          if (!val) {
            return t('validation.password_is_required')
          }
          if (val.length < 8) {
            return t('validation.password_must_be_8_characters')
          }
          return null
        },
      })(<Input type="password" tabIndex={1} />)}
      <Form.Item>
        <Button htmlType="submit" className="ant-btn-secondary" tabIndex={2}>
          {t('register.create_user')}
        </Button>
      </Form.Item>
    </div>
  )
}

export default withValidations<Props, Fields, RegisterResult>({
  mapPropsToFields: (props) => ({
    company: props.company,
    email: props.email,
  }),
  onSubmit: (values) => ({
    password: values.password!,
  }),
})(RegisterForm)
