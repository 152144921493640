import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Subtitle from '../elements/Subtitle'

import './SalaryRegistration.css'

const SalaryRegistrationDisabled = (): ReactElement | null => {
  return (
    <div className="time-registration">
      <Subtitle>{t('time_registration.time_registration')}</Subtitle>
      <p>{t('time_registration.detailed_time_registration_disabled')}</p>
    </div>
  )
}

export default SalaryRegistrationDisabled
