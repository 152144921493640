import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { paths } from '../../routes'
import { formatDate, getDate } from '../../utils/date-utils'
import { leaveRegistrationDescription } from '../../utils/time-registration-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import LeaveRegistrationModal from '../leave-registration/LeaveRegistrationModal'

type Props = {
  employee: Employee
  company: Company
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: List<LeaveType>

  addAlert: addAlertSignature
  createTimeRegistration: (timeReg: TimeRegistrationCreationFields) => void
  createTimeRegistrationBulk: (timeBulk: TimeRegistrationBulkFields) => void
  updateTimeRegistration: (timeReg: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string, employeeID: string) => void
}

export default function LeaveRegistrationCard(props: Props): ReactElement | null {
  const [editing, setEditing] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)

  const setEditVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const { timeRegistrations, addAlert } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations?.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        addAlert('success', t('dashboard.card_leave_registration.alert.success'), { timeout: 5 })
        setEditVisibility(false)
      }
    }
  }, [previousTimeRegistrations, timeRegistrations, addAlert])

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        props.deleteTimeRegistration(id, props.employee.id)
      }
    }
  }

  const today = getDate()
  const registrations = timeRegistrations.timeRegistrations
    .filter((reg) => reg.class === 'Leave' && isSameDay(getDate(reg.date), today))
    .map((reg) => ({
      ...reg,
      date: formatDate(reg.date),
      className: reg.approved ? 'registration-row-approved' : 'registration-row-unapproved',
      description: leaveRegistrationDescription(reg, props.employee, props.leaveTypes.toArray()),
    }))

  return (
    <div className="card-leave-registration">
      <Headline>{t('dashboard.card_leave_registration.title', { date: formatDate(today) })}</Headline>
      <div className="registrations-list">
        {registrations.map((timeRegistration) => {
          return (
            <div key={timeRegistration.id} className="registration-row">
              <Card className={timeRegistration.className}>
                <div className="registration-row-info" onClick={() => setEditVisibility(timeRegistration.id)}>
                  <div className="leave-registration-date">{timeRegistration.date}</div>
                  <div className="leave-registration-description">{timeRegistration.description}</div>
                </div>
                {!timeRegistration.immutable && (
                  <span onClick={remove(timeRegistration.id)} className="registration-row-delete" />
                )}
              </Card>
            </div>
          )
        })}
      </div>

      <Button className="dashboard-choice-register-button" type="primary" onClick={() => setEditVisibility(true)}>
        {t('dashboard.card_leave_registration.register_new')}
      </Button>
      <Link to={'/' + paths.LEAVE_REGISTRATION}>
        <Button className="dashboard-choice-page-button">{t('dashboard.card_leave_registration.go_to_page')}</Button>
      </Link>

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <LeaveRegistrationModal
          visible={editing !== false}
          locked={false}
          company={props.company}
          employee={props.employee}
          timeRegistrationID={typeof editing !== 'boolean' ? editing : undefined}
          timeRegistrations={props.timeRegistrations}
          leaveTypes={props.leaveTypes}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          createTimeRegistration={props.createTimeRegistration}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>
    </div>
  )
}
