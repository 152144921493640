import React from 'react'

import { fetchExpenseCategories } from '../api/expense-categories'
import ActionTypes from '../constants/action-types'
import ExpenseCategory from '../model/expenseCategory'
import { ExpenseCategoryAction } from '../reducers/expenseCategories'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingExpenseCategories(companyID: string): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_LOADING,
    companyID,
  }
}
function loadedExpenseCategories(
  companyID: string,
  expenseCategories: ExpenseCategory[],
  partial = false
): ExpenseCategoryAction {
  return {
    type: partial ? ActionTypes.EXPENSE_CATEGORY_LOADED_PARTIAL : ActionTypes.EXPENSE_CATEGORY_LOADED,
    expenseCategories,
    companyID,
  }
}
function failedLoadingExpenseCategories(companyID: string, error: Error): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getExpenseCategories(companyID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingExpenseCategories(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchExpenseCategories(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedExpenseCategories(companyID, data)),
          (data) => dispatch(loadedExpenseCategories(companyID, data, true)),
          (offset) => dispatch(getExpenseCategories(companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingExpenseCategories(companyID, e))
        }
      })
  }
}
