import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Input from '../elements/input'

type Fields = {
  email?: string
}

export type RequestPasswordResult = {
  email: string
}

function RequestPasswordForm(props: FormComponentProps<Fields, RequestPasswordResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      {decorateField('email', {
        placeholder: t('common.email'),
        validate: (val) => {
          if (!val) {
            return t('validation.email_is_required')
          }
          if (!val.match(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,10})$/i)) {
            return t('validation.email_is_invalid')
          }
          return null
        },
      })(<Input type="email" />)}
      <Form.Item>
        <Button htmlType="submit" className="ant-btn-secondary">
          {t('request_password.request_new_password')}
        </Button>
      </Form.Item>
    </div>
  )
}

export default withValidations<object, Fields, RequestPasswordResult>({
  onSubmit: (values) => ({ email: values.email! }),
})(RequestPasswordForm)
