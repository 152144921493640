import React, { ReactElement } from 'react'

import { addDocument, deleteDocument, getDocuments, updateDocument } from '../actions/documents'
import DocumentsComponent from '../components/documents/Documents'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../model/document'
import { CompanyReducer } from '../reducers/company'
import { DocumentCategoryReducer } from '../reducers/documentCategories'
import { DocumentReducer } from '../reducers/documents'
import { EmployeeReducer } from '../reducers/employee'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  company: CompanyReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  employee: EmployeeReducer
  user: UserReducer
}

type Actions = {
  getDocuments: (employeeID: string) => Promise<Document[] | void>
  deleteDocument: (documentID: string) => void
  addDocument: (employeeID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (employeeID: string, document: DocumentMutableFields) => Promise<Document | void>
}

function Documents(props: Reducers & Actions): ReactElement | null {
  if (!props.employee.employee || !props.company.company) {
    jsBrowserHistory.push('/')
    return null
  }
  return (
    <DocumentsComponent
      company={props.company.company}
      documents={props.documents}
      documentCategories={props.documentCategories.documentCategories}
      employee={props.employee.employee}
      user={props.user}
      getDocuments={props.getDocuments}
      deleteDocument={props.deleteDocument}
      addDocument={props.addDocument}
      updateDocument={props.updateDocument}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    company: state.company,
    documents: state.documents,
    documentCategories: state.documentCategories,
    employee: state.employee,
    user: state.user,
  }),
  {
    getDocuments: getDocuments,
    addDocument: addDocument,
    updateDocument: updateDocument,
    deleteDocument: deleteDocument,
  }
)(Documents)
