import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import Raven from 'raven-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { browserHistory, Router } from 'react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import thunk from 'redux-thunk'

import 'react-app-polyfill/ie11'

import { handleMessage } from './actions/socket'
import { fetchUser } from './api/user'
import LocaleProvider from './components/antd/locale-provider'
import { getAntdStrings } from './locale'
import { SwipeBack } from './plugins'
import rootReducer from './reducers'
import { UserParams } from './reducers/user'
import registerServiceWorker from './registerServiceWorker'
import { BUILD_NUMBER, VERSION_NUMBER } from './release'
import routes from './routes'
import { areCookiesReady, getAccessToken } from './utils/cookie-utils'
import { listen, subscribe, unsubscribe } from './utils/socket-utils'
import { t } from './utils/translation-utils'
import { checkVersion } from './utils/version-utils'

import './index.css'

export * from './plugins'

if (process.env.REACT_APP_SENTRY_URL) {
  Raven.config(process.env.REACT_APP_SENTRY_URL, {
    release: `v${VERSION_NUMBER}-${BUILD_NUMBER}`,
  }).install()
}

if (SwipeBack) {
  SwipeBack.enable()
}

browserHistory.listen((location) => {
  // Scroll to top
  if (!location.state || !location.state.preventScrollToTop) {
    window.scrollTo(0, 0)
  }
  // Color background to match footer
  if (location.pathname.indexOf('/pay-slips/') === 0) {
    document.body.style.backgroundColor = '#000'
  } else {
    document.body.style.backgroundColor = '#fff'
  }
})

App.addListener('appUrlOpen', (data) => {
  const slug = data.url.split('.dk').pop()
  if (slug) {
    browserHistory.push(slug)
  }
})
App.addListener('appStateChange', (state) => {
  if (state.isActive) {
    if (getAccessToken()) {
      // Ping an authorized endpoint to log user out if token is expired
      fetchUser()
    }
  }
})

areCookiesReady()
  .then(() => {
    return checkVersion()
  })
  .then((versionCheck) => {
    if (versionCheck) {
      if (window.confirm(t('app.version_deprecated'))) {
        Browser.open({ url: versionCheck.updateUrl })
      }
      return
    }
    let timeout = null
    const enhancer = compose(
      applyMiddleware(thunk),
      batchedSubscribe((notify) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          notify()
        }, 50)
      })
    )
    const store = createStore(rootReducer, { user: UserParams({ loggedIn: !!getAccessToken() }) }, enhancer)

    store.subscribe(() => {
      subscribe(store.getState())
      unsubscribe(store.getState())
    })
    listen(function (data) {
      handleMessage(store, data)
    })

    ReactDOM.render(
      <Provider store={store} key="reduxProvider">
        <LocaleProvider locale={getAntdStrings()}>
          <Router history={browserHistory} routes={routes} />
        </LocaleProvider>
      </Provider>,
      document.getElementById('root')
    )
    registerServiceWorker()
  })
