import React, { CSSProperties, ReactElement, useState } from 'react'
import { useEffectOnce } from 'react-use'

import Spin from '../antd/spin'

import './LoadingOverlay.css'

let isRefreshing = false
export function isPullToRefreshing() {
  return isRefreshing
}

type Props = {
  children: React.ReactNode
  onRefresh: () => Promise<any>
}

export default function PullToRefresh(props: Props): ReactElement | null {
  type State = {
    enabled: boolean
    pulling: boolean
    startY?: number
    distance?: number
    refreshing: boolean
  }
  const [state, setState] = useState<State>({
    enabled: true,
    pulling: false,
    refreshing: false,
  })

  const scrollContainer = React.useRef<HTMLDivElement>(null)
  let timeout: ReturnType<typeof setTimeout> | null = null

  const handleScroll = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    if (scrollContainer.current?.scrollTop || 0 > 0) {
      // Disable refresh when not at the top
      setState((prev) => ({ ...prev, enabled: false }))
    } else {
      timeout = setTimeout(() => {
        // Wait 0.5 seconds before enabling refresh after hitting the top
        setState((prev) => ({ ...prev, enabled: scrollContainer.current?.scrollTop === 0 }))
      }, 200)
    }
  }

  useEffectOnce(() => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.addEventListener('scroll', handleScroll)
      return scrollContainer.current.removeEventListener('scroll', handleScroll)
    }
  })

  const handleTouchStart = (e: React.TouchEvent) => {
    if (state.enabled && scrollContainer.current?.scrollTop === 0) {
      // User started pulling, mark start point
      setState((prev) => ({ ...prev, pulling: true, startY: e.nativeEvent?.touches[0].clientY }))
    }
  }
  const handleTouchMove = (e: React.TouchEvent) => {
    if (state.startY === undefined || !e.nativeEvent) {
      return
    }
    const distance = e.nativeEvent.touches[0].clientY - state.startY
    if (distance > 0) {
      // User pulled down, mark distance and show loader
      setState((prev) => ({ ...prev, pulling: true, distance }))
    }
  }
  const handleTouchEnd = () => {
    if (!state.pulling || state.refreshing) {
      return
    }
    if (state.enabled && state.distance && state.distance > 50) {
      // User pulled far enough down, start refreshing and show loader
      setState((prev) => ({ ...prev, pulling: false, distance: 60, refreshing: true }))
      isRefreshing = true
      // Wait for request (though at least 1 sec)
      Promise.all([props.onRefresh(), new Promise((resolve) => setTimeout(resolve, 1000))])
        .then(() => {
          isRefreshing = false
          // Refresh finished, remove loader
          setState((prev) => ({ ...prev, distance: undefined, refreshing: false }))
        })
        .catch(() => {
          isRefreshing = false
        })
    } else {
      // User didn't pull far enough down, remove loader
      setState((prev) => ({ ...prev, pulling: false, distance: 0 }))
    }
  }

  let className = 'ptr'
  const style: CSSProperties = {}
  if (state.enabled) {
    if (state.pulling) {
      className += ' ptr-pulling'
    }
    if (state.refreshing) {
      className += ' ptr-refreshing'
    }
    if (state.distance != null) {
      style.marginTop = Math.min(state.distance, 100)
    }
  }
  return (
    <div
      className={className}
      style={style}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={scrollContainer}
    >
      <Spin className="ptr-loader" />
      {props.children}
    </div>
  )
}
