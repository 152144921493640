import { Browser } from '@capacitor/browser'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import DocumentCategory from '../../model/documentCategory'
import Employee from '../../model/employee'
import { DocumentReducer } from '../../reducers/documents'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { isWeb } from '../../utils/device-utils'
import { formatError } from '../../utils/error-utils'
import { secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Subtitle from '../elements/Subtitle'
import DocumentModalForm, { DocumentResult } from './DocumentModalForm'

type Props = {
  visible: boolean
  company: Company
  employee: Employee
  documentID?: string
  fileID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>

  addDocument: (employeeID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (employeeID: string, document: DocumentMutableFields) => Promise<Document | void>
}

export default function DocumentModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, documents } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, documents.error, error, setError)
  }, [visible, documents, error])

  const handleDocument = (documentID: string) => {
    return (e: React.MouseEvent) => {
      if (!isWeb()) {
        e.preventDefault()

        Browser.open({ url: secureUrl('/v2/documentDownload/' + documentID + '?disposition=attachment') })
      }
    }
  }

  const handleSubmit = (values: DocumentResult) => {
    if (props.documentID) {
      const document = props.documents.documents.find((document) => document.id === props.documentID)
      if (document) {
        props.updateDocument(props.employee.id, {
          ...document,
          documentCategoryID: values.documentCategoryID,
          name: values.name,
        })
      }
    } else if (props.fileID) {
      props.addDocument(props.employee.id, {
        companyID: props.company.id,
        employeeID: props.employee.id,
        documentCategoryID: values.documentCategoryID,
        name: values.name,
        visibleForEmployee: true,
        fileID: props.fileID,
      })
    }
  }

  return (
    <div className="document-modal">
      <Subtitle>{props.documentID ? t('documents.edit_document') : t('documents.create_document')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DocumentModalForm
        documentID={props.documentID}
        documents={props.documents}
        documentCategories={props.documentCategories}
        onSubmit={handleSubmit}
      />
      {props.documentID && (
        <a
          href={secureUrl('/v2/documentDownload/' + props.documentID + '?disposition=attachment')}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleDocument(props.documentID)}
        >
          <Button className="ant-btn ant-btn-primary" style={{ width: '100%', marginTop: '25px' }}>
            {t('documents.view_document')}
          </Button>
        </a>
      )}
    </div>
  )
}
