import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Alert from '../model/alert'
import { assign } from '../utils/object-utils'
import { ReducerAction } from '../utils/reducer-utils'

export interface AlertReducer {
  nextID: number
  alerts: List<Alert>
}

const Params = Record({
  nextID: 1,
  alerts: List<Alert>(),
})

export interface AlertAction extends ReducerAction {
  alertID?: string
  alert?: Alert
}

export default (state: Record<AlertReducer> = Params(), action: AlertAction = { type: '' }): Record<AlertReducer> => {
  switch (action.type) {
    case ActionTypes.ALERT_ADDED:
      return state
        .set('nextID', state.get('nextID') + 1)
        .set('alerts', state.get('alerts').unshift(assign({ id: state.get('nextID').toString() }, action.alert)))
    case ActionTypes.ALERT_REMOVED:
      if (action.alertID) {
        const idx = state.get('alerts').findIndex((item) => item.id === action.alertID)
        if (idx !== -1) {
          return state.set('alerts', state.get('alerts').delete(idx))
        }
        return state
      }
      return state.set('alerts', state.get('alerts').pop())

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params()
    default:
      return state
  }
}
