import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Employee from '../../model/employee'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../../model/oneTimePay'
import SalaryPeriod from '../../model/salaryPeriod'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { formatDate, trimCurrentYear } from '../../utils/date-utils'
import { getPlatform } from '../../utils/device-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import DumbLink from '../elements/DumbLink'
import NoContractMessage from '../widgets/NoContractMessage'
import PullToRefresh from '../widgets/PullToRefresh'
import FeeModal from './FeeModal'

import './Fees.css'

type Props = {
  employee: Employee
  oneTimePays: OneTimePayReducer
  salaryPeriods: List<SalaryPeriod>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getOneTimePays: (employeeID: string) => Promise<OneTimePay[] | void>
  createOneTimePay: (oneTimePay: OneTimePayCreationFields) => void
  deleteOneTimePay: (oneTimePayID: string) => void
  updateOneTimePay: (oneTimePay: OneTimePayMutableFields) => void
}

export default function Fees(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  type State = {
    modalKey: number
    editing: string | boolean
  }
  const [state, setState] = useState<State>({ modalKey: 1, editing: false })

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setState((state) => ({
      modalKey: state.modalKey + 1,
      editing: id,
    }))
  }

  const { oneTimePays } = props
  const previousOneTimePays = usePrevious(oneTimePays)

  useEffect(() => {
    if (previousOneTimePays && previousOneTimePays.saving && !oneTimePays.saving) {
      if (!oneTimePays.error) {
        setEditVisibility(false)
      }
    }
  })

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteOneTimePay(id)
      }
    }
  }

  type OneTimePayRow = {
    id: string
    className: string
    type: string
    dispositionDate: string
    title: string
    amount: string
    approved: boolean
    immutable: boolean
    deletable: boolean
  }
  type Month = {
    month: string
    oneTimePays: OneTimePayRow[]
  }

  const getFees = (): Month[] => {
    const months: Record<string, OneTimePayRow[]> = props.oneTimePays.oneTimePays
      .filter((oneTimePay) => oneTimePay.type === 'Compensation' && !deleting.some((id) => id === oneTimePay.id))
      .reduce((months: Record<string, OneTimePayRow[]>, oneTimePay) => {
        let month = formatDate(oneTimePay.dispositionDate, 'MMMM yyyy')
        month = month[0].toUpperCase() + month.substring(1)
        // eslint-disable-next-line no-prototype-builtins
        if (!months.hasOwnProperty(month)) {
          months[month] = []
        }
        let className = 'registration-row-unapproved'
        if (oneTimePay.approved) {
          className = 'registration-row-approved'
        }
        months[month].push({
          id: oneTimePay.id,
          className,
          type: oneTimePay.type,
          dispositionDate: trimCurrentYear(formatDate(oneTimePay.dispositionDate)),
          title: oneTimePay.title,
          amount: formatCurrency(oneTimePay.amount, 2),
          approved: oneTimePay.approved,
          immutable: oneTimePay.immutable,
          deletable: oneTimePay.deletable,
        })
        return months
      }, {})
    const oneTimePays: Month[] = []
    for (const month in months) {
      oneTimePays.push({
        month,
        oneTimePays: months[month],
      })
    }
    return oneTimePays
  }

  const employee = props.employee
  if (!employee.earliestMutableContract) {
    return <NoContractMessage employee={employee} />
  }

  const fees = getFees()
  return (
    <div className={'fees registrations-list platform-' + getPlatform()}>
      <PullToRefresh
        onRefresh={() => {
          return Promise.all([props.getOneTimePays(employee.id)])
        }}
      >
        {fees.map((row) => {
          return (
            <div key={row.month}>
              <div className="fees-header">{row.month}</div>
              {row.oneTimePays.map((oneTimePay) => {
                return (
                  <div key={oneTimePay.id} className="registration-row">
                    <Card className={oneTimePay.className}>
                      <div className="fee-date" onClick={() => setEditVisibility(oneTimePay.id)}>
                        {oneTimePay.dispositionDate} (
                        {oneTimePay.approved ? t('common.approved') : t('common.not_approved')})
                      </div>
                      <div className="fee-amount" onClick={() => setEditVisibility(oneTimePay.id)}>
                        {oneTimePay.amount}
                      </div>
                      <div className="fee-title" onClick={() => setEditVisibility(oneTimePay.id)}>
                        {oneTimePay.title}
                      </div>
                      {oneTimePay.deletable && (
                        <span onClick={remove(oneTimePay.id)} className="registration-row-delete" />
                      )}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {fees.length === 0 && <div className="content-text">{t('fees.no_fees_yet')}</div>}
      </PullToRefresh>
      <DumbLink onClick={() => setEditVisibility(true)} className="fees-add">
        {t('fees.create_fee')}
      </DumbLink>

      <Modal
        key={state.modalKey}
        visible={state.editing !== false}
        closable={true}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <FeeModal
          visible={state.editing !== false}
          employee={employee}
          oneTimePayID={typeof state.editing !== 'boolean' ? state.editing : undefined}
          oneTimePays={props.oneTimePays}
          salaryPeriods={props.salaryPeriods}
          createOneTimePay={props.createOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>
    </div>
  )
}
