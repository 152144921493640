import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { SalaryDefinition } from '../../model/remuneration'
import SalaryType from '../../model/salaryType'
import { DateFormat } from '../../model/types'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import SalaryClass from '../../types/salary-class'
import { formatAPIDate, formatDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { getCurrentLocale } from '../../utils/language-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { translateGroupTitle } from '../../utils/translation-utils'
import { t } from '../../utils/translation-utils'
import { validateDecimal } from '../../utils/validation-utils'
import Form from '../antd/form'
import Select from '../antd/select'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Input from '../elements/input'
import RegistrationCostCenter, { NO_COST_CENTER } from '../elements/RegistrationCostCenter'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  locked: boolean
  salaryRegistrationID?: string
  employee: Employee
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>
}

type Fields = {
  salaryTypeID?: string
  costCenterID?: string
  date: Date
  quantity?: string
  note?: string
  immutable: boolean
}

export type ResultFields = {
  salaryTypeID: string
  costCenterID?: string
  date: DateFormat
  quantity: number
  note: string
}

function SalaryRegistrationModalForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const getTypes = (): SalaryDefinition[] => {
    if (!props.employee.activeContract) {
      return []
    }
    const exists: string[] = []
    return props.employee.activeContract.remuneration.salary
      .filter((salary) =>
        props.salaryTypes.some(
          (type) => type.id === salary.salaryTypeID && type.class === SalaryClass.SUPPLEMENT_VARIED
        )
      )
      .filter((salary) => {
        if (exists.indexOf(salary.salaryTypeID) === -1) {
          exists.push(salary.salaryTypeID)
          return true
        }
        return false
      })
  }

  const { decorateField, getFieldValue } = props

  if (getFieldValue('immutable') || props.locked) {
    let type = t('common.unknown')
    if (props.employee.activeContract) {
      const salaryRow = props.employee.activeContract.remuneration.salary.find(
        (row) => row.salaryTypeID === getFieldValue('salaryTypeID')
      )
      if (salaryRow) {
        type = translateGroupTitle(salaryRow)
      }
    }
    return (
      <div>
        {props.getFormError()}
        <Form.Item>
          <label>{t('common.date')}</label>
          {formatDate(getFieldValue('date'))}
        </Form.Item>
        <Form.Item>
          <label>{t('salary_registration.quantity')}</label>
          {getFieldValue('quantity')}
        </Form.Item>
        <Form.Item>
          <label>{t('common.type')}</label>
          {type}
        </Form.Item>
        <Form.Item>
          <label>{t('common.note')}</label>
          {getFieldValue('note')}
        </Form.Item>
      </div>
    )
  }

  return (
    <div>
      {props.getFormError()}
      {decorateField('date', {
        placeholder: t('common.date'),
        validate: (val) => {
          if (!val) {
            return t('validation.date_is_required')
          }
          return null
        },
      })(<DatePicker allowClear={false} tabIndex={3} style={{ width: '100%' }} />)}
      {decorateField('quantity', {
        placeholder: t('salary_registration.quantity'),
        validate: (val) => {
          if (!val) {
            return t('validation.quantity_is_required')
          }
          if (!validateDecimal(val) || forceParseInputNumber(val) <= 0) {
            return t('validation.quantity_is_invalid')
          }
          return null
        },
      })(<Input tabIndex={4} inputMode="decimal" lang={getCurrentLocale()} />)}
      {decorateField('salaryTypeID', {
        placeholder: t('common.type'),
        validate: (val) => (!val ? t('validation.type_must_be_chosen') : null),
      })(
        <Select dropdownMatchSelectWidth={false} tabIndex={5}>
          {getTypes().map((row) => {
            return (
              <Select.Option key={row.salaryTypeID} value={row.salaryTypeID}>
                {translateGroupTitle(row)} ({formatCurrency(row.rate)})
              </Select.Option>
            )
          })}
        </Select>
      )}
      <RegistrationCostCenter
        tabIndex={6}
        company={props.company}
        costCenters={props.costCenters}
        departments={props.departments}
        decorateField={decorateField}
        getFieldValue={getFieldValue}
      />
      {decorateField('note', {
        placeholder: t('common.note'),
      })(<Input tabIndex={7} />)}
      <Form.Item>
        <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={10}>
          {t('salary_registration.register_salary')}
        </Button>
      </Form.Item>
      {props.salaryRegistrations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      salaryTypeID: undefined,
      costCenterID: NO_COST_CENTER,
      date: getDate(),
      immutable: false,
    }
    // just pick the first supplement varied salary line on the contract
    const salary = props.employee.activeContract?.remuneration.salary.filter((def) =>
      props.salaryTypes.some((t) => t.id === def.salaryTypeID && t.class === SalaryClass.SUPPLEMENT_VARIED)
    )
    if (salary && salary.length > 0) {
      fields.salaryTypeID = salary[0].salaryTypeID
    }
    const salaryRegistration = props.salaryRegistrations.salaryRegistrations.find(
      (salaryRegistration) => salaryRegistration.id === props.salaryRegistrationID
    )
    if (salaryRegistration) {
      fields.date = getDate(salaryRegistration.date)
      fields.quantity = formatInputNumber(salaryRegistration.quantity)
      fields.salaryTypeID = salaryRegistration.salaryTypeID
      fields.note = salaryRegistration.note
      fields.immutable = salaryRegistration.immutable
      fields.costCenterID = salaryRegistration.costCenterID
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'quantity':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: ResultFields = {
      salaryTypeID: values.salaryTypeID!,
      costCenterID: values.costCenterID === NO_COST_CENTER ? undefined : values.costCenterID,
      date: formatAPIDate(values.date),
      quantity: forceParseInputNumber(values.quantity),
      note: values.note ?? '',
    }
    return result
  },
})(SalaryRegistrationModalForm)
