const defaultLocale = {
  okText: 'OK',
  cancelText: 'Cancel',
  justOkText: 'OK',
}

let runtimeLocale = {
  ...defaultLocale,
}

export function changeConfirmLocale(newLocale) {
  if (newLocale) {
    runtimeLocale = {
      ...runtimeLocale,
      ...newLocale,
    }
  } else {
    runtimeLocale = {
      ...defaultLocale,
    }
  }
}

export function getConfirmLocale() {
  return runtimeLocale
}
