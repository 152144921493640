import React from 'react'

import { fetchLeaveTypes } from '../api/leave-types'
import ActionTypes from '../constants/action-types'
import LeaveType from '../model/leaveType'
import { LeaveTypeAction } from '../reducers/leaveTypes'
import { isRequestError } from '../utils/error-utils'

function loadingLeaveTypes(companyID: string): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_LOADING,
    companyID,
  }
}
function loadedLeaveTypes(companyID: string, leaveTypes: LeaveType[]): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_LOADED,
    leaveTypes,
    companyID,
  }
}
function failedLoadingLeaveTypes(companyID: string, error: Error): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getLeaveTypes(companyID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loadingLeaveTypes(companyID))
    return fetchLeaveTypes(companyID)
      .then((res) => {
        dispatch(loadedLeaveTypes(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingLeaveTypes(companyID, e))
        }
      })
  }
}
