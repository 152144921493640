import React from 'react'

import { fetchPaySlips, fetchSalaryPeriods } from '../api/pay-slips'
import ActionTypes from '../constants/action-types'
import PaySlip from '../model/paySlip'
import SalaryPeriod from '../model/salaryPeriod'
import { PaySlipAction } from '../reducers/paySlips'
import { SalaryPeriodAction } from '../reducers/salaryPeriods'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingPaySlips(employeeID: string): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIPS_LOADING,
    employeeID,
  }
}
function loadedPaySlips(employeeID: string, paySlips: PaySlip[], partial = false): PaySlipAction {
  return {
    type: partial ? ActionTypes.PAY_SLIPS_LOADED_PARTIAL : ActionTypes.PAY_SLIPS_LOADED,
    paySlips,
    employeeID,
  }
}
function failedLoadingPaySlips(employeeID: string, error: Error): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIPS_LOAD_FAILED,
    error,
    employeeID,
  }
}

export function addedPaySlip(paySlipID: string, paySlip: PaySlip): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_ADDED,
    paySlipID,
    paySlip,
  }
}
export function updatedPaySlip(paySlipID: string, paySlip: PaySlip): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_UPDATED,
    paySlipID,
    paySlip,
  }
}
export function deletedPaySlip(paySlipID: string): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_DELETED,
    paySlipID,
  }
}

function loadingSalaryPeriods(employeeID: string): SalaryPeriodAction {
  return {
    type: ActionTypes.SALARY_PERIODS_LOADING,
    employeeID,
  }
}
function loadedSalaryPeriods(employeeID: string, salaryPeriods: SalaryPeriod[]): SalaryPeriodAction {
  return {
    type: ActionTypes.SALARY_PERIODS_LOADED,
    salaryPeriods,
    employeeID,
  }
}
function failedLoadingSalaryPeriods(employeeID: string, error: Error): SalaryPeriodAction {
  return {
    type: ActionTypes.SALARY_PERIODS_LOAD_FAILED,
    error,
    employeeID,
  }
}

export function getPaySlips(employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingPaySlips(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchPaySlips(employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedPaySlips(employeeID, data)),
          (data) => dispatch(loadedPaySlips(employeeID, data, true)),
          (offset) => dispatch(getPaySlips(employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPaySlips(employeeID, e))
        }
      })
  }
}

export function getSalaryPeriods(employeeID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loadingSalaryPeriods(employeeID))
    return fetchSalaryPeriods(employeeID)
      .then((res) => {
        dispatch(loadedSalaryPeriods(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSalaryPeriods(employeeID, e))
        }
      })
  }
}
