import { List } from 'immutable'
import React, { ReactElement } from 'react'

import DocumentCategory from '../../model/documentCategory'
import { DocumentReducer } from '../../reducers/documents'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  documentID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
}

type Fields = {
  documentCategoryID?: string
  name?: string
}

export type DocumentResult = {
  documentCategoryID: string
  name: string
}

function DocumentModalForm(props: Props & FormComponentProps<Fields, DocumentResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('documentCategoryID', {
            placeholder: t('documents.document_category'),
            validate: (val) => (!val ? t('validation.document_category_is_required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.documentCategories
                .filter(
                  (documentCategory) =>
                    documentCategory.active || documentCategory.id === getFieldValue('documentCategoryID')
                )
                .map((documentCategory) => {
                  return (
                    <Select.Option key={documentCategory.id} value={documentCategory.id}>
                      {documentCategory.name}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('documents.description'),
            validate: (val) => (!val ? t('validation.description_is_required') : null),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" className="ant-btn-secondary">
            {props.documentID ? t('documents.edit_document') : t('documents.create_document')}
          </Button>
        </Col>
      </Row>
      {props.documents.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, DocumentResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {}
    const document = props.documents.documents.find((document) => document.id === props.documentID)
    if (document) {
      fields.documentCategoryID = document.documentCategoryID
      fields.name = document.name
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      documentCategoryID: values.documentCategoryID!,
      name: values.name!,
    }
  },
})(DocumentModalForm)
