import Group from './group'
import Radio from './radio'
import Button from './radioButton'

import './style/css'

Radio.Button = Button
Radio.Group = Group
export { Button, Group }
export default Radio
