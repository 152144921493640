import React, { CSSProperties, ReactElement, ReactNode } from 'react'

type Props = {
  onClick: () => void
  className?: string
  style?: CSSProperties
  children: ReactNode
}

/**
 * A widget for when you need something to appear like a link, but it does not behave like one;
 * specifically that it does not have the to-prop.
 * The onClick function should take no parameters, as this component will handle it for it.
 * @param props
 * @constructor
 */
export default function DumbLink(props: Props): ReactElement {
  let className = 'dumb-link'
  if (props.className) {
    className = className + ' ' + props.className
  }
  return (
    <a
      href={'#!'}
      style={props.style}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault()
        props.onClick()
      }}
      className={className}
    >
      {props.children}
    </a>
  )
}
