import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchTimeRegistrationTemplates(
  employeeID: string
): Promise<RequestResponse<TimeRegistrationTemplate[]>> {
  return secureRequest('GET', url('/v2/timeRegistrationTemplates', { employeeID }))
}

export function postTimeRegistrationTemplates(
  template: TimeRegistrationTemplateCreationFields
): Promise<RequestResponse<TimeRegistrationTemplate>> {
  return secureRequest('POST', url('/v2/timeRegistrationTemplates'), {
    body: {
      employeeID: template.employeeID,
      class: template.class,
      lines: template.lines,
    },
  })
}

export function delTimeRegistrationTemplates(employeeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/timeRegistrationTemplates', { employeeID }))
}
