import React, { CSSProperties, ReactElement, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

import { getAntdStrings } from '../../../locale'
import { DateFormat } from '../../../model/types'
import { formatDate, getDate } from '../../../utils/date-utils'
import { t } from '../../../utils/translation-utils'
import Icon from '../icon'
import createCustomHeader from './CustomHeader'

type Props = {
  value?: [Date, Date | null]
  defaultValue?: [Date, Date | null]
  allowClear?: boolean
  style?: CSSProperties
  placeholder?: [string, string]
  onChange?: (value: [Date, Date]) => void
  onStateChange?: (open: boolean) => void
  format?: string
  tabIndex?: number
  disabled?: boolean
  showTwoMonths?: boolean
}

export default function RangePicker(props: Props): ReactElement | null {
  const [value, setValue] = useState<[Date | null, Date | null]>(props.value || props.defaultValue || [null, null])
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(0) // 0 = closed, 1 = pick start, 2 = pick end

  const { value: propsValue } = props

  useEffect(() => {
    if (propsValue) {
      setValue((prev) => ({ ...prev, value: propsValue }))
    }
  }, [propsValue])

  const { format = 'do MMM yyyy' } = props

  const handleClose = () => {
    setStep(0)
    setOpen(false)
    if (props.onStateChange) {
      props.onStateChange(false)
    }
  }

  const handleChange = (newValue: [Date, Date | null]) => {
    setValue(newValue)
    if (props.onChange) {
      props.onChange([newValue[0], newValue[1] ?? newValue[0]])
    }
    switch (step) {
      case 2:
        handleClose()
        break
      default:
        setStep((prev) => prev + 1)
        break
    }
  }

  const clearSelection = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setValue([null, null])
  }

  const { allowClear = false } = props

  const antdStrings = getAntdStrings()

  const startPlaceholder = props.placeholder ? props.placeholder[0] : antdStrings.DatePicker.lang.rangePlaceholder[0]
  const endPlaceholder = props.placeholder ? props.placeholder[1] : antdStrings.DatePicker.lang.rangePlaceholder[1]

  const clearIcon =
    !props.disabled && allowClear && value && (value[0] || value[1]) ? (
      <Icon type="cross-circle" className={`picker-clear`} onClick={clearSelection} />
    ) : null

  const CustomInput = React.forwardRef(
    (
      { value, onClick }: { value?: `${DateFormat} - ${DateFormat}`; onClick?: () => void },
      ref: React.LegacyRef<HTMLInputElement>
    ) => {
      if (step !== 0) {
        return (
          <div className={`react-datepicker-input-wrapper react-datepicker-instructions`}>
            <span className="react-datepicker-instructions-text">
              {step === 1 ? t('date_picker.select_start_date') : t('date_picker.select_end_date')}
            </span>
            <span className={`picker-icon`} />
          </div>
        )
      }
      const values = value?.split(' - ')
      const value0 = (values && values[0] && formatDate(values[0], format)) || ''
      const value1 = (values && values[1] && formatDate(values[1], format)) || value0 || ''
      return (
        <div
          onClick={() => {
            if (onClick) {
              onClick()
              setOpen(true)
              setStep(1)
              if (props.onStateChange) {
                props.onStateChange(true)
              }
            }
          }}
          className={`react-datepicker-input-wrapper react-datepicker-input-range`}
        >
          <input
            disabled={props.disabled}
            value={value0}
            readOnly
            placeholder={startPlaceholder}
            tabIndex={props.tabIndex}
            ref={ref}
          />
          <span className={`picker-separator`}> ~ </span>
          <input disabled={props.disabled} value={value1} readOnly placeholder={endPlaceholder} />
          {clearIcon}
          <span className={`picker-icon`} />
        </div>
      )
    }
  )

  return (
    <span style={props.style} className={`react-datepicker-container`}>
      <ReactDatePicker
        onChange={handleChange}
        onCalendarClose={() => {
          handleClose()
          const newValue = value
          if (newValue[0] !== null && newValue[1] !== null) {
            return
          }
          handleChange([newValue[0] ?? getDate(), newValue[1] ?? newValue[0]])
        }}
        open={open}
        startDate={value[0]}
        endDate={value[1]}
        selected={value[1] ?? value[0]}
        selectsRange
        customInput={<CustomInput />}
        renderCustomHeader={(params) =>
          createCustomHeader({
            showTwoMonths: !!props.showTwoMonths,
            ...params,
          })
        }
        disabled={props.disabled}
        locale={'da'}
        dateFormat={'yyyy-MM-dd'}
        monthsShown={props.showTwoMonths ? 2 : 1}
        popperClassName={props.showTwoMonths ? 'two-months-shown' : ''}
        showWeekNumbers
        showMonthDropdown={!props.showTwoMonths}
        showYearDropdown={!props.showTwoMonths}
        fixedHeight
        dropdownMode="select"
        todayButton={t('date_picker.today')}
        disabledKeyboardNavigation
      />
    </span>
  )
}
