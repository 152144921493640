import React, { ReactElement, useEffect } from 'react'

import {
  createSalaryRegistration,
  deleteSalaryRegistration,
  getSalaryRegistrations,
  updateSalaryRegistration,
} from '../actions/salary-registrations'
import { getSalaryTypes } from '../actions/salary-types'
import SalaryRegistrationDisabled from '../components/salary-registration/SalaryRegistrationDisabled'
import SalaryRegistrationTabComponent from '../components/salary-registration/SalaryRegistrationTab'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import SalaryType from '../model/salaryType'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employee'
import { SalaryRegistrationReducer } from '../reducers/salaryRegistrations'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { hasSalaryRegistration } from '../utils/employee-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: SalaryTypeReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
}

type Actions = {
  deleteSalaryRegistration: (id: string) => void
  getSalaryTypes: (companyID: string) => Promise<SalaryType[] | void>
  getSalaryRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<SalaryRegistration[] | void>
  createSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
  updateSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
}

function SalaryRegistrationTab(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { employee, salaryTypes } = props
  const thisEmployee = employee.employee

  useEffect(() => {
    if (!thisEmployee || !hasSalaryRegistration(thisEmployee, salaryTypes.salaryTypes.toArray())) {
      jsBrowserHistory.push('/')
    }
  }, [thisEmployee, employee, salaryTypes])

  const company = props.company.company

  if (!thisEmployee || !company || thisEmployee.activeContract?.salaryRegistrationMethodType === 'Coarse') {
    return <SalaryRegistrationDisabled />
  }

  return (
    <SalaryRegistrationTabComponent
      showHistory={props.location.pathname.indexOf('/history') !== -1}
      company={company}
      employee={thisEmployee}
      costCenters={props.costCenters.costCenters}
      departments={props.departments.departments}
      salaryRegistrations={props.salaryRegistrations}
      salaryTypes={props.salaryTypes.salaryTypes}
      getSalaryRegistrations={props.getSalaryRegistrations}
      createSalaryRegistration={props.createSalaryRegistration}
      updateSalaryRegistration={props.updateSalaryRegistration}
      deleteSalaryRegistration={props.deleteSalaryRegistration}
      getSalaryTypes={props.getSalaryTypes}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    employee: state.employee,
    salaryTypes: state.salaryTypes,
    salaryRegistrations: state.salaryRegistrations,
    costCenters: state.costCenters,
    departments: state.departments,
  }),
  {
    getSalaryRegistrations: getSalaryRegistrations,
    createSalaryRegistration: createSalaryRegistration,
    updateSalaryRegistration: updateSalaryRegistration,
    deleteSalaryRegistration: deleteSalaryRegistration,
    getSalaryTypes: getSalaryTypes,
  }
)(SalaryRegistrationTab)
