import { DirectionsRenderer, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { t } from '../../utils/translation-utils'
import Switch from '../antd/switch'
import Alert from '../elements/alert'
import LoadingOverlay from '../widgets/LoadingOverlay'

type DirectionsResult = google.maps.DirectionsResult

const getGoogleMapsKey = () => {
  return process.env.REACT_APP_GOOGLE_MAPS_KEY || 'AIzaSyDQRws1zrCcwmbyxagxaavAmQ9BwBtcYME'
}

type Props = {
  from?: string
  to?: string
  avoidFerries: boolean
  disabled: boolean
  updateDistance: (meters: number, avoidFerries: boolean) => void
  updateReturnDistance?: (meters: number) => void
}

const center = { lat: 56.2, lng: 10.5 }

export default function CarAllowanceMap(props: Props): ReactElement | null {
  type State = {
    directions: DirectionsResult | null
    warning?: string
    avoidFerries: boolean
  }
  const [state, setState] = useState<State>({ directions: null, avoidFerries: props.avoidFerries })
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getGoogleMapsKey(),
  })

  const { updateDistance, updateReturnDistance } = props

  const updateDirections = useCallback(
    (from: string, to: string, avoidFerries: boolean) => {
      const interval = setInterval(() => {
        // Ensure Maps have been loaded
        if (!window.google) {
          return
        }
        clearInterval(interval)

        const DirectionsService = new window.google.maps.DirectionsService()
        DirectionsService.route(
          {
            origin: from,
            destination: to,
            travelMode: window.google.maps.TravelMode.DRIVING,
            avoidFerries: avoidFerries,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setState((prev) => ({ ...prev, directions: result }))
              if (result && result.routes.length > 0) {
                const legs = result.routes[0].legs
                if (legs.length > 0) {
                  if (legs[0].distance) {
                    updateDistance(legs[0].distance.value, state.avoidFerries)
                  }
                }
              }
            } else {
              setState((prev) => ({ ...prev, warning: t('car_allowance.no_route_found') }))
            }
          }
        )
        if (updateReturnDistance) {
          DirectionsService.route(
            {
              origin: to,
              destination: from,
              travelMode: window.google.maps.TravelMode.DRIVING,
              avoidFerries: avoidFerries,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                if (result && result.routes.length > 0) {
                  const legs = result.routes[0].legs
                  if (legs.length > 0) {
                    if (legs[0].distance) {
                      if (updateReturnDistance) {
                        updateReturnDistance(legs[0].distance.value)
                      }
                    }
                  }
                }
              } else {
                setState((prev) => ({ ...prev, warning: t('car_allowance.no_return_route_found') }))
              }
            }
          )
        }
      }, 10)
    },
    [updateDistance, updateReturnDistance, state]
  )

  const { from, to } = props
  const previousFrom = usePrevious(from)
  const previousTo = usePrevious(to)
  const previousState = usePrevious(state)

  useEffect(() => {
    if (from && to) {
      if (
        from === previousFrom &&
        to === previousTo &&
        (!previousState || previousState.avoidFerries === state.avoidFerries)
      ) {
        return // ignore duplicate calls
      }
      setState((prev) => ({ ...prev, warning: undefined }))
      updateDirections(from, to, state.avoidFerries)
    } else if (!!state.directions && !!state.warning) {
      setState((prev) => ({ ...prev, directions: null, warning: undefined }))
    }
  }, [from, to, previousFrom, previousTo, state, previousState, updateDirections])

  const _toggleFerries = (ferry: boolean) => {
    setState((prev) => ({ ...prev, avoidFerries: ferry }))
    if (from && to) {
      updateDirections(from, to, ferry)
    }
  }

  if (!isLoaded) {
    return <LoadingOverlay />
  }

  return (
    <div>
      <GoogleMap
        zoom={6}
        center={center}
        options={{ disableDefaultUI: true }}
        mapContainerClassName="employees-car-allowance-map"
      >
        {state.directions && <DirectionsRenderer directions={state.directions} />}
      </GoogleMap>
      <div>
        <div className="ant-switch-wrapper">
          <Switch onChange={_toggleFerries} checked={state.avoidFerries} disabled={props.disabled} />
          <span className="ant-switch-text">{t('car_allowance.avoid_ferries')}</span>
        </div>
      </div>
      {state.warning && (
        <Alert message={state.warning} type="warning" showIcon className="employees-car-allowance-warning" />
      )}
    </div>
  )
}
