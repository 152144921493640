import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../../model/salaryRegistration'
import SalaryType from '../../model/salaryType'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { paths } from '../../routes'
import { getDate } from '../../utils/date-utils'
import { formatCurrency, formatDisplayNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import SalaryRegistrationModal from '../salary-registration/SalaryRegistrationModal'

type Props = {
  employee: Employee
  company: Company
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>
  costCenters: List<CostCenter>
  departments: List<Department>

  addAlert: addAlertSignature
  createSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
  updateSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
}

export default function SalaryRegistrationCard(props: Props): ReactElement | null {
  const [creating, setCreating] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const setCreateVisibility = (toggle: boolean) => {
    setModalKey((prev) => prev + 1)
    setCreating(toggle)
  }

  const { salaryRegistrations, addAlert } = props
  const previousSalaryRegistrations = usePrevious(salaryRegistrations)

  useEffect(() => {
    if (previousSalaryRegistrations?.saving && !salaryRegistrations.saving) {
      if (!salaryRegistrations.error) {
        addAlert('success', t('dashboard.card_salary_registration.alert.success'), { timeout: 5 })
        setCreateVisibility(false)
      }
    }
  }, [previousSalaryRegistrations, salaryRegistrations, addAlert])

  const today = getDate()
  const summary = salaryRegistrations.salaryRegistrations
    .filter((reg) => isSameDay(getDate(reg.date), today))
    .reduce((list: { rate: number; quantity: number }[], reg) => {
      const salary = props.employee.activeContract?.remuneration.salary.find(
        (salary) => salary.salaryTypeID === reg.salaryTypeID
      )
      const rate = salary?.rate ?? 0
      const idx = list.findIndex((i) => i.rate === rate)
      if (idx === -1) {
        list.push({ rate, quantity: reg.quantity })
      } else {
        list[idx].quantity += reg.quantity
      }
      return list
    }, [])
    .sort((a, b) => b.rate - a.rate)

  return (
    <div className="card-salary-registration">
      <Headline>{t('dashboard.card_salary_registration.title')}</Headline>

      <div className="card-salary-registration-summary">
        {summary.map((item, i) => {
          return (
            <span key={i}>
              {t('dashboard.card_salary_registration.summary.line', {
                quantity: formatDisplayNumber(item.quantity),
                rate: formatCurrency(item.rate, 2),
                total: formatCurrency(item.quantity * item.rate, 2),
              })}
            </span>
          )
        })}
        {summary.length !== 1 && (
          <span>
            {t('dashboard.card_salary_registration.summary.total', {
              total: formatCurrency(
                summary.reduce((total, item) => total + item.quantity * item.rate, 0),
                2
              ),
            })}
          </span>
        )}
      </div>

      <Button className="dashboard-choice-register-button" type="primary" onClick={() => setCreateVisibility(true)}>
        {t('dashboard.card_salary_registration.register_new')}
      </Button>
      <Link to={'/' + paths.SALARY_REGISTRATION}>
        <Button className="dashboard-choice-page-button">{t('dashboard.card_salary_registration.go_to_page')}</Button>
      </Link>

      <Modal
        key={modalKey}
        visible={creating}
        onOk={() => setCreateVisibility(false)}
        onCancel={() => setCreateVisibility(false)}
        footer={null}
      >
        <SalaryRegistrationModal
          visible={creating}
          locked={false}
          company={props.company}
          employee={props.employee}
          salaryRegistrations={props.salaryRegistrations}
          salaryTypes={props.salaryTypes}
          costCenters={props.costCenters}
          departments={props.departments}
          createSalaryRegistration={props.createSalaryRegistration}
          updateSalaryRegistration={props.updateSalaryRegistration}
        />
      </Modal>
    </div>
  )
}
