import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Employee from '../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../model/oneTimePay'
import SalaryPeriod from '../../model/salaryPeriod'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { approveState } from '../../utils/approve-utils'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import Alert from '../elements/alert'
import FeeModalForm, { FeeResult } from './FeeModalForm'

type Props = {
  visible: boolean
  employee: Employee
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryPeriods: List<SalaryPeriod>
  createOneTimePay: (oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (oneTimePay: OneTimePayMutableFields) => void
}

export default function FeeModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, oneTimePays } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePays.error, error, setError)
  }, [visible, oneTimePays, error])

  const handleSubmit = (values: FeeResult) => {
    const oneTimePay: OneTimePayCreationFields = {
      employeeID: props.employee.id,
      type: 'Compensation',
      title: values.title,
      amount: values.amount,
      dispositionDate: values.dispositionDate,
      approved: approveState(props.employee),
    }
    if (props.oneTimePayID) {
      props.updateOneTimePay({
        ...oneTimePay,
        id: props.oneTimePayID,
      })
    } else {
      props.createOneTimePay(oneTimePay)
    }
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <FeeModalForm
        oneTimePayID={props.oneTimePayID}
        employee={props.employee}
        oneTimePays={props.oneTimePays}
        salaryPeriods={props.salaryPeriods}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
