import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CostCenter from '../model/costCenter'
import { ReducerAction } from '../utils/reducer-utils'

export interface CostCenterReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  costCenters: List<CostCenter>
  error: Error | null
}

const Params = Record<CostCenterReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  costCenters: List<CostCenter>(),
  error: null,
})

export interface CostCenterAction extends ReducerAction {
  costCenters?: CostCenter[]
}

const comparator = (a: CostCenter, b: CostCenter) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<CostCenterReducer> = Params(),
  action: CostCenterAction = { type: '' }
): Record<CostCenterReducer> => {
  switch (action.type) {
    case ActionTypes.COST_CENTER_LOADING:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COST_CENTER_LOADED:
    case ActionTypes.COST_CENTER_LOADED_PARTIAL:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.COST_CENTER_LOADED_PARTIAL,
        costCenters: state.get('costCenters').concat(List(action.costCenters)).sort(comparator),
      })
    case ActionTypes.COST_CENTER_LOAD_FAILED:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
