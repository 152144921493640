import Group from './Group'
import InputElement from './Input'
import Search from './Search'
import TextArea from './TextArea'

import './style/css'

const Input = Object.assign(InputElement, { Group, Search, TextArea })

export default Input
