import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import LeaveBalance from '../model/leaveBalance'
import { ReducerAction } from '../utils/reducer-utils'

export interface LeaveBalanceReducer {
  employeeID: string | undefined
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  dirty: boolean
  leaveBalances: List<LeaveBalance>
  error: Error | null
}

const Params = Record<LeaveBalanceReducer>({
  employeeID: undefined,
  companyID: undefined,
  loading: false,
  loaded: false,
  dirty: false,
  leaveBalances: List<LeaveBalance>(),
  error: null,
})

export interface LeaveBalanceAction extends ReducerAction {
  employeeID?: string
  leaveBalances?: LeaveBalance[]
  leaveBalance?: LeaveBalance
}

export default (
  state: Record<LeaveBalanceReducer> = Params(),
  action: LeaveBalanceAction = { type: '' }
): Record<LeaveBalanceReducer> => {
  switch (action.type) {
    case ActionTypes.LEAVE_BALANCES_LOADING:
      return Params({
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.LEAVE_BALANCES_LOADED:
      return Params({
        employeeID: action.employeeID,
        loaded: true,
        leaveBalances: List(action.leaveBalances),
      })
    case ActionTypes.LEAVE_BALANCES_LOAD_FAILED:
      return Params({
        employeeID: action.employeeID,
        error: action.error,
      })
    case ActionTypes.LEAVE_BALANCE_DIRTY: {
      return state.set('dirty', true)
    }

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
