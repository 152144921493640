import React from 'react'

import { delSwipe, fetchSwipes, fetchSwipeStatus, postSwipe } from '../api/swipe'
import ActionTypes from '../constants/action-types'
import Swipe, { SwipePayOutType, SwipeStatus } from '../model/swipe'
import { SwipeAction } from '../reducers/swipes'
import { SwipeStatusAction } from '../reducers/swipeStatus'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

export function loadingSwipeStatus(): SwipeStatusAction {
  return {
    type: ActionTypes.SWIPE_STATUS_LOADING,
  }
}
function loadedSwipeStatus(swipe: SwipeStatus): SwipeStatusAction {
  return {
    type: ActionTypes.SWIPE_STATUS_LOADED,
    swipe,
  }
}
function failedLoadingSwipeStatus(error: Error): SwipeStatusAction {
  return {
    type: ActionTypes.SWIPE_STATUS_LOAD_FAILED,
    error,
  }
}

function loadingSwipes(employeeID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_LOADING,
    employeeID,
  }
}
export function loadedSwipes(employeeID: string, swipes: Swipe[], partial = false): SwipeAction {
  return {
    type: partial ? ActionTypes.SWIPE_LOADED_PARTIAL : ActionTypes.SWIPE_LOADED,
    swipes,
    employeeID,
  }
}
function failedLoadingSwipes(employeeID: string, error: Error): SwipeAction {
  return {
    type: ActionTypes.SWIPE_LOAD_FAILED,
    error,
    employeeID,
  }
}

function addingSwipe(employeeID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_ADDING,
    employeeID,
  }
}
export function addedSwipe(swipeID: string, swipe: Swipe): SwipeAction {
  return {
    type: ActionTypes.SWIPE_ADDED,
    swipeID,
    swipe,
  }
}
function failedAddingSwipe(employeeID: string, error: Error): SwipeAction {
  return {
    type: ActionTypes.SWIPE_ADD_FAILED,
    error,
    employeeID,
  }
}

export function updatedSwipe(swipeID: string, swipe: Swipe): SwipeAction {
  return {
    type: ActionTypes.SWIPE_UPDATED,
    swipeID,
    swipe,
  }
}

function deletingSwipe(swipeID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_DELETING,
    swipeID,
  }
}
export function deletedSwipe(swipeID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_DELETED,
    swipeID,
  }
}
function failedDeletingSwipe(swipeID: string, error: Error): SwipeAction {
  return {
    type: ActionTypes.SWIPE_DELETE_FAILED,
    error,
    swipeID,
  }
}

export function getSwipeStatus(employeeID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loadingSwipeStatus())
    return fetchSwipeStatus(employeeID)
      .then((res) => {
        dispatch(loadedSwipeStatus(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSwipeStatus(e))
        }
      })
  }
}

export function getSwipes(employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingSwipes(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchSwipes(employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedSwipes(employeeID, data)),
          (data) => dispatch(loadedSwipes(employeeID, data, true)),
          (offset) => dispatch(getSwipes(employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSwipes(employeeID, e))
        }
      })
  }
}

export function createSwipe(employeeID: string, amount: number, payOut: SwipePayOutType) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(addingSwipe(employeeID))
    return postSwipe(employeeID, amount, payOut)
      .then((res) => {
        dispatch(addedSwipe(res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingSwipe(employeeID, e))
        }
      })
  }
}

export function deleteSwipe(swipeID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(deletingSwipe(swipeID))
    return delSwipe(swipeID)
      .then(() => {
        dispatch(deletedSwipe(swipeID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingSwipe(swipeID, e))
        }
      })
  }
}
