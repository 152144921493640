import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SalaryPeriod from '../model/salaryPeriod'
import { ReducerAction } from '../utils/reducer-utils'

export interface SalaryPeriodReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  salaryPeriods: List<SalaryPeriod>
  error: Error | null
}

const Params = Record<SalaryPeriodReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  salaryPeriods: List(),
  error: null,
})

export interface SalaryPeriodAction extends ReducerAction {
  employeeID?: string
  salaryPeriods?: SalaryPeriod[]
}

export default (
  state: Record<SalaryPeriodReducer> = Params(),
  action: SalaryPeriodAction = { type: '' }
): Record<SalaryPeriodReducer> => {
  switch (action.type) {
    case ActionTypes.SALARY_PERIODS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.SALARY_PERIODS_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        salaryPeriods: List(action.salaryPeriods),
      })
    case ActionTypes.SALARY_PERIODS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
