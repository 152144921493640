import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type LeaveHistoryMonth = {
  month: DateFormat
  contractWorkDays: number
  workDays: number
  officeDays: number
  daysOff: number
}

export function getLeaveHistory(employeeID: string, year: number): Promise<RequestResponse<LeaveHistoryMonth[]>> {
  return secureRequest('GET', url('/v2/leaveHistory', { employeeID, year }))
}
