import React from 'react'

import Upload from './Upload'

class Dragger extends React.Component {
  render() {
    const { props } = this
    return <Upload {...props} type="drag" style={{ ...props.style, height: props.height }} />
  }
}
export default Dragger
