import omit from 'omit.js'
import React, { CSSProperties, ReactElement, ReactNode, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import classNames from '../../antd/_util/classNames'
import Icon from '../icon'

type Props = {
  loading?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
  id?: string
  style?: CSSProperties
  disabled?: boolean
  tabIndex?: number
  type?: 'primary' | 'danger' | 'secondary'
  shape?: 'circle' | 'circle-outline'
  size?: 'large' | 'default' | 'small'
  htmlType?: 'submit' | 'button' | 'reset'
  className?: string
  icon?: string
  children?: ReactNode
  prefixCls?: string
}

export default function Button(props: Props): ReactElement | null {
  const [loading, setLoading] = useState(props.loading || false)

  const previousLoading = usePrevious(props.loading)

  useEffect(() => {
    if (typeof props.loading !== 'boolean') {
      return
    }
    if (previousLoading !== props.loading) {
      setLoading(props.loading)
    }
  }, [previousLoading, props.loading])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const onClick = props.onClick
    if (onClick) {
      onClick(e)
    }
  }

  const { type, shape, size, className, htmlType, children, icon, prefixCls = 'ant-btn', ...others } = props

  // large => lg
  // small => sm
  let sizeCls = ''
  switch (size) {
    case 'large':
      sizeCls = 'lg'
      break
    case 'small':
      sizeCls = 'sm'
      break
    default:
      break
  }

  const classes = classNames(prefixCls, className, {
    [`${prefixCls}-${type}`]: type,
    [`${prefixCls}-${shape}`]: shape,
    [`${prefixCls}-${sizeCls}`]: sizeCls,
    [`${prefixCls}-icon-only`]: !children && icon,
    [`${prefixCls}-loading`]: loading,
  })

  const iconType = loading ? 'loading' : icon
  const iconNode = iconType ? <Icon type={iconType} /> : null

  return (
    <button {...omit(others, ['loading'])} type={htmlType || 'button'} className={classes} onClick={handleClick}>
      {iconNode}
      {children}
    </button>
  )
}
