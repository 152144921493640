import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Input from '../elements/input'

type Fields = {
  password?: string
}

export type ResetPasswordResult = {
  password: string
}

function ResetPasswordForm(props: FormComponentProps<Fields, ResetPasswordResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      {decorateField('password', {
        placeholder: t('common.password'),
        validate: (val) => {
          if (!val) {
            return t('validation.password_is_required')
          }
          if (val.length < 8) {
            return t('validation.password_must_be_8_characters')
          }
          return null
        },
      })(<Input type="password" />)}
      <Form.Item>
        <Button htmlType="submit" className="ant-btn-secondary">
          {t('reset_password.approve_new_password')}
        </Button>
      </Form.Item>
    </div>
  )
}

export default withValidations<object, Fields, ResetPasswordResult>({
  onSubmit: (values) => ({ password: values.password! }),
})(ResetPasswordForm)
