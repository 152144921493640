import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import classNames from '../../antd/_util/classNames'

type Props = {
  type?: string
  align?: string
  justify?: string
  className?: string
  prefixCls?: string
  style?: CSSProperties
  children?: ReactNode
}

export default function Row(props: Props): ReactElement | null {
  const { type, justify, align, className, children, prefixCls = 'ant-row', ...others } = props
  const classes = classNames(
    {
      [prefixCls]: !type,
      [`${prefixCls}-${type}`]: type,
      [`${prefixCls}-${type}-${justify}`]: type && justify,
      [`${prefixCls}-${type}-${align}`]: type && align,
    },
    className
  )
  return (
    <div {...others} className={classes}>
      {children}
    </div>
  )
}
