import TimeRegistration, {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchTimeRegistrations(
  employeeID: string,
  fromDate: DateFormat | undefined,
  toDate: DateFormat | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<TimeRegistration[]>> {
  return secureRequest('GET', url('/v2/timeRegistrations', { employeeID, from: fromDate, to: toDate, limit, offset }))
}

export function postTimeRegistration(
  employeeID: string,
  timeRegistration: TimeRegistrationCreationFields
): Promise<RequestResponse<TimeRegistration>> {
  return secureRequest('POST', url('/v2/timeRegistrations'), {
    body: {
      employeeID: timeRegistration.employeeID,
      class: timeRegistration.class,
      salaryTypeID: timeRegistration.salaryTypeID,
      leaveTypeID: timeRegistration.leaveTypeID,
      projectID: timeRegistration.projectID,
      date: timeRegistration.date,
      start: timeRegistration.start,
      end: timeRegistration.end,
      days: timeRegistration.days,
      hours: timeRegistration.hours,
      minutes: timeRegistration.minutes,
      note: timeRegistration.note,
      leaveSubTypeID: timeRegistration.leaveSubTypeID,
      costCenterID: timeRegistration.costCenterID,
      approved: timeRegistration.approved,
    },
  })
}

export function postTimeRegistrationBulk(
  employeeID: string,
  timeRegistration: TimeRegistrationBulkFields
): Promise<RequestResponse<TimeRegistration[]>> {
  return secureRequest('POST', url('/v2/timeRegistrations/bulk'), {
    body: {
      employeeID: timeRegistration.employeeID,
      class: timeRegistration.class,
      salaryTypeID: timeRegistration.salaryTypeID,
      leaveTypeID: timeRegistration.leaveTypeID,
      startDate: timeRegistration.startDate,
      endDate: timeRegistration.endDate,
      note: timeRegistration.note,
      leaveSubTypeID: timeRegistration.leaveSubTypeID,
      costCenterID: timeRegistration.costCenterID,
      approved: timeRegistration.approved,
    },
  })
}

export function putTimeRegistration(
  timeRegistrationID: string,
  timeRegistration: TimeRegistrationMutableFields
): Promise<RequestResponse<TimeRegistration>> {
  return secureRequest('PUT', url('/v2/timeRegistrations/' + timeRegistrationID), {
    body: {
      employeeID: timeRegistration.employeeID,
      class: timeRegistration.class,
      salaryTypeID: timeRegistration.salaryTypeID,
      leaveTypeID: timeRegistration.leaveTypeID,
      projectID: timeRegistration.projectID,
      date: timeRegistration.date,
      days: timeRegistration.days,
      start: timeRegistration.start,
      end: timeRegistration.end,
      hours: timeRegistration.hours,
      minutes: timeRegistration.minutes,
      note: timeRegistration.note,
      leaveSubTypeID: timeRegistration.leaveSubTypeID,
      costCenterID: timeRegistration.costCenterID,
    },
  })
}

export function delTimeRegistration(timeRegistrationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/timeRegistrations/' + timeRegistrationID))
}
