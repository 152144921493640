import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { CarAllowanceRate, fetchCarAllowanceRates } from '../../api/car-allowances'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { DateFormat } from '../../model/types'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { formatAPIDate, formatDate, getDate, isTimeBetween } from '../../utils/date-utils'
import { combineSearchOption, FormComponentProps, withValidations } from '../../utils/form-utils'
import { getCurrentLocale } from '../../utils/language-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import { validateDecimal } from '../../utils/validation-utils'
import Form from '../antd/form'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Input from '../elements/input'
import RegistrationCostCenter, { NO_COST_CENTER } from '../elements/RegistrationCostCenter'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CarAllowanceMap from './CarAllowanceMap'

type Props = {
  locked: boolean
  carAllowanceID?: string
  employee: Employee
  carAllowances: CarAllowanceReducer
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>
}

type Fields = {
  date: Date
  createReturnTrip: boolean
  avoidFerries: boolean
  licensePlate?: string
  locationFrom?: string
  locationTo?: string
  kilometers?: string
  returnKilometers?: string
  reason?: string
  immutable: boolean
  costCenterID?: string
  rate?: string
}

export type ResultFields = {
  date: DateFormat
  avoidFerries: boolean
  licensePlate: string
  locationFrom: string
  locationTo: string
  createReturnTrip: boolean
  kilometers: number
  returnKilometers: number
  reason: string
  costCenterID?: string
  rate?: number
}

type Navigation = {
  from: string
  to: string
}

function CarAllowanceModalForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const [rates, setRates] = useState<CarAllowanceRate[]>()
  const [loading, setLoading] = useState(true)

  useEffectOnce(() => {
    fetchCarAllowanceRates()
      .then((res) => {
        if (!res) {
          return
        }
        setRates(res.data)
      })
      .finally(() => setLoading(false))
  })

  const [state, setState] = useState<Navigation>(() => {
    const state = { from: '', to: '' }
    if (props.carAllowanceID) {
      const carAllowance = props.carAllowances.carAllowances.find(
        (carAllowance) => props.carAllowanceID === carAllowance.id
      )
      if (carAllowance) {
        state.from = carAllowance.locationFrom || ''
        state.to = carAllowance.locationTo || ''
      }
    }
    return state
  })

  const locationFrom = props.getFieldValue('locationFrom')
  const locationTo = props.getFieldValue('locationTo')

  useEffect(() => {
    const distanceTimeout = setTimeout(() => {
      if (!locationFrom || !locationTo) {
        return
      }
      setState((prev) => ({ ...prev, from: locationFrom, to: locationTo }))
    }, 500)
    return () => clearTimeout(distanceTimeout)
  }, [locationFrom, locationTo])

  const updateDistance = (meters: number, avoidFerries: boolean) => {
    const { setFieldValue } = props
    setFieldValue('kilometers', formatInputNumber(meters / 1000, 2))
    setFieldValue('avoidFerries', avoidFerries)
  }
  const updateReturnDistance = (meters: number) => {
    const { setFieldValue } = props
    setFieldValue('returnKilometers', formatInputNumber(meters / 1000, 2))
  }

  const getLocations = (): string[] => {
    type Location = {
      name: string
      count: number
    }
    const locations: Record<string, Location> = {}
    const setLocation = (newLocation: string) => {
      newLocation = newLocation.replace(/ +/g, ' ')
      if (!locations[newLocation.toLowerCase()]) {
        locations[newLocation.toLowerCase()] = { name: newLocation, count: 1 }
      } else {
        locations[newLocation.toLowerCase()].count = locations[newLocation.toLowerCase()].count + 1
      }
    }
    props.carAllowances.carAllowances.forEach((carAllowance) => {
      setLocation(carAllowance.locationFrom)
      setLocation(carAllowance.locationTo)
    })
    return Object.values(locations)
      .sort((a, b) => {
        if (a.count === b.count) {
          return a.name.localeCompare(b.name)
        }
        return b.count - a.count
      })
      .filter((_, i) => i < 10) // limit to first 10
      .map((location) => location.name)
  }

  const { decorateField, getFieldValue } = props
  const isNew = !props.carAllowanceID

  if (loading) {
    return <LoadingOverlay />
  }

  const date = getDate(getFieldValue('date'))
  const carAllowanceRate =
    rates?.find((rates) => isTimeBetween(date, rates.validFrom, rates.validTo))?.rates.find((rate) => rate.step === 0)
      ?.rate ?? 0

  if (getFieldValue('immutable') || props.locked) {
    return (
      <div>
        {props.getFormError()}
        <Form.Item>
          <label>{t('common.date')}</label>
          {formatDate(getFieldValue('date'))}
        </Form.Item>
        <Form.Item>
          <label>{t('common.from')}</label>
          {getFieldValue('locationFrom')}
        </Form.Item>
        <Form.Item>
          <label>{t('common.to')}</label>
          {getFieldValue('locationTo')}
        </Form.Item>
        <Form.Item>
          <label>{t('car_allowance.purpose')}</label>
          {getFieldValue('reason')}
        </Form.Item>
        <Form.Item>
          <label>{t('car_allowance.license_plate')}</label>
          {getFieldValue('licensePlate')}
        </Form.Item>
        <Form.Item>
          <label>{t('car_allowance.distance')}</label>
          {getFieldValue('kilometers')} km
        </Form.Item>
        <Form.Item>
          <label>{t('car_allowance.suggested_route')}</label>
          <CarAllowanceMap
            from={state.from}
            to={state.to}
            avoidFerries={getFieldValue('avoidFerries')}
            disabled={true}
            updateDistance={updateDistance}
          />
        </Form.Item>
      </div>
    )
  }

  return (
    <div>
      {props.getFormError()}
      {decorateField('date', {
        placeholder: t('common.date'),
        validate: (val) => {
          if (!val) {
            return t('validation.date_is_required')
          }
          return null
        },
      })(<DatePicker allowClear={false} tabIndex={3} style={{ width: '100%' }} />)}
      {decorateField('locationFrom', {
        placeholder: t('common.from'),
        validate: (val) => (!val ? t('validation.from_is_required') : null),
      })(
        <Select
          mode="combobox"
          dropdownMatchSelectWidth={false}
          tabIndex={4}
          optionLabelProp="title"
          filterOption={(inputValue: string, option: ReactElement) => {
            return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }}
        >
          {getLocations().map((location) => {
            return (
              <Select.Option key={location} value={location} title={location}>
                {location}
              </Select.Option>
            )
          })}
        </Select>
      )}
      {decorateField('locationTo', {
        placeholder: t('common.to'),
        validate: (val) => (!val ? t('validation.to_is_required') : null),
      })(
        <Select
          mode="combobox"
          dropdownMatchSelectWidth={false}
          tabIndex={5}
          optionLabelProp="title"
          filterOption={(inputValue: string, option: ReactElement) => {
            return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }}
        >
          {getLocations().map((location) => {
            return (
              <Select.Option key={location} value={location} title={location}>
                {location}
              </Select.Option>
            )
          })}
        </Select>
      )}
      {decorateField('reason', {
        placeholder: t('car_allowance.purpose'),
        validate: (val) => (!val ? t('validation.purpose_is_required') : null),
      })(<Input tabIndex={6} />)}
      {decorateField('licensePlate', {
        placeholder: t('car_allowance.license_plate'),
        validate: (val) => (!val ? t('validation.license_plate_is_required') : null),
      })(<Input tabIndex={7} />)}
      {decorateField('kilometers', {
        placeholder: t('car_allowance.distance'),
        suffix: t('unit.km'),
        validate: (val) => {
          if (!val) {
            return t('validation.distance_is_required')
          }
          if (!validateDecimal(val)) {
            return t('validation.distance_is_invalid')
          }
          return null
        },
      })(<Input tabIndex={8} inputMode="decimal" lang={getCurrentLocale()} />)}
      {!!rates &&
        props.company.settingsEnabled.some((setting) => setting === 'AllowRatePerCarAllowance') &&
        decorateField('rate', {
          placeholder: t('car_allowance.rate'),
          suffix: t('car_allowance.rate.suffix'),
          validate: (val) => {
            if (!val) {
              return null
            }
            const n = forceParseInputNumber(val)
            if (!validateDecimal(val) || n < 0) {
              return t('car_allowance.rate.invalid')
            }
            if (n > carAllowanceRate) {
              return t('car_allowance.rate.above_limit', { limit: formatCurrency(carAllowanceRate, 2) })
            }
            return null
          },
        })(<Input tabIndex={9} inputMode="decimal" lang={getCurrentLocale()} />)}
      {isNew &&
        decorateField('createReturnTrip', {
          title: t('car_allowance.also_create_return_trip'),
        })(
          <RadioGroup>
            <Radio value={true}>{t('common.yes')}</Radio>
            <Radio value={false}>{t('common.no')}</Radio>
          </RadioGroup>
        )}
      <RegistrationCostCenter
        tabIndex={10}
        company={props.company}
        costCenters={props.costCenters}
        departments={props.departments}
        decorateField={decorateField}
        getFieldValue={getFieldValue}
      />
      <Form.Item>
        <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={5}>
          {t('car_allowance.register_car_allowance')}
        </Button>
      </Form.Item>
      <Form.Item>
        <label>
          {t('car_allowance.suggested_route')}
          {(!state.from || !state.to) && ' (' + t('car_allowance.enter_to_from') + ')'}
        </label>
        <CarAllowanceMap
          from={state.from}
          to={state.to}
          avoidFerries={getFieldValue('avoidFerries')}
          disabled={false}
          updateDistance={updateDistance}
          updateReturnDistance={isNew ? updateReturnDistance : undefined}
        />
      </Form.Item>
      {props.carAllowances.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const summary = props.carAllowances.carAllowances.reduce(
      (summary, carAllowance) => {
        if (carAllowance.avoidFerries) {
          summary.avoidFerry += 1
        } else {
          summary.withFerry += 1
        }
        return summary
      },
      { withFerry: 0, avoidFerry: 0 }
    )
    const fields: Fields = {
      date: getDate(),
      // so if the most common option for user is to avoid ferries; avoid ferries per default
      avoidFerries: summary.avoidFerry > summary.withFerry,
      createReturnTrip: false,
      immutable: false,
      costCenterID: NO_COST_CENTER,
    }
    if (props.carAllowances.carAllowances.size > 0) {
      fields.licensePlate = props.carAllowances.carAllowances.first()?.licensePlate
    }
    if (props.carAllowanceID) {
      const carAllowance = props.carAllowances.carAllowances.find(
        (carAllowance) => carAllowance.id === props.carAllowanceID
      )
      if (carAllowance) {
        fields.date = getDate(carAllowance.date)
        fields.licensePlate = carAllowance.licensePlate
        fields.locationFrom = carAllowance.locationFrom
        fields.locationTo = carAllowance.locationTo
        fields.kilometers = formatInputNumber(carAllowance.kilometers, 2)
        fields.reason = carAllowance.reason
        fields.avoidFerries = carAllowance.avoidFerries
        fields.immutable = carAllowance.immutable
        fields.costCenterID = carAllowance.costCenterID
        fields.rate = carAllowance.rate ? formatInputNumber(carAllowance.rate) : undefined
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'kilometers':
      case 'returnKilometers':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const rate = values.rate ? forceParseInputNumber(values.rate) : undefined
    return {
      date: formatAPIDate(values.date),
      licensePlate: values.licensePlate!,
      locationFrom: values.locationFrom!,
      locationTo: values.locationTo!,
      createReturnTrip: values.createReturnTrip,
      kilometers: forceParseInputNumber(values.kilometers),
      returnKilometers: forceParseInputNumber(values.returnKilometers),
      avoidFerries: values.avoidFerries,
      reason: values.reason!,
      costCenterID: values.costCenterID === NO_COST_CENTER ? undefined : values.costCenterID,
      rate: rate && rate > 0 ? rate : undefined,
    }
  },
})(CarAllowanceModalForm)
