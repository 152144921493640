import classNames from 'classnames'
import PropTypes from 'prop-types'
import RcCheckbox from 'rc-checkbox'
import React from 'react'
import shallowEqual from 'shallowequal'

class Radio extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !shallowEqual(this.props, nextProps) ||
      !shallowEqual(this.state, nextState) ||
      !shallowEqual(this.context.radioGroup, nextContext.radioGroup)
    )
  }

  render() {
    const { props, context } = this
    const { prefixCls, className, children, style, ...restProps } = props
    const { radioGroup } = context
    let radioProps = { ...restProps }
    if (radioGroup) {
      radioProps.name = radioGroup.name
      radioProps.onChange = radioGroup.onChange
      radioProps.checked = props.value === radioGroup.value
      radioProps.disabled = props.disabled || radioGroup.disabled
    }
    const wrapperClassString = classNames(className, {
      [`${prefixCls}-wrapper`]: true,
      [`${prefixCls}-wrapper-checked`]: radioProps.checked,
      [`${prefixCls}-wrapper-disabled`]: radioProps.disabled,
    })

    return (
      <label
        className={wrapperClassString}
        style={style}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        <RcCheckbox {...radioProps} prefixCls={prefixCls} />
        {children !== undefined ? <span>{children}</span> : null}
      </label>
    )
  }
}

Radio.defaultProps = {
  prefixCls: 'ant-radio',
  type: 'radio',
}

Radio.contextTypes = {
  radioGroup: PropTypes.any,
}

export default Radio
