import React, { ReactElement, ReactNode, useState } from 'react'

import Company from '../../model/company'
import { Day } from '../../model/types'
import CompanySetting from '../../types/company-setting'
import { getLanguage } from '../../utils/cookie-utils'
import { getDateForWeekDay } from '../../utils/date-utils'
import { formatDay, week } from '../../utils/day-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import {
  forceParseInputNumber,
  formatInputAsMinutes,
  formatInputNumber,
  formatMinutesAsTime,
  parseInputNumber,
  parseTimeAsMinutes,
} from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { capitalise } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Icon from '../elements/icon'
import Input from '../elements/input'

type BasicTimeReg = {
  hours?: number
  start?: number
  end?: number
}

type Props = {
  company: Company
  weekStart: Date
  workWeek: Day[]
  registrations: Partial<Record<Day, BasicTimeReg[]>>
}

type RegistrationProps = Props & {
  templateMode: false
  templateButton: () => ReactNode
}

type TemplateProps = Props & {
  templateMode: true
  hasEmployeeTemplate: boolean
  hasCompanyTemplate: boolean

  onDelete: () => void
  onNone: () => void
}

type Fields = Partial<Record<`${Day}-hours`, string>> &
  Partial<Record<`${Day}-startTime`, string>> &
  Partial<Record<`${Day}-endTime`, string>> &
  Partial<Record<`${Day}-breakMinutes`, string>>

type ResultDay = {
  date: Date
  hours: number
  start?: number
  end?: number
}

export type WorkHoursResult = {
  days: ResultDay[]
}

function WorkHoursWeekForm(
  props: (RegistrationProps | TemplateProps) & FormComponentProps<Fields, WorkHoursResult>
): ReactElement | null {
  const [disabled, setDisabled] = useState(props.templateMode && !props.hasEmployeeTemplate && props.hasCompanyTemplate)
  const { decorateField, getFieldValue } = props

  const doTimeBasedRegistration = (): boolean => {
    if (week.some((day) => !!props.getFieldValue(`${day}-startTime`) || !!props.getFieldValue(`${day}-endTime`))) {
      // one of the registrations is set
      return true
    }
    return props.company.settingsEnabled.some((setting) => setting === CompanySetting.REGISTER_WORK_HOURS_START_END)
  }

  const remove = (day: Day) => {
    return () => {
      props.setFieldValue(`${day}-hours`, undefined)
      props.setFieldValue(`${day}-startTime`, undefined)
      props.setFieldValue(`${day}-endTime`, undefined)
      props.setFieldValue(`${day}-breakMinutes`, undefined)
    }
  }

  const unlock = () => {
    setDisabled(false)
    week.forEach((day) => {
      props.setFieldValue(`${day}-hours`, undefined)
      props.setFieldValue(`${day}-startTime`, undefined)
      props.setFieldValue(`${day}-endTime`, undefined)
      props.setFieldValue(`${day}-breakMinutes`, undefined)
    })
  }

  return (
    <div className="work-hours-week-form">
      {props.templateMode && <p>{t('work_hours_registration_tab.simple_form.template_intro')}</p>}
      {disabled && <p>{t('work_hours_registration_tab.simple_form.disabled_intro')}</p>}
      <p>{t('work_hours_registration_tab.simple_form.intro')}</p>
      {!doTimeBasedRegistration() && (
        <>
          {!props.templateMode && props.templateButton()}
          <div className="work-hours-week-form-hours-week">
            {week.map((day) => {
              if (!props.workWeek.some((d) => d === day)) {
                return null
              }
              return (
                <Row key={day}>
                  <Col span={8}>
                    <span className="ant-form-item-label">{capitalise(formatDay(day))}</span>
                  </Col>
                  <Col span={16}>
                    {decorateField(`${day}-hours`, {
                      placeholder: capitalise(formatDay(day)),
                      skipWrapper: true,
                      skipLabel: true,
                    })(<Input inputMode="decimal" lang={getLanguage()} disabled={disabled} />)}
                  </Col>
                </Row>
              )
            })}
          </div>
        </>
      )}
      {doTimeBasedRegistration() && (
        <>
          <p>{t('work_hours_registration_tab.simple_form.time_based.intro')}</p>
          {!props.templateMode && props.templateButton()}
          <Row>
            <Col span={5} className="work-hours-weekday">
              {t('work_hours_registration_tab.simple_form.time_based.header.day')}
            </Col>
            <Col span={6}>{t('work_hours_registration_tab.simple_form.time_based.header.start')}</Col>
            <Col span={6}>{t('work_hours_registration_tab.simple_form.time_based.header.end')}</Col>
            <Col span={6}>{t('work_hours_registration_tab.simple_form.time_based.header.break')}</Col>
          </Row>
          {week.map((day) => {
            if (!props.workWeek.some((d) => d === day)) {
              return null
            }
            return (
              <Row key={day}>
                <Col span={5} className="work-hours-weekday">
                  <span className="ant-form-item-label">{capitalise(formatDay(day))}</span>
                </Col>
                <Col span={6}>
                  {decorateField(`${day}-startTime`, {
                    placeholder: t('work_hours_registration_tab.simple_form.time_based.start'),
                    skipLabel: true,
                    skipWrapper: true,
                  })(<Input inputMode="decimal" lang={getLanguage()} disabled={disabled} />)}
                </Col>
                <Col span={6}>
                  {decorateField(`${day}-endTime`, {
                    placeholder: t('work_hours_registration_tab.simple_form.time_based.end'),
                    skipLabel: true,
                    skipWrapper: true,
                  })(<Input inputMode="decimal" lang={getLanguage()} disabled={disabled} />)}
                </Col>
                <Col span={5}>
                  {decorateField(`${day}-breakMinutes`, {
                    placeholder: t('work_hours_registration_tab.simple_form.time_based.break'),
                    skipLabel: true,
                    skipWrapper: true,
                    validate: (val) => {
                      if (!val) {
                        return null
                      }
                      if (!val.match(/^([0-9,.]+)/)) {
                        return t('work_hours_registration_tab.simple_form.time_based.invalid')
                      }
                      const num = forceParseInputNumber(val)
                      if (num % 1 !== 0) {
                        return t('work_hours_registration_tab.simple_form.time_based.must_be_integer')
                      }
                      const startTime = getFieldValue(`${day}-startTime`)
                      const endTime = getFieldValue(`${day}-endTime`)
                      if (startTime && endTime && parseTimeAsMinutes(endTime) - parseTimeAsMinutes(startTime) <= num) {
                        return t('work_hours_registration_tab.simple_form.time_based.longer_before_total')
                      }
                      return null
                    },
                  })(<Input inputMode="decimal" lang={getLanguage()} disabled={disabled} />)}
                </Col>
                <Col span={2} className="text-cell">
                  {(!!getFieldValue(`${day}-startTime`) ||
                    !!getFieldValue(`${day}-endTime`) ||
                    !!getFieldValue(`${day}-breakMinutes`)) && (
                    <span onClick={remove(day)} style={{ cursor: 'pointer' }}>
                      <Icon type={'cross'} />
                    </span>
                  )}
                </Col>
              </Row>
            )
          })}
        </>
      )}
      <Row>
        {disabled && (
          <Col span={12}>
            <Button onClick={() => unlock()}>{t('work_hours_registration_tab.simple_form.unlock')}</Button>
          </Col>
        )}
        {!disabled && (
          <>
            <Col span={props.templateMode ? (props.hasEmployeeTemplate ? 8 : 12) : 24}>
              <Button htmlType="submit" size="large" type="primary">
                {t('work_hours_registration_tab.simple_form.submit' + (props.templateMode ? '.template' : ''))}
              </Button>
            </Col>
            {props.templateMode && props.hasEmployeeTemplate && (
              <Col span={8}>
                <Button onClick={props.onDelete} size="large" type="danger">
                  {t(
                    'work_hours_registration_tab.simple_form.' +
                      (props.hasCompanyTemplate ? 'delete_with_company' : 'delete')
                  )}
                </Button>
              </Col>
            )}
          </>
        )}
        {props.templateMode && (
          <Col span={props.hasEmployeeTemplate ? 8 : 12}>
            <Button onClick={props.onNone} size="large">
              {t('work_hours_registration_tab.simple_form.none')}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default withValidations<RegistrationProps | TemplateProps, Fields, WorkHoursResult>({
  mapPropsToFields: (props) => {
    return week.reduce((fields: Fields, day) => {
      const row = props.registrations[day]
      if (row && row.length > 0) {
        const reg = row[0]
        fields[`${day}-hours`] = formatInputNumber(reg.hours)
        if (reg.start !== undefined) {
          fields[`${day}-startTime`] = formatMinutesAsTime(reg.start)
          fields[`${day}-breakMinutes`] = '0'
          if (reg.end !== undefined) {
            fields[`${day}-endTime`] = formatMinutesAsTime(reg.end)
            fields[`${day}-breakMinutes`] = formatInputNumber(
              ((reg.end - reg.start) / 60 - (reg.hours ? reg.hours : 0)) * 60,
              0
            )
          } else if (reg.hours && reg.hours > 0) {
            fields[`${day}-endTime`] = formatMinutesAsTime(reg.start + reg.hours * 60)
          }
        }
      }
      return fields
    }, {})
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key.match(/.+startTime$/) || key.match(/.endTime$/)) {
      if (options.trigger === 'onBlur') {
        setByPath(values, key, formatInputAsMinutes(val as string))
      } else {
        setByPath(values, key, (val as string).replace(/[ ]/g, ''))
      }
    } else if (key.match(/.+hours$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values, props) => {
    const days: ResultDay[] = []
    week.forEach((day) => {
      const result: ResultDay = {
        date: getDateForWeekDay(props.weekStart, day),
        hours: 0,
      }
      const startTime = values[`${day}-startTime`]
      const endTime = values[`${day}-endTime`]
      if (startTime) {
        result.start = parseTimeAsMinutes(startTime)
        if (endTime) {
          result.end = parseTimeAsMinutes(endTime)
          result.hours = (result.end - result.start - forceParseInputNumber(values[`${day}-breakMinutes`])) / 60
        }
      } else {
        result.hours = forceParseInputNumber(values[`${day}-hours`])
      }
      days.push(result)
    })
    return { days }
  },
})(WorkHoursWeekForm)
