import Company from '../model/company'
import Employee from '../model/employee'
import { isEmployeeType } from './employee-utils'

export function hasSwipe(company?: Company, employee?: Employee): boolean {
  if (!employee || !company) {
    return false
  }
  if (!employee.hasSwipe) {
    return false
  }
  if (!company.enableSwipe) {
    return false
  }
  if (!company.swipeEmployeeTypes) {
    return false
  }
  return company.swipeEmployeeTypes.some((employeeType) => isEmployeeType(employee, employeeType))
}
