import React from 'react'

import { fetchProjects } from '../api/projects'
import ActionTypes from '../constants/action-types'
import Project from '../model/project'
import { ProjectAction } from '../reducers/projects'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingProjects(companyID: string): ProjectAction {
  return {
    type: ActionTypes.PROJECT_LOADING,
    companyID,
  }
}
function loadedProjects(companyID: string, projects: Project[], partial = false): ProjectAction {
  return {
    type: partial ? ActionTypes.PROJECT_LOADED_PARTIAL : ActionTypes.PROJECT_LOADED,
    projects,
    companyID,
  }
}
function failedLoadingProjects(companyID: string, error: Error): ProjectAction {
  return {
    type: ActionTypes.PROJECT_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getProjects(companyID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingProjects(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchProjects(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedProjects(companyID, data)),
          (data) => dispatch(loadedProjects(companyID, data, true)),
          (offset) => dispatch(getProjects(companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingProjects(companyID, e))
        }
      })
  }
}
