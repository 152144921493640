import { isSameDay, subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { useEffect } from 'react'
import { ReactElement } from 'react'
import { usePrevious } from 'react-use'

import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import { DateFormat } from '../../model/types'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import CostCenterAccounting from '../../types/cost-center-accounting'
import ReimbursementVoucherApprovalState from '../../types/reimbursement-voucher-approval-state'
import { getLanguage } from '../../utils/cookie-utils'
import { formatAPIDate, getDate, isTimeBefore } from '../../utils/date-utils'
import { isWeb } from '../../utils/device-utils'
import { combineSearchOption, FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import { getCurrencies, validateDecimal } from '../../utils/validation-utils'
import Form from '../antd/form'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Switch from '../antd/switch'
import Alert from '../elements/alert'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

const NO_COST_CENTER = 'NoCostCenter'

type Props = {
  waitForScan: boolean
  waitForCreation: boolean
  immediatePayEnabled: boolean
  reimbursementVoucherID?: string
  employee: Employee
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  editable: boolean
}

type Fields = {
  receiptDate: Date
  isForeignCurrency: boolean
  disburseable: boolean
  ready: boolean
  immediatePay: boolean
  costCenterID: string
  currency?: string
  disburseAmount?: string
  expenseCategoryID?: string
  note?: string
  amount?: string
  cardNote?: string
}

export type ResultFields = {
  receiptDate: DateFormat
  disburseable: boolean
  ready: boolean
  immediatePay: boolean
  currency: string
  disburseAmount: number
  costCenterID?: string
  expenseCategoryID?: string
  note?: string
  amount?: number
  cardNote?: string
}

function ReimbursementVouchersModalForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const { reimbursementVoucherID, reimbursementVouchers } = props
  const previousReimbursementVoucherID = usePrevious(reimbursementVoucherID)

  useEffect(() => {
    const { getFieldValue, setFieldValue } = props
    if (previousReimbursementVoucherID !== reimbursementVoucherID) {
      const reimbursementVoucher = reimbursementVouchers.reimbursementVouchers.find(
        (voucher) => voucher.id === reimbursementVoucherID
      )
      if (reimbursementVoucher) {
        if (isSameDay(getFieldValue('receiptDate'), getDate()) && reimbursementVoucher.receiptDate) {
          const receiptDate = getDate(reimbursementVoucher.receiptDate)
          setFieldValue('receiptDate', receiptDate)
        }
        if (!getFieldValue('disburseAmount')) {
          setFieldValue('isForeignCurrency', false)
          if (reimbursementVoucher.disburseAmount) {
            setFieldValue('disburseAmount', formatInputNumber(reimbursementVoucher.disburseAmount, 2))
          } else if (!reimbursementVoucher.currency && reimbursementVoucher.amount) {
            // if no currency, force assume it to default currency, so we use amount for disburseAmount
            setFieldValue('disburseAmount', formatInputNumber(reimbursementVoucher.amount, 2))
          } else if (reimbursementVoucher.currency && reimbursementVoucher.currency !== 'DKK') {
            setFieldValue('isForeignCurrency', true)
          }
        }
        if (getFieldValue('disburseable') == null) {
          setFieldValue('disburseable', reimbursementVoucher.disburseable)
        }
      }
    }
  })

  const { decorateField, getFieldError, getFieldValue } = props
  const enabled = props.editable !== false

  const costCenterAccounting =
    props.costCenters.size > 0 &&
    (props.costCenterAccounting === CostCenterAccounting.DEPARTMENTS ||
      props.costCenterAccounting === CostCenterAccounting.COST_CENTERS)
  const isReceiptDateOld = isTimeBefore(getFieldValue('receiptDate'), subMonths(getDate(), 12))

  return (
    <div>
      {props.getFormError()}
      {decorateField('expenseCategoryID', {
        placeholder: t('reimbursement_vouchers.expense_category'),
      })(
        <Select tabIndex={1} disabled={!enabled}>
          {props.expenseCategories
            .filter(
              (expenseCategory) => expenseCategory.active || expenseCategory.id === getFieldValue('expenseCategoryID')
            )
            .map((expenseCategory) => {
              return (
                <Select.Option key={expenseCategory.id} value={expenseCategory.id}>
                  {expenseCategory.name}
                </Select.Option>
              )
            })}
        </Select>
      )}
      {decorateField('note', {
        placeholder: t('common.note'),
      })(<Input tabIndex={2} disabled={!enabled} />)}
      {isReceiptDateOld && <Alert type={'warning'} message={t('reimbursement_voucher.date_is_old')} showIcon />}
      <div style={{ position: 'relative' }}>
        {decorateField('receiptDate', {
          placeholder: t('common.date'),
          validate: (val) => {
            if (!val) {
              return t('validation.date_is_required')
            }
            return null
          },
        })(<DatePicker allowClear={false} tabIndex={3} style={{ width: '100%' }} />)}
        <Form.Item validateStatus={getFieldError('isForeignCurrency') ? 'error' : 'success'}>
          <div className="ant-switch-wrapper">
            {decorateField('isForeignCurrency', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch tabIndex={7} disabled={!enabled} />)}
            <span className="ant-switch-text">{t('reimbursement_vouchers.foreign_currency')}</span>
          </div>
        </Form.Item>
        {getFieldValue('isForeignCurrency') && (
          <Form.Item validateStatus={getFieldError('currency') || getFieldError('amount') ? 'error' : 'success'}>
            <label htmlFor="amount" title={t('reimbursement_vouchers.amount_in_foreign_currency')}>
              {getFieldError('currency') ||
                getFieldError('amount') ||
                t('reimbursement_vouchers.amount_in_foreign_currency')}
            </label>
            <Input.Group compact className="country-code-phone-group">
              {decorateField('currency', {
                placeholder: t('common.currency'),
                validate: (val) => {
                  if (!val) {
                    return t('validation.currency_is_required')
                  }
                  if (getCurrencies().indexOf(val) === -1 || val === '---') {
                    return t('validation.currency_is_invalid')
                  }
                  return null
                },
                skipWrapper: true,
                skipLabel: true,
              })(
                <Select tabIndex={6} dropdownMatchSelectWidth={false} disabled={!enabled || props.waitForScan}>
                  {getCurrencies().map((prefix) => {
                    return (
                      <Select.Option key={prefix} value={prefix} disabled={prefix === '---'}>
                        {prefix}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
              {decorateField('amount', {
                placeholder: t('common.amount'),
                skipWrapper: true,
                skipLabel: true,
                validate: (val) => {
                  if (!val) {
                    return t('validation.amount_is_required')
                  }
                  if (!validateDecimal(val)) {
                    return t('validation.amount_is_invalid')
                  }
                  return null
                },
              })(
                <Input tabIndex={7} inputMode="decimal" lang={getLanguage()} disabled={!enabled || props.waitForScan} />
              )}
            </Input.Group>
          </Form.Item>
        )}
        {decorateField('disburseAmount', {
          placeholder: t('reimbursement_vouchers.disburse_amount'),
          suffix: 'kr. ',
          validate: (val) => {
            if (!val) {
              return t('validation.disburse_amount_is_required')
            }
            if (!validateDecimal(val)) {
              return t('validation.disburse_amount_is_invalid')
            }
            return null
          },
        })(<Input tabIndex={8} inputMode="decimal" lang={getLanguage()} disabled={!enabled || props.waitForScan} />)}
        {props.waitForScan && <LoadingOverlay />}
      </div>
      <Form.Item validateStatus={getFieldError('disburseable') ? 'error' : 'success'} style={{ marginBottom: 20 }}>
        {decorateField('disburseable', {
          title: t('reimbursement_vouchers.disburseable_type'),
        })(
          <RadioGroup tabIndex={9}>
            <Radio value={true} disabled={!enabled}>
              {t('reimbursement_vouchers.own_voucher_disburseable')}
            </Radio>
            <Radio value={false} disabled={!enabled}>
              {t('reimbursement_vouchers.company_card_only_accounting')}
            </Radio>
          </RadioGroup>
        )}
      </Form.Item>
      {!getFieldValue('disburseable') &&
        decorateField('cardNote', {
          title: t('reimbursement_vouchers.description_of_company_card_for_accounting'),
          placeholder: t('common.description'),
        })(<Input tabIndex={10} disabled={!enabled} />)}
      {costCenterAccounting &&
        decorateField('costCenterID', {
          placeholder: t('reimbursement_vouchers.cost_center'),
        })(
          <Select
            dropdownMatchSelectWidth={false}
            showSearch={isWeb()}
            filterOption={(inputValue: string, option: ReactElement) => {
              return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
            tabIndex={11}
          >
            <Select.Option key={NO_COST_CENTER} value={NO_COST_CENTER}>
              <i>
                {props.costCenterAccounting === CostCenterAccounting.DEPARTMENTS
                  ? t('reimbursement_vouchers.no_department')
                  : t('reimbursement_vouchers.no_cost_center')}
              </i>
            </Select.Option>
            {props.costCenters
              .filter((costCenter) => costCenter.active)
              .map((costCenter) => {
                return (
                  <Select.Option key={costCenter.id} value={costCenter.id}>
                    {costCenter.name}
                  </Select.Option>
                )
              })}
          </Select>
        )}
      <Form.Item validateStatus={getFieldError('ready') ? 'error' : 'success'} style={{ marginTop: 20 }}>
        <label>{t('reimbursement_vouchers.ready_for_approval')}</label>
        <div className="ant-switch-wrapper">
          {decorateField('ready', {
            skipWrapper: true,
            skipLabel: true,
            valueOnChecked: true,
            noBlur: true,
          })(<Switch tabIndex={12} disabled={!enabled} />)}
          <span className="ant-switch-text">{t('reimbursement_vouchers.send_for_approval')}</span>
        </div>
      </Form.Item>
      {props.immediatePayEnabled && getFieldValue('disburseable') && (
        <Form.Item validateStatus={getFieldError('immediatePay') ? 'error' : 'success'}>
          <label>{t('reimbursement_vouchers.immediate_pay_label')}</label>
          <div className="ant-switch-wrapper">
            {decorateField('immediatePay', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch tabIndex={13} disabled={!enabled} />)}
            <span className="ant-switch-text">{t('reimbursement_vouchers.ask_for_immediate_pay')}</span>
          </div>
        </Form.Item>
      )}
      {enabled && (
        <Form.Item>
          <div style={{ position: 'relative', margin: -5, padding: 5 }}>
            <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={14}>
              {t('reimbursement_vouchers.add_voucher')}
            </Button>
            {props.waitForCreation && <LoadingOverlay />}
          </div>
        </Form.Item>
      )}
      {props.reimbursementVouchers.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      receiptDate: getDate(),
      isForeignCurrency: false,
      disburseable: true,
      ready: true,
      immediatePay: !!props.immediatePayEnabled,
      costCenterID: NO_COST_CENTER,
    }
    const reimbursementVoucher = props.reimbursementVouchers.reimbursementVouchers.find(
      (voucher) => voucher.id === props.reimbursementVoucherID
    )
    if (reimbursementVoucher) {
      if (reimbursementVoucher.receiptDate) {
        fields.receiptDate = getDate(reimbursementVoucher.receiptDate)
      }
      if (reimbursementVoucher.disburseAmount) {
        fields.disburseAmount = formatInputNumber(reimbursementVoucher.disburseAmount, 2)
      }
      if (reimbursementVoucher.amount && reimbursementVoucher.currency !== 'DKK') {
        fields.isForeignCurrency = true
        if (reimbursementVoucher.amount) {
          fields.amount = formatInputNumber(reimbursementVoucher.amount, 2)
        }
        fields.currency = reimbursementVoucher.currency
      }
      if (reimbursementVoucher.expenseCategory) {
        fields.expenseCategoryID = reimbursementVoucher.expenseCategory.id
      }
      fields.note = reimbursementVoucher.note
      fields.disburseable = reimbursementVoucher.disburseable
      fields.cardNote = reimbursementVoucher.cardNote
      fields.costCenterID = reimbursementVoucher.costCenterID ? reimbursementVoucher.costCenterID : NO_COST_CENTER
      fields.ready = reimbursementVoucher.approvalState === ReimbursementVoucherApprovalState.READY
      fields.immediatePay = reimbursementVoucher.immediatePay
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'amount':
      case 'disburseAmount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: ResultFields = {
      receiptDate: formatAPIDate(values.receiptDate),
      disburseable: values.disburseable,
      ready: values.ready,
      immediatePay: values.immediatePay,
      disburseAmount: values.disburseAmount !== undefined ? forceParseInputNumber(values.disburseAmount) : 0,
      amount: values.amount !== undefined ? forceParseInputNumber(values.amount) : 0,
      currency: values.currency || 'DKK',
      costCenterID: values.costCenterID === NO_COST_CENTER ? undefined : values.costCenterID,
      expenseCategoryID: values.expenseCategoryID,
      note: values.note,
      cardNote: values.cardNote,
    }
    if (!values.isForeignCurrency) {
      result.amount = result.disburseAmount // when the same currency, these two values are the same
      result.currency = 'DKK' // for set it to DKK, just in case it was blank
    }
    if (values.disburseable) {
      result.cardNote = undefined
    } else {
      result.immediatePay = false
    }
    return result
  },
})(ReimbursementVouchersModalForm)
