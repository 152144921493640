import { RequestResponse } from '../utils/request-utils'

export function handlePagination<T>(
  res: RequestResponse<T[]>,
  limit: number,
  offset: number | undefined,
  callLoaded: (data: T[]) => void,
  callPartialLoaded: (data: T[]) => void,
  fetchCall: (offset: number) => void
): T[] {
  offset = offset ?? 0
  if (res.pagination && res.pagination.count > offset + limit) {
    callPartialLoaded(res.data)
    fetchCall(offset + limit)
    return res.data
  } else {
    callLoaded(res.data)
    return res.data
  }
}
