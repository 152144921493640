import React, { ReactElement } from 'react'

import {
  createTimeRegistration,
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import TimeRegistrationDisabledComponent from '../components/time-registration/TimeRegistrationDisabled'
import TimeRegistrationTabComponent from '../components/time-registration/TimeRegistrationTab'
import { TimeRegistrationMode } from '../components/types'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employee'
import { LeaveBalanceReducer } from '../reducers/leaveBalances'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { paths } from '../routes'
import CompanySetting from '../types/company-setting'
import { hasFlex, hasOvertime } from '../utils/employee-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: LeaveTypeReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  leaveBalances: LeaveBalanceReducer
}

type Actions = {
  deleteTimeRegistration: (id: string, employeeID: string) => void
  getTimeRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
}

function TimeBoxRegistrationTab(props: Reducers & Actions & RouteProps): ReactElement | null {
  const employee = props.employee.employee
  const company = props.company.company

  if (!company || !employee) {
    // just default to FlexTime for now
    return <TimeRegistrationDisabledComponent mode={'Flex'} />
  }

  const employeeHasFlex = hasFlex(employee, props.leaveTypes.leaveTypes.toArray())
  const employeeHasOvertime = hasOvertime(employee, props.leaveTypes.leaveTypes.toArray())

  if (!employeeHasFlex && !employeeHasOvertime) {
    jsBrowserHistory.push('/' + paths.LEAVE_REGISTRATION)
    return null
  }

  const mode: TimeRegistrationMode = employeeHasFlex ? 'Flex' : 'Overtime'

  if (employee.activeContract?.timeRegistrationMethodType === 'Coarse') {
    return <TimeRegistrationDisabledComponent mode={mode} />
  }

  if (company.settingsEnabled.some((setting) => setting === CompanySetting.DISABLE_APP_FLEX_OVERTIME_REGISTRATION)) {
    return <TimeRegistrationDisabledComponent mode={mode} />
  }

  return (
    <TimeRegistrationTabComponent
      mode={mode}
      showHistory={props.location.pathname.indexOf('/history') !== -1}
      company={company}
      employee={employee}
      timeRegistrations={props.timeRegistrations}
      leaveTypes={props.leaveTypes.leaveTypes}
      leaveBalances={props.leaveBalances.leaveBalances}
      costCenters={props.costCenters.costCenters}
      departments={props.departments.departments}
      getTimeRegistrations={props.getTimeRegistrations}
      createTimeRegistration={props.createTimeRegistration}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    employee: state.employee,
    timeRegistrations: state.timeRegistrations,
    leaveTypes: state.leaveTypes,
    leaveBalances: state.leaveBalances,
    costCenters: state.costCenters,
    departments: state.departments,
  }),
  {
    getTimeRegistrations: getTimeRegistrations,
    createTimeRegistration: createTimeRegistration,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
  }
)(TimeBoxRegistrationTab)
