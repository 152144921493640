import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { CarAllowanceMutableFields } from '../../model/carAllowance'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { approveState } from '../../utils/approve-utils'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import Alert from '../elements/alert'
import CarAllowanceModalForm, { ResultFields } from './CarAllowanceModalForm'

type Props = {
  visible: boolean
  locked: boolean
  employee: Employee
  carAllowanceID?: string
  carAllowances: CarAllowanceReducer
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>

  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
}

export default function CarAllowanceModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, carAllowances } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, carAllowances.error, error, setError)
  }, [visible, carAllowances, error, setError])

  const handleSubmit = (values: ResultFields) => {
    if (props.locked) {
      return
    }
    const carAllowance: CarAllowanceMutableFields = {
      employeeID: props.employee.id,
      date: values.date,
      licensePlate: values.licensePlate,
      locationFrom: values.locationFrom,
      locationTo: values.locationTo,
      kilometers: values.kilometers,
      reason: values.reason,
      avoidFerries: values.avoidFerries,
      costCenterID: values.costCenterID,
      rate: values.rate,
    }
    if (props.carAllowanceID) {
      carAllowance.id = props.carAllowanceID
      props.updateCarAllowance(carAllowance)
    } else {
      const carAllowances = [{ ...carAllowance, approved: approveState(props.employee) }]
      if (values.createReturnTrip) {
        carAllowances.push({
          employeeID: props.employee.id,
          date: values.date,
          licensePlate: values.licensePlate,
          locationFrom: values.locationTo,
          locationTo: values.locationFrom,
          kilometers: values.returnKilometers,
          reason: values.reason,
          avoidFerries: values.avoidFerries,
          costCenterID: values.costCenterID,
          approved: approveState(props.employee),
          rate: values.rate,
        })
      }
      props.createCarAllowances(carAllowances)
    }
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CarAllowanceModalForm
        locked={props.locked}
        carAllowanceID={props.carAllowanceID}
        employee={props.employee}
        carAllowances={props.carAllowances}
        company={props.company}
        costCenters={props.costCenters}
        departments={props.departments}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
