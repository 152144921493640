import DocumentCategory from '../model/documentCategory'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDocumentCategories(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<DocumentCategory[]>> {
  return secureRequest('GET', url('/v2/documentCategories', { companyID, limit, offset }))
}
