import React from 'react'

import {
  delReimbursementVoucher,
  fetchReimbursementVouchers,
  patchReimbursementVoucher,
  patchReimbursementVouchersReady,
  postReimbursementVoucher,
  putReimbursementVoucher,
} from '../api/reimbursement-vouchers'
import ActionTypes from '../constants/action-types'
import ReimbursementVoucher, {
  ReimbursementVoucherMutableDataFields,
  ReimbursementVoucherMutableFields,
} from '../model/reimbursementVoucher'
import { ReimbursementVoucherAction } from '../reducers/reimbursementVouchers'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingReimbursementVouchers(companyID: string, employeeID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHERS_LOADING,
    companyID,
    employeeID,
  }
}
function loadedReimbursementVouchers(
  companyID: string,
  employeeID: string,
  reimbursementVouchers: ReimbursementVoucher[],
  partial = false
): ReimbursementVoucherAction {
  return {
    type: partial ? ActionTypes.REIMBURSEMENT_VOUCHERS_LOADED_PARTIAL : ActionTypes.REIMBURSEMENT_VOUCHERS_LOADED,
    companyID,
    employeeID,
    reimbursementVouchers: reimbursementVouchers || [],
  }
}
function failedLoadingReimbursementVouchers(
  companyID: string,
  employeeID: string,
  error: Error
): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHERS_LOAD_FAILED,
    companyID,
    employeeID,
    error,
  }
}

function creatingReimbursementVoucher(companyID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHERS_CREATING,
    companyID,
  }
}
export function createdReimbursementVoucher(
  companyID: string,
  reimbursementVoucher: ReimbursementVoucher
): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHERS_CREATED,
    companyID,
    reimbursementVoucher,
  }
}
function failedCreatingReimbursementVoucher(companyID: string, error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHERS_CREATE_FAILED,
    error,
    companyID,
  }
}
function cancelledCreatingReimbursementVoucher(companyID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHERS_CREATE_CANCELLED,
    companyID,
  }
}

function updatingReimbursementVoucher() {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_UPDATING,
  }
}
export function updatedReimbursementVoucher(reimbursementVoucher: ReimbursementVoucher): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_UPDATED,
    reimbursementVoucherID: reimbursementVoucher.id,
    reimbursementVoucher,
  }
}
function failedUpdatingReimbursementVoucher(error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_UPDATE_FAILED,
    error,
  }
}

function deletingReimbursementVoucher(reimbursementVoucherID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_DELETING,
    reimbursementVoucherID,
  }
}
export function deletedReimbursementVoucher(reimbursementVoucherID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_DELETED,
    reimbursementVoucherID,
  }
}
function failedDeletingReimbursementVoucher(reimbursementVoucherID: string, error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_DELETE_FAILED,
    error,
    reimbursementVoucherID,
  }
}

export function getReimbursementVouchers(companyID: string, employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingReimbursementVouchers(companyID, employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchReimbursementVouchers(companyID, employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedReimbursementVouchers(companyID, employeeID, data)),
          (data) => dispatch(loadedReimbursementVouchers(companyID, employeeID, data, true)),
          (offset) => dispatch(getReimbursementVouchers(companyID, employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingReimbursementVouchers(companyID, employeeID, e))
        }
      })
  }
}

export function createReimbursementVoucher(companyID: string, fileID: string, approved?: boolean) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(creatingReimbursementVoucher(companyID))
    return postReimbursementVoucher(companyID, fileID, true, approved)
      .then((res) => {
        dispatch(createdReimbursementVoucher(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingReimbursementVoucher(companyID, e))
        }
      })
  }
}

export function cancelCreateReimbursementVoucher(companyID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(cancelledCreatingReimbursementVoucher(companyID))
  }
}

export function updateReimbursementVoucher(reimbursementVoucher: ReimbursementVoucherMutableFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingReimbursementVoucher())
    return putReimbursementVoucher(reimbursementVoucher.id, reimbursementVoucher)
      .then((res) => {
        dispatch(updatedReimbursementVoucher(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function updateReimbursementVoucherFields(reimbursementVoucher: ReimbursementVoucherMutableDataFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVoucher(reimbursementVoucher.id, reimbursementVoucher)
      .then((res) => {
        dispatch(updatedReimbursementVoucher(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function readyReimbursementVouchers(reimbursementVoucherIDs: string[]) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVouchersReady(reimbursementVoucherIDs)
      .then((res) => {
        res.data.forEach((voucher) => {
          dispatch(updatedReimbursementVoucher(voucher))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function deleteReimbursementVoucher(reimbursementVoucherID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(deletingReimbursementVoucher(reimbursementVoucherID))
    return delReimbursementVoucher(reimbursementVoucherID)
      .then(() => {
        dispatch(deletedReimbursementVoucher(reimbursementVoucherID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingReimbursementVoucher(reimbursementVoucherID, e))
        }
      })
  }
}
