import Swipe, { SwipePayOutType, SwipeStatus } from '../model/swipe'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSwipeStatus(employeeID: string): Promise<RequestResponse<SwipeStatus>> {
  return secureRequest('GET', url('/v2/employees/' + employeeID + '/swipe'))
}

export function fetchSwipes(employeeID: string, limit: number, offset: number): Promise<RequestResponse<Swipe[]>> {
  return secureRequest('GET', url('/v2/swipes', { employeeID, limit, offset }))
}

export function postSwipe(
  employeeID: string,
  amount: number,
  payOut: SwipePayOutType
): Promise<RequestResponse<Swipe>> {
  return secureRequest('POST', url('/v2/swipes/'), {
    body: {
      employeeID,
      amount,
      payOut,
    },
  })
}

export function delSwipe(swipeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/swipes/' + swipeID))
}
