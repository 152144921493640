import React from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { deleteEmployeeEmergencyContact, updateEmployeeEmergencyContact } from '../actions/employee-emergency-contacts'
import { updateEmployee } from '../actions/employees'
import { logout } from '../actions/user'
import ProfileComponent from '../components/profile/Profile'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import Employee from '../model/employee'
import { EmployeeEmergencyContactMutableFields } from '../model/employeeEmergencyContact'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { EmployeeReducer } from '../reducers/employee'
import { EmployeeEmergencyContactReducer } from '../reducers/employeeEmergencyContacts'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  employee: EmployeeReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  updateEmployeeEmergencyContact: (
    employeeID: string,
    employeeEmergencyContact: EmployeeEmergencyContactMutableFields
  ) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  logout: () => Promise<boolean | void>
}

function Profile(props: Reducers & Actions) {
  if (!props.company.company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <ProfileComponent
      alerts={props.alerts}
      company={props.company.company}
      companyFeatures={props.companyFeatures.companyFeatures}
      employee={props.employee}
      employeeEmergencyContacts={props.employeeEmergencyContacts}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateEmployee={props.updateEmployee}
      updateEmployeeEmergencyContact={props.updateEmployeeEmergencyContact}
      deleteEmployeeEmergencyContact={props.deleteEmployeeEmergencyContact}
      logout={props.logout}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    companyFeatures: state.companyFeatures,
    employee: state.employee,
    employeeEmergencyContacts: state.employeeEmergencyContacts,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateEmployee: updateEmployee,
    updateEmployeeEmergencyContact: updateEmployeeEmergencyContact,
    deleteEmployeeEmergencyContact: deleteEmployeeEmergencyContact,
    logout: logout,
  }
)(Profile)
