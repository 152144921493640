import React from 'react'

import {
  delTimeRegistration,
  fetchTimeRegistrations,
  postTimeRegistration,
  postTimeRegistrationBulk,
  putTimeRegistration,
} from '../api/time-registrations'
import ActionTypes from '../constants/action-types'
import TimeRegistration, {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { TimeRegistrationAction } from '../reducers/timeRegistrations'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid, RequestError } from '../utils/request-utils'
import { handlePagination } from './pagination'
import { loadingSwipeStatus } from './swipe'

function loadingTimeRegistrations(employeeID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_LOADING,
    employeeID,
  }
}
function loadedTimeRegistrations(
  employeeID: string,
  fromDate: DateFormat | undefined,
  toDate: DateFormat | undefined,
  registrations: TimeRegistration[],
  partial = false
): TimeRegistrationAction {
  return {
    type: partial ? ActionTypes.TIME_REGISTRATIONS_LOADED_PARTIAL : ActionTypes.TIME_REGISTRATIONS_LOADED,
    employeeID,
    fromDate,
    toDate,
    registrations,
  }
}
function failedLoadingTimeRegistrations(employeeID: string, error: Error): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_LOAD_FAILED,
    employeeID,
    error,
  }
}

function creatingTimeRegistration(employeeID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_CREATING,
    employeeID,
  }
}
export function createdTimeRegistration(employeeID: string, registration: TimeRegistration): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_CREATED,
    employeeID,
    registrationID: registration.id,
    registration,
  }
}
function failedCreatingTimeRegistration(employeeID: string, error: Error): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_CREATE_FAILED,
    error,
    employeeID,
  }
}

function updatingTimeRegistration(employeeID: string, registrationID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_UPDATING,
    employeeID,
    registrationID,
  }
}
export function updatedTimeRegistration(
  employeeID: string,
  registrationID: string,
  registration: TimeRegistration
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_UPDATED,
    employeeID,
    registrationID,
    registration,
  }
}
function failedUpdatingTimeRegistration(
  employeeID: string,
  registrationID: string,
  error: Error
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_UPDATE_FAILED,
    error,
    employeeID,
    registrationID,
  }
}

function deletingTimeRegistration(registrationID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_DELETING,
    registrationID,
  }
}
export function deletedTimeRegistration(registrationID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_DELETED,
    registrationID,
  }
}
function failedDeletingTimeRegistration(registrationID: string, error: Error): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATIONS_DELETE_FAILED,
    error,
    registrationID,
  }
}

export function getTimeRegistrations(employeeID: string, fromDate?: DateFormat, toDate?: DateFormat, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingTimeRegistrations(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchTimeRegistrations(employeeID, fromDate, toDate, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedTimeRegistrations(employeeID, fromDate, toDate, data)),
          (data) => dispatch(loadedTimeRegistrations(employeeID, fromDate, toDate, data, true)),
          (offset) => dispatch(getTimeRegistrations(employeeID, fromDate, toDate, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingTimeRegistrations(employeeID, e))
        }
      })
  }
}

export function createTimeRegistration(registration: TimeRegistrationCreationFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(creatingTimeRegistration(registration.employeeID))
    dispatch(loadingSwipeStatus())
    return postTimeRegistration(registration.employeeID, registration)
      .then((res) => {
        dispatch(createdTimeRegistration(registration.employeeID, res.data))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingTimeRegistration(registration.employeeID, e))
        }
      })
  }
}

export function createTimeRegistrationBulk(registration: TimeRegistrationBulkFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(creatingTimeRegistration(registration.employeeID))
    dispatch(loadingSwipeStatus())
    return postTimeRegistrationBulk(registration.employeeID, registration)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((timeRegistration) => {
            dispatch(createdTimeRegistration(registration.employeeID, timeRegistration))
          })
        } else {
          dispatch(
            failedCreatingTimeRegistration(
              registration.employeeID,
              new RequestError('Der er allerede registreret for denne periode.', 'InvalidRequest', [])
            )
          )
        }
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingTimeRegistration(registration.employeeID, e))
        }
      })
  }
}

export function updateTimeRegistration(registration: TimeRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>) => {
    const registrationID = registration.id
    if (!registrationID) {
      return PromiseVoid
    }
    dispatch(updatingTimeRegistration(registration.employeeID, registrationID))
    dispatch(loadingSwipeStatus())
    return putTimeRegistration(registrationID, registration)
      .then((res) => {
        dispatch(updatedTimeRegistration(registration.employeeID, registrationID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTimeRegistration(registration.employeeID, registrationID, e))
        }
      })
  }
}

export function deleteTimeRegistration(registrationID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(deletingTimeRegistration(registrationID))
    dispatch(loadingSwipeStatus())
    return delTimeRegistration(registrationID)
      .then(() => {
        dispatch(deletedTimeRegistration(registrationID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingTimeRegistration(registrationID, e))
        }
      })
  }
}
