import Raven from 'raven-js'
import React from 'react'

import {
  deleteLogin,
  fetchEmployeeInvite,
  fetchUser,
  patchDeviceTokenLogin,
  postDeviceToken,
  postEmployeeInvite,
  postLogin,
  postPasswordRequest,
  postPasswordReset,
} from '../api/user'
import ActionTypes from '../constants/action-types'
import User from '../model/user'
import { UserAction } from '../reducers/user'
import {
  createMfaDeviceToken,
  getMfaDeviceToken,
  setAccessToken,
  setActiveEmployee,
  setDeviceToken,
} from '../utils/cookie-utils'
import { isRequestError } from '../utils/error-utils'
import { ChannelMFAError, RequestResponse } from '../utils/request-utils'
import { failedLoadingCompany, loadedCompany, loadingCompany } from './companies'
import { failedLoadingEmployee, loadedEmployee, loadingEmployee } from './employees'

function registeringUser(): UserAction {
  return {
    type: ActionTypes.USER_REGISTERING,
  }
}
function registeredUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_REGISTERED,
    user,
  }
}
function failedRegisteringUser(errorMessage: Error): UserAction {
  return {
    type: ActionTypes.USER_REGISTERING_FAILED,
    error: errorMessage,
  }
}

function loggingOutUser(): UserAction {
  return {
    type: ActionTypes.USER_LOGGING_OUT,
  }
}
function loggedOutUser(): UserAction {
  return {
    type: ActionTypes.USER_LOGGED_OUT,
  }
}
function logoutFailedUser(error: Error): UserAction {
  return {
    type: ActionTypes.USER_LOGOUT_FAILED,
    error,
  }
}

function loggingInUser(): UserAction {
  return {
    type: ActionTypes.USER_LOGGING_IN,
  }
}
function loggedInUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_LOGGED_IN,
    user,
  }
}
function loggingInUserFailed(errorMessage: Error): UserAction {
  return {
    type: ActionTypes.USER_LOGIN_FAILED,
    error: errorMessage,
  }
}

function loadingUser(): UserAction {
  return {
    type: ActionTypes.USER_LOADING,
  }
}
export function loadedUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_LOADED,
    user,
  }
}
function failedLoadingUser(error: Error): UserAction {
  return {
    type: ActionTypes.USER_LOAD_FAILED,
    error,
  }
}

function requestingPassword(): UserAction {
  return {
    type: ActionTypes.USER_REQUESTING_PASSWORD,
  }
}
function requestedPassword(): UserAction {
  return {
    type: ActionTypes.USER_REQUESTED_PASSWORD,
  }
}
function failedRequestingPassword(errorMessage: Error): UserAction {
  return {
    type: ActionTypes.USER_REQUEST_PASSWORD_FAILED,
    error: errorMessage,
  }
}

function resettingPassword(): UserAction {
  return {
    type: ActionTypes.USER_RESETTING_PASSWORD,
  }
}
function didResetPassword(): UserAction {
  return {
    type: ActionTypes.USER_RESET_PASSWORD,
  }
}
function failedResettingPassword(errorMessage: Error): UserAction {
  return {
    type: ActionTypes.USER_RESET_PASSWORD_FAILED,
    error: errorMessage,
  }
}

function creatingDeviceToken(): UserAction {
  return {
    type: ActionTypes.USER_CREATING_DEVICE_TOKEN,
  }
}
function createdDeviceToken(): UserAction {
  return {
    type: ActionTypes.USER_CREATED_DEVICE_TOKEN,
  }
}
function failedCreatingDeviceToken(errorMessage: Error): UserAction {
  return {
    type: ActionTypes.USER_CREATE_DEVICE_TOKEN_FAILED,
    error: errorMessage,
  }
}

export function getInvite(token: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(loadingCompany())
    dispatch(loadingEmployee())
    return fetchEmployeeInvite(token)
      .then((res) => {
        dispatch(loadedCompany(res.data.company))
        dispatch(loadedEmployee([res.data.employee]))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompany(e))
          dispatch(failedLoadingEmployee(e))
        }
      })
  }
}

export function register(token: string, password: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(registeringUser())
    return postEmployeeInvite(token, password)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        dispatch(registeredUser(res.data.user))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRegisteringUser(e))
        }
      })
  }
}

export function logout() {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loggingOutUser())
    return deleteLogin()
      .then(() => {
        setAccessToken(null)
        setDeviceToken(null)
        setActiveEmployee(null)
        Raven.setUserContext()
        dispatch(loggedOutUser())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(logoutFailedUser(e))
          return e
        }
      })
  }
}

export function login(
  email: string,
  password: string,
  challengeID?: string,
  response?: string,
  recoveryCode?: string,
  trustDevice = false
) {
  return (dispatch: React.Dispatch<any>): Promise<RequestResponse | ChannelMFAError> => {
    dispatch(loggingInUser())
    let mfaDeviceToken = getMfaDeviceToken()
    if (trustDevice && !mfaDeviceToken) {
      mfaDeviceToken = createMfaDeviceToken()
    }
    return postLogin(email, password, mfaDeviceToken, challengeID, response, recoveryCode, trustDevice)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        Raven.setUserContext({ id: res.data.user.id })
        dispatch(loggedInUser(res.data.user))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(loggingInUserFailed(e))
        }
        return e
      })
  }
}

export function requestPassword(email: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(requestingPassword())
    return postPasswordRequest(email)
      .then(() => {
        dispatch(requestedPassword())
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRequestingPassword(e))
        }
      })
  }
}

export function resetPassword(token: string, password: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(resettingPassword())
    return postPasswordReset(token, password)
      .then(() => {
        dispatch(didResetPassword())
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedResettingPassword(e))
        }
      })
  }
}

export function createDeviceToken(deviceName: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(creatingDeviceToken())
    return postDeviceToken(deviceName)
      .then((res) => {
        setDeviceToken(res.data.token, 30 * 24 * 60 * 60)
        dispatch(createdDeviceToken())
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingDeviceToken(e))
        }
      })
  }
}

export function deviceTokenLogin(deviceToken: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(loggingInUser())
    return patchDeviceTokenLogin(deviceToken)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        Raven.setUserContext({ id: res.data.user.id })
        dispatch(loggedInUser(res.data.user))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(loggingInUserFailed(e))
        }
        return e
      })
  }
}

export function getUser() {
  return (dispatch: React.Dispatch<any>): Promise<User | void> => {
    dispatch(loadingUser())
    return fetchUser()
      .then((res) => {
        dispatch(loadedUser(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingUser(e))
        }
      })
  }
}
