import React, { CSSProperties, ReactElement } from 'react'

import Popover from '../antd/popover'

import './Elements.css'

export default function ContextMenu(props: {
  style?: CSSProperties
  children?: React.ReactNode
  className?: string
  text?: string
}): ReactElement {
  return (
    <Popover
      placement="bottomRight"
      content={props.children}
      trigger="click"
      overlayClassName="context-menu-popover"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div>
        <div className={props.className} style={props.style}>
          {props.text}
        </div>
      </div>
    </Popover>
  )
}
