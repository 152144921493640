import React from 'react'

import { fetchContracts } from '../api/contracts'
import ActionTypes from '../constants/action-types'
import Contract from '../model/contract'
import { ContractAction } from '../reducers/contracts'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingContracts(employeeID: string): ContractAction {
  return {
    type: ActionTypes.CONTRACTS_LOADING,
    employeeID,
  }
}
function loadedContracts(employeeID: string, contracts: Contract[], partial = false): ContractAction {
  return {
    type: partial ? ActionTypes.CONTRACTS_LOADED_PARTIAL : ActionTypes.CONTRACTS_LOADED,
    contracts,
    employeeID,
  }
}
function failedLoadingContracts(employeeID: string, error: Error): ContractAction {
  return {
    type: ActionTypes.CONTRACTS_LOAD_FAILED,
    error,
    employeeID,
  }
}

export function getContracts(employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingContracts(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchContracts(employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedContracts(employeeID, data)),
          (data) => dispatch(loadedContracts(employeeID, data, true)),
          (offset) => dispatch(getContracts(employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingContracts(employeeID, e))
        }
      })
  }
}
