import { SplashScreen } from '@capacitor/splash-screen'
import { addYears, startOfYear, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { getCarAllowances } from './actions/car-allowances'
import { getCompany } from './actions/companies'
import { getCompanyFeatures } from './actions/company-features'
import { getContracts } from './actions/contracts'
import { getCostCenters } from './actions/cost-centers'
import { getDepartments } from './actions/departments'
import { getDocumentCategories } from './actions/document-categories'
import { getDocuments } from './actions/documents'
import { getEmployeeEmergencyContact } from './actions/employee-emergency-contacts'
import { getEmployee } from './actions/employees'
import { getExpenseCategories } from './actions/expense-categories'
import { getLeaveBalances } from './actions/leave-balances'
import { getLeaveTypes } from './actions/leave-types'
import { getOneTimePays } from './actions/one-time-pays'
import { getPaySlips } from './actions/pay-slips'
import { getProjects } from './actions/projects'
import { getReimbursementVouchers } from './actions/reimbursement-vouchers'
import { getSalaryRegistrations } from './actions/salary-registrations'
import { getSalaryTypes } from './actions/salary-types'
import { getSwipes, getSwipeStatus } from './actions/swipe'
import { getTimeRegistrationTemplates } from './actions/time-registration-templates'
import { getTimeRegistrations } from './actions/time-registrations'
import { deviceTokenLogin, getUser, logout } from './actions/user'
import { getClock } from './api/misc'
import Alert from './components/elements/alert'
import Button from './components/elements/button'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Landing from './components/landing/Landing'
import jsBrowserHistory from './components/widgets/jsBrowserHistory'
import LoadingOverlay from './components/widgets/LoadingOverlay'
import { isPullToRefreshing } from './components/widgets/PullToRefresh'
import { DateFormat } from './model/types'
import { CarAllowanceReducer } from './reducers/carAllowances'
import { CompanyReducer } from './reducers/company'
import { CompanyFeatureReducer } from './reducers/companyFeatures'
import { ContractReducer } from './reducers/contracts'
import { CostCenterReducer } from './reducers/costCenters'
import { DepartmentReducer } from './reducers/departments'
import { DocumentCategoryReducer } from './reducers/documentCategories'
import { DocumentReducer } from './reducers/documents'
import { EmployeeReducer } from './reducers/employee'
import { EmployeeEmergencyContactReducer } from './reducers/employeeEmergencyContacts'
import { ExpenseCategoryReducer } from './reducers/expenseCategories'
import { LeaveBalanceReducer } from './reducers/leaveBalances'
import { LeaveTypeReducer } from './reducers/leaveTypes'
import { OneTimePayReducer } from './reducers/oneTimePays'
import { PaySlipReducer } from './reducers/paySlips'
import { ProjectReducer } from './reducers/projects'
import { ReimbursementVoucherReducer } from './reducers/reimbursementVouchers'
import { SalaryRegistrationReducer } from './reducers/salaryRegistrations'
import { SalaryTypeReducer } from './reducers/salaryTypes'
import { SwipeReducer } from './reducers/swipes'
import { SwipeStatusReducer } from './reducers/swipeStatus'
import { TimeRegistrationReducer } from './reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from './reducers/timeRegistrationTemplates'
import { UserReducer } from './reducers/user'
import { paths } from './routes'
import { getActiveEmployee, getDeviceToken, setClock, setDeviceToken } from './utils/cookie-utils'
import { formatAPIDate, getDate } from './utils/date-utils'
import { getPlatform, isNative } from './utils/device-utils'
import { connectToReducer } from './utils/reducer-utils'
import { RouteProps } from './utils/route-utils'
import { t } from './utils/translation-utils'

import './App.css'

type Reducers = {
  carAllowances: CarAllowanceReducer
  company: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  contracts: ContractReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  employee: EmployeeReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  expenseCategories: ExpenseCategoryReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: LeaveTypeReducer
  oneTimePays: OneTimePayReducer
  paySlips: PaySlipReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryTypes: SalaryTypeReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  salaryRegistrations: SalaryRegistrationReducer
  swipeStatus: SwipeStatusReducer
  swipes: SwipeReducer
  projects: ProjectReducer
  user: UserReducer
}

type Actions = {
  getUser: () => void
  getCarAllowances: (employeeID: string) => void
  getCompany: (companyID: string) => void
  getCompanyFeatures: (employeeID: string) => void
  getContracts: (employeeID: string) => void
  getCostCenters: (companyID: string) => void
  getDepartments: (companyID: string) => void
  getDocuments: (employeeID: string) => void
  getDocumentCategories: (companyID: string) => void
  getEmployee: (preferredEmployeeID?: string) => void
  getEmployeeEmergencyContact: (employeeID: string) => void
  getExpenseCategories: (companyID: string) => void
  getLeaveTypes: (companyID: string) => void
  getOneTimePays: (employeeID: string) => void
  getPaySlips: (employeeID: string) => void
  getReimbursementVouchers: (companyID: string, employeeID: string) => void
  getSalaryTypes: (companyID: string) => void
  getProjects: (companyID: string) => void
  getTimeRegistrations: (employeeID: string, fromDate: DateFormat, toDate: DateFormat) => void
  getTimeRegistrationTemplates: (employeeID: string) => void
  deviceTokenLogin: (deviceToken: string) => void
  logout: () => Promise<boolean | void>
  getSwipes: (employeeID: string) => void
  getSwipeStatus: (employeeID: string) => void
  getLeaveBalances: (employeeID: string) => void
  getSalaryRegistrations: (employeeID: string, fromDate: DateFormat, toDate: DateFormat) => void
}

function App(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { user, deviceTokenLogin, employee, getEmployee } = props

  useEffectOnce(() => {
    SplashScreen.hide()
  })

  useEffect(() => {
    if (isNative() && !user.loggedIn && !user.loggingIn) {
      const deviceToken = getDeviceToken()
      if (deviceToken) {
        if (user.error?.message === 'Device Token Not Found') {
          // unset device token, since it was not found
          setDeviceToken(null)
        } else {
          deviceTokenLogin(deviceToken)
        }
      }
    }
  }, [user, deviceTokenLogin])

  const previousUser = usePrevious(user)
  const previousEmployee = usePrevious(employee)

  const {
    location,
    company,
    getUser,
    carAllowances,
    getCompany,
    getCarAllowances,
    companyFeatures,
    getCompanyFeatures,
    contracts,
    getContracts,
    documents,
    getDocuments,
    documentCategories,
    getDocumentCategories,
    employeeEmergencyContacts,
    getEmployeeEmergencyContact,
    expenseCategories,
    getExpenseCategories,
    costCenters,
    getCostCenters,
    departments,
    getDepartments,
    leaveTypes,
    getLeaveTypes,
    oneTimePays,
    getOneTimePays,
    paySlips,
    getPaySlips,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryTypes,
    getSalaryTypes,
    timeRegistrations,
    getTimeRegistrations,
    leaveBalances,
    getLeaveBalances,
    swipes,
    getSwipes,
    swipeStatus,
    getSwipeStatus,
    salaryRegistrations,
    getSalaryRegistrations,
    projects,
    getProjects,
    timeRegistrationTemplates,
    getTimeRegistrationTemplates,
  } = props

  useEffect(() => {
    if (previousUser && !previousUser.loggedIn && user.loggedIn) {
      jsBrowserHistory.push(location.query.ref || '/')
    }
    if (user.loggedIn) {
      if (!user.loading && !user.user) {
        getUser()
      }
      if (!employee.loading && !employee.loaded) {
        getEmployee(getActiveEmployee())
      }
      if (previousEmployee && !previousEmployee.loaded && employee.loaded) {
        // get current time from server
        getClock()
          .then((res) => setClock(res.data.now))
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {}) // ignore errors
        const thisEmployee = employee.employee
        if (thisEmployee) {
          const companyID = thisEmployee.companyID
          if ((!company.loading && !company.loaded) || company.companyID !== companyID) {
            getCompany(companyID)
          }
          if (thisEmployee.onboardingState === 'Draft') {
            if (location.pathname !== '/' + paths.PROFILE) {
              jsBrowserHistory.push('/' + paths.PROFILE)
            }
          }
          if (thisEmployee.activeContract) {
            if (!carAllowances.loading && !carAllowances.loaded) {
              getCarAllowances(thisEmployee.id)
            }
            if (!companyFeatures.loading && !companyFeatures.loaded) {
              getCompanyFeatures(companyID)
            }
            if (!documents.loading && !documents.loaded) {
              getDocuments(thisEmployee.id)
            }
            if (!documentCategories.loading && !documentCategories.loaded) {
              getDocumentCategories(companyID)
            }
            if (!employeeEmergencyContacts.loading && !employeeEmergencyContacts.loaded) {
              getEmployeeEmergencyContact(thisEmployee.id)
            }
            if (!expenseCategories.loading && !expenseCategories.loaded) {
              getExpenseCategories(companyID)
            }
            if (!costCenters.loading && !costCenters.loaded) {
              getCostCenters(companyID)
            }
            if (!departments.loading && !departments.loaded) {
              getDepartments(companyID)
            }
            if (!leaveTypes.loading && !leaveTypes.loaded) {
              getLeaveTypes(companyID)
            }
            if (!oneTimePays.loading && !oneTimePays.loaded) {
              getOneTimePays(thisEmployee.id)
            }
            if (!paySlips.loading && !paySlips.loaded) {
              getPaySlips(thisEmployee.id)
            }
            if (!reimbursementVouchers.loading && !reimbursementVouchers.loaded) {
              getReimbursementVouchers(companyID, thisEmployee.id)
            }
            if (!salaryTypes.loading && !salaryTypes.loaded) {
              getSalaryTypes(companyID)
            }
            if (!projects.loading && !projects.loaded) {
              getProjects(companyID)
            }
            if (!timeRegistrations.loading && !timeRegistrations.loaded) {
              getTimeRegistrations(
                thisEmployee.id,
                formatAPIDate(startOfYear(subYears(getDate(), 1))),
                formatAPIDate(addYears(getDate(), 1))
              )
            }
            if (!leaveBalances.loading && !leaveBalances.loaded) {
              getLeaveBalances(thisEmployee.id)
            }
            if (!swipes.loading && !swipes.loaded) {
              getSwipes(thisEmployee.id)
            }
            if (!swipeStatus.loading && !swipeStatus.loaded) {
              getSwipeStatus(thisEmployee.id)
            }
            if (!salaryRegistrations.loading && !salaryRegistrations.loaded) {
              getSalaryRegistrations(
                thisEmployee.id,
                formatAPIDate(startOfYear(subYears(getDate(), 1))),
                formatAPIDate(addYears(getDate(), 1))
              )
            }
            if (!contracts.loading && !contracts.loaded) {
              getContracts(thisEmployee.id)
            }
            if (!timeRegistrationTemplates.loading && !timeRegistrationTemplates.loaded) {
              getTimeRegistrationTemplates(thisEmployee.id)
            }
          }
        }
      }
    }
  }, [
    location,
    company,
    carAllowances,
    getCompany,
    getCarAllowances,
    companyFeatures,
    getCompanyFeatures,
    contracts,
    getContracts,
    documents,
    getDocuments,
    documentCategories,
    getDocumentCategories,
    employeeEmergencyContacts,
    getEmployeeEmergencyContact,
    expenseCategories,
    getExpenseCategories,
    costCenters,
    getCostCenters,
    departments,
    getDepartments,
    leaveTypes,
    getLeaveTypes,
    oneTimePays,
    getOneTimePays,
    paySlips,
    getPaySlips,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryTypes,
    getSalaryTypes,
    timeRegistrations,
    getTimeRegistrations,
    leaveBalances,
    getLeaveBalances,
    swipes,
    getSwipes,
    swipeStatus,
    getSwipeStatus,
    employee,
    getEmployee,
    salaryRegistrations,
    getSalaryRegistrations,
    projects,
    getProjects,
    timeRegistrationTemplates,
    getTimeRegistrationTemplates,
    previousEmployee,
    previousUser,
    user,
    getUser,
  ])

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault()
    props.logout().then((res) => {
      if (res) {
        jsBrowserHistory.push('/' + paths.LOGIN)
      }
    })
  }

  const isLoading = (): boolean => {
    if (!props.user.loggedIn) {
      return false
    }
    if (isPullToRefreshing()) {
      return false
    }
    if (!props.employee.loaded || !props.company.loaded) {
      return true
    }
    if (props.employee.employee && props.employee.employee.earliestMutableContract) {
      return (
        !props.companyFeatures.loaded ||
        !props.costCenters.loaded ||
        !props.departments.loaded ||
        !props.documents.loaded ||
        !props.employeeEmergencyContacts.loaded ||
        !props.expenseCategories.loaded ||
        !props.leaveTypes.loaded ||
        !props.paySlips.loaded ||
        !props.salaryTypes.loaded ||
        !props.projects.loaded
      )
    }
    return false
  }

  const thisCompany = company.company
  const thisEmployee = employee.employee
  if (props.user.loggedIn && props.employee.loaded && !thisEmployee) {
    return (
      <div>
        <Header location={props.location} />
        <div className="login-wrapper">
          <Alert message={t('error_message.message.user_is_not_employee')} type="error" showIcon />
          <Button
            onClick={handleLogout}
            size="large"
            className="ant-btn-danger"
            style={{ display: 'block', width: '100%', marginTop: '100px' }}
          >
            {t('common.log_out')}
          </Button>
        </div>
      </div>
    )
  }
  if (isLoading()) {
    return <Landing />
  }
  if (!props.user.loggedIn) {
    return (
      <div>
        <Header location={props.location} />
        <div className="login-wrapper">{props.children}</div>
      </div>
    )
  }
  if (props.location.pathname.indexOf('/' + paths.PAY_SLIPS + '/') === 0) {
    return <div>{props.children}</div>
  }
  if (!thisCompany || !thisEmployee) {
    return <LoadingOverlay />
  }
  return (
    <div>
      <Header location={props.location} employee={thisEmployee} />
      <div
        className={
          'main-wrapper platform-' + getPlatform() + (thisEmployee.onboardingState === 'Draft' ? ' no-footer' : '')
        }
      >
        {props.children}
      </div>
      <Footer
        location={props.location}
        company={thisCompany}
        employee={thisEmployee}
        companyFeatures={props.companyFeatures.companyFeatures}
        leaveTypes={props.leaveTypes.leaveTypes}
        salaryTypes={props.salaryTypes.salaryTypes}
        projects={props.projects.projects}
      />
    </div>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    carAllowances: state.carAllowances,
    company: state.company,
    companyFeatures: state.companyFeatures,
    contracts: state.contracts,
    documents: state.documents,
    documentCategories: state.documentCategories,
    employee: state.employee,
    employeeEmergencyContacts: state.employeeEmergencyContacts,
    expenseCategories: state.expenseCategories,
    costCenters: state.costCenters,
    departments: state.departments,
    leaveTypes: state.leaveTypes,
    oneTimePays: state.oneTimePays,
    paySlips: state.paySlips,
    reimbursementVouchers: state.reimbursementVouchers,
    salaryTypes: state.salaryTypes,
    timeRegistrations: state.timeRegistrations,
    user: state.user,
    swipeStatus: state.swipeStatus,
    swipes: state.swipes,
    leaveBalances: state.leaveBalances,
    salaryRegistrations: state.salaryRegistrations,
    projects: state.projects,
    timeRegistrationTemplates: state.timeRegistrationTemplates,
  }),
  {
    getUser: getUser,
    getCarAllowances: getCarAllowances,
    getCompany: getCompany,
    getCompanyFeatures: getCompanyFeatures,
    getContracts: getContracts,
    getDocuments: getDocuments,
    getDocumentCategories: getDocumentCategories,
    getEmployee: getEmployee,
    getEmployeeEmergencyContact: getEmployeeEmergencyContact,
    getExpenseCategories: getExpenseCategories,
    getCostCenters: getCostCenters,
    getDepartments: getDepartments,
    getLeaveTypes: getLeaveTypes,
    getOneTimePays: getOneTimePays,
    getPaySlips: getPaySlips,
    getReimbursementVouchers: getReimbursementVouchers,
    getSalaryTypes: getSalaryTypes,
    getTimeRegistrations: getTimeRegistrations,
    deviceTokenLogin: deviceTokenLogin,
    logout: logout,
    getSwipes: getSwipes,
    getSwipeStatus: getSwipeStatus,
    getLeaveBalances: getLeaveBalances,
    getSalaryRegistrations: getSalaryRegistrations,
    getProjects: getProjects,
    getTimeRegistrationTemplates: getTimeRegistrationTemplates,
  }
)(App)
