import React from 'react'

import { delCarAllowance, fetchCarAllowances, postCarAllowances, putCarAllowance } from '../api/car-allowances'
import ActionTypes from '../constants/action-types'
import CarAllowance, { CarAllowanceMutableFields } from '../model/carAllowance'
import { DateFormat } from '../model/types'
import { CarAllowanceAction } from '../reducers/carAllowances'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingCarAllowances(employeeID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_LOADING,
    employeeID,
  }
}
function loadedCarAllowances(
  employeeID: string,
  fromDate: DateFormat | undefined,
  toDate: DateFormat | undefined,
  carAllowances: CarAllowance[],
  partial = false
): CarAllowanceAction {
  return {
    type: partial ? ActionTypes.CAR_ALLOWANCES_LOADED_PARTIAL : ActionTypes.CAR_ALLOWANCES_LOADED,
    employeeID,
    fromDate,
    toDate,
    carAllowances,
  }
}
function failedLoadingCarAllowances(employeeID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_LOAD_FAILED,
    employeeID,
    error,
  }
}

function creatingCarAllowances(employeeID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_CREATING,
    employeeID,
  }
}
export function createdCarAllowances(employeeID: string, carAllowances: CarAllowance[]): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_CREATED,
    employeeID,
    carAllowances,
  }
}
function failedCreatingCarAllowances(employeeID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_CREATE_FAILED,
    error,
    employeeID,
  }
}

function updatingCarAllowance(employeeID: string, carAllowanceID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_UPDATING,
    employeeID,
    carAllowanceID,
  }
}
export function updatedCarAllowance(
  employeeID: string,
  carAllowanceID: string,
  carAllowance: CarAllowance
): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_UPDATED,
    employeeID,
    carAllowanceID,
    carAllowance,
  }
}
function failedUpdatingCarAllowance(employeeID: string, carAllowanceID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_UPDATE_FAILED,
    error,
    employeeID,
    carAllowanceID,
  }
}

function deletingCarAllowance(carAllowanceID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_DELETING,
    carAllowanceID,
  }
}
export function deletedCarAllowance(carAllowanceID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_DELETED,
    carAllowanceID,
  }
}
function failedDeletingCarAllowance(carAllowanceID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_DELETE_FAILED,
    error,
    carAllowanceID,
  }
}

export function getCarAllowances(employeeID: string, fromDate?: DateFormat, toDate?: DateFormat, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance[] | void> => {
    if (!offset) {
      dispatch(loadingCarAllowances(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchCarAllowances(employeeID, fromDate, toDate, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedCarAllowances(employeeID, fromDate, toDate, data)),
          (data) => dispatch(loadedCarAllowances(employeeID, fromDate, toDate, data, true)),
          (offset) => dispatch(getCarAllowances(employeeID, fromDate, toDate, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCarAllowances(employeeID, e))
        }
      })
  }
}

export function createCarAllowances(carAllowances: CarAllowanceMutableFields[]) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance[] | void> => {
    const employeeID = carAllowances[0].employeeID
    dispatch(creatingCarAllowances(employeeID))
    return postCarAllowances(employeeID, carAllowances)
      .then((res) => {
        dispatch(createdCarAllowances(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingCarAllowances(employeeID, e))
        }
      })
  }
}

export function updateCarAllowance(carAllowance: CarAllowanceMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance | void> => {
    const carAllowanceID = carAllowance.id
    if (!carAllowanceID) {
      return PromiseVoid
    }
    dispatch(updatingCarAllowance(carAllowance.employeeID, carAllowanceID))
    return putCarAllowance(carAllowanceID, carAllowance)
      .then((res) => {
        dispatch(updatedCarAllowance(carAllowance.employeeID, carAllowanceID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCarAllowance(carAllowance.employeeID, carAllowanceID, e))
        }
      })
  }
}

export function deleteCarAllowance(carAllowance: CarAllowance) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingCarAllowance(carAllowance.id))
    return delCarAllowance(carAllowance.id)
      .then(() => {
        dispatch(deletedCarAllowance(carAllowance.id))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCarAllowance(carAllowance.id, e))
        }
      })
  }
}
