import Employee from '../model/employee'
import Project from '../model/project'
import { isTimeAfter, isTimeBefore } from './date-utils'

export function projectFilter(p: Project, e: Employee, date?: Date): boolean {
  if (!p.employeeIDs.some((id) => id === e.id)) {
    return false
  }
  // if date not provided, then we always show this project, regardless of
  // whether they can select it right now
  if (!date) {
    return true
  }
  if (p.validFrom && isTimeBefore(date, p.validFrom)) {
    return false
  }
  if (p.validTo && isTimeAfter(date, p.validTo)) {
    return false
  }
  return true
}
