import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import LeaveType from '../model/leaveType'
import { ReducerAction } from '../utils/reducer-utils'

export interface LeaveTypeReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  leaveTypes: List<LeaveType>
  error: Error | null
}

const Params = Record<LeaveTypeReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  leaveTypes: List<LeaveType>(),
  error: null,
})

export interface LeaveTypeAction extends ReducerAction {
  leaveTypes?: LeaveType[]
}

export default (
  state: Record<LeaveTypeReducer> = Params(),
  action: LeaveTypeAction = { type: '' }
): Record<LeaveTypeReducer> => {
  switch (action.type) {
    case ActionTypes.LEAVE_TYPES_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.LEAVE_TYPES_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        leaveTypes: List(action.leaveTypes),
      })
    case ActionTypes.LEAVE_TYPES_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
