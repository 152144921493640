import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getCostCenters } from '../actions/cost-centers'
import { getExpenseCategories } from '../actions/expense-categories'
import {
  cancelCreateReimbursementVoucher,
  createReimbursementVoucher,
  deleteReimbursementVoucher,
  getReimbursementVouchers,
  readyReimbursementVouchers,
  updateReimbursementVoucher,
  updateReimbursementVoucherFields,
} from '../actions/reimbursement-vouchers'
import ReimbursementVouchersComponent from '../components/reimbursement-vouchers/ReimbursementVouchers'
import ReimbursementVouchersDisabledComponent from '../components/reimbursement-vouchers/ReimbursementVouchersDisabled'
import CostCenter from '../model/costCenter'
import ExpenseCategory from '../model/expenseCategory'
import ReimbursementVoucher, {
  ReimbursementVoucherMutableDataFields,
  ReimbursementVoucherMutableFields,
} from '../model/reimbursementVoucher'
import Reducers from '../reducers'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CostCenterReducer } from '../reducers/costCenters'
import { EmployeeReducer } from '../reducers/employee'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { ReimbursementVoucherReducer } from '../reducers/reimbursementVouchers'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  employee: EmployeeReducer
  expenseCategories: ExpenseCategoryReducer
  costCenters: CostCenterReducer
  reimbursementVouchers: ReimbursementVoucherReducer
}

type Actions = {
  getReimbursementVouchers: (companyID: string, employeeID: string) => Promise<ReimbursementVoucher[] | void>
  createReimbursementVoucher: (companyID: string, fileID: string) => Promise<ReimbursementVoucher | void>
  cancelCreateReimbursementVoucher: (companyID: string) => void
  updateReimbursementVoucher: (voucher: ReimbursementVoucherMutableFields) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (
    voucher: ReimbursementVoucherMutableDataFields
  ) => Promise<ReimbursementVoucher | void>
  readyReimbursementVouchers: (reimbursementVoucherIDs: string[]) => void
  deleteReimbursementVoucher: (reimbursementVoucherID: string) => void
  getExpenseCategories: (companyID: string) => Promise<ExpenseCategory[] | void>
  getCostCenters: (companyID: string) => Promise<CostCenter[] | void>
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

function ReimbursementVouchers(props: Reducers & Actions): ReactElement | null {
  const company = props.company.company
  const employee = props.employee.employee
  if (
    !company ||
    !employee ||
    !props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Reimbursement Vouchers') ||
    employee.disableReimbursementVouchers
  ) {
    return <ReimbursementVouchersDisabledComponent />
  }

  return (
    <ReimbursementVouchersComponent
      company={company}
      employee={employee}
      expenseCategories={props.expenseCategories.expenseCategories}
      reimbursementVouchers={props.reimbursementVouchers}
      costCenters={props.costCenters.costCenters}
      getReimbursementVouchers={props.getReimbursementVouchers}
      createReimbursementVoucher={props.createReimbursementVoucher}
      cancelCreateReimbursementVoucher={props.cancelCreateReimbursementVoucher}
      updateReimbursementVoucher={props.updateReimbursementVoucher}
      updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
      readyReimbursementVouchers={props.readyReimbursementVouchers}
      deleteReimbursementVoucher={props.deleteReimbursementVoucher}
      getExpenseCategories={props.getExpenseCategories}
      getCostCenters={props.getCostCenters}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    companyFeatures: state.companyFeatures,
    employee: state.employee,
    expenseCategories: state.expenseCategories,
    costCenters: state.costCenters,
    reimbursementVouchers: state.reimbursementVouchers,
  }),
  {
    getReimbursementVouchers: getReimbursementVouchers,
    createReimbursementVoucher: createReimbursementVoucher,
    cancelCreateReimbursementVoucher: cancelCreateReimbursementVoucher,
    updateReimbursementVoucher: updateReimbursementVoucher,
    updateReimbursementVoucherFields: updateReimbursementVoucherFields,
    readyReimbursementVouchers: readyReimbursementVouchers,
    deleteReimbursementVoucher: deleteReimbursementVoucher,
    getExpenseCategories: getExpenseCategories,
    getCostCenters: getCostCenters,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(ReimbursementVouchers)
