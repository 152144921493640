import { getLanguage } from './cookie-utils'
import { t } from './translation-utils'

// Credit: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
function round(value: number, exp: number): number {
  value = value || 0
  exp = exp === undefined ? -2 : -Math.abs(exp)
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  let s = value.toString().split('e')
  value = Math.round(+(s[0] + 'e' + (s[1] ? +s[1] - exp : -exp)))
  // Shift back
  s = value.toString().split('e')
  return +(s[0] + 'e' + (s[1] ? +s[1] + exp : exp))
}

function formatVisualNumber(num?: number | string, decimals = 6, useThousandSeparator = false): string {
  if (!num) {
    return '0'
  }
  if (typeof num === 'number' && isNaN(num)) {
    return num.toString()
  }
  let str = ''
  if (typeof num === 'number') {
    num = num || 0
    if (num % 1 !== 0) {
      num = round(num, decimals)
    }
    str = num.toString()
  } else {
    str = num
  }
  let thousandSeparator = '.'
  if (getLanguage() === 'en') {
    thousandSeparator = ','
  }
  const parts = str.replace(/,/g, '').replace(/\./g, ',').split(',')
  if (isNaN(parseInt(parts[0])) || (parts[1] && isNaN(parseInt(parts[1])))) {
    // if either part is invalid, return what it was
    return str
  }
  if (useThousandSeparator) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
  }
  switch (getLanguage()) {
    case 'en':
      return parts.join('.')
    case 'da':
      return parts.join(',')
    default:
      return parts.join('.')
  }
}

export function formatInputNumber(num?: number | string, decimals = 2): string {
  return formatVisualNumber(num, decimals, false)
}

interface NumberOptions {
  trim: boolean
}

export function parseInputNumber(str?: string | number, options?: NumberOptions): string | number {
  options = options || { trim: true } // we trim per default, as it must be explicitly disabled for when user is typing
  if (typeof str === 'number') {
    return str
  }
  str = str || ''
  str = str.replace(/[^0-9,.]/g, '')
  const num = str.replace(/,/g, '.')
  if (num) {
    if (!options.trim && num.match(/[.0]$/) && (num.match(/\./g) || []).length <= 1) {
      // if last symbol is 0 or . (and it is the only occurrence of a .), then the user is editing, so do nothing
      return num
    }
    return parseFloat(num)
  }
  return 0
}

export function forceParseInputNumber(str?: string | number): number {
  const result = parseInputNumber(str, { trim: true })
  if (typeof result === 'number') {
    return result
  }
  return !isNaN(parseFloat(result)) ? parseFloat(result) : 0
}

export function formatNumber(num?: number, decimals?: number, fillWithZeros = true): string {
  if (!num) {
    return '0'
  }
  decimals = decimals || 0
  let value = formatVisualNumber(round(num, decimals), decimals, true).toString()
  // Fill with zeros, if necessary
  if (fillWithZeros && decimals > 0) {
    let separator = ','
    if (getLanguage() === 'en') {
      separator = '.'
    }
    if (value.indexOf(separator) === -1) {
      value += separator
    }
    const diff = decimals - value.split(separator)[1].length
    if (diff > 0) {
      value += '0'.repeat(diff)
    }
  }
  return value
}

/**
 * A helper function for displaying a number with maximum 2 decimals
 *
 * @param num The number to display
 * @param maxDecimals Maximum number of decimals (default 2)
 */
export function formatDisplayNumber(num?: number, maxDecimals = 2): string {
  return formatNumber(num, maxDecimals, false)
}

export function formatCurrency(num?: number, decimals = 0, currency = 'kr.'): string {
  return formatNumber(num, decimals) + ' ' + currency
}

export function addLeadingZeros(num: number, pad = 2): string {
  let output = ''
  for (let i = pad - 1; i > 0; i--) {
    if (num < Math.pow(10, i)) {
      output += '0'
    }
  }
  output += num
  return output
}

export function formatMinutesAsTime(num: number): string {
  const hours = Math.floor(num / 60)
  return `${addLeadingZeros(hours)}:${addLeadingZeros(num % 60)}`
}

export function parseTimeAsMinutes(time: string): number {
  const [hours, minutes] = time.split(':')
  let parsedHours = 0
  let parsedMinutes = 0
  if (hours !== undefined) {
    parsedHours = parseInt(hours, 10) * 60
  }
  if (minutes !== undefined) {
    parsedMinutes = parseInt(minutes, 10)
  }
  return parsedHours + parsedMinutes
}

export function formatInputAsMinutes(input: string): string {
  const formatted = input.replace(/[ :,.]/g, '').replace(/^(\d{1,2})(\d{2})/, '$1:$2')
  return formatted.match(/^\d{1}:\d{2}$/) ? '0' + formatted : formatted
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isNumeric(n: any): boolean {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 * Format days in a standard format with the unit afterwards
 * @param days
 * @param forceDecimals
 */
export function formatDays(days: number, forceDecimals = false): string {
  if (days === undefined) {
    days = 0
  }
  if (forceDecimals) {
    return `${formatNumber(days, 2)} ${t('unit.days', { count: days })}`
  }
  return `${formatDisplayNumber(days, 2)} ${t('unit.days', { count: days })}`
}
