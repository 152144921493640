import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { getInvite, register } from '../actions/user'
import Alert from '../components/elements/alert'
import RegisterComponent from '../components/register/Register'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { CompanyReducer } from '../reducers/company'
import { EmployeeReducer } from '../reducers/employee'
import { UserReducer } from '../reducers/user'
import { regularComponentDidUpdate } from '../utils/component-utils'
import { formatError } from '../utils/error-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
  company: CompanyReducer
  employee: EmployeeReducer
}

type Actions = {
  getInvite: (token: string) => void
  register: (token: string, password: string) => void
}

function Register(props: RouteProps & Reducers & Actions): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  useEffectOnce(() => {
    const token = props.params.splat
    if (!token) {
      return
    }
    if ((!props.company.loaded && !props.company.loading) || (!props.employee.loaded && !props.employee.loading)) {
      props.getInvite(token)
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(props.company.error || props.employee.error, error, setError)
  }, [props.company, props.employee, error])

  if (error) {
    return <Alert message={formatError(error)} type="error" showIcon />
  }
  const loading = !props.company.loaded || !props.employee.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.params.splat) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <RegisterComponent
      token={props.params.splat}
      user={props.user}
      company={props.company.company!}
      employee={props.employee.employee!}
      register={props.register}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
    company: state.company,
    employee: state.employee,
  }),
  {
    getInvite: getInvite,
    register: register,
  }
)(Register)
