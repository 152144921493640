import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { createCarAllowances, updateCarAllowance } from '../actions/car-allowances'
import { switchCompany } from '../actions/companies'
import { updateEmployee } from '../actions/employees'
import { getPaySlips } from '../actions/pay-slips'
import { createSalaryRegistration, updateSalaryRegistration } from '../actions/salary-registrations'
import { getSwipeStatus } from '../actions/swipe'
import {
  createTimeRegistration,
  createTimeRegistrationBulk,
  deleteTimeRegistration,
  updateTimeRegistration,
} from '../actions/time-registrations'
import DashboardComponent from '../components/dashboard/Dashboard'
import { CarAllowanceMutableFields } from '../model/carAllowance'
import Employee from '../model/employee'
import PaySlip from '../model/paySlip'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import { SwipeStatus } from '../model/swipe'
import TimeRegistration, {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { AlertReducer } from '../reducers/alerts'
import { CarAllowanceReducer } from '../reducers/carAllowances'
import { CompanyReducer } from '../reducers/company'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employee'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { PaySlipReducer } from '../reducers/paySlips'
import { ProjectReducer } from '../reducers/projects'
import { SalaryRegistrationReducer } from '../reducers/salaryRegistrations'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { SwipeStatusReducer } from '../reducers/swipeStatus'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  paySlips: PaySlipReducer
  swipeStatus: SwipeStatusReducer
  projects: ProjectReducer
  leaveTypes: LeaveTypeReducer
  salaryTypes: SalaryTypeReducer
  companyFeatures: CompanyFeatureReducer
  timeRegistrations: TimeRegistrationReducer
  carAllowances: CarAllowanceReducer
  salaryRegistrations: SalaryRegistrationReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
}

type Actions = {
  getPaySlips: (employeeID: string) => Promise<PaySlip[] | void>
  getSwipeStatus: (employeeID: string) => Promise<SwipeStatus | void>
  switchCompany: (companyID: string, employeeID: string) => void
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature

  updateEmployee: (employee: Employee) => void

  createTimeRegistration: (registration: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  createTimeRegistrationBulk: (timeBulk: TimeRegistrationBulkFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  deleteTimeRegistration: (id: string, employeeID: string) => void
  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  createSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
  updateSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
}

function Dashboard(props: Reducers & Actions): ReactElement | null {
  return (
    <DashboardComponent
      alerts={props.alerts}
      company={props.company}
      employee={props.employee}
      paySlips={props.paySlips.paySlips}
      swipeStatus={props.swipeStatus}
      projects={props.projects.projects}
      leaveTypes={props.leaveTypes.leaveTypes}
      salaryTypes={props.salaryTypes.salaryTypes}
      costCenters={props.costCenters.costCenters}
      departments={props.departments.departments}
      companyFeatures={props.companyFeatures.companyFeatures}
      timeRegistrations={props.timeRegistrations}
      carAllowances={props.carAllowances}
      salaryRegistrations={props.salaryRegistrations}
      getPaySlips={props.getPaySlips}
      getSwipeStatus={props.getSwipeStatus}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      switchCompany={props.switchCompany}
      updateEmployee={props.updateEmployee}
      createTimeRegistration={props.createTimeRegistration}
      createTimeRegistrationBulk={props.createTimeRegistrationBulk}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      createCarAllowances={props.createCarAllowances}
      updateCarAllowance={props.updateCarAllowance}
      createSalaryRegistration={props.createSalaryRegistration}
      updateSalaryRegistration={props.updateSalaryRegistration}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    employee: state.employee,
    paySlips: state.paySlips,
    swipeStatus: state.swipeStatus,
    projects: state.projects,
    leaveTypes: state.leaveTypes,
    salaryTypes: state.salaryTypes,
    companyFeatures: state.companyFeatures,
    timeRegistrations: state.timeRegistrations,
    costCenters: state.costCenters,
    departments: state.departments,
    carAllowances: state.carAllowances,
    salaryRegistrations: state.salaryRegistrations,
  }),
  {
    getPaySlips: getPaySlips,
    getSwipeStatus: getSwipeStatus,
    addAlert: addAlert,
    removeAlert: removeAlert,
    switchCompany: switchCompany,
    updateEmployee: updateEmployee,
    createTimeRegistration: createTimeRegistration,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    createCarAllowances: createCarAllowances,
    updateCarAllowance: updateCarAllowance,
    createTimeRegistrationBulk: createTimeRegistrationBulk,
    createSalaryRegistration: createSalaryRegistration,
    updateSalaryRegistration: updateSalaryRegistration,
  }
)(Dashboard)
