import { isNumeric } from './number-utils'

// eslint-disable-next-line no-unused-vars
export function assign(dest, src) {
  let mergeArrays = false
  if (typeof arguments[arguments.length - 1] === 'boolean') {
    mergeArrays = arguments[arguments.length - 1]
    delete arguments[arguments.length - 1]
  }
  dest = dest || {}
  for (let i = 1; i < arguments.length; i++) {
    for (let key in arguments[i]) {
      if (arguments[i][key] && typeof arguments[i][key] === 'object' && arguments[i][key].constructor === Object) {
        dest[key] = assign(dest[key], arguments[i][key], mergeArrays)
      } else if (mergeArrays && arguments[i][key] instanceof Array) {
        if (!dest[key]) {
          dest[key] = []
        }
        arguments[i][key].forEach((val, idx) => {
          if (val !== null && typeof val === 'object' && val.constructor === Object) {
            dest[key][idx] = assign(dest[key][idx], val, mergeArrays)
          } else {
            dest[key][idx] = val
          }
        })
      } else {
        dest[key] = arguments[i][key]
      }
    }
  }
  return dest
}

export function getByPath(obj, path) {
  if (path.indexOf('.') === -1) {
    return obj[path]
  }
  const paths = path.split('.')
  let current = obj
  for (let i = 0; i < paths.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (current && typeof current === 'object' && current.hasOwnProperty(paths[i])) {
      current = current[paths[i]]
    } else {
      return undefined
    }
  }
  return current
}
export function setByPath(obj, path, val) {
  if (path.indexOf('.') === -1) {
    obj[path] = val
    return
  }
  const paths = path.split('.')
  let current = obj
  for (let i = 0; i < paths.length - 1; i++) {
    if (!current[paths[i]]) {
      current[paths[i]] = isNumeric(paths[i + 1]) ? [] : {}
    }
    current = current[paths[i]]
  }
  current[paths.pop()] = val
}
