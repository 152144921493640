import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { createSwipe, deleteSwipe, getSwipes, getSwipeStatus } from '../actions/swipe'
import SwipeComponent from '../components/swipe/Swipe'
import SwipeDisabledComponent from '../components/swipe/SwipeDisabled'
import Swipe, { SwipePayOutType } from '../model/swipe'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { EmployeeReducer } from '../reducers/employee'
import { SwipeReducer } from '../reducers/swipes'
import { SwipeStatusReducer } from '../reducers/swipeStatus'
import TransferDestinationType from '../types/transfer-destination-type'
import { isEmployeeType } from '../utils/employee-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  employee: EmployeeReducer
  swipes: SwipeReducer
  swipeStatus: SwipeStatusReducer
}

type Actions = {
  getSwipes: (employeeID: string) => void
  createSwipe: (employeeID: string, amount: number, payOut: SwipePayOutType) => Promise<Swipe | void>
  deleteSwipe: (swipeID: string) => Promise<boolean | void>
  getSwipeStatus: (employeeID: string) => void
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

function SwipeController(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.company.company
  const employee = props.employee.employee

  if (!company || !employee) {
    return null
  }
  if (
    !props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Swipe') ||
    !company.enableSwipe ||
    !company.swipeEmployeeTypes ||
    !company.swipeEmployeeTypes.some((employeeType) => isEmployeeType(employee, employeeType)) ||
    !(
      (employee.transferDestinationType === TransferDestinationType.DK_ACCOUNT &&
        !!employee.bankRegistrationNumber &&
        !!employee.bankAccountNumber) ||
      employee.transferDestinationType === TransferDestinationType.DK_NEMKONTO
    )
  ) {
    return <SwipeDisabledComponent />
  }

  const splat = props.params.splat
  let subsection
  if (splat) {
    const parts = (props.params.splat || '').split('/')
    if (parts.length > 0) {
      subsection = parts[0]
    }
  }

  return (
    <SwipeComponent
      subsection={subsection}
      company={company}
      employee={employee}
      swipes={props.swipes}
      swipeStatus={props.swipeStatus}
      getSwipes={props.getSwipes}
      createSwipe={props.createSwipe}
      deleteSwipe={props.deleteSwipe}
      getSwipeStatus={props.getSwipeStatus}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    companyFeatures: state.companyFeatures,
    employee: state.employee,
    swipes: state.swipes,
    swipeStatus: state.swipeStatus,
  }),
  {
    getSwipes: getSwipes,
    createSwipe: createSwipe,
    deleteSwipe: deleteSwipe,
    getSwipeStatus: getSwipeStatus,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(SwipeController)
