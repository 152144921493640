import { Device } from '@capacitor/device'

let deviceName = 'browser'
async function loadPlatform() {
  const info = await Device.getInfo()
  deviceName = `${info.manufacturer} ${info.model} - ${info.operatingSystem} ${info.osVersion}`
}
const platformReady = loadPlatform()
export function isPlatformReady() {
  return platformReady
}

export const Platforms = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
}

export function getPlatform() {
  if (process.env.REACT_APP_PLATFORM) {
    return process.env.REACT_APP_PLATFORM
  }
  return Platforms.WEB
}
export function isWeb() {
  return getPlatform() === Platforms.WEB
}
export function isIos() {
  return getPlatform() === Platforms.IOS
}
export function isAndroid() {
  return getPlatform() === Platforms.ANDROID
}
export function isNative() {
  return isIos() || isAndroid()
}

export function getDeviceName() {
  return deviceName
}
