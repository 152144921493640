import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { createOneTimePay, deleteOneTimePay, getOneTimePays, updateOneTimePay } from '../actions/one-time-pays'
import { getSalaryPeriods } from '../actions/pay-slips'
import FeesComponent from '../components/fees/Fees'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { AlertReducer } from '../reducers/alerts'
import { EmployeeReducer } from '../reducers/employee'
import { OneTimePayReducer } from '../reducers/oneTimePays'
import { SalaryPeriodReducer } from '../reducers/salaryPeriods'
import AffiliationType from '../types/affiliation-type'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  employee: EmployeeReducer
  oneTimePays: OneTimePayReducer
  salaryPeriods: SalaryPeriodReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getOneTimePays: (employeeID: string) => Promise<OneTimePay[] | void>
  createOneTimePay: (oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (oneTimePay: OneTimePayMutableFields) => void
  deleteOneTimePay: (oneTimePayID: string) => void
  getSalaryPeriods: (employeeID: string) => void
}

function Fees(props: Reducers & Actions): ReactElement | null {
  const employee = props.employee.employee
  const { salaryPeriods, getSalaryPeriods } = props
  useEffect(() => {
    if (!employee) {
      return
    }
    if (!salaryPeriods.loading && !salaryPeriods.loaded) {
      getSalaryPeriods(employee.id)
    }
  })

  if (!employee) {
    jsBrowserHistory.push('/')
    return null
  }
  if (employee.affiliationType !== AffiliationType.FREELANCER) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <FeesComponent
      employee={employee}
      oneTimePays={props.oneTimePays}
      salaryPeriods={props.salaryPeriods.salaryPeriods}
      getOneTimePays={props.getOneTimePays}
      createOneTimePay={props.createOneTimePay}
      updateOneTimePay={props.updateOneTimePay}
      deleteOneTimePay={props.deleteOneTimePay}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    employee: state.employee,
    oneTimePays: state.oneTimePays,
    salaryPeriods: state.salaryPeriods,
  }),
  {
    getOneTimePays: getOneTimePays,
    createOneTimePay: createOneTimePay,
    updateOneTimePay: updateOneTimePay,
    deleteOneTimePay: deleteOneTimePay,
    getSalaryPeriods: getSalaryPeriods,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(Fees)
