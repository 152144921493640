import { SocketData } from '../actions/socket'
import { AllReducers } from '../reducers'
import { getAccessToken } from './cookie-utils'
import { getHost } from './request-utils'

export type ListenFunction = (data: SocketData) => void

let socket: WebSocket | null = null
let socketKeepAlive: ReturnType<typeof setTimeout> | null = null
let subscriptions: string[] = []
const listeners: ListenFunction[] = []

function getSocketURL() {
  const host = getHost().replace(/^http/, 'ws')
  return host + '/v2/ws'
}

function createSocket() {
  if (socketKeepAlive) {
    clearTimeout(socketKeepAlive)
  }
  if (socket == null) {
    socket = new WebSocket(getSocketURL())
    socket.addEventListener('message', function (event) {
      const data = JSON.parse(event.data)
      for (let i = 0; i < listeners.length; i++) {
        const fn = listeners[i]
        fn(data)
      }
    })
  } else if (socket.readyState === 3) {
    socket = null
    subscriptions.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      sendMessage('subscribe', key)
    })
  }
  socketKeepAlive = setTimeout(() => {
    createSocket()
  }, 1000)
}

function sendMessage(command: string, channel?: string) {
  createSocket()
  if (socket && socket.readyState === 1) {
    socket.send(JSON.stringify({ accessToken: getAccessToken(), command, channel }))
  } else {
    setTimeout(() => {
      sendMessage(command, channel)
    }, 1000)
  }
}

export function subscribe(state: AllReducers) {
  if (!state.user.loggedIn) {
    return
  }
  // Subscribe employee
  if (state.employee.employee && state.employee.employee.id) {
    const key = 'employee:' + state.employee.employee.id
    if (subscriptions.indexOf(key) === -1) {
      subscriptions.push(key)
      sendMessage('subscribe', key)
    }
  }
}
export function unsubscribe(state: AllReducers) {
  if (state.user.loggedIn) {
    return
  }
  // Unsubscribe all
  if (subscriptions.length > 0) {
    subscriptions = []
    sendMessage('unsubscribe')
  }
}

export function listen(fn: ListenFunction) {
  listeners.push(fn)
}
