import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import EmployeeEmergencyContact from '../model/employeeEmergencyContact'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmployeeEmergencyContactReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  employeeEmergencyContact: EmployeeEmergencyContact | undefined
  error: Error | null
}

const Params = Record<EmployeeEmergencyContactReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  employeeEmergencyContact: undefined,
  error: null,
})

export interface EmployeeEmergencyContactAction extends ReducerAction {
  employeeID?: string
  employeeEmergencyContact?: EmployeeEmergencyContact
}

export default (
  state: Record<EmployeeEmergencyContactReducer> = Params(),
  action: EmployeeEmergencyContactAction = { type: '' }
): Record<EmployeeEmergencyContactReducer> => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        employeeEmergencyContact: action.employeeEmergencyContact,
      })
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error || null,
      })

    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_UPDATED:
      if (action.employeeID !== state.get('employeeID')) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('employeeEmergencyContact', action.employeeEmergencyContact)
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_DELETED:
      if (action.employeeID !== state.get('employeeID')) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('employeeEmergencyContact', undefined)
    case ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
