import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import { getPlatform } from '../../utils/device-utils'
import { getFooterItems, MenuItem } from '../../utils/footer-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import Popover from '../antd/popover'

import './Footer.css'

type Props = {
  location: RoutePropsLocation
  company: Company
  companyFeatures: List<CompanyFeature>
  employee: Employee
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  projects: List<Project>
}

export default function Footer(props: Props): ReactElement | null {
  const [popoverOpen, setPopoverOpen] = useState(false)
  if (props.employee.onboardingState === 'Draft') {
    return null
  }

  const items = getFooterItems(
    props.employee,
    props.company,
    props.companyFeatures.toArray(),
    props.leaveTypes.toArray(),
    props.salaryTypes.toArray(),
    props.projects.toArray()
  )
  const path = props.location.pathname

  if (items.length < 4) {
    // if there are fewer than 4 elements, prepend the dashboard link
    items.unshift({ path: '/', title: t('footer.dashboard') })
  }

  let bottomItems = items.slice(0, 4)
  let menuItems: MenuItem[] = []
  if (items.length > 4) {
    bottomItems = bottomItems.slice(0, 3)
    menuItems = items.slice(3)
  }

  return (
    <footer className={'footer platform-' + getPlatform()}>
      {bottomItems.map((item) => {
        let className = 'nav-item'
        const paths = [item.path, ...(item.paths || [])]
        if (paths.indexOf(path) !== -1) {
          className += ' nav-item-active'
        }
        return (
          <Link key={item.title} to={item.path} className={className}>
            {item.title}
          </Link>
        )
      })}
      {menuItems.length > 0 && (
        <Popover
          placement="bottomRight"
          visible={popoverOpen}
          onVisibleChange={(open: boolean) => {
            if (open !== popoverOpen) {
              setPopoverOpen(open)
            }
          }}
          content={menuItems.reverse().map((item) => {
            let className = 'nav-popover-item'
            const paths = [item.path, ...(item.paths || [])]
            if (paths.indexOf(path) !== -1) {
              className += ' nav-item-active'
            }
            return (
              <Link key={item.title} to={item.path} className={className} onClick={() => setPopoverOpen(false)}>
                <span>{item.title}</span>
              </Link>
            )
          })}
          trigger="click"
          overlayClassName="nav-popover-menu"
        >
          <div className="nav-item nav-item-toggle">
            <span className="nav-toggle-circle" />
            <span className="nav-toggle-circle" />
            <span className="nav-toggle-circle" />
          </div>
        </Popover>
      )}
    </footer>
  )
}
