type Value = string | undefined | null
type Mapping = { [key: string]: unknown }
type Argument = Value | Mapping | Argument[]

export default function classNames(...args: Argument[]): string {
  const handleArgument = (arg: Argument): string[] => {
    if (!arg) {
      return []
    }
    if (Array.isArray(arg)) {
      return arg.reduce((list: string[], val) => {
        return [...list, ...handleArgument(val)]
      }, [])
    }
    const list = []
    switch (typeof arg) {
      case 'string':
        list.push(arg)
        break
      case 'object':
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            list.push(key)
          }
        })
        break
    }
    return list
  }
  return args
    .reduce((classNames: string[], argument) => {
      if (!argument) {
        return classNames
      }
      return [...classNames, ...handleArgument(argument)]
    }, [])
    .join(' ')
}
