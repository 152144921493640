import Department from '../model/department'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDepartments(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<Department[]>> {
  return secureRequest('GET', url('/v2/departments', { companyID, limit, offset }))
}
