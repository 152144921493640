import EmployeeEmergencyContact, { EmployeeEmergencyContactMutableFields } from '../model/employeeEmergencyContact'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchEmployeeEmergencyContact(employeeID: string): Promise<RequestResponse<EmployeeEmergencyContact>> {
  return secureRequest('GET', url('/v2/employeeEmergencyContacts/' + employeeID))
}

export function putEmployeeEmergencyContact(
  employeeID: string,
  employeeEmergencyContact: EmployeeEmergencyContactMutableFields
): Promise<RequestResponse<EmployeeEmergencyContact>> {
  return secureRequest('PUT', url('/v2/employeeEmergencyContacts/' + employeeID), {
    body: {
      name: employeeEmergencyContact.name,
      email: employeeEmergencyContact.email,
      phoneNumberCountryCode: employeeEmergencyContact.phoneNumberCountryCode,
      phoneNumber: employeeEmergencyContact.phoneNumber,
      relation: employeeEmergencyContact.relation,
    },
  })
}

export function delEmployeeEmergencyContact(employeeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/employeeEmergencyContacts/' + employeeID))
}
