import { addDays, isSameDay, startOfDay, startOfWeek } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import Employee from '../../model/employee'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { paths } from '../../routes'
import { formatAPIDate, getDate, isTimeBetween } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import TimeRegistrationModal from '../time-registration/TimeRegistrationModal'
import WorkHoursSummary from '../work-hours-registration/WorkHoursSummary'
import WorkHoursCardForm, { WorkHoursCardResult } from './WorkHoursCardForm'

type Props = {
  company: Company
  employee: Employee
  timeRegistrations: TimeRegistrationReducer

  addAlert: addAlertSignature
  createTimeRegistration: (registration: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  deleteTimeRegistration: (id: string, employeeID: string) => void
}

export default function WorkHoursCard(props: Props): ReactElement | null {
  const [showModal, setShowModal] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const { timeRegistrations, addAlert } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  const setShowEdit = (toggle: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowModal(toggle)
  }

  useEffect(() => {
    if (previousTimeRegistrations?.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        addAlert('success', t('dashboard.card_work_hours.alert.success'), { timeout: 5 })
        setShowEdit(false)
      }
    }
  })

  const today = startOfDay(getDate())
  const weekStart = startOfWeek(getDate(), { weekStartsOn: 1 })
  const weekEnd = addDays(weekStart, 6)
  const registrations = props.timeRegistrations.timeRegistrations.filter(
    (reg) => reg.class === 'Work Hours' && isTimeBetween(getDate(reg.date), weekStart, weekEnd)
  )

  const handleSubmit = (values: WorkHoursCardResult) => {
    const date = formatAPIDate(today)
    const existing = registrations.find((reg) => reg.date === date)
    if (existing) {
      if (values.hours === 0 && !values.start) {
        // if 0 and no start, we consider it a deletion
        props.deleteTimeRegistration(existing.id, props.employee.id)
      } else if (existing.hours !== values.hours || existing.start !== values.start) {
        // only update if an actual difference
        props.updateTimeRegistration({ ...existing, hours: values.hours, start: values.start, end: values.end })
      }
    } else if (values.hours > 0 || !!values.start) {
      // only create ones with hours or start
      props.createTimeRegistration({
        employeeID: props.employee.id,
        class: 'Work Hours',
        date,
        hours: values.hours,
        start: values.start,
        end: values.end,
        approved: true,
        note: '',
      })
    }
  }
  const summaryHours = registrations.reduce((hours, reg) => {
    return hours + (reg.hours ?? 0)
  }, 0)
  // detailed if there are more than registration today
  const isDetailed = registrations.filter((reg) => isSameDay(getDate(reg.date), today)).size > 1
  return (
    <div className="card-work-hours">
      <Headline>{t('dashboard.card_work_hours.title')}</Headline>
      <WorkHoursSummary summaryHours={summaryHours} />
      {!isDetailed && (
        <WorkHoursCardForm
          company={props.company}
          timeRegistrations={props.timeRegistrations.timeRegistrations}
          onSubmit={handleSubmit}
        />
      )}
      {isDetailed && (
        <>
          <Button className="dashboard-choice-register-button" type="primary" onClick={() => setShowEdit(true)}>
            {t('dashboard.card_work_hours.register_new')}
          </Button>
          <Modal
            key={modalKey}
            visible={showModal}
            onOk={() => setShowEdit(false)}
            onCancel={() => setShowEdit(false)}
            width={376}
            footer={null}
          >
            <TimeRegistrationModal
              visible={showModal}
              locked={false}
              company={props.company}
              employee={props.employee}
              salaryTypes={List()}
              leaveTypes={List()}
              mode={'Work Hours'}
              timeRegistrations={props.timeRegistrations}
              projects={List()}
              costCenters={List()}
              departments={List()}
              createTimeRegistration={props.createTimeRegistration}
              updateTimeRegistration={props.updateTimeRegistration}
              includeDateRange={[weekStart, weekEnd]}
              suggestedDate={today}
            />
          </Modal>
        </>
      )}
      <Link to={'/' + paths.WORK_HOURS_REGISTRATION}>
        <Button className="dashboard-choice-page-button">{t('dashboard.card_work_hours.go_to_page')}</Button>
      </Link>
    </div>
  )
}
