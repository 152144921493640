import React, { ReactElement } from 'react'

import { SwipeStatusReducer } from '../../reducers/swipeStatus'
import { formatCurrency, formatDisplayNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'

type Props = {
  swipeStatus: SwipeStatusReducer

  closeModal: () => void
}

export default function SwipeFeeExplanation(props: Props): ReactElement | null {
  return (
    <div className="swipe-fee-explanation">
      <p style={{ paddingRight: '20px' }}>
        {tx(
          props.swipeStatus.swipeFeeCostFrequency === 'Period'
            ? 'swipe.fee.explanation.line_1.period'
            : 'swipe.fee.explanation.line_1.each',
          {
            fee:
              props.swipeStatus.swipeFeeCostType === 'Fixed' ? (
                <strong>{formatCurrency(props.swipeStatus.swipeFeeCost, 2)}</strong>
              ) : (
                <strong>{formatDisplayNumber(props.swipeStatus.swipeFeeCost)} %</strong>
              ),
          }
        )}
      </p>
      {(props.swipeStatus.swipeFeeCostCap ?? 0) > 0 && (
        <p>
          {tx('swipe.fee.explanation.cap', {
            cap: <strong>{formatCurrency(props.swipeStatus.swipeFeeCostCap, 2)}</strong>,
          })}
        </p>
      )}
      <p>{t('swipe.fee.explanation.deduction')}</p>
      <div className="button-container">
        <Button onClick={() => props.closeModal()}>{t('common.close')}</Button>
      </div>
    </div>
  )
}
