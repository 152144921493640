import { Language } from '../model/types'
import { getLanguage } from './cookie-utils'

export function getDefaultLanguage(): Language {
  let lang
  if (typeof navigator !== 'undefined') {
    lang = navigator.languages !== undefined ? navigator.languages[0] : navigator.language
  }
  if (lang) {
    return lang.split('-')[0] as Language
  }
  return 'da'
}

export function getCurrentLocale() {
  const currentLang = getLanguage()
  switch (currentLang) {
    case 'da':
      return 'da-DK'
    case 'en':
      return 'en-GB'
    default:
      return 'da-DK'
  }
}

export function getCurrentLanguage() {
  switch (getLanguage()) {
    case 'en':
      return 'en'
    default:
    case 'da':
      return 'da'
  }
}
