import { addDays, addYears, compareAsc, getISOWeek, startOfWeek, startOfYear, subDays, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Contract from '../../model/contract'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import Project from '../../model/project'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import TimeRegistrationTemplate, {
  TimeRegistrationTemplateCreationFields,
  TimeRegistrationTemplateLine,
} from '../../model/timeRegistrationTemplate'
import { DateFormat, Day } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../../reducers/timeRegistrationTemplates'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import {
  formatAPIDate,
  formatDate,
  formatShortDate,
  getDate,
  isSameOrBefore,
  isTimeAfter,
  isTimeBefore,
  isTimeBetween,
} from '../../utils/date-utils'
import { getCurrentWorkWeek, numberToDay, week } from '../../utils/day-utils'
import { formatError } from '../../utils/error-utils'
import { formatDisplayNumber, formatNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Subtitle from '../elements/Subtitle'
import TimeRegistrationModal from '../time-registration/TimeRegistrationModal'
import Alerts from '../widgets/Alerts'
import PullToRefresh from '../widgets/PullToRefresh'
import WorkHoursSummary from './WorkHoursSummary'
import WorkHoursWeekForm, { WorkHoursResult } from './WorkHoursWeekForm'

import './WorkHours.css'

type BaseProps = {
  alerts: AlertReducer
  company: Company
  employee: Employee
  contracts: List<Contract>
  timeRegistrationTemplates: TimeRegistrationTemplateReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (employeeID: string) => Promise<boolean | void>
}

type RegistrationProps = BaseProps & {
  templateMode: false
  contracts: List<Contract>
  timeRegistrations: TimeRegistrationReducer
  projects: List<Project>
  costCenters: List<CostCenter>
  departments: List<Department>
  companyFeatures: List<CompanyFeature>

  getTimeRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistration: (registration: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  deleteTimeRegistration: (id: string, employeeID: string) => void
}

type TemplateProps = BaseProps & {
  templateMode: true
}

export default function WorkHoursRegistrationTab(props: RegistrationProps | TemplateProps): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [editing, setEditing] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [detailedWeeks, setDetailedWeeks] = useState<Date[]>([])
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(getDate(), { weekStartsOn: 1 }))
  const [error, setError] = useState<Error | null>(null)

  const { timeRegistrationTemplates } = props

  const setEditVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }
  const setTemplateModal = (t: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowTemplateModal(t)
  }

  const timeRegistrations = props.templateMode ? null : props.timeRegistrations
  const { addAlert, templateMode } = props

  useEffect(() => {
    if (!templateMode) {
      if (regularComponentDidUpdate(timeRegistrations!.error, error, setError)) {
        return
      }
    }
    if (regularComponentDidUpdate(timeRegistrationTemplates.error, error, setError)) {
      return
    }
  }, [templateMode, timeRegistrations, timeRegistrationTemplates, error])

  const previousTimeRegistrations = usePrevious(templateMode ? null : timeRegistrations)

  useEffect(() => {
    if (templateMode) {
      return
    }
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations!.saving) {
      if (!timeRegistrations!.error) {
        addAlert('success', t('work_hours_registration_tab.alert.success'), { timeout: 5 })
        setEditVisibility(false)
      }
    }
  }, [templateMode, timeRegistrations, previousTimeRegistrations, addAlert])

  const previousTimeRegistrationTemplates = usePrevious(timeRegistrationTemplates)

  useEffect(() => {
    if (templateMode) {
      return
    }
    if (previousTimeRegistrationTemplates?.saving && !timeRegistrationTemplates.saving) {
      if (!timeRegistrationTemplates.error) {
        setTemplateModal(false)
      }
    }
  }, [templateMode, previousTimeRegistrationTemplates, timeRegistrationTemplates])

  const changeDetailed = (weekStart: Date, detailed: boolean) => {
    if (!detailed) {
      setDetailedWeeks((prev) => prev.filter((d) => compareAsc(d, weekStart) !== 0))
    } else {
      setDetailedWeeks((prev) => [...prev, weekStart])
    }
  }

  const registrations = props.templateMode
    ? []
    : props.timeRegistrations.timeRegistrations.filter((reg) => reg.class === 'Work Hours').toArray()
  type registrationWeek = {
    weekStart: Date
    workWeek: Day[]
    registrations: Record<Day, TimeRegistration[]>
  }
  const getWorkWeek = (weekStart: Date) => {
    const weekEnd = addDays(weekStart, 6)
    const contractA = props.contracts.find((c) => isTimeBetween(weekStart, c.validFrom, c.validTo ?? weekStart))
    const contracts: Contract[] = []
    if (contractA) {
      contracts.push(contractA)
      if (contractA.validTo && isTimeBefore(contractA.validTo, weekEnd)) {
        const contractB = props.contracts.find((c) => isTimeBetween(weekEnd, c.validFrom, c.validTo ?? weekEnd))
        if (contractB) {
          contracts.push(contractB)
        }
      }
    }
    let test = weekStart
    const workWeek: Day[] = []
    contracts.forEach((contract) => {
      // use the earliest of validTo or weekEnd
      let against = contract.validTo ?? weekEnd
      if (isTimeAfter(against, weekEnd)) {
        against = weekEnd
      }
      while (isSameOrBefore(test, against)) {
        const day = numberToDay(test.getDay())
        if (getCurrentWorkWeek(contract.workCycle, contract.workCycleAnchorDate, weekStart).some((d) => d === day)) {
          workWeek.push(day)
        }
        test = addDays(test, 1)
      }
    })
    return workWeek
  }
  const weeks = registrations
    .reduce((weeks: registrationWeek[], reg) => {
      const weekStart = startOfWeek(getDate(reg.date), { weekStartsOn: 1 })
      let weekIndex = weeks.findIndex((week) => compareAsc(week.weekStart, weekStart) === 0)
      if (weekIndex < 0) {
        weeks.push({
          weekStart: weekStart,
          workWeek: getWorkWeek(weekStart),
          registrations: {
            ['Monday']: [],
            ['Tuesday']: [],
            ['Wednesday']: [],
            ['Thursday']: [],
            ['Friday']: [],
            ['Saturday']: [],
            ['Sunday']: [],
          },
        })
        weekIndex = weeks.length - 1
      }
      const week = weeks[weekIndex]
      week.registrations[numberToDay(getDate(reg.date).getDay())].push(reg)
      weeks[weekIndex] = week
      return weeks
    }, [])
    .sort((a, b) => {
      return formatAPIDate(b.weekStart).localeCompare(formatAPIDate(a.weekStart))
    })

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (props.templateMode) {
        return
      }
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteTimeRegistration(id, props.employee.id)
      }
    }
  }

  type TimeRegistrationRow = {
    key: string
    id: string
    date: string
    hours: string
    note?: string
  }

  const handleSimpleSubmit = (values: WorkHoursResult) => {
    if (props.templateMode) {
      const lines = values.days.reduce((lines: TimeRegistrationTemplateLine[], day) => {
        if (day.hours === 0) {
          // ignore days without hours
          return lines
        }
        return [
          ...lines,
          {
            weekday: numberToDay(day.date.getDay()),
            hours: day.hours,
            start: day.start,
            end: day.end,
            note: '',
          },
        ]
      }, [])
      props
        .saveTimeRegistrationTemplates({
          employeeID: props.employee.id,
          class: 'Work Hours',
          lines,
        })
        .then((res) => {
          if (res) {
            addAlert('success', t('work_hours_registration_tab.alert.template_saved'), { timeout: 5 })
          }
        })
    } else {
      values.days.forEach((day) => {
        const date = formatAPIDate(day.date)
        const existing = registrations.find((reg) => reg.date === date)
        if (existing) {
          if (day.hours === 0 && !day.start) {
            // if 0 and no start, we consider it a deletion
            props.deleteTimeRegistration(existing.id, props.employee.id)
          } else if (existing.hours !== day.hours || existing.start !== day.start) {
            // only update if an actual difference
            props.updateTimeRegistration({ ...existing, hours: day.hours, start: day.start, end: day.end })
          }
        } else if (day.hours > 0 || !!day.start) {
          // only create ones with hours or start
          props.createTimeRegistration({
            employeeID: props.employee.id,
            class: 'Work Hours',
            date,
            hours: day.hours,
            start: day.start,
            end: day.end,
            approved: true,
            note: '',
          })
        }
      })
    }
  }

  const deleteTimeRegistrationTemplate = () => {
    props.deleteTimeRegistrationTemplate(props.employee.id).then(() => {
      addAlert('success', t('work_hours_registration_tab.alert.template_deleted'), { timeout: 5 })
    })
  }

  const noneTimeRegistrationTemplate = () => {
    props
      .saveTimeRegistrationTemplates({
        employeeID: props.employee.id,
        class: 'Work Hours',
        lines: [],
      })
      .then((res) => {
        if (res) {
          addAlert('success', t('work_hours_registration_tab.alert.template_none_saved'), { timeout: 5 })
        }
      })
  }

  const formatFromDate = (weekStart: Date): string => {
    const weekEnd = addDays(weekStart, 6)
    if (weekStart.getFullYear() !== weekEnd.getFullYear()) {
      return formatDate(weekStart, t('date.date_short_format'))
    }
    if (weekStart.getMonth() !== weekEnd.getMonth()) {
      return formatDate(weekStart, t('date.day_month_short'))
    }
    return formatDate(weekStart, t('date.day_of_month'))
  }

  const templateButton = () => {
    if (props.templateMode) {
      return null
    }
    if (!props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursAutomatic')) {
      return null
    }

    return (
      <div className="work-week-template-button">
        <Button type="secondary" onClick={() => setTemplateModal(true)}>
          {t('work_hours_registration_tab.template')}
        </Button>
      </div>
    )
  }

  const showTemplateWeek = () => {
    const findEmployee = (t: TimeRegistrationTemplate): boolean => t.employeeID === props.employee.id
    const findCompany = (t: TimeRegistrationTemplate): boolean => t.companyID === props.company.id && !t.employeeID
    const employeeTemplate = props.timeRegistrationTemplates.timeRegistrationTemplates.find((t) => findEmployee(t))
    const companyTemplate = props.timeRegistrationTemplates.timeRegistrationTemplates.find((t) => findCompany(t))
    return (
      <div className="work-week-simple work-week-template">
        <div className="work-week-header">
          <Subtitle>{t('work_hours_registration_tab.week.title.template')}</Subtitle>
        </div>
        <WorkHoursWeekForm
          weekStart={startOfWeek(getDate())}
          company={props.company}
          registrations={
            (employeeTemplate ?? companyTemplate)?.lines.reduce(
              (o: Partial<Record<Day, TimeRegistrationTemplateLine[]>>, line) => {
                const list = o[line.weekday]
                if (!list) {
                  o[line.weekday] = [line]
                } else {
                  o[line.weekday] = [...list, line]
                }
                return o
              },
              {}
            ) ?? {}
          }
          workWeek={week}
          templateMode={true}
          hasEmployeeTemplate={!!employeeTemplate}
          hasCompanyTemplate={!!companyTemplate}
          onDelete={() => deleteTimeRegistrationTemplate()}
          onNone={() => noneTimeRegistrationTemplate()}
          onSubmit={handleSimpleSubmit}
        />
      </div>
    )
  }

  const showWeek = (w: registrationWeek) => {
    // we decide to force the display detailed,
    const forceDetailed =
      w.workWeek.length === 0 || // if no work week
      week.some(
        // if any day has more than one registration OR we are outside the work week,
        (day) =>
          w.registrations[day].length > 1 || (!w.workWeek.some((d) => d == day) && w.registrations[day].length > 0)
      ) ||
      week.some(
        // or if there are both registrations with start/end and without
        (day) =>
          w.registrations[day].some((r) => !!r.start || !!r.end) &&
          week.some((day) => w.registrations[day].some((r) => !r.start && !r.end))
      )
    const isDetailed = detailedWeeks.some((d) => compareAsc(d, w.weekStart) === 0) || forceDetailed
    const detailedSwitch = (
      <div className="btn-group">
        <Button
          size="large"
          type={!isDetailed ? 'secondary' : undefined}
          onClick={() => changeDetailed(w.weekStart, false)}
        >
          {t('work_hours_registration_tab.week.detailed_toggle.simple')}
        </Button>
        <Button
          size="large"
          type={isDetailed ? 'secondary' : undefined}
          onClick={() => changeDetailed(w.weekStart, true)}
        >
          {t('work_hours_registration_tab.week.detailed_toggle.detailed')}
        </Button>
      </div>
    )
    const summaryHours = week.reduce((hours, day) => {
      return (
        hours +
        w.registrations[day].reduce((hours, reg) => {
          return hours + (reg.hours ?? 0)
        }, 0)
      )
    }, 0)
    if (!isDetailed) {
      return (
        <Card key={formatAPIDate(w.weekStart)} className="work-week-simple">
          <div className="work-week-header">
            <Subtitle>
              {t('work_hours_registration_tab.week.title', {
                week_number: formatNumber(getISOWeek(w.weekStart)),
                week_from: formatFromDate(w.weekStart),
                week_to: formatShortDate(addDays(w.weekStart, 6)),
              })}
            </Subtitle>
            {detailedSwitch}
          </div>
          <WorkHoursSummary summaryHours={summaryHours} />
          <WorkHoursWeekForm
            templateMode={false}
            company={props.company}
            weekStart={w.weekStart}
            registrations={w.registrations}
            workWeek={w.workWeek}
            onSubmit={handleSimpleSubmit}
            templateButton={templateButton}
          />
        </Card>
      )
    }
    const regs = week
      .reduce((regs: TimeRegistration[], day) => {
        return [...regs, ...w.registrations[day]]
      }, [])
      .filter((reg) => !deleting.some((id) => id === reg.id))
      .map(
        (reg): TimeRegistrationRow => ({
          key: reg.id,
          id: reg.id,
          date: formatDate(reg.date),
          hours: formatDisplayNumber(reg.hours) + ' ' + t('unit.hours', { count: reg.hours ?? 0 }),
          note: reg.note,
        })
      )
    return (
      <Card key={formatAPIDate(w.weekStart)} className="work-week-detailed registrations-list">
        <div className="work-week-header">
          <Subtitle>
            {t('work_hours_registration_tab.week.title', {
              week_number: formatNumber(getISOWeek(w.weekStart)),
              week_from: formatFromDate(w.weekStart),
              week_to: formatShortDate(addDays(w.weekStart, 6)),
            })}
          </Subtitle>
          {!forceDetailed && detailedSwitch}
        </div>
        <WorkHoursSummary summaryHours={summaryHours} />
        {templateButton()}
        {regs.map((timeRegistration) => {
          return (
            <div key={timeRegistration.id} className="registration-row">
              <Card key={timeRegistration.id} className={'registration-row-approved'}>
                <div className="registration-row-info" onClick={() => setEditVisibility(timeRegistration.id)}>
                  <div className="time-registration-date">{timeRegistration.date}</div>
                  <div className="time-registration-description">{timeRegistration.hours}</div>
                </div>
                <span onClick={remove(timeRegistration.id)} className="registration-row-delete" />
              </Card>
            </div>
          )
        })}
        <Button onClick={() => setEditVisibility(true)} className="register-time-registration" type="primary">
          {t('work_hours_registration_tab.week.new_registration')}
        </Button>
      </Card>
    )
  }

  const currentWeek: registrationWeek = weeks.find((w) => compareAsc(w.weekStart, currentWeekStart) === 0) ?? {
    weekStart: currentWeekStart,
    workWeek: getWorkWeek(currentWeekStart),
    registrations: {
      ['Monday']: [],
      ['Tuesday']: [],
      ['Wednesday']: [],
      ['Thursday']: [],
      ['Friday']: [],
      ['Saturday']: [],
      ['Sunday']: [],
    },
  }

  return (
    <div className="employees-work-hours-tab">
      {!props.templateMode && (
        <PullToRefresh
          onRefresh={() => {
            const employee = props.employee
            const promiseList: Promise<any>[] = [
              props.getTimeRegistrations(
                employee.id,
                formatAPIDate(startOfYear(subYears(getDate(), 1))),
                formatAPIDate(addYears(getDate(), 1))
              ),
            ]
            return Promise.all(promiseList)
          }}
        >
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {error && <Alert type={'error'} message={formatError(error)} showIcon />}
          {!props.templateMode && (
            <Row style={{ marginBottom: '15px' }} className="work-hours-week-nav">
              <Col span={7}>
                <Button onClick={() => setCurrentWeekStart((prev) => subDays(prev, 7))}>
                  {t('work_hours_registration_tab.week.previous')}
                </Button>
              </Col>
              <Col span={10} style={{ textAlign: 'center' }}>
                <Button onClick={() => setCurrentWeekStart(startOfWeek(getDate(), { weekStartsOn: 1 }))}>
                  {t('work_hours_registration_tab.week.this_week')}
                </Button>
              </Col>
              <Col span={7} style={{ textAlign: 'right' }}>
                <Button onClick={() => setCurrentWeekStart((prev) => addDays(prev, 7))}>
                  {t('work_hours_registration_tab.week.next')}
                </Button>
              </Col>
            </Row>
          )}
          {showWeek(currentWeek)}
        </PullToRefresh>
      )}
      {props.templateMode && (
        <>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {error && <Alert type={'error'} message={formatError(error)} showIcon />}
          {showTemplateWeek()}
        </>
      )}

      {!props.templateMode && (
        <Modal
          key={modalKey}
          visible={editing}
          onOk={() => setEditVisibility(false)}
          onCancel={() => setEditVisibility(false)}
          width={376}
          footer={null}
        >
          <TimeRegistrationModal
            visible={editing !== false}
            locked={false}
            company={props.company}
            employee={props.employee}
            salaryTypes={List()}
            leaveTypes={List()}
            mode={'Work Hours'}
            timeRegistrationID={typeof editing === 'string' ? editing : undefined}
            timeRegistrations={props.timeRegistrations}
            projects={props.projects}
            costCenters={props.costCenters}
            departments={props.departments}
            createTimeRegistration={props.createTimeRegistration}
            updateTimeRegistration={props.updateTimeRegistration}
            includeDateRange={[currentWeek.weekStart, addDays(currentWeek.weekStart, 6)]}
            suggestedDate={currentWeek.weekStart}
          />
        </Modal>
      )}
      {!props.templateMode && (
        <Modal
          key={`template-${modalKey}`}
          visible={showTemplateModal}
          onOk={() => setTemplateModal(false)}
          onCancel={() => setTemplateModal(false)}
          width={376}
          footer={null}
        >
          <WorkHoursRegistrationTab
            templateMode={true}
            alerts={props.alerts}
            company={props.company}
            employee={props.employee}
            contracts={props.contracts}
            timeRegistrationTemplates={props.timeRegistrationTemplates}
            addAlert={props.addAlert}
            removeAlert={props.removeAlert}
            saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
            deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
          />
        </Modal>
      )}
    </div>
  )
}
