import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Company from '../../model/company'
import PaySlip from '../../model/paySlip'
import CompanySetting from '../../types/company-setting'
import { formatShortDate, getDate, isSameOrAfter } from '../../utils/date-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Headline from '../elements/Headline'

type Props = {
  paySlips: List<PaySlip>
  company: Company
}

export default function DefaultCard(props: Props): ReactElement | null {
  const [forceShowPay, setForceShowPay] = useState(false)

  const getNextPaySlip = (): PaySlip | undefined => {
    return props.paySlips
      .filter((paySlip) => isSameOrAfter(getDate(paySlip.dispositionDate), getDate()))
      .sort((a, b) => a.dispositionDate.localeCompare(b.dispositionDate))
      .first()
  }
  const getNextGrossSalary = (): string => {
    const paySlip = getNextPaySlip()
    if (!paySlip) {
      return '-'
    }
    let amount = 0
    paySlip.calculations.forEach((item) => {
      if (item.type === 'Salary') {
        amount = item.result
      }
    })
    if (!amount) {
      return '-'
    }
    return formatCurrency(amount, 0)
  }
  const getNextNetSalary = (): string => {
    const paySlip = getNextPaySlip()
    if (!paySlip) {
      return '-'
    }
    const amount = paySlip.payCheck
    return formatCurrency(amount, 0)
  }
  const getNextPaySlipTime = (): string => {
    const paySlip = getNextPaySlip()
    if (!paySlip) {
      return '-'
    }
    return formatShortDate(paySlip.dispositionDate)
  }

  const hidePayslip = props.company.settingsEnabled.some(
    (setting) => setting === CompanySetting.DISABLE_APP_UPCOMING_PAY_SLIP
  )

  return (
    <div className={'card-default' + (forceShowPay ? ' force-show-pay' : '')}>
      <Headline>
        {t('dashboard.card_default.next_paycheck_from')} {props.company.name}
      </Headline>
      <div className="stat-pay-at">{getNextPaySlipTime()}</div>
      {!hidePayslip && (
        <>
          <div className="stat-pay-toggle">
            <Button className="ant-btn-secondary" onClick={() => setForceShowPay((prev) => !prev)}>
              {forceShowPay ? t('dashboard.card_default.hide_pay') : t('dashboard.card_default.show_pay')}
            </Button>
          </div>

          <div className="stat-pay">
            <p>{t('dashboard.card_default.net_pay')}</p>

            <div className="stat-large">{getNextNetSalary()}</div>
            <div className="stat-small">
              {t('dashboard.card_default.gross_pay')}: {getNextGrossSalary()}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
