import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import Company from '../../model/company'
import Employee from '../../model/employee'
import { getDate } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'

type Props = {
  company: Company
  employee: Employee

  closeModal: () => void
}

export default function LeaveCalculationExplanation(props: Props): ReactElement | null {
  const daysEarning =
    props.employee.activeContract?.remuneration.leave.find((leave) => leave.type?.name === 'DenmarkVacationAccrual')
      ?.days || 25
  const normalLeaveEarn = daysEarning === 25

  const today = getDate()

  // if we are after August (=7), the current vacation year starts this year
  const startYear = today.getMonth() >= 8 ? today.getFullYear() : today.getFullYear() - 1
  const endYear = startYear + 1

  return (
    <div className="leave-calculation-explanation">
      <p>{t('leave_registration.future_calculation_explanation_part1', { startYear, endYear })}</p>
      <p>
        {normalLeaveEarn
          ? t('leave_registration.future_calculation_explanation_part2')
          : t('leave_registration.future_calculation_explanation_part2_alternative', { days: daysEarning })}
      </p>
      <p>
        {t('leave_registration.future_calculation_explanation_part3')}
        {!normalLeaveEarn && (
          <> {t('leave_registration.future_calculation_explanation_part3_extra', { days: daysEarning })}</>
        )}
      </p>
      <p className="disclaimer">
        {t('leave_registration.future_disclaimer')}
        {props.company.vacationExcessLimit > 0 && <> {t('leave_registration.future_disclaimer_excess')}</>}
        {props.company.automaticVacationTransferLimit > 0 && <> {t('leave_registration.future_disclaimer_transfer')}</>}
      </p>
      <p>
        {t('leave_registration.future_calculation_explanation_more')}{' '}
        <Link to={'https://salary.dk/ny-ferielov/'} target={'_blank'}>
          {t('leave_registration.future_calculation_explanation_more_link')}
        </Link>
        .
      </p>
      <div className="button-container">
        <Button onClick={() => props.closeModal()}>{t('common.close')}</Button>
      </div>
    </div>
  )
}
