import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Subtitle from '../elements/Subtitle'

export default function SwipeDisabled(): ReactElement {
  return (
    <div className="swipe">
      <Subtitle>{t('swipe.title')}</Subtitle>
      <p>{t('swipe.disabled')}</p>
    </div>
  )
}
