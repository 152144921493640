import { isSameDay, startOfYear, subYears } from 'date-fns'

import OneTimePay from '../model/oneTimePay'
import SalaryPeriod from '../model/salaryPeriod'
import { DateFormat } from '../model/types'
import { getDate, isTimeBefore } from './date-utils'

// return a list of disposition dates based on periods, and a potential additional date
export function getDispositionDateList(salaryPeriods: SalaryPeriod[], additionalDate?: DateFormat): DateFormat[] {
  const startOfLastYear = startOfYear(subYears(getDate(), 1))

  const dates: DateFormat[] = salaryPeriods
    .filter(
      (salaryPeriod) =>
        !!salaryPeriod.dispositionDate &&
        isTimeBefore(startOfLastYear, getDate(salaryPeriod.latestStartTime)) &&
        (!additionalDate || !isSameDay(getDate(salaryPeriod.dispositionDate), getDate(additionalDate)))
    )
    .reduce((dates: DateFormat[], period) => {
      if (!period.dispositionDate) {
        return dates
      }
      return [...dates, period.dispositionDate]
    }, [])

  if (additionalDate) {
    dates.push(additionalDate)
  }

  return dates.sort((a, b) => b.localeCompare(a))
}

export function getOneTimePaySelectedDate(oneTimePays: OneTimePay[], oneTimePayID?: string): DateFormat | undefined {
  if (!oneTimePayID) {
    return undefined
  }

  const oneTimePay = oneTimePays.find((oneTimePay) => oneTimePay.id === oneTimePayID)
  if (!oneTimePay) {
    return undefined
  }
  return oneTimePay.dispositionDate
}
