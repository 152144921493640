import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import PaySlip from '../model/paySlip'
import { ReducerAction } from '../utils/reducer-utils'

export interface PaySlipReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  paySlips: List<PaySlip>
  error: Error | null
}

const Params = Record<PaySlipReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  paySlips: List<PaySlip>(),
  error: null,
})

export interface PaySlipAction extends ReducerAction {
  employeeID?: string
  paySlipID?: string
  paySlips?: PaySlip[]
  paySlip?: PaySlip
}

const comparator = (a: PaySlip, b: PaySlip) => {
  return b.dispositionDate.localeCompare(a.dispositionDate, 'da-dk')
}

export default (
  state: Record<PaySlipReducer> = Params(),
  action: PaySlipAction = { type: '' }
): Record<PaySlipReducer> => {
  let idx = -1
  switch (action.type) {
    case ActionTypes.PAY_SLIPS_LOADING:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.PAY_SLIPS_LOADED:
    case ActionTypes.PAY_SLIPS_LOADED_PARTIAL:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        loading: action.type === ActionTypes.PAY_SLIPS_LOADED_PARTIAL,
        paySlips: state.get('paySlips').concat(List(action.paySlips)).sort(comparator),
      })
    case ActionTypes.PAY_SLIPS_LOAD_FAILED:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error,
      })

    case ActionTypes.PAY_SLIP_ADDED:
      if (!action.paySlip) {
        return state
      }
      idx = state.get('paySlips').findIndex((item) => !!action.paySlipID && item.id === action.paySlipID)
      if (idx !== -1) {
        return state.set('paySlips', state.get('paySlips').set(idx, action.paySlip))
      }
      return state.set('paySlips', state.get('paySlips').push(action.paySlip))
    case ActionTypes.PAY_SLIP_UPDATED:
      if (!action.paySlip) {
        return state
      }
      idx = state.get('paySlips').findIndex((item) => !!action.paySlipID && item.id === action.paySlipID)
      if (idx === -1) {
        return state
      }
      return state.set('paySlips', state.get('paySlips').set(idx, action.paySlip))
    case ActionTypes.PAY_SLIP_DELETED:
      idx = state.get('paySlips').findIndex((item) => !!action.paySlipID && item.id === action.paySlipID)
      if (idx === -1) {
        return state
      }
      return state.set('paySlips', state.get('paySlips').delete(idx))

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
