import Employee from '../model/employee'
import LeaveType from '../model/leaveType'
import Project from '../model/project'
import TimeRegistration from '../model/timeRegistration'
import LeaveDuration from '../types/leave-duration'
import { isSalaried } from './employee-utils'
import { formatLeaveDuration, formatLeaveTypeName } from './format-utils'
import { formatNumber } from './number-utils'
import { t } from './translation-utils'

export function timeRegistrationDescription(timeRegistration: TimeRegistration, projects: Project[]): string {
  const hoursBasis = timeRegistration.hours || (timeRegistration.minutes || 0) / 60 || 0
  const hours = Math.floor(Math.abs(hoursBasis))
  let description = formatNumber(hours) + ' ' + t('unit.hours', { count: hours })
  const minutes = (Math.abs(hoursBasis) - hours) * 60
  if (minutes > 0) {
    description += ', '
    description += formatNumber(minutes) + ' ' + t('unit.minutes', { count: minutes })
  }
  if (timeRegistration.class === 'Flex' || timeRegistration.class === 'Overtime') {
    if (hoursBasis < 0) {
      if (timeRegistration.class === 'Flex') {
        description = t('time_registration.flex_earned') + ': ' + description
      } else {
        description = t('time_registration.overtime_earned') + ': ' + description
      }
    } else {
      if (timeRegistration.class === 'Overtime') {
        description = t('time_registration.flex_used') + ': ' + description
      } else {
        description = t('time_registration.overtime_used') + ': ' + description
      }
    }
  }
  if (
    (timeRegistration.class === 'Hours' || timeRegistration.class === 'Project Hours') &&
    timeRegistration.projectID
  ) {
    const project = projects.find((p) => p.id === timeRegistration.projectID)
    if (project) {
      description += ' (' + project.name + ')'
    }
  }
  return description
}

export function leaveRegistrationDescription(
  timeRegistration: TimeRegistration,
  employee: Employee,
  leaveTypes: LeaveType[]
): string {
  const leaveType = leaveTypes.find((type) => type.id === timeRegistration.leaveTypeID)
  let description = leaveType ? formatLeaveTypeName(leaveType.name, isSalaried(employee)) : ''
  if (timeRegistration.days !== 1) {
    let extra
    switch (timeRegistration.days) {
      case 1:
        extra = formatLeaveDuration(LeaveDuration.FULL_DAY).toLowerCase()
        break
      case 0.75:
        extra = formatLeaveDuration(LeaveDuration.THREE_QUARTERS_DAY).toLowerCase()
        break
      case 0.5:
        extra = formatLeaveDuration(LeaveDuration.HALF_DAY).toLowerCase()
        break
      case 0.25:
        extra = formatLeaveDuration(LeaveDuration.QUARTER_DAY).toLowerCase()
        break
      default:
        extra = formatNumber(timeRegistration.days, 2)
        break
    }
    if (extra) {
      description += ' (' + extra + ')'
    }
  }
  return description
}
