import { addYears, startOfYear, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import LeaveBalance from '../../model/leaveBalance'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import { SalaryDefinition } from '../../model/remuneration'
import SalaryType from '../../model/salaryType'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { DateFormat } from '../../model/types'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { paths } from '../../routes'
import SettledState from '../../types/settled-state'
import { formatAPIDate, formatDate, getDate, trimCurrentYear } from '../../utils/date-utils'
import { getPlatform } from '../../utils/device-utils'
import { formatInputNumber, formatNumber } from '../../utils/number-utils'
import { timeRegistrationDescription } from '../../utils/time-registration-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import DumbLink from '../elements/DumbLink'
import PullToRefresh from '../widgets/PullToRefresh'
import TimeRegistrationModal from './TimeRegistrationModal'

import './TimeRegistration.css'

type BaseProps = {
  company: Company
  employee: Employee
  timeRegistrations: TimeRegistrationReducer

  showHistory: boolean

  getTimeRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  deleteTimeRegistration: (id: string, employeeID: string) => void
}

type ProjectProps = BaseProps & {
  mode: 'Project Hours'

  projects: List<Project>

  getProjects: (companyID: string) => Promise<Project[] | void>
}

type HoursProps = BaseProps & {
  mode: 'Hours'

  salaryTypes: List<SalaryType>
  costCenters: List<CostCenter>
  departments: List<Department>
  projects: List<Project>

  getSalaryTypes: (companyID: string) => Promise<SalaryType[] | void>
  getProjects: (companyID: string) => Promise<Project[] | void>
}

type TimeBoxProps = BaseProps & {
  mode: 'Flex' | 'Overtime'

  costCenters: List<CostCenter>
  departments: List<Department>
  leaveTypes: List<LeaveType>
  leaveBalances: List<LeaveBalance>
}

export default function TimeRegistrationTab(props: ProjectProps | HoursProps | TimeBoxProps): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const setEditVisibility = useCallback(
    (id: string | boolean) => {
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setEditing(id)
    },
    [setModalKey, setEditing]
  )

  const { timeRegistrations } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        setEditVisibility(false)
      }
    }
  }, [previousTimeRegistrations, timeRegistrations, setEditVisibility])

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteTimeRegistration(id, props.employee.id)
      }
    }
  }

  const getUnsettledHours = (): string => {
    const hours = timeRegistrations.timeRegistrations
      .filter((timeRegistration) => timeRegistration.class === props.mode)
      .reduce((hours, timeRegistration) => {
        if (timeRegistration.state === SettledState.PENDING && timeRegistration.hours) {
          return hours + timeRegistration.hours
        }
        return hours
      }, 0)
    return formatInputNumber(hours) + ' ' + t('unit.hours', { count: hours })
  }

  const getFlexBalance = (): string => {
    if (props.mode !== 'Flex') {
      return '-'
    }
    const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === 'DenmarkFlexTime')
    if (!leaveType) {
      return '-'
    }
    const flexBalance = props.leaveBalances.find((balance) => balance.leaveTypeID === leaveType.id)
    if (!flexBalance) {
      return '-'
    }
    const hours = (flexBalance.earned - flexBalance.used - flexBalance.registered) / 60
    return formatNumber(hours) + ' ' + t('unit.hours', { count: hours })
  }

  type TimeRegistrationRow = {
    id: string
    month: string
    className: string
    date: string
    description: string
    approved: boolean
    immutable: boolean
  }

  type TimeRegistrationRowBox = {
    month: string
    timeRegistrations: TimeRegistrationRow[]
  }

  const getTimeRegistrations = (): TimeRegistrationRowBox[] => {
    const contract = props.employee.activeContract
    return timeRegistrations.timeRegistrations
      .filter(
        (timeRegistration) =>
          timeRegistration.class === props.mode &&
          timeRegistration.settled === props.showHistory &&
          !deleting.some((id) => id === timeRegistration.id)
      )
      .map((timeRegistration) => {
        let month = formatDate(timeRegistration.date, 'MMMM yyyy')
        month = month[0].toUpperCase() + month.substring(1)
        let className = 'registration-row-unapproved'
        let date = trimCurrentYear(formatDate(timeRegistration.date))
        if (contract) {
          const salaryRow = contract.remuneration.salary.find(
            (salary: SalaryDefinition) => timeRegistration.salaryTypeID === salary.salaryTypeID
          )
          if (salaryRow) {
            date += ' (' + translateGroupTitle(salaryRow) + ')'
          }
        }
        if (timeRegistration.approved) {
          className = 'registration-row-approved'
        }
        return {
          id: timeRegistration.id,
          month,
          className,
          date,
          description: timeRegistrationDescription(
            timeRegistration,
            props.mode === 'Hours' || props.mode === 'Project Hours' ? props.projects.toArray() : []
          ),
          approved: timeRegistration.approved,
          immutable: timeRegistration.immutable,
        }
      })
      .reduce((boxes: TimeRegistrationRowBox[], row: TimeRegistrationRow) => {
        if (boxes.length === 0) {
          return [{ month: row.month, timeRegistrations: [row] }]
        }
        if (boxes[boxes.length - 1].month !== row.month) {
          return [...boxes, { month: row.month, timeRegistrations: [row] }]
        }
        const last = boxes[boxes.length - 1]
        last.timeRegistrations.push(row)
        boxes[boxes.length - 1] = last
        return boxes
      }, [])
  }

  const locked = !props.employee.earliestMutableContract
  const registrations = getTimeRegistrations()
  return (
    <div className={'time-registration registrations-list platform-' + getPlatform()}>
      <PullToRefresh
        onRefresh={() => {
          const employee = props.employee
          const companyID = employee.companyID
          const promiseList: Promise<any>[] = [
            props.getTimeRegistrations(
              employee.id,
              formatAPIDate(startOfYear(subYears(getDate(), 1))),
              formatAPIDate(addYears(getDate(), 1))
            ),
          ]
          if (props.mode === 'Hours') {
            promiseList.push(props.getSalaryTypes(companyID))
          }
          if (props.mode === 'Hours' || props.mode === 'Project Hours') {
            promiseList.push(props.getProjects(companyID))
          }
          return Promise.all(promiseList)
        }}
      >
        {!props.showHistory && props.mode === 'Hours' && (
          <div className="time-registration-summary">
            {t('time_registration.awaiting_payout')}: {getUnsettledHours()}
          </div>
        )}
        {!props.showHistory && props.mode === 'Flex' && (
          <div className="time-registration-summary">
            {t('time_registration.flex_balance')}: {getFlexBalance()}
          </div>
        )}
        {props.showHistory ? (
          <Link
            to={
              '/' +
              (props.mode === 'Hours'
                ? paths.TIME_REGISTRATION
                : props.mode === 'Project Hours'
                ? paths.PROJECT_REGISTRATION
                : paths.TIME_BOX_REGISTRATION)
            }
            className="time-registration-history"
          >
            {t('common.go_back')}
          </Link>
        ) : (
          <Link
            to={
              '/' +
              (props.mode === 'Hours'
                ? paths.TIME_REGISTRATION
                : props.mode === 'Project Hours'
                ? paths.PROJECT_REGISTRATION
                : paths.TIME_BOX_REGISTRATION) +
              '/' +
              paths.HISTORY
            }
            className="time-registration-history"
          >
            {t('common.show_history')}
          </Link>
        )}
        {registrations.map((row) => {
          return (
            <div key={row.month}>
              <div className="time-registrations-header">{row.month}</div>
              {row.timeRegistrations.map((timeRegistration) => {
                return (
                  <div key={timeRegistration.id} className="registration-row">
                    <Card key={timeRegistration.id} className={timeRegistration.className}>
                      <div className="registration-row-info" onClick={() => setEditVisibility(timeRegistration.id)}>
                        <div className="time-registration-date">{timeRegistration.date}</div>
                        <div className="time-registration-description">{timeRegistration.description}</div>
                      </div>
                      {!timeRegistration.immutable && !locked && (
                        <span onClick={remove(timeRegistration.id)} className="registration-row-delete" />
                      )}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {registrations.length === 0 && (
          <div className="content-text">
            {props.showHistory
              ? t('time_registration.no_historic_time_registrations')
              : t('time_registration.no_time_registrations_yet')}
          </div>
        )}
      </PullToRefresh>
      {!props.showHistory && !locked && (
        <DumbLink onClick={() => setEditVisibility(true)} className="time-registrations-add">
          {props.mode === 'Flex'
            ? t('time_registration.register_flex_time')
            : props.mode === 'Overtime'
            ? t('time_registration.register_overtime')
            : props.mode === 'Project Hours'
            ? t('time_registration.register_project_time')
            : t('time_registration.register_time')}
        </DumbLink>
      )}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <TimeRegistrationModal
          visible={editing !== false}
          locked={locked}
          mode={props.mode}
          company={props.company}
          employee={props.employee}
          timeRegistrationID={typeof editing !== 'boolean' ? editing : undefined}
          timeRegistrations={props.timeRegistrations}
          salaryTypes={props.mode === 'Hours' ? props.salaryTypes : List()}
          leaveTypes={props.mode === 'Flex' || props.mode === 'Overtime' ? props.leaveTypes : List()}
          costCenters={props.mode === 'Project Hours' ? List() : props.costCenters}
          departments={props.mode === 'Project Hours' ? List() : props.departments}
          projects={props.mode === 'Project Hours' || props.mode === 'Hours' ? props.projects : List()}
          createTimeRegistration={props.createTimeRegistration}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>
    </div>
  )
}
