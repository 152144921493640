import React from 'react'

import { fetchEmployees, putEmployee } from '../api/employees'
import ActionTypes from '../constants/action-types'
import Employee from '../model/employee'
import { EmployeeAction } from '../reducers/employee'
import { isRequestError } from '../utils/error-utils'

export function loadingEmployee(preferredEmployeeID?: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_LOADING,
    employeeID: preferredEmployeeID,
  }
}
export function loadedEmployee(employees: Employee[], preferredEmployeeID?: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_LOADED,
    employees,
    employeeID: preferredEmployeeID,
  }
}
export function failedLoadingEmployee(errorMessage: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_LOAD_FAILED,
    error: errorMessage,
  }
}

export function switchEmployee(employeeID: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_SELECTED,
    employeeID,
  }
}

function updatingEmployee(employeeID: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_UPDATING,
    employeeID,
  }
}
export function updatedEmployee(employeeID: string, employee: Employee): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_UPDATED,
    employeeID,
    employee,
  }
}
function failedUpdatingEmployee(employeeID: string, errorMessage: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_UPDATE_FAILED,
    error: errorMessage,
    employeeID,
  }
}

export function getEmployee(preferredEmployeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<Employee[] | void> => {
    if (!preferredEmployeeID) {
      preferredEmployeeID = undefined
    }
    dispatch(loadingEmployee(preferredEmployeeID))
    return fetchEmployees()
      .then((res) => {
        dispatch(loadedEmployee(res.data, preferredEmployeeID))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingEmployee(e))
        }
      })
  }
}

export function updateEmployee(employee: Employee) {
  return (dispatch: React.Dispatch<any>): Promise<Employee | void> => {
    dispatch(updatingEmployee(employee.id))
    return putEmployee(employee)
      .then((res) => {
        dispatch(updatedEmployee(res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployee(employee.id, e))
        }
      })
  }
}
