import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import RequestPasswordForm, { RequestPasswordResult } from './RequestPasswordForm'

import './RequestPassword.css'

type Props = {
  user: UserReducer

  requestPassword: (email: string) => Promise<boolean | void>
}

export default function RequestPassword(props: Props): ReactElement | null {
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    regularComponentDidUpdate(props.user.error, error, setError)
  }, [props.user, error])

  const handleSubmit = (values: RequestPasswordResult) => {
    props.requestPassword(values.email).then(() => {
      setCompleted(true)
    })
  }

  return (
    <div className="request-password">
      <Subtitle>{t('request_password.forgot_password')}</Subtitle>
      {completed ? (
        <div>
          <p>{t('request_password.check_your_email_for_new_password')}</p>
          <p>&nbsp;</p>
          <p>
            <Link to={'/' + paths.LOGIN}>{t('common.go_back')}</Link>
          </p>
        </div>
      ) : (
        <div>
          <p>{t('request_password.enter_email_to_request_password')}</p>

          {error && <Alert message={formatError(error)} type="error" showIcon />}
          <RequestPasswordForm onSubmit={handleSubmit} />
          {props.user.requestingPassword && <LoadingOverlay />}
        </div>
      )}
    </div>
  )
}
