import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import SalaryPeriod from '../../model/salaryPeriod'
import { DateFormat } from '../../model/types'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { getLanguage } from '../../utils/cookie-utils'
import { formatDate, getDate, isTimeBefore } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { getDispositionDateList, getOneTimePaySelectedDate } from '../../utils/one-time-pays-utils'
import { t } from '../../utils/translation-utils'
import { validateDecimal } from '../../utils/validation-utils'
import Form from '../antd/form'
import Select from '../antd/select'
import Button from '../elements/button'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  oneTimePayID?: string
  employee: Employee
  oneTimePays: OneTimePayReducer
  salaryPeriods: List<SalaryPeriod>
}

type Fields = {
  title?: string
  dispositionDate?: DateFormat
  amount?: string
  immutable: boolean
}

export type FeeResult = {
  title: string
  dispositionDate: DateFormat
  amount: number
}

function FeeModalForm(props: Props & FormComponentProps<Fields, FeeResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  if (getFieldValue('immutable')) {
    const dispositionDate = getFieldValue('dispositionDate')
    return (
      <div>
        {props.getFormError()}
        <Form.Item>
          <label>{t('common.date')}</label>
          {dispositionDate ? formatDate(dispositionDate) : '-'}
        </Form.Item>
        <Form.Item>
          <label>{t('fees.title')}</label>
          {getFieldValue('title')}
        </Form.Item>
        <Form.Item>
          <label>{t('fees.amount')}</label>
          {formatCurrency(forceParseInputNumber(getFieldValue('amount')))}
        </Form.Item>
      </div>
    )
  }

  const isEditing = !!props.oneTimePayID
  return (
    <div>
      {props.getFormError()}
      {decorateField('dispositionDate', {
        placeholder: t('fees.disposition_date'),
        validate: (val) => (!val ? t('validation.disposition_date_is_required') : null),
      })(
        <Select dropdownMatchSelectWidth={false}>
          {getDispositionDateList(
            props.salaryPeriods.toArray(),
            getOneTimePaySelectedDate(props.oneTimePays.oneTimePays.toArray(), props.oneTimePayID)
          ).map((date) => {
            return (
              <Select.Option key={date} value={date}>
                {formatDate(date)}
              </Select.Option>
            )
          })}
        </Select>
      )}
      {decorateField('title', {
        placeholder: t('fees.title'),
        validate: (val) => (!val ? t('validation.description_is_required') : null),
      })(<Input />)}
      {decorateField('amount', {
        placeholder: t('common.amount'),
        suffix: ' kr.',
        validate: (val) => {
          if (!val || !validateDecimal(val)) {
            return t('validation.amount_is_required')
          }
          if (forceParseInputNumber(val) <= 0) {
            return t('validation.amount_is_invalid')
          }
          return null
        },
      })(<Input inputMode="decimal" lang={getLanguage()} />)}
      <Form.Item>
        <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={8}>
          {isEditing ? t('fees.update_fee') : t('fees.create_fee')}
        </Button>
      </Form.Item>
      {props.oneTimePays.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, FeeResult>({
  mapPropsToFields: (props) => {
    const firstDispositionDate = props.salaryPeriods
      .filter((salaryPeriod) => isTimeBefore(getDate(), getDate(salaryPeriod.latestStartTime)))
      .first()
    const fields: Fields = {
      dispositionDate: firstDispositionDate ? firstDispositionDate.dispositionDate : undefined,
      immutable: false,
    }
    const oneTimePay = props.oneTimePays.oneTimePays.find((oneTimePay) => oneTimePay.id === props.oneTimePayID)
    if (oneTimePay) {
      fields.title = oneTimePay.title
      fields.dispositionDate = oneTimePay.dispositionDate
      fields.amount = formatInputNumber(oneTimePay.amount)
      fields.immutable = oneTimePay.immutable
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'amount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    return {
      title: values.title || '',
      dispositionDate: values.dispositionDate || '',
      amount: forceParseInputNumber(values.amount),
    }
  },
})(FeeModalForm)
