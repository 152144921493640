import { AnyAction, Store } from 'redux'

import CarAllowance from '../model/carAllowance'
import Document from '../model/document'
import LeaveBalance from '../model/leaveBalance'
import OneTimePay from '../model/oneTimePay'
import PaySlip from '../model/paySlip'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import SalaryRegistration from '../model/salaryRegistration'
import Swipe from '../model/swipe'
import TimeRegistration from '../model/timeRegistration'
import { logDebug } from '../utils/log-utils'
import { createdCarAllowances, deletedCarAllowance, updatedCarAllowance } from './car-allowances'
import { addedDocument, deletedDocument, updatedDocument } from './documents'
import { markLeaveBalanceDirty } from './leave-balances'
import { createdOneTimePay, deletedOneTimePay, updatedOneTimePay } from './one-time-pays'
import { addedPaySlip, deletedPaySlip, updatedPaySlip } from './pay-slips'
import {
  createdReimbursementVoucher,
  deletedReimbursementVoucher,
  updatedReimbursementVoucher,
} from './reimbursement-vouchers'
import { createdSalaryRegistration, deletedSalaryRegistration, updatedSalaryRegistration } from './salary-registrations'
import { addedSwipe, deletedSwipe, getSwipeStatus, updatedSwipe } from './swipe'
import { createdTimeRegistration, deletedTimeRegistration, updatedTimeRegistration } from './time-registrations'

type operation = string

const CREATE: operation = 'create'
const UPDATE: operation = 'update'
const DELETE: operation = 'delete'

function handleCarAllowance(store: Store, operation: operation, data: CarAllowance): void {
  switch (operation) {
    case CREATE:
      store.dispatch(createdCarAllowances(data.employeeID, [data]))
      break
    case UPDATE:
      store.dispatch(updatedCarAllowance(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCarAllowance(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleDocument(store: Store, operation: operation, data: Document): void {
  switch (operation) {
    case CREATE:
      store.dispatch(addedDocument(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedDocument(data.employeeID, data))
      break
    case DELETE:
      store.dispatch(deletedDocument(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleLeaveBalance(store: Store, operation: operation, data: LeaveBalance): void {
  switch (operation) {
    case UPDATE:
      store.dispatch(markLeaveBalanceDirty(data.employeeID))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleOneTimePay(store: Store, operation: operation, data: OneTimePay): void {
  switch (operation) {
    case CREATE:
      store.dispatch(createdOneTimePay(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedOneTimePay(data.employeeID, data))
      break
    case DELETE:
      store.dispatch(deletedOneTimePay(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleReimbursementVoucher(store: Store, operation: operation, data: ReimbursementVoucher): void {
  switch (operation) {
    case CREATE:
      store.dispatch(createdReimbursementVoucher(data.companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedReimbursementVoucher(data))
      break
    case DELETE:
      store.dispatch(deletedReimbursementVoucher(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleTimeRegistration(store: Store, operation: operation, data: TimeRegistration): void {
  switch (operation) {
    case CREATE:
      store.dispatch(createdTimeRegistration(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedTimeRegistration(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedTimeRegistration(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSalaryRegistration(store: Store, operation: operation, data: SalaryRegistration): void {
  switch (operation) {
    case CREATE:
      store.dispatch(createdSalaryRegistration(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedSalaryRegistration(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedSalaryRegistration(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSwipe(store: Store, operation: operation, data: Swipe): void {
  switch (operation) {
    case CREATE:
      store.dispatch(addedSwipe(data.id, data))
      break
    case UPDATE:
      store.dispatch(updatedSwipe(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedSwipe(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handlePaySlip(store: Store, operation: operation, data: PaySlip, employeeID: string): void {
  const state = store.getState()
  switch (operation) {
    case CREATE:
      store.dispatch(addedPaySlip(data.id, data))
      // if there is an update to our pay slip, then our Swipe situation may have change
      if (state.company.company.enableSwipe) {
        store.dispatch(getSwipeStatus(employeeID) as unknown as AnyAction)
      }
      break
    case UPDATE:
      store.dispatch(updatedPaySlip(data.id, data))
      // if there is an update to our pay slip, then our Swipe situation may have change
      if (state.company.company.enableSwipe) {
        store.dispatch(getSwipeStatus(employeeID) as unknown as AnyAction)
      }
      break
    case DELETE:
      store.dispatch(deletedPaySlip(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

interface SocketItem {
  type: string
  operation: operation
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Object
}

export interface SocketData {
  channel: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Object | SocketItem[]
  type?: string
  operation?: operation
}

function handleMessageData(store: Store, channel: string, data: SocketItem): void {
  logDebug('socket', { ...data, channel })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, employeeID] = channel.split(':')
  switch (data.type) {
    case 'carAllowance':
      handleCarAllowance(store, data.operation, data.data as CarAllowance)
      break
    case 'document':
      handleDocument(store, data.operation, data.data as Document)
      break
    case 'employeeTimeRegistration':
      handleTimeRegistration(store, data.operation, data.data as TimeRegistration)
      break
    case 'leaveBalance':
      handleLeaveBalance(store, data.operation, data.data as LeaveBalance)
      break
    case 'oneTimePay':
      handleOneTimePay(store, data.operation, data.data as OneTimePay)
      break
    case 'reimbursementVoucher':
      handleReimbursementVoucher(store, data.operation, data.data as ReimbursementVoucher)
      break
    case 'paySlip':
      handlePaySlip(store, data.operation, data.data as PaySlip, employeeID)
      break
    case 'salaryRegistration':
      handleSalaryRegistration(store, data.operation, data.data as SalaryRegistration)
      break
    case 'swipe':
      handleSwipe(store, data.operation, data.data as Swipe)
      break
    default:
      // Unhandled type
      break
  }
}

export function handleMessage(store: Store, data: SocketData): void {
  if (data.channel == null) {
    return
  }
  if (Array.isArray(data.data)) {
    logDebug('socket array', { length: data.data.length, channel: data.channel })
    for (const item in data.data) {
      handleMessageData(store, data.channel, data.data[item])
    }
  } else {
    handleMessageData(store, data.channel, data as SocketItem)
  }
}
