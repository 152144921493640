import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import ReimbursementVoucherApprovalState from '../types/reimbursement-voucher-approval-state'
import { ReducerAction } from '../utils/reducer-utils'

export interface ReimbursementVoucherReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  reimbursementVouchers: List<ReimbursementVoucher>
  error: Error | null
}

const Params = Record<ReimbursementVoucherReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  reimbursementVouchers: List<ReimbursementVoucher>(),
  error: null,
})

export interface ReimbursementVoucherAction extends ReducerAction {
  employeeID?: string
  reimbursementVouchers?: ReimbursementVoucher[]
  reimbursementVoucher?: ReimbursementVoucher
  reimbursementVoucherID?: string
}

const order = {
  [ReimbursementVoucherApprovalState.DRAFT]: 0,
  [ReimbursementVoucherApprovalState.READY]: 1,
  [ReimbursementVoucherApprovalState.APPROVED]: 2,
}
const comparator = (a: ReimbursementVoucher, b: ReimbursementVoucher) => {
  const sort = order[a.approvalState] - order[b.approvalState]
  if (sort !== 0) {
    return sort
  }
  return b.createdAt.localeCompare(a.createdAt, 'da-dk')
}

export default (
  state: Record<ReimbursementVoucherReducer> = Params(),
  action: ReimbursementVoucherAction = { type: '' }
): Record<ReimbursementVoucherReducer> => {
  const idx = state
    .get('reimbursementVouchers')
    .findIndex(
      (item) =>
        item.id === action.reimbursementVoucherID ||
        (!!action.reimbursementVoucher && item.id === action.reimbursementVoucher.id)
    )

  switch (action.type) {
    case ActionTypes.REIMBURSEMENT_VOUCHERS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.REIMBURSEMENT_VOUCHERS_LOADED:
    case ActionTypes.REIMBURSEMENT_VOUCHERS_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        loading: action.type === ActionTypes.REIMBURSEMENT_VOUCHERS_LOADED_PARTIAL,
        reimbursementVouchers: state
          .get('reimbursementVouchers')
          .concat(List(action.reimbursementVouchers))
          .sort(comparator),
      })
    case ActionTypes.REIMBURSEMENT_VOUCHERS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error || null,
      })

    case ActionTypes.REIMBURSEMENT_VOUCHERS_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHERS_CREATED: {
      if (!action.reimbursementVoucher) {
        return state
      }
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set(
            'reimbursementVouchers',
            state.get('reimbursementVouchers').set(idx, action.reimbursementVoucher).sort(comparator)
          )
      }
      return state
        .set('saving', false)
        .set(
          'reimbursementVouchers',
          state.get('reimbursementVouchers').push(action.reimbursementVoucher).sort(comparator)
        )
    }
    case ActionTypes.REIMBURSEMENT_VOUCHERS_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.REIMBURSEMENT_VOUCHERS_CREATE_CANCELLED:
      return state.set('saving', false)

    case ActionTypes.REIMBURSEMENT_VOUCHER_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHER_UPDATED:
      if (!action.reimbursementVoucher) {
        return state
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set(
          'reimbursementVouchers',
          state.get('reimbursementVouchers').set(idx, action.reimbursementVoucher).sort(comparator)
        )
    case ActionTypes.REIMBURSEMENT_VOUCHER_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.REIMBURSEMENT_VOUCHER_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHER_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('reimbursementVouchers', state.get('reimbursementVouchers').delete(idx))
    case ActionTypes.REIMBURSEMENT_VOUCHER_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
