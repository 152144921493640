import omit from 'omit.js'

import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { paths } from '../routes'
import MfaChannel from '../types/mfa-channel'
import { getAccessToken, setAccessToken } from './cookie-utils'
require('isomorphic-fetch')

export class RequestError extends Error {
  message: string
  type: string
  details: string[]

  constructor(message: string, type: string, details: string[]) {
    super(message)

    // restore prototype chain
    const actualProto = new.target.prototype
    Object.setPrototypeOf(this, actualProto)

    this.message = message
    this.type = type
    this.details = details
  }

  toString(): string {
    return this.message
  }
}

export class ChannelMFAError extends RequestError {
  channel: MfaChannel
  mfaChallengeID: string

  constructor(message: string, type: string, details: string[], channel: MfaChannel, mfaChallengeID: string) {
    super(message, type, details)

    // restore prototype chain
    const actualProto = new.target.prototype
    Object.setPrototypeOf(this, actualProto)

    this.channel = channel
    this.mfaChallengeID = mfaChallengeID
  }
}

type Pagination = {
  count: number
}

export interface RequestResponse<DataType = void> {
  data: DataType
  pagination?: Pagination
  error?: RequestError
}

interface WindowAPIUrl {
  sally_api_url?: string
}
declare let window: WindowAPIUrl

export function getHost(): string {
  if (window.sally_api_url) {
    return window.sally_api_url
  }
  return process.env.REACT_APP_API_URL || 'http://localhost:3100'
}

export type URLQuery = Record<string, string | number | boolean | undefined>

export function url(path: string, query: URLQuery = {}): string {
  let url = getHost() + (path.substring(0, 1) !== '/' ? '/' : '') + path
  if (query) {
    const params = []
    for (const key in query) {
      const val = query[key]
      if (val !== null && val !== undefined) {
        params.push(key + '=' + encodeURIComponent(val))
      }
    }
    if (params.length) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + params.join('&')
    }
  }
  return url
}

export function secureUrl(path: string, query?: { [index: string]: any }): string {
  return url(path, { ...query, apiKey: getAccessToken() })
}

type RequestOptions = {
  headers?: Record<string, string>
  ignore401?: boolean
  body?: string | Record<string, unknown> | (string | number | boolean | Record<string, unknown>)[]
}

export function request<ResponseType>(
  method: string,
  url: string,
  options: RequestOptions = {}
): Promise<ResponseType> {
  const isAuthorized = Object.keys(options.headers || {}).some((key) => {
    return key.toLowerCase() === 'authorization'
  })

  const ignore401 = !!options.ignore401

  let body = options.body
  if (body && typeof body !== 'string') {
    body = JSON.stringify(body)
  }
  const payload = {
    ...{
      method: method || 'GET',
      headers: {
        ...options.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Sally-Context': 'SalaryEmployee',
      },
    },
    body,
    ...omit(options, ['ignore401', 'body', 'headers']),
  }
  return fetch(url, payload)
    .then((res: Response) => {
      if (!ignore401 && isAuthorized && res.status === 401) {
        setAccessToken(null)
        document.location =
          '/' + paths.LOGIN + '?ref=' + encodeURIComponent(document.location.pathname + document.location.search)
      }
      if (isAuthorized && res.status === 403) {
        jsBrowserHistory.push('/')
      }
      return res
    })
    .then((res: Response) => {
      return res.text()
    })
    .then((text: string) => {
      let res: { [index: string]: any } = {}
      try {
        res = JSON.parse(text)
      } catch (e) {
        // Ignore JSON error
      }

      const error = res.error || res
      if (error.message) {
        let err: RequestError | ChannelMFAError | undefined = undefined
        if (res.mfaChallengeID) {
          err = new ChannelMFAError(error.message, error.type, error.details, res.channel, res.mfaChallengeID)
        } else {
          err = new RequestError(error.message, error.type, error.details)
        }
        Object.keys(res).forEach((key) => {
          if (err) {
            switch (key) {
              case 'message':
                err.message = res[key]
                break
              case 'type':
                err.type = res[key]
                break
              case 'channel':
                if (err instanceof ChannelMFAError) {
                  err.channel = res[key]
                }
                break
              case 'mfaChallengeID':
                if (err instanceof ChannelMFAError) {
                  err.mfaChallengeID = res[key]
                }
                break
            }
          }
        })
        if (err) {
          throw err
        }
      }
      return res as ResponseType
    })
}

export function dataRequest<DataType>(
  method: string,
  url: string,
  options: RequestOptions = {}
): Promise<RequestResponse<DataType>> {
  return request<RequestResponse<DataType>>(method, url, options)
}

export function secureRequest<DataType>(
  method: string,
  url: string,
  options: RequestOptions = {}
): Promise<RequestResponse<DataType>> {
  if (!options.headers) {
    options.headers = {}
  }
  options.headers['Authorization'] = getAccessToken() || ''
  return dataRequest<DataType>(method, url, options)
}

export const PromiseVoid = new Promise<void>((_) => undefined)
