import ExpenseCategory from '../model/expenseCategory'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchExpenseCategories(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<ExpenseCategory[]>> {
  return secureRequest('GET', url('/v2/expenseCategories', { companyID, limit, offset }))
}
