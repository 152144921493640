import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Swipe from '../model/swipe'
import { ReducerAction } from '../utils/reducer-utils'

export interface SwipeReducer {
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  swipes: List<Swipe>
  error: Error | null
}

const Params = Record<SwipeReducer>({
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  swipes: List<Swipe>(),
  error: null,
})

export interface SwipeAction extends ReducerAction {
  employeeID?: string
  swipeID?: string
  swipes?: Swipe[]
  swipe?: Swipe
}

const comparator = (a: Swipe, b: Swipe) => {
  if (!a.paymentDate) {
    return -1
  }
  if (!b.paymentDate) {
    return 1
  }
  return b.paymentDate.localeCompare(a.paymentDate, 'da-dk')
}

export default (state: Record<SwipeReducer> = Params(), action: SwipeAction = { type: '' }): Record<SwipeReducer> => {
  const idx = state.get('swipes').findIndex((item) => item.id === action.swipeID)

  switch (action.type) {
    case ActionTypes.SWIPE_LOADING:
      return Params({
        employeeID: action.employeeID || state.get('employeeID'),
        loading: true,
      })
    case ActionTypes.SWIPE_LOADED:
    case ActionTypes.SWIPE_LOADED_PARTIAL:
      return Params({
        employeeID: action.employeeID || state.get('employeeID'),
        loaded: true,
        loading: action.type === ActionTypes.SWIPE_LOADED_PARTIAL,
        swipes: state.get('swipes').concat(List(action.swipes)).sort(comparator),
      })
    case ActionTypes.SWIPE_LOAD_FAILED:
      return state.set('loading', false).set('error', action.error || null)
    case ActionTypes.SWIPE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SWIPE_ADDED:
    case ActionTypes.SWIPE_UPDATED:
      if (!action.swipe) {
        return state
      }
      if (idx === -1) {
        return state.set('saving', false).set('swipes', state.get('swipes').push(action.swipe))
      }
      return state.set('saving', false).set('swipes', state.get('swipes').set(idx, action.swipe))
    case ActionTypes.SWIPE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.SWIPE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SWIPE_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('swipes', state.get('swipes').delete(idx))
    case ActionTypes.SWIPE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        employeeID: action.employeeID,
      })
    default:
      return state
  }
}
