import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Employee from '../../model/employee'
import { EmployeeEmergencyContactMutableFields } from '../../model/employeeEmergencyContact'
import { AlertReducer } from '../../reducers/alerts'
import { EmployeeReducer } from '../../reducers/employee'
import { EmployeeEmergencyContactReducer } from '../../reducers/employeeEmergencyContacts'
import { BUILD_NUMBER, VERSION_NUMBER } from '../../release'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { setLanguage } from '../../utils/cookie-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import ProfileForm, { ProfileResult } from './ProfileForm'

import './Profile.css'

type Props = {
  alerts: AlertReducer
  company: Company
  companyFeatures: List<CompanyFeature>
  employee: EmployeeReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  updateEmployeeEmergencyContact: (employeeID: string, contact: EmployeeEmergencyContactMutableFields) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  logout: () => Promise<boolean | void>
}

export default function Profile(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { employee: employeeReducer, addAlert } = props
  const previousEmployee = usePrevious(employeeReducer)

  useEffect(() => {
    if (previousEmployee && previousEmployee.saving && !employeeReducer.saving) {
      if (!employeeReducer.error) {
        addAlert('success', t('profile.profile_saved'), { timeout: 5 })

        if (employeeReducer.employee?.onboardingState === 'Draft') {
          jsBrowserHistory.push('/')
        }
      }
    }
  }, [previousEmployee, employeeReducer, addAlert])

  const { employeeEmergencyContacts } = props

  useEffect(() => {
    regularComponentDidUpdate(employeeReducer.error || employeeEmergencyContacts.error, error, setError)
  }, [employeeReducer, employeeEmergencyContacts, error, setError])

  const handleSubmit = (values: ProfileResult) => {
    const employee = { ...props.employee.employee!, onboardingState: 'Final' as const, ...values }
    props.updateEmployee(employee).then(() => {
      const emergencyContact: EmployeeEmergencyContactMutableFields = {
        name: values.emergencyContactName || '',
        email: values.emergencyContactEmail || '',
        phoneNumberCountryCode: values.emergencyContactPhoneNumberCountryCode,
        phoneNumber: values.emergencyContactPhoneNumber,
        relation: values.emergencyContactRelation || '',
      }
      if (
        emergencyContact.name ||
        emergencyContact.email ||
        emergencyContact.phoneNumber ||
        emergencyContact.relation
      ) {
        props.updateEmployeeEmergencyContact(employee.id, emergencyContact)
      } else {
        props.deleteEmployeeEmergencyContact(employee.id)
      }
    })
    setLanguage(values.language)
  }

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    props.logout().then((res) => {
      if (res) {
        jsBrowserHistory.push('/' + paths.LOGIN)
      }
    })
  }

  const employee = props.employee.employee
  if (!employee) {
    return null // should probably not happen
  }

  return (
    <div>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {employee.onboardingState === 'Draft' && (
        <Alert message={t('profile.onboarding_alert')} type="warning" showIcon />
      )}
      <ProfileForm
        company={props.company}
        companyFeatures={props.companyFeatures}
        employee={employee}
        employeeReducer={props.employee}
        employeeEmergencyContact={props.employeeEmergencyContacts.employeeEmergencyContact}
        onSubmit={handleSubmit}
      />
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <Row>
        <Col span={24}>
          <Button
            onClick={handleLogout}
            size="large"
            className="ant-btn-danger"
            style={{ display: 'block', width: '100%', marginTop: '30px' }}
          >
            {t('profile.log_out_from_account')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Link to={paths.LEAVE_STATISTICS}>
            <Button size="large" style={{ display: 'block', width: '100%', marginTop: '30px' }}>
              {t('leave_registration.leave_statistics')}
            </Button>
          </Link>
        </Col>
      </Row>

      <div className="release-info">
        v{VERSION_NUMBER}-{BUILD_NUMBER}
      </div>
    </div>
  )
}
