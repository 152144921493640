import React from 'react'

import { fetchCompany } from '../api/companies'
import ActionTypes from '../constants/action-types'
import Company from '../model/company'
import { CompanyAction } from '../reducers/company'
import { setActiveEmployee } from '../utils/cookie-utils'
import { isRequestError } from '../utils/error-utils'
import { ReducerAction } from '../utils/reducer-utils'
import { switchEmployee } from './employees'

export function loadingCompany(): CompanyAction {
  return {
    type: ActionTypes.COMPANY_LOADING,
  }
}
export function loadedCompany(company: Company): CompanyAction {
  return {
    type: ActionTypes.COMPANY_LOADED,
    company,
  }
}
export function failedLoadingCompany(errorMessage: Error): CompanyAction {
  return {
    type: ActionTypes.COMPANY_LOAD_FAILED,
    error: errorMessage,
  }
}

export function switchingCompany(companyID: string): ReducerAction {
  return {
    type: ActionTypes.CHANGE_ACTIVE_COMPANY,
    companyID: companyID,
  }
}

export function getCompany(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Company | void> => {
    dispatch(loadingCompany())
    return fetchCompany(companyID)
      .then((res) => {
        dispatch(loadedCompany(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompany(e))
        }
      })
  }
}

export function switchCompany(companyID: string, employeeID: string) {
  return (dispatch: React.Dispatch<any>): void => {
    setActiveEmployee(employeeID)
    dispatch(switchingCompany(companyID))
    dispatch(getCompany(companyID))
    return dispatch(switchEmployee(employeeID))
  }
}
