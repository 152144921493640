import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyFeature from '../model/companyFeature'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyFeatureReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  companyFeatures: List<CompanyFeature>
  error: Error | null
}

const Params = Record<CompanyFeatureReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  companyFeatures: List<CompanyFeature>(),
  error: null,
})

export interface CompanyFeatureAction extends ReducerAction {
  companyFeatures?: CompanyFeature[]
}

export default (
  state: Record<CompanyFeatureReducer> = Params(),
  action: CompanyFeatureAction = { type: '' }
): Record<CompanyFeatureReducer> => {
  switch (action.type) {
    case ActionTypes.COMPANY_FEATURES_LOADING:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_FEATURES_LOADED:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        companyFeatures: List(action.companyFeatures),
      })
    case ActionTypes.COMPANY_FEATURES_LOAD_FAILED:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
