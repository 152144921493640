import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import Employee from '../../model/employee'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import RegisterForm, { RegisterResult } from './RegisterForm'

import './Register.css'

type Props = {
  token: string
  user: UserReducer
  company: Company
  employee: Employee

  register: (token: string, password: string) => void
}

export default function Register(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { user } = props
  useEffect(() => {
    regularComponentDidUpdate(user.error, error, setError)
  }, [user, error])

  const handleSubmit = (values: RegisterResult) => {
    props.register(props.token, values.password)
  }

  return (
    <div className="register">
      <Subtitle>{t('register.create_new_user')}</Subtitle>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <RegisterForm company={props.company.name} email={props.employee.email} onSubmit={handleSubmit} />
      {props.user.loggingIn && <LoadingOverlay />}
    </div>
  )
}
