import React, { ReactElement, useState } from 'react'

import './Slider.css'

const MAX_VALUE = 150 // the higher the smoother when dragging
const SPEED = 12

// based on https://codepen.io/souporserious/pen/XJQLEb
type Props = {
  disabled: boolean
  onReached: () => void
}

export default function Slider(props: Props): ReactElement | null {
  const [currValue, setCurrValue] = useState(0)
  const [slider, setSlider] = useState<HTMLInputElement>()
  const rafID = React.useRef<number>()

  // listen for unlock
  const unlockStartHandler = (e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>) => {
    // clear raf if trying again
    if (rafID.current) {
      window.cancelAnimationFrame(rafID.current)
    }

    // set to desired value
    const value = +e.currentTarget.value
    setCurrValue(value)
    setSlider(e.currentTarget)
  }

  const animateHandler = () => {
    // determine if we need to continue
    if (currValue > -1) {
      window.requestAnimationFrame(animateHandler)
    }

    // decrement value
    const value = currValue - SPEED
    const thisSlider = slider
    if (thisSlider) {
      thisSlider.value = value.toString()
    }
    setCurrValue(value)
    setSlider(thisSlider)
  }

  const unlockEndHandler = (e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>) => {
    // store current value
    const value = +e.currentTarget.value

    // determine if we have reached success or not
    if (value >= MAX_VALUE - 10) {
      // reset input range
      setCurrValue(0)
      setSlider((prev) => {
        if (!prev) {
          return prev
        }
        prev.value = '0'
        return prev
      })

      props.onReached()
    } else {
      rafID.current = window.requestAnimationFrame(animateHandler)
      setCurrValue(value)
      setSlider(e.currentTarget)
    }
  }

  return (
    <input
      type="range"
      className={'slider' + (props.disabled ? ' disabled' : '')}
      defaultValue={currValue}
      disabled={props.disabled}
      min={0}
      max={MAX_VALUE}
      onMouseDown={unlockStartHandler}
      onTouchStart={unlockStartHandler}
      onMouseUp={unlockEndHandler}
      onTouchEnd={unlockEndHandler}
    />
  )
}
