import React, { ReactElement } from 'react'

import { getProjects } from '../actions/projects'
import { getSalaryTypes } from '../actions/salary-types'
import {
  createTimeRegistration,
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import TimeRegistrationDisabledComponent from '../components/time-registration/TimeRegistrationDisabled'
import TimeRegistrationTabComponent from '../components/time-registration/TimeRegistrationTab'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import Project from '../model/project'
import SalaryType from '../model/salaryType'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employee'
import { ProjectReducer } from '../reducers/projects'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { paths } from '../routes'
import CompanySetting from '../types/company-setting'
import { isHourlyPaid } from '../utils/employee-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: SalaryTypeReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  projects: ProjectReducer
}

type Actions = {
  deleteTimeRegistration: (id: string, employeeID: string) => void
  getSalaryTypes: (companyID: string) => Promise<SalaryType[] | void>
  getTimeRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  getProjects: (companyID: string) => Promise<Project[] | void>
}

function TimeRegistrationTab(props: Reducers & Actions & RouteProps): ReactElement | null {
  const employee = props.employee.employee
  const company = props.company.company

  if (!company || !employee || employee.activeContract?.timeRegistrationMethodType === 'Coarse') {
    return <TimeRegistrationDisabledComponent mode={'Hours'} />
  }

  if (!isHourlyPaid(employee)) {
    jsBrowserHistory.push('/' + paths.LEAVE_REGISTRATION)
    return null
  }

  if (company.settingsEnabled.some((setting) => setting === CompanySetting.DISABLE_APP_TIME_REGISTRATION)) {
    return <TimeRegistrationDisabledComponent mode={'Hours'} />
  }

  return (
    <TimeRegistrationTabComponent
      mode={'Hours'}
      showHistory={props.location.pathname.indexOf('/history') !== -1}
      company={company}
      employee={employee}
      timeRegistrations={props.timeRegistrations}
      salaryTypes={props.salaryTypes.salaryTypes}
      costCenters={props.costCenters.costCenters}
      departments={props.departments.departments}
      projects={props.projects.projects}
      getTimeRegistrations={props.getTimeRegistrations}
      createTimeRegistration={props.createTimeRegistration}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      getSalaryTypes={props.getSalaryTypes}
      getProjects={props.getProjects}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    company: state.company,
    employee: state.employee,
    timeRegistrations: state.timeRegistrations,
    salaryTypes: state.salaryTypes,
    costCenters: state.costCenters,
    departments: state.departments,
    projects: state.projects,
  }),
  {
    getTimeRegistrations: getTimeRegistrations,
    createTimeRegistration: createTimeRegistration,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    getSalaryTypes: getSalaryTypes,
    getProjects: getProjects,
  }
)(TimeRegistrationTab)
