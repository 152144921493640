import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { CarAllowanceMutableFields } from '../../model/carAllowance'
import CompanyFeature from '../../model/companyFeature'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import PaySlip from '../../model/paySlip'
import Project from '../../model/project'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../../model/salaryRegistration'
import SalaryType from '../../model/salaryType'
import { SwipeStatus } from '../../model/swipe'
import TimeRegistration, {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { AlertReducer } from '../../reducers/alerts'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { CompanyReducer } from '../../reducers/company'
import { EmployeeReducer } from '../../reducers/employee'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { SwipeStatusReducer } from '../../reducers/swipeStatus'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { hasFlex } from '../../utils/employee-utils'
import { formatError } from '../../utils/error-utils'
import { formatCurrency } from '../../utils/number-utils'
import { hasSwipe } from '../../utils/swipe-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Alerts from '../widgets/Alerts'
import NoContractMessage from '../widgets/NoContractMessage'
import PullToRefresh from '../widgets/PullToRefresh'
import CarAllowanceCard from './CarAllowanceCard'
import CardChoiceSwitcher from './CardChoiceSwitcher'
import DefaultCard from './DefaultCard'
import LeaveRegistrationCard from './LeaveRegistrationCard'
import SalaryRegistrationCard from './SalaryRegistrationCard'
import TimeRegistrationCard from './TimeRegistrationCard'
import WorkHoursCard from './WorkHoursCard'

import './Dashboard.css'

type Props = {
  alerts: AlertReducer
  company: CompanyReducer
  employee: EmployeeReducer
  paySlips: List<PaySlip>
  swipeStatus: SwipeStatusReducer
  projects: List<Project>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  companyFeatures: List<CompanyFeature>
  costCenters: List<CostCenter>
  departments: List<Department>
  timeRegistrations: TimeRegistrationReducer
  carAllowances: CarAllowanceReducer
  salaryRegistrations: SalaryRegistrationReducer

  getPaySlips: (employeeID: string) => Promise<PaySlip[] | void>
  getSwipeStatus: (employeeID: string) => Promise<SwipeStatus | void>
  switchCompany: (companyID: string, employeeID: string) => void
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature

  updateEmployee: (employee: Employee) => void

  createTimeRegistration: (registration: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  createTimeRegistrationBulk: (timeBulk: TimeRegistrationBulkFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  deleteTimeRegistration: (id: string, employeeID: string) => void
  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  createSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
  updateSalaryRegistration: (reg: SalaryRegistrationMutableFields) => Promise<SalaryRegistration | void>
}

export default function Dashboard(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { timeRegistrations, carAllowances, salaryRegistrations } = props
  useEffect(() => {
    if (regularComponentDidUpdate(timeRegistrations.error, error, setError)) {
      return
    }
    if (regularComponentDidUpdate(carAllowances.error, error, setError)) {
      return
    }
    if (regularComponentDidUpdate(salaryRegistrations.error, error, setError)) {
      return
    }
  }, [timeRegistrations, carAllowances, salaryRegistrations, error, setError])

  const { company } = props

  const employee = props.employee.employee
  const thisCompany = company.company

  type Swipeable = {
    hasSwipe: boolean
    canSwipe: boolean
    available?: number
  }

  const getSwipeable = (): Swipeable => {
    const result: Swipeable = { hasSwipe: false, canSwipe: false }
    if (!hasSwipe(thisCompany, employee)) {
      return result
    }

    result.hasSwipe = true

    if (!props.swipeStatus.loaded) {
      return result
    }

    result.canSwipe = props.swipeStatus.canSwipeNow
    result.available = props.swipeStatus.available

    return result
  }

  const switchCompany = () => {
    if (!employee) {
      return
    }
    // we sort employees by id to ensure a stable sorting
    const employees = props.employee.employees.sort((a, b) => a.id.localeCompare(b.id))
    if (employees.size === 0) {
      return
    }
    let found = false
    // then we build an array where only employees _after_ our current one are included.
    let otherEmployee = employees
      .reduce((employees: Employee[], otherEmployee) => {
        if (found) {
          employees.push(otherEmployee)
          return employees
        }
        if (otherEmployee.id === employee.id) {
          found = true
        }
        return employees
      }, [])
      .shift()
    if (!otherEmployee) {
      // then we need to start over again
      otherEmployee = employees.first()
    }
    if (!otherEmployee) {
      return
    }
    if (otherEmployee.id === employee.id) {
      return
    }
    props.switchCompany(otherEmployee.companyID, otherEmployee.id)
  }

  const swipeable = getSwipeable()

  if (!employee || !thisCompany) {
    return null // TODO: Should not happen
  }

  const hasMultipleCompanies = props.employee.employees.size > 1
  const frontPageChoice = employee.employeeAppFrontPageChoice ?? 'Default'
  const companySwitcher = (
    <span className="content-text">
      {t('dashboard.company_switch.you_are_logged_in_as')} <strong>{employee.name}</strong>
      {hasMultipleCompanies && (
        <>
          {' '}
          {t('dashboard.company_switch.logged_in_at')} {thisCompany.name}
        </>
      )}
      .{' '}
      {hasMultipleCompanies && (
        <Button onClick={() => switchCompany()}>{t('dashboard.company_switch.switch_company')}</Button>
      )}
    </span>
  )

  if (!employee.activeContract) {
    if (hasMultipleCompanies) {
      return (
        <div className="dashboard">
          {companySwitcher}
          <NoContractMessage employee={employee} />
        </div>
      )
    }
    return <NoContractMessage employee={employee} />
  }

  return (
    <div className="dashboard">
      <PullToRefresh
        onRefresh={() => {
          return Promise.all([props.getPaySlips(employee.id), props.getSwipeStatus(employee.id)])
        }}
      >
        {error && <Alert type={'error'} message={formatError(error)} showIcon />}
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

        <div className="dashboard-controls">
          {companySwitcher}
          <CardChoiceSwitcher
            employee={employee}
            company={thisCompany}
            projects={props.projects}
            leaveTypes={props.leaveTypes}
            salaryTypes={props.salaryTypes}
            companyFeatures={props.companyFeatures}
            updateEmployee={props.updateEmployee}
          />
        </div>

        <Card className="dashboard-choice-card">
          {frontPageChoice === 'Default' && <DefaultCard paySlips={props.paySlips} company={thisCompany} />}
          {(frontPageChoice === 'Time Registration' ||
            frontPageChoice === 'Time Box Registration' ||
            frontPageChoice === 'Project Registration') && (
            <TimeRegistrationCard
              mode={
                frontPageChoice === 'Time Box Registration'
                  ? hasFlex(employee, props.leaveTypes.toArray())
                    ? 'Flex'
                    : 'Overtime'
                  : frontPageChoice === 'Project Registration'
                  ? 'Project Hours'
                  : 'Hours'
              }
              employee={employee}
              company={thisCompany}
              timeRegistrations={props.timeRegistrations}
              salaryTypes={props.salaryTypes}
              leaveTypes={props.leaveTypes}
              costCenters={props.costCenters}
              departments={props.departments}
              projects={props.projects}
              addAlert={props.addAlert}
              createTimeRegistration={props.createTimeRegistration}
              updateTimeRegistration={props.updateTimeRegistration}
              deleteTimeRegistration={props.deleteTimeRegistration}
            />
          )}
          {frontPageChoice === 'Work Hours' && (
            <WorkHoursCard
              company={thisCompany}
              employee={employee}
              addAlert={props.addAlert}
              timeRegistrations={props.timeRegistrations}
              createTimeRegistration={props.createTimeRegistration}
              updateTimeRegistration={props.updateTimeRegistration}
              deleteTimeRegistration={props.deleteTimeRegistration}
            />
          )}
          {frontPageChoice === 'Car Allowance' && (
            <CarAllowanceCard
              employee={employee}
              carAllowances={props.carAllowances}
              company={thisCompany}
              costCenters={props.costCenters}
              departments={props.departments}
              addAlert={props.addAlert}
              createCarAllowances={props.createCarAllowances}
              updateCarAllowance={props.updateCarAllowance}
            />
          )}
          {frontPageChoice === 'Leave Registration' && (
            <LeaveRegistrationCard
              employee={employee}
              company={thisCompany}
              timeRegistrations={props.timeRegistrations}
              leaveTypes={props.leaveTypes}
              addAlert={props.addAlert}
              createTimeRegistration={props.createTimeRegistration}
              createTimeRegistrationBulk={props.createTimeRegistrationBulk}
              updateTimeRegistration={props.updateTimeRegistration}
              deleteTimeRegistration={props.deleteTimeRegistration}
            />
          )}
          {frontPageChoice === 'Salary Registration' && (
            <SalaryRegistrationCard
              employee={employee}
              company={thisCompany}
              salaryRegistrations={props.salaryRegistrations}
              salaryTypes={props.salaryTypes}
              costCenters={props.costCenters}
              departments={props.departments}
              addAlert={props.addAlert}
              createSalaryRegistration={props.createSalaryRegistration}
              updateSalaryRegistration={props.updateSalaryRegistration}
            />
          )}
        </Card>

        {swipeable.hasSwipe && (
          <Link to={'/' + paths.SWIPE + (!swipeable.canSwipe ? '/history' : '')} className="swipe-link">
            <Button>
              {swipeable.canSwipe && (swipeable.available || 0) > 0
                ? t('dashboard.swipe.swipe_link_available', {
                    amount: formatCurrency(Math.floor(swipeable.available || 0)),
                  })
                : t('dashboard.swipe.swipe_link')}
            </Button>
          </Link>
        )}
      </PullToRefresh>
    </div>
  )
}
