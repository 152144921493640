import React, { ReactElement, ReactNode, useState } from 'react'

import Form from '../components/antd/form'
import Alert from '../components/elements/alert'
import { formatValidationErrors } from './error-utils'
import { assign, getByPath, setByPath } from './object-utils'

// We use a generic field, to emphasis that it must be an object
type GenericFields = Record<string, unknown>

type ValidateFunction<Fields extends GenericFields, Key extends keyof Fields> = (
  value: Fields[Key],
  state: Fields
) => string | null

interface FormFunctions<Props, Fields extends GenericFields, Result extends GenericFields> {
  mapPropsToFields?: (props: Props) => Fields
  onChange?: <Key extends keyof Fields>(
    key: Key,
    val: Fields[Key],
    allValues: Fields,
    options: Record<string, unknown>,
    props: Props
  ) => Partial<Fields>
  onSubmit?: (values: Fields, props: Props) => Result
  submitOnChange?: boolean
}

function resolveValue(e: any) {
  if (e instanceof Object && !(e instanceof Date) && !(e instanceof Array)) {
    if (e.target.type === 'checkbox') {
      return e.target.checked
    }
    return e.target.value
  }
  return e
}
function hasErrors(errors: { [index: string]: unknown }): boolean {
  let hasError = false
  for (const key in errors) {
    if (errors[key]) {
      if (typeof errors[key] === 'object') {
        hasError = hasErrors(errors[key] as { [index: string]: unknown })
      } else {
        hasError = true
        break
      }
    }
  }
  return hasError
}

export interface DecorateFieldOptions<Fields extends GenericFields, Key extends keyof Fields> {
  placeholder?: string
  title?: string
  suffix?: string
  validate?: ValidateFunction<Fields, Key>
  trigger?: string
  skipWrapper?: boolean
  skipLabel?: boolean
  valueOnChecked?: boolean
  onChange?: (_: any) => Partial<Fields>
  noBlur?: boolean
  onBlur?: (_?: any) => void
}

export type decorateFieldSignature<Fields extends GenericFields> = <Key extends keyof Fields = keyof Fields>(
  id: Key,
  options: DecorateFieldOptions<Fields, Key>
) => (
  child: ReactElement<DecorateFieldOptions<Fields, Key>>
) =>
  | React.ReactElement<DecorateFieldOptions<Fields, Key>, string | React.JSXElementConstructor<any>>
  | React.ReactElement<DecorateFieldOptions<Fields, Key>, string | React.JSXElementConstructor<Fields>>[]
export type getFieldValueSignature<Fields> = <Key extends keyof Fields = keyof Fields>(id: Key) => Fields[Key]
export type setFieldValueSignature<Fields> = <Key extends keyof Fields = keyof Fields>(
  id: Key,
  value: Fields[Key]
) => void
export type getFieldErrorSignature<Fields> = <Key extends keyof Fields = keyof Fields>(id: Key) => any

interface FormComponentOtherProps<Fields extends GenericFields, Result extends GenericFields> {
  onSubmit?: (values: Result) => void
  onBlur?: (values: Fields) => void
  onBack?: (values: Result) => void
}

export interface FormComponentProps<Fields extends GenericFields, Result extends GenericFields>
  extends FormComponentOtherProps<Fields, Result> {
  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  getFieldError: getFieldErrorSignature<Fields>
  getFormError: () => React.ReactElement | null
  goBack: (e: MouseEvent) => void
}

interface DecorateFieldProps<Fields extends GenericFields, Key extends keyof Fields> {
  key: string
  id: Key
  placeholder?: string
  value: any
  onChange?: (_e: any) => Partial<Fields>
  onBlur?: (_e: any) => void
  checked?: unknown
}

interface ValidateOptions {
  trigger?: string
  mustValidate?: boolean
}

export function withValidations<Props, Fields extends GenericFields, Result extends GenericFields>(
  options: FormFunctions<Props, Fields, Result>
): (
  WrappedComponent: (
    props: Props & FormComponentProps<Fields, Result>
  ) => React.ReactElement<Props & FormComponentProps<Fields, Result>> | null
) => (
  props: Props & FormComponentOtherProps<Fields, Result>
) => React.ReactElement<Props & FormComponentProps<Fields, Result>> | null {
  options = {
    ...{
      mapPropsToFields: (_props): Fields => ({} as Fields),
      onChange: <Key extends keyof Fields = keyof Fields>(key: Key, val: Fields[Key]) => {
        const values: Partial<Fields> = {}
        values[key] = val
        return values
      },
      onSubmit: (values: Fields): Result => values as unknown as Result,
      submitOnChange: false,
    },
    ...options,
  }
  const { mapPropsToFields, onChange, onSubmit, submitOnChange } = options
  return function (
    WrappedComponent: (
      props: Props & FormComponentProps<Fields, Result>
    ) => ReactElement<Props & FormComponentProps<Fields, Result>> | null
  ): (
    props: Props & FormComponentOtherProps<Fields, Result>
  ) => ReactElement<Props & FormComponentProps<Fields, Result>> | null {
    return function (props: Props & FormComponentOtherProps<Fields, Result>): ReactElement | null {
      type FormComponentState = Fields & {
        error: string | null
        errors: Record<string, boolean>
      }

      const [state, setState] = useState<FormComponentState>(() => {
        let state = {}
        if (mapPropsToFields) {
          state = mapPropsToFields(props)
        }
        return { ...state, error: null, errors: {} } as FormComponentState
      })

      const validators: Map<string, ValidateFunction<Fields, keyof Fields>> = new Map()

      const validate = (state: FormComponentState & Fields, mustValidate: boolean): { [index: string]: boolean } => {
        const errors = {}
        validators.forEach((validator, key) => {
          if (mustValidate || getByPath(state.errors, key) !== undefined) {
            if (validator) {
              setByPath(errors, key, validator(getByPath(state as Fields, key), state))
            }
          }
        })
        return errors
      }

      const _prepareStateChange = (newState: FormComponentState & Fields): FormComponentState & Fields => {
        // remove nullable fields
        return newState as NonNullable<FormComponentState & Fields>
      }

      const _changeAndValidate = <Key extends keyof Fields>(key: Key, e: any, options: ValidateOptions) => {
        options = options || {}
        const val = resolveValue(e)
        setState((state) => {
          const newState = assign(
            { errors: {} },
            state,
            onChange ? onChange(key, val, state, { trigger: options.trigger }, props) : {},
            true
          )
          if (options.mustValidate) {
            setByPath(newState.errors, key, null)
          }
          newState.errors = validate(assign({}, state, newState, true), false)
          return _prepareStateChange(newState)
        })
      }

      const handleSubmit = (e: any) => {
        if (typeof e === 'object') {
          e.preventDefault()
        }
        setState((state) => {
          //doing this inside setState ensures we are operating on the newest state
          state = { ...state, error: null }
          const errors = validate(state, true)
          if (hasErrors(errors)) {
            return { ...state, error: formatValidationErrors(), errors }
          } else if (props.onSubmit) {
            const values = onSubmit
              ? onSubmit({ ...state, error: null, errors: {} }, props)
              : (state as unknown as Result)
            props.onSubmit(values)
          }
          return state
        })
      }

      const decorateField = <Key extends keyof Fields>(id: Key, options: DecorateFieldOptions<Fields, Key>) => {
        options = options || {}
        const { placeholder, suffix, validate, trigger, skipWrapper, skipLabel } = options
        const title = options.title || placeholder
        if (validate) {
          validators.set(id.toString(), validate as ValidateFunction<Fields, keyof Fields>)
        }
        return (child: ReactElement<DecorateFieldOptions<Fields, Key>>) => {
          const props: DecorateFieldProps<Fields, Key> = {
            key: id.toString(),
            id,
            placeholder,
            value: getByPath(state, id),
            onChange:
              options.onChange ||
              ((e) => {
                _changeAndValidate(id, e, {
                  trigger: 'onChange',
                  mustValidate: trigger === 'onChange',
                })
                if (child.props.onChange) {
                  child.props.onChange(e)
                }
                if (submitOnChange) {
                  setTimeout(() => {
                    handleSubmit(e)
                  }, 50)
                }
                return {}
              }),
            onBlur: options.noBlur
              ? undefined
              : options.onBlur ||
                ((e) => {
                  _changeAndValidate(id, e, {
                    trigger: 'onBlur',
                    mustValidate: true,
                  })
                  if (child.props.onBlur) {
                    child.props.onBlur(e)
                  }
                  if (submitOnChange) {
                    setTimeout(() => {
                      handleSubmit(e)
                    }, 50)
                  }
                }),
          }
          if (options.valueOnChecked) {
            props.checked = props.value
          }
          let children = [React.cloneElement(child, props)]
          if (suffix) {
            children.push(
              <span key={id.toString() + '-suffix'} className="ant-form-suffix">
                {suffix}
              </span>
            )
            children = [
              <div key={id.toString() + '-wrapper'} className="ant-field-wrapper">
                {children}
              </div>,
            ]
          }
          if (!skipLabel && title) {
            children.unshift(
              <label key={id.toString() + '-label'} htmlFor={id.toString()} title={title}>
                {(getByPath(state.errors, id.toString()) as ReactNode) || title}
              </label>
            )
          }
          if (!skipWrapper) {
            children = [
              <Form.Item
                key={id.toString() + '-item'}
                validateStatus={getByPath(state.errors, id.toString()) ? 'error' : 'success'}
              >
                {children}
              </Form.Item>,
            ]
          }
          if (children.length === 1) {
            return children[0]
          }
          return children
        }
      }

      const handleBack = (e: MouseEvent) => {
        if (!props.onBack) {
          return
        }
        e.preventDefault()
        const values = onSubmit ? onSubmit({ ...state, error: null, errors: {} }, props) : (state as unknown as Result)
        props.onBack(values)
      }

      return (
        <Form layout="horizontal" onSubmit={handleSubmit} autoComplete="off">
          <WrappedComponent
            {...props}
            decorateField={decorateField}
            getFieldValue={<Key extends keyof Fields>(key: Key) => getByPath(state, key)}
            setFieldValue={<Key extends keyof Fields>(key: Key, val: Fields[Key]) => {
              setState((state) => {
                return _prepareStateChange({ ...state, [key]: val })
              })
            }}
            getFieldError={<Key extends keyof Fields>(key: Key) => getByPath(state.errors, key)}
            getFormError={() => (state.error ? <Alert message={state.error} type="error" showIcon /> : null)}
            goBack={handleBack}
          />
        </Form>
      )
    }
  }
}

export function combineSearchOption(option: ReactElement): string {
  const combineChildren = (children: any): string => {
    let text = ''
    if (children.join) {
      text += children.join('')
    }
    if (children.children) {
      text += combineChildren(children.children)
    }
    return text
  }
  let text = ''
  if (option.props.children) {
    text = combineChildren(option.props.children)
  }
  if (option.props.title) {
    text += option.props.title
  }
  return text
}
