import Raven from 'raven-js'

export function logDebug(message: string, ...args: unknown[]) {
  if (process.env.NODE_ENV !== 'production') {
    console.log.apply(message, args)
  }
}

export function log(message: string, ...args: unknown[]) {
  Raven.captureMessage(message, {
    level: 'info',
    extra: { args },
  })
  if (process.env.NODE_ENV !== 'production') {
    console.log.apply(message, args)
  }
}

export function logWarning(message: string, ...args: unknown[]) {
  Raven.captureMessage(message, {
    level: 'warning',
    extra: { args },
  })
  if (process.env.NODE_ENV !== 'production') {
    console.warn.apply(message, args)
  }
}

export function logError(message: string, ...args: unknown[]) {
  Raven.captureMessage(message, {
    level: 'error',
    extra: { args },
  })
  if (process.env.NODE_ENV !== 'production') {
    console.error.apply(message, args)
  }
}
