import classNames from 'classnames'
import PropTypes from 'prop-types'
import RcCheckbox from 'rc-checkbox'
import React from 'react'
import shallowEqual from 'shallowequal'

class Checkbox extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !shallowEqual(this.props, nextProps) ||
      !shallowEqual(this.state, nextState) ||
      !shallowEqual(this.context.checkboxGroup, nextContext.checkboxGroup)
    )
  }

  render() {
    const { props, context } = this
    const { prefixCls, className, children, indeterminate, style, onMouseEnter, onMouseLeave, ...restProps } = props
    const { checkboxGroup } = context
    let checkboxProps = { ...restProps }
    if (checkboxGroup) {
      checkboxProps.onChange = () => checkboxGroup.toggleOption({ label: children, value: props.value })
      checkboxProps.checked = checkboxGroup.value.indexOf(props.value) !== -1
      checkboxProps.disabled = props.disabled || checkboxGroup.disabled
    }
    const classString = classNames(className, {
      [`${prefixCls}-wrapper`]: true,
    })
    const checkboxClass = classNames({
      [`${prefixCls}-indeterminate`]: indeterminate,
    })
    return (
      <label className={classString} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <RcCheckbox {...checkboxProps} prefixCls={prefixCls} className={checkboxClass} />
        {children !== undefined ? <span>{children}</span> : null}
      </label>
    )
  }
}

Checkbox.defaultProps = {
  prefixCls: 'ant-checkbox',
  indeterminate: false,
}

Checkbox.contextTypes = {
  checkboxGroup: PropTypes.any,
}

export default Checkbox
