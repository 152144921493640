import React, { ReactElement } from 'react'

import Spin from '../antd/spin'

import './LoadingOverlay.css'

type Props = {
  text?: string | React.ReactNode
  inline?: boolean
}

export default function LoadingOverlay(props: Props): ReactElement | null {
  return (
    <div className={'loading-overlay' + (props.inline ? ' inline' : '')}>
      <Spin />
      {props.text !== '' && <span className="loading-text">{props.text}</span>}
    </div>
  )
}
