import CarAllowance, { CarAllowanceMutableFields } from '../model/carAllowance'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCarAllowances(
  employeeID: string,
  fromDate: DateFormat | undefined,
  toDate: DateFormat | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<CarAllowance[]>> {
  return secureRequest('GET', url('/v2/carAllowances', { employeeID, from: fromDate, to: toDate, limit, offset }))
}

export function postCarAllowances(
  employeeID: string,
  carAllowances: CarAllowanceMutableFields[]
): Promise<RequestResponse<CarAllowance[]>> {
  return secureRequest('POST', url('/v2.2/carAllowances'), {
    body: carAllowances.map((carAllowance) => {
      return {
        employeeID: carAllowance.employeeID,
        date: carAllowance.date,
        kilometers: carAllowance.kilometers,
        licensePlate: carAllowance.licensePlate,
        reason: carAllowance.reason,
        locationFrom: carAllowance.locationFrom,
        locationTo: carAllowance.locationTo,
        avoidFerries: carAllowance.avoidFerries,
        costCenterID: carAllowance.costCenterID,
        approved: carAllowance.approved,
        rate: carAllowance.rate,
      }
    }),
  })
}

export function putCarAllowance(
  carAllowanceID: string,
  carAllowance: CarAllowanceMutableFields
): Promise<RequestResponse<CarAllowance>> {
  return secureRequest('PUT', url('/v2/carAllowances/' + carAllowanceID), {
    body: {
      date: carAllowance.date,
      kilometers: carAllowance.kilometers,
      licensePlate: carAllowance.licensePlate,
      reason: carAllowance.reason,
      locationFrom: carAllowance.locationFrom,
      locationTo: carAllowance.locationTo,
      costCenterID: carAllowance.costCenterID,
      rate: carAllowance.rate,
    },
  })
}

export function delCarAllowance(carAllowanceID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/carAllowances/' + carAllowanceID))
}

export type CarAllowanceRate = {
  validFrom: DateFormat
  validTo: DateFormat
  rates: {
    step: number
    rate: number
  }[]
}

export function fetchCarAllowanceRates(): Promise<RequestResponse<CarAllowanceRate[]>> {
  return secureRequest('GET', url('/v2/carAllowances/rates'))
}
