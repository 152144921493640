import classNames from 'classnames'
import debounce from 'lodash.debounce'
import omit from 'omit.js'
import PropTypes from 'prop-types'
import Animate from 'rc-animate'
import React from 'react'

import './style/css'

function shouldDelay(spinning, delay) {
  return !!spinning && !!delay && !isNaN(Number(delay))
}

class Spin extends React.Component {
  constructor(props) {
    super(props)

    const { spinning, delay } = props
    const shouldBeDelayed = shouldDelay(spinning, delay)
    this.state = {
      spinning: spinning && !shouldBeDelayed,
    }
    this.originalUpdateSpinning = this.updateSpinning
    this.debouncifyUpdateSpinning(props)
  }

  componentDidMount() {
    this.updateSpinning()
  }

  componentDidUpdate() {
    this.debouncifyUpdateSpinning()
    this.updateSpinning()
  }

  componentWillUnmount() {
    this.cancelExistingSpin()
  }

  debouncifyUpdateSpinning = (props) => {
    const { delay } = props || this.props
    if (delay) {
      this.cancelExistingSpin()
      this.updateSpinning = debounce(this.originalUpdateSpinning, delay)
    }
  }

  updateSpinning = () => {
    const { spinning } = this.props
    const { spinning: currentSpinning } = this.state
    if (currentSpinning !== spinning) {
      this.setState({ spinning })
    }
  }

  cancelExistingSpin() {
    const { updateSpinning } = this
    if (updateSpinning && updateSpinning.cancel) {
      updateSpinning.cancel()
    }
  }

  isNestedPattern() {
    return !!(this.props && this.props.children)
  }

  render() {
    const { className, size, prefixCls, tip, wrapperClassName, ...restProps } = this.props
    const { spinning, notCssAnimationSupported } = this.state

    const spinClassName = classNames(
      prefixCls,
      {
        [`${prefixCls}-sm`]: size === 'small',
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-spinning`]: spinning,
        [`${prefixCls}-show-text`]: !!tip || notCssAnimationSupported,
      },
      className
    )

    // fix https://fb.me/react-unknown-prop
    const divProps = omit(restProps, ['spinning', 'delay'])

    const spinElement = (
      <div {...divProps} className={spinClassName}>
        <span className={`${prefixCls}-dot`}>
          <i />
          <i />
          <i />
          <i />
        </span>
        {tip ? <div className={`${prefixCls}-text`}>{tip}</div> : null}
      </div>
    )
    if (this.isNestedPattern()) {
      let animateClassName = prefixCls + '-nested-loading'
      if (wrapperClassName) {
        animateClassName += ' ' + wrapperClassName
      }
      const containerClassName = classNames({
        [`${prefixCls}-container`]: true,
        [`${prefixCls}-blur`]: spinning,
      })
      return (
        <Animate {...divProps} component="div" className={animateClassName} style={null} transitionName="fade">
          {spinning && <div key="loading">{spinElement}</div>}
          <div className={containerClassName} key="container">
            {this.props.children}
          </div>
        </Animate>
      )
    }
    return spinElement
  }
}

Spin.defaultProps = {
  prefixCls: 'ant-spin',
  spinning: true,
  size: 'default',
  wrapperClassName: '',
}
Spin.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  spinning: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  wrapperClassName: PropTypes.string,
}

export default Spin
