import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Employee from '../model/employee'
import User from '../model/user'
import { ReducerAction } from '../utils/reducer-utils'

export interface UserReducer {
  user: User | undefined
  loading: boolean
  loggedIn: boolean
  loggingIn: boolean
  requestedPassword: boolean
  requestingPassword: boolean
  resettingPassword: boolean
  creatingDeviceToken: boolean
  error: Error | null
}

export const UserParams = Record<UserReducer>({
  user: undefined,
  loading: false,
  loggedIn: false,
  loggingIn: false,
  requestedPassword: false,
  requestingPassword: false,
  resettingPassword: false,
  creatingDeviceToken: false,
  error: null,
})

export interface UserAction extends ReducerAction {
  user?: User
  employee?: Employee
}

export default (state: Record<UserReducer> = UserParams(), action: UserAction = { type: '' }): Record<UserReducer> => {
  switch (action.type) {
    case ActionTypes.USER_REGISTERING:
    case ActionTypes.USER_LOGGING_IN:
      return UserParams({
        loggingIn: true,
      })
    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_IN:
      return UserParams({
        user: action.user,
        loggedIn: true,
      })
    case ActionTypes.USER_REGISTERING_FAILED:
    case ActionTypes.USER_LOGIN_FAILED:
      return state.set('loggingIn', false).set('error', action.error || null)

    case ActionTypes.USER_LOADING:
      return state.set('loading', true)
    case ActionTypes.USER_LOADED:
      return UserParams({
        user: action.user,
        loggedIn: true,
      })
    case ActionTypes.USER_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loggingIn', false)
        .set('loggedIn', false)
        .set('error', action.error || null)

    case ActionTypes.USER_REQUESTING_PASSWORD:
      return UserParams({
        user: action.user,
        requestingPassword: true,
      })
    case ActionTypes.USER_REQUESTED_PASSWORD:
      return UserParams({
        user: action.user,
        requestedPassword: true,
      })
    case ActionTypes.USER_REQUEST_PASSWORD_FAILED:
      return state.set('requestingPassword', false).set('error', action.error || null)

    case ActionTypes.USER_RESETTING_PASSWORD:
      return state.set('resettingPassword', true).set('error', null)
    case ActionTypes.USER_RESET_PASSWORD:
      return state.set('resettingPassword', false)
    case ActionTypes.USER_RESET_PASSWORD_FAILED:
      return state.set('resettingPassword', false).set('error', action.error || null)

    case ActionTypes.USER_CREATING_DEVICE_TOKEN:
      return state.set('creatingDeviceToken', true).set('error', null)
    case ActionTypes.USER_CREATED_DEVICE_TOKEN:
      return state.set('creatingDeviceToken', false)
    case ActionTypes.USER_CREATE_DEVICE_TOKEN_FAILED:
      return state.set('creatingDeviceToken', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGING_OUT:
      // do nothing when logging out, first actually do it when confirmed
      return state
    case ActionTypes.USER_LOGGED_OUT:
      return UserParams()
    case ActionTypes.USER_LOGOUT_FAILED:
      return state.set('error', action.error || null)
    default:
      return state
  }
}
