import classNames from 'classnames'
import PropTypes from 'prop-types'
import RcSelect, { OptGroup, Option } from 'rc-select'
import React from 'react'

import warning from '../_util/warning'

import './style/css'

const SelectPropTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large', 'small']),
  combobox: PropTypes.bool,
  notFoundContent: PropTypes.any,
  showSearch: PropTypes.bool,
  optionLabelProp: PropTypes.string,
  transitionName: PropTypes.string,
  choiceTransitionName: PropTypes.string,
}

// => It is needless to export the declaration of below two inner components.
// export { Option, OptGroup };

class Select extends React.Component {
  getLocale() {
    const { antLocale } = this.context
    if (antLocale && antLocale.Select) {
      return antLocale.Select
    }
    return {
      notFoundContent: 'Not found',
    }
  }

  render() {
    const {
      prefixCls,
      className = '',
      size,
      mode,
      // @deprecated
      multiple,
      tags,
      combobox,
      ...restProps
    } = this.props
    warning(
      !multiple && !tags && !combobox,
      '`Select[multiple|tags|combobox]` is deprecated, please use `Select[mode]` instead.'
    )

    const cls = classNames(
      {
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-sm`]: size === 'small',
      },
      className
    )

    const locale = this.getLocale()
    let { notFoundContent = locale.notFoundContent, optionLabelProp } = this.props
    const isCombobox = mode === 'combobox' || combobox
    if (isCombobox) {
      notFoundContent = null
      // children 带 dom 结构时，无法填入输入框
      optionLabelProp = optionLabelProp || 'value'
    }

    const modeConfig = {
      multiple: mode === 'multiple' || multiple,
      tags: mode === 'tags' || tags,
      combobox: isCombobox,
    }

    return (
      <RcSelect
        {...restProps}
        {...modeConfig}
        prefixCls={prefixCls}
        className={cls}
        optionLabelProp={optionLabelProp || 'children'}
        notFoundContent={notFoundContent}
      />
    )
  }
}

Select.Option = Option
Select.OptGroup = OptGroup

Select.defaultProps = {
  prefixCls: 'ant-select',
  showSearch: false,
  transitionName: 'slide-up',
  choiceTransitionName: 'zoom',
}

Select.propTypes = SelectPropTypes

Select.contextTypes = {
  antLocale: PropTypes.object,
}

export default Select
