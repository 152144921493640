import Company from '../model/company'
import Employee from '../model/employee'
import User from '../model/user'
import { dataRequest, RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchUser(): Promise<RequestResponse<User>> {
  return secureRequest('GET', url('/v2/users'), { ignore401: true })
}

type Invite = {
  company: Company
  employee: Employee
}

export function fetchEmployeeInvite(token: string): Promise<RequestResponse<Invite>> {
  return dataRequest('GET', url('/v2/employeeInvite/' + token))
}

type AccessToken = {
  accessToken: string
  user: User
}

export function postEmployeeInvite(token: string, password: string): Promise<RequestResponse<AccessToken>> {
  return dataRequest('POST', url('/v2/employeeInvite/' + token), {
    body: { password },
  })
}

export function postLogin(
  email: string,
  password: string,
  deviceToken?: string,
  mfaChallengeID?: string,
  mfaResponse?: string,
  recoveryCode?: string,
  trustDevice = false
): Promise<RequestResponse<AccessToken>> {
  return dataRequest('POST', url('/v2/login'), {
    body: { email, password, deviceToken, mfaChallengeID, mfaResponse, recoveryCode, trustDevice },
  })
}

export function deleteLogin(): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/login'))
}

export function postPasswordRequest(email: string): Promise<RequestResponse> {
  return dataRequest('POST', url('/v2/passwordReset/'), {
    body: { email },
  })
}

interface Token {
  token: string
}

export function postPasswordReset(token: string, password: string): Promise<RequestResponse<Token>> {
  return dataRequest('POST', url('/v2/passwordReset/' + token), {
    body: { password },
  })
}

export function postDeviceToken(deviceName: string): Promise<RequestResponse<Token>> {
  return secureRequest('POST', url('/v2/deviceTokens'), {
    body: { deviceName },
  })
}

export function patchDeviceTokenLogin(token: string): Promise<RequestResponse<AccessToken>> {
  return dataRequest('PATCH', url('/v2/deviceTokens'), {
    body: { token },
  })
}
