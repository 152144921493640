import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { paths } from '../../routes'
import MfaChannel from '../../types/mfa-channel'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatMfaChannel } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Switch from '../antd/switch'
import Button from '../elements/button'
import DumbLink from '../elements/DumbLink'
import Input from '../elements/input'

type Props = {
  email?: string
  channel?: MfaChannel
  remember?: boolean
}

export type Fields = {
  email?: string
  password?: string
  remember: boolean
  useRecoveryCode: boolean
  response?: string
  recoveryCode?: string
  trustDevice: boolean
}

function LoginForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const setUseRecoveryCode = () => {
    const { setFieldValue } = props
    setFieldValue('useRecoveryCode', true)
  }

  const { decorateField, getFieldValue } = props
  if (props.channel) {
    const useRecoveryCode = getFieldValue('useRecoveryCode')
    let desc
    if (useRecoveryCode) {
      desc = t('login.one_time_code')
    } else {
      desc = `${formatMfaChannel(props.channel)} ${t('login.two_factor_code')}`
    }
    return (
      <div>
        <p style={{ textAlign: 'left' }}>{t('login.enter_your_code_below', { desc })}</p>
        <p>&nbsp;</p>
        {!useRecoveryCode &&
          decorateField('response', {
            placeholder: t('common.code'),
            validate: (val) => {
              if (!val) {
                return t('validation.code_is_required')
              }
              return null
            },
          })(<Input inputMode="numeric" autoComplete="one-time-code" />)}
        {useRecoveryCode &&
          decorateField('recoveryCode', {
            placeholder: t('common.one_time_code'),
            validate: (val) => {
              if (!val) {
                return t('validation.one_time_code_is_required')
              }
              return null
            },
          })(<Input inputMode="numeric" />)}
        <div className="ant-switch-wrapper">
          {decorateField('trustDevice', {
            skipWrapper: true,
            skipLabel: true,
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
          <span className="ant-switch-text">{t('login.remember_device')}</span>
        </div>
        <Form.Item>
          <Button htmlType="submit" className="ant-btn-secondary">
            {t('common.log_in')}
          </Button>
        </Form.Item>
        <Form.Item>
          <DumbLink onClick={setUseRecoveryCode} className="login-form-forgot">
            {t('login.use_one_time_code')}
          </DumbLink>
        </Form.Item>
      </div>
    )
  }
  return (
    <div>
      {decorateField('email', {
        placeholder: t('common.email'),
        validate: (val) => {
          if (!val) {
            return t('validation.email_is_required')
          }
          return null
        },
      })(<Input type="email" />)}
      {decorateField('password', {
        placeholder: t('common.password'),
        validate: (val) => (!val ? t('validation.password_is_required') : null),
      })(<Input type="password" />)}
      <div className="ant-switch-wrapper">
        {decorateField('remember', {
          skipWrapper: true,
          skipLabel: true,
          valueOnChecked: true,
          noBlur: true,
        })(<Switch />)}
        <span className="ant-switch-text">{t('login.remember_me')}</span>
      </div>
      <Form.Item>
        <Button htmlType="submit" className="ant-btn-secondary">
          {t('common.log_in')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Link to={'/' + paths.REQUEST_PASSWORD} className="login-form-forgot">
          {t('login.forgot_password')}
        </Link>
      </Form.Item>
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      useRecoveryCode: false,
      remember: !!props.remember,
      trustDevice: false,
    }
    if (props.email) {
      fields.email = props.email
    }
    return fields
  },
})(LoginForm)
