import React from 'react'

import { fetchDocumentCategories } from '../api/document-categories'
import ActionTypes from '../constants/action-types'
import DocumentCategory from '../model/documentCategory'
import { DocumentCategoryAction } from '../reducers/documentCategories'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingDocumentCategories(companyID: string): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_LOADING,
    companyID,
  }
}
function loadedDocumentCategories(
  companyID: string,
  documentCategories: DocumentCategory[],
  partial = false
): DocumentCategoryAction {
  return {
    type: partial ? ActionTypes.DOCUMENT_CATEGORY_LOADED_PARTIAL : ActionTypes.DOCUMENT_CATEGORY_LOADED,
    companyID,
    documentCategories,
  }
}
function failedLoadingDocumentCategories(companyID: string, error: Error): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_LOAD_FAILED,
    companyID,
    error,
  }
}

export function getDocumentCategories(companyID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingDocumentCategories(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchDocumentCategories(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedDocumentCategories(companyID, data)),
          (data) => dispatch(loadedDocumentCategories(companyID, data, true)),
          (offset) => dispatch(getDocumentCategories(companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDocumentCategories(companyID, e))
        }
      })
  }
}
