import ReimbursementVoucher, {
  ReimbursementVoucherMutableDataFields,
  ReimbursementVoucherMutableFields,
} from '../model/reimbursementVoucher'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchReimbursementVouchers(
  companyID: string,
  employeeID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('GET', url('/v2/reimbursementVouchers', { companyID, employeeID, limit, offset }))
}

export function postReimbursementVoucher(
  companyID: string,
  fileID: string,
  disburseable = true,
  approved?: boolean
): Promise<RequestResponse<ReimbursementVoucher>> {
  return secureRequest('POST', url('/v2/reimbursementVouchers'), {
    body: { companyID, fileID, disburseable, approved },
  })
}

export function putReimbursementVoucher(
  reimbursementVoucherID: string,
  reimbursementVoucher: ReimbursementVoucherMutableFields
): Promise<RequestResponse<ReimbursementVoucher>> {
  return secureRequest('PUT', url('/v2/reimbursementVouchers/' + reimbursementVoucherID), {
    body: {
      disburseable: reimbursementVoucher.disburseable,
      expenseCategoryID: reimbursementVoucher.expenseCategoryID,
      note: reimbursementVoucher.note,
      cardNote: reimbursementVoucher.cardNote,
      disburseAmount: reimbursementVoucher.disburseAmount,
      costCenterID: reimbursementVoucher.costCenterID,
      immediatePay: reimbursementVoucher.immediatePay,
    },
  })
}

export function patchReimbursementVoucher(
  reimbursementVoucherID: string,
  reimbursementVoucher: ReimbursementVoucherMutableDataFields
): Promise<RequestResponse<ReimbursementVoucher>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/' + reimbursementVoucherID), {
    body: {
      amount: reimbursementVoucher.amount,
      amountVAT: reimbursementVoucher.amountVAT,
      receiptDate: reimbursementVoucher.receiptDate,
      currency: reimbursementVoucher.currency,
    },
  })
}

export function patchReimbursementVouchersReady(
  reimbursementVoucherIDs: string[]
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/ready'), {
    body: reimbursementVoucherIDs,
  })
}

export function delReimbursementVoucher(reimbursementVoucherID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/reimbursementVouchers/' + reimbursementVoucherID))
}
