import React from 'react'

import { delDocument, fetchDocuments, postDocument, putDocument } from '../api/documents'
import ActionTypes from '../constants/action-types'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../model/document'
import { DocumentAction } from '../reducers/documents'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingDocuments(employeeID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_LOADING,
    employeeID,
  }
}
function loadedDocuments(employeeID: string, documents: Document[], partial = false): DocumentAction {
  return {
    type: partial ? ActionTypes.DOCUMENT_LOADED_PARTIAL : ActionTypes.DOCUMENT_LOADED,
    documents,
    employeeID,
  }
}
function failedLoadingDocuments(employeeID: string, error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_LOAD_FAILED,
    error,
    employeeID,
  }
}

function addingDocument(employeeID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_ADDING,
    employeeID,
  }
}
export function addedDocument(employeeID: string, document: Document): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_ADDED,
    employeeID,
    document,
  }
}
function failedAddingDocument(employeeID: string, error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_ADD_FAILED,
    employeeID,
    error,
  }
}

function updatingDocument(employeeID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_UPDATING,
    employeeID,
  }
}
export function updatedDocument(employeeID: string, document: Document): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_UPDATED,
    employeeID,
    documentID: document.id,
    document,
  }
}
function failedUpdatingDocument(employeeID: string, error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_UPDATE_FAILED,
    error,
    employeeID,
  }
}

function deletingDocument(): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_DELETING,
  }
}
export function deletedDocument(documentID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_DELETED,
    documentID,
  }
}
function failedDeletingDocument(error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_DELETE_FAILED,
    error,
  }
}

export function getDocuments(employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingDocuments(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchDocuments(employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedDocuments(employeeID, data)),
          (data) => dispatch(loadedDocuments(employeeID, data, true)),
          (offset) => dispatch(getDocuments(employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDocuments(employeeID, e))
        }
      })
  }
}

export function addDocument(employeeID: string, document: DocumentCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<Document | void> => {
    dispatch(addingDocument(employeeID))
    return postDocument(document)
      .then((res) => {
        dispatch(addedDocument(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingDocument(employeeID, e))
        }
      })
  }
}

export function updateDocument(employeeID: string, document: DocumentMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<Document | void> => {
    dispatch(updatingDocument(employeeID))
    return putDocument(document)
      .then((res) => {
        dispatch(updatedDocument(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingDocument(employeeID, e))
        }
      })
  }
}

export function deleteDocument(id: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingDocument())
    return delDocument(id)
      .then(() => {
        dispatch(deletedDocument(id))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingDocument(e))
        }
      })
  }
}
