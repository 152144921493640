import React from 'react'

import { fetchSalaryTypes } from '../api/salary-types'
import ActionTypes from '../constants/action-types'
import SalaryType from '../model/salaryType'
import { SalaryTypeAction } from '../reducers/salaryTypes'
import { isRequestError } from '../utils/error-utils'

function loadingSalaryTypes(companyID: string): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPES_LOADING,
    companyID,
  }
}
function loadedSalaryTypes(companyID: string, salaryTypes: SalaryType[]): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPES_LOADED,
    salaryTypes,
    companyID,
  }
}
function failedLoadingSalaryTypes(companyID: string, error: Error): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPES_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getSalaryTypes(companyID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loadingSalaryTypes(companyID))
    return fetchSalaryTypes(companyID)
      .then((res) => {
        dispatch(loadedSalaryTypes(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSalaryTypes(companyID, e))
        }
      })
  }
}
