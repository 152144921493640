import React, { ReactElement } from 'react'

import LeaveStatisticsComponent from '../components/leave-statistics/LeaveStatistics'
import { EmployeeReducer } from '../reducers/employee'
import { PaySlipReducer } from '../reducers/paySlips'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  employee: EmployeeReducer
  paySlips: PaySlipReducer
}

function LeaveStatistics(props: Reducers): ReactElement | null {
  if (!props.employee.employee) {
    return null
  }

  return <LeaveStatisticsComponent employee={props.employee.employee} paySlips={props.paySlips.paySlips} />
}

export default connectToReducer<Reducers, any>(
  (state) => ({
    employee: state.employee,
    paySlips: state.paySlips,
  }),
  {}
)(LeaveStatistics)
