import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Document from '../model/document'
import { ReducerAction } from '../utils/reducer-utils'

export interface DocumentReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  documents: List<Document>
  error: Error | null
}

const Params = Record<DocumentReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  documents: List(),
  error: null,
})

export interface DocumentAction extends ReducerAction {
  employeeID?: string
  documentID?: string
  documents?: Document[]
  document?: Document
}

const comparator = (a: Document, b: Document) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DocumentReducer> = Params(),
  action: DocumentAction = { type: '' }
): Record<DocumentReducer> => {
  let idx = -1
  switch (action.type) {
    case ActionTypes.DOCUMENT_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.DOCUMENT_LOADED:
    case ActionTypes.DOCUMENT_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        loading: action.type === ActionTypes.DOCUMENT_LOADED_PARTIAL,
        documents: state.get('documents').concat(List(action.documents)).sort(comparator),
      })
    case ActionTypes.DOCUMENT_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error || null,
      })

    case ActionTypes.DOCUMENT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_ADDED:
      if (!action.document) {
        return state
      }
      idx = state.get('documents').findIndex((item) => !!action.document && item.id === action.document.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('documents', state.get('documents').set(idx, action.document).sort(comparator))
      }
      return state.set('saving', false).set('documents', state.get('documents').push(action.document).sort(comparator))
    case ActionTypes.DOCUMENT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DOCUMENT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_UPDATED:
      if (!action.document) {
        return state
      }
      idx = state.get('documents').findIndex((item) => !!action.document && item.id === action.document.id)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('documents', state.get('documents').set(idx, action.document).sort(comparator))
    case ActionTypes.DOCUMENT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DOCUMENT_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_DELETED:
      idx = state.get('documents').findIndex((item) => item.id === action.documentID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('documents', state.get('documents').delete(idx))
    case ActionTypes.DOCUMENT_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
