import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import TimeRegistrationTemplate from '../model/timeRegistrationTemplate'
import { ReducerAction } from '../utils/reducer-utils'

export type TimeRegistrationTemplateReducer = {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  timeRegistrationTemplates: List<TimeRegistrationTemplate>
  error: Error | null
}

const Params = Record<TimeRegistrationTemplateReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  timeRegistrationTemplates: List<TimeRegistrationTemplate>(),
  error: null,
})

export type TimeRegistrationTemplateAction = ReducerAction & {
  companyID?: string
  employeeID?: string | null
  template?: TimeRegistrationTemplate
  templates?: TimeRegistrationTemplate[]
  templateDeleted?: boolean
}

export default (
  state: Record<TimeRegistrationTemplateReducer> = Params(),
  action: TimeRegistrationTemplateAction = { type: '' }
): Record<TimeRegistrationTemplateReducer> => {
  // only process actions for the active employee
  if (action.employeeID && state.get('employeeID') && action.employeeID !== state.get('employeeID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.TIME_REGISTRATION_TEMPLATE_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID ?? state.get('companyID'),
        employeeID: action.employeeID ?? state.get('employeeID'),
      })
    case ActionTypes.TIME_REGISTRATION_TEMPLATE_LOADED:
      return Params({
        loaded: true,
        companyID: action.companyID ?? state.get('companyID'),
        employeeID: action.employeeID ?? state.get('employeeID'),
        timeRegistrationTemplates: List(action.templates),
      })
    case ActionTypes.TIME_REGISTRATION_TEMPLATE_LOAD_FAILED:
      return Params({
        companyID: action.companyID ?? state.get('companyID'),
        employeeID: action.employeeID ?? state.get('employeeID'),
        error: action.error || null,
      })

    case ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVING:
      return state.set('saving', true)
    case ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVED: {
      let idx = -1
      if (action.employeeID) {
        idx = state.get('timeRegistrationTemplates').findIndex((t) => t.employeeID === action.employeeID)
      } else {
        idx = state.get('timeRegistrationTemplates').findIndex((t) => t.companyID === action.companyID && !t.employeeID)
      }
      if (action.templateDeleted) {
        if (idx > -1) {
          return state
            .set('saving', false)
            .set('timeRegistrationTemplates', state.get('timeRegistrationTemplates').delete(idx))
        }
        return state.set('saving', false)
      }
      if (!action.template) {
        return state
      }
      if (idx > -1) {
        state = state.set('timeRegistrationTemplates', state.get('timeRegistrationTemplates').set(idx, action.template))
      } else {
        state = state.set('timeRegistrationTemplates', state.get('timeRegistrationTemplates').push(action.template))
      }
      return state.set('saving', false)
    }
    case ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
