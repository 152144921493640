import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Subtitle from '../elements/Subtitle'
import { TimeRegistrationEditMode } from '../types'

import './TimeRegistration.css'

export default function TimeRegistrationDisabled(props: { mode: TimeRegistrationEditMode }): ReactElement | null {
  return (
    <div className="time-registration">
      <Subtitle>
        {props.mode === 'Project Hours'
          ? t('time_registration.project_registration')
          : props.mode === 'Work Hours'
          ? t('time_registration.work_hours_registration')
          : t('time_registration.time_registration')}
      </Subtitle>
      <p>
        {props.mode === 'Project Hours'
          ? t('time_registration.project_registration_disabled')
          : props.mode === 'Work Hours'
          ? t('time_registration.work_hours_registration_disabled')
          : t('time_registration.detailed_time_registration_disabled')}
      </p>
    </div>
  )
}
