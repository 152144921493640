import da from 'date-fns/locale/da'
import { registerLocale } from 'react-datepicker'

import Picker from './DatePicker'
import RangePicker from './RangePicker'

import './style/css'

registerLocale('da', da)

const DatePicker = Object.assign(Picker, { RangePicker })

export default DatePicker
