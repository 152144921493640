import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Contract from '../model/contract'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  contracts: List<Contract>
  error: Error | null
}

const Params = Record<ContractReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  contracts: List<Contract>(),
  error: null,
})

export interface ContractAction extends ReducerAction {
  employeeID?: string
  contracts?: Contract[]
}

const comparator = (a: Contract, b: Contract) => {
  return a.validFrom.localeCompare(b.validFrom)
}

export default (
  state: Record<ContractReducer> = Params(),
  action: ContractAction = { type: '' }
): Record<ContractReducer> => {
  switch (action.type) {
    case ActionTypes.CONTRACTS_LOADING:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.CONTRACTS_LOADED:
    case ActionTypes.CONTRACTS_LOADED_PARTIAL:
      return new Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        loading: action.type === ActionTypes.CONTRACTS_LOADED_PARTIAL,
        contracts: List(action.contracts).sort(comparator),
      })
    case ActionTypes.CONTRACTS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        error: action.error,
      })

    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    case ActionTypes.CHANGE_ACTIVE_COMPANY:
      return Params({
        companyID: action.companyID,
      })
    default:
      return state
  }
}
