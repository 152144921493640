import React from 'react'

import {
  delSalaryRegistration,
  fetchSalaryRegistrations,
  postSalaryRegistration,
  putSalaryRegistration,
} from '../api/salary-registrations'
import ActionTypes from '../constants/action-types'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import { DateFormat } from '../model/types'
import { SalaryRegistrationAction } from '../reducers/salaryRegistrations'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'
import { loadingSwipeStatus } from './swipe'

function loadingSalaryRegistrations(employeeID: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_LOADING,
    employeeID,
  }
}
function loadedSalaryRegistrations(
  employeeID: string,
  fromDate: DateFormat,
  toDate: DateFormat,
  registrations: SalaryRegistration[],
  partial = false
): SalaryRegistrationAction {
  return {
    type: partial ? ActionTypes.SALARY_REGISTRATIONS_LOADED_PARTIAL : ActionTypes.SALARY_REGISTRATIONS_LOADED,
    employeeID,
    fromDate,
    toDate,
    registrations,
  }
}
function failedLoadingSalaryRegistrations(employeeID: string, errorMessage: Error): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_LOAD_FAILED,
    employeeID,
    error: errorMessage,
  }
}

function creatingSalaryRegistration(employeeID: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_CREATING,
    employeeID,
  }
}
export function createdSalaryRegistration(
  employeeID: string,
  registration: SalaryRegistration
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_CREATED,
    employeeID,
    registrationID: registration.id,
    registration,
  }
}
function failedCreatingSalaryRegistration(employeeID: string, errorMessage: Error): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_CREATE_FAILED,
    error: errorMessage,
    employeeID,
  }
}

function updatingSalaryRegistration(employeeID: string, registrationID: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_UPDATING,
    employeeID,
    registrationID,
  }
}
export function updatedSalaryRegistration(
  employeeID: string,
  registrationID: string,
  registration: SalaryRegistration
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_UPDATED,
    employeeID,
    registrationID,
    registration,
  }
}
function failedUpdatingSalaryRegistration(
  employeeID: string,
  registrationID: string,
  errorMessage: Error
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_UPDATE_FAILED,
    error: errorMessage,
    employeeID,
    registrationID,
  }
}

function deletingSalaryRegistration(registrationID: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_DELETING,
    registrationID,
  }
}
export function deletedSalaryRegistration(registrationID: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_DELETED,
    registrationID,
  }
}
function failedDeletingSalaryRegistration(registrationID: string, errorMessage: Error): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATIONS_DELETE_FAILED,
    error: errorMessage,
    registrationID,
  }
}

export function getSalaryRegistrations(employeeID: string, fromDate: DateFormat, toDate: DateFormat, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration[] | void> => {
    if (!offset) {
      dispatch(loadingSalaryRegistrations(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchSalaryRegistrations(employeeID, fromDate, toDate, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedSalaryRegistrations(employeeID, fromDate, toDate, data)),
          (data) => dispatch(loadedSalaryRegistrations(employeeID, fromDate, toDate, data, true)),
          (offset) => dispatch(getSalaryRegistrations(employeeID, fromDate, toDate, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSalaryRegistrations(employeeID, e))
        }
      })
  }
}

export function createSalaryRegistration(registration: SalaryRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration | void> => {
    dispatch(creatingSalaryRegistration(registration.employeeID))
    dispatch(loadingSwipeStatus())
    return postSalaryRegistration(registration.employeeID, registration)
      .then((res) => {
        dispatch(createdSalaryRegistration(registration.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingSalaryRegistration(registration.employeeID, e))
        }
      })
  }
}

export function updateSalaryRegistration(registration: SalaryRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration | void> => {
    const registrationID = registration.id
    if (!registrationID) {
      return PromiseVoid
    }
    dispatch(updatingSalaryRegistration(registration.employeeID, registrationID))
    dispatch(loadingSwipeStatus())
    return putSalaryRegistration(registrationID, registration)
      .then((res) => {
        dispatch(updatedSalaryRegistration(registration.employeeID, registrationID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingSalaryRegistration(registration.employeeID, registrationID, e))
        }
      })
  }
}

export function deleteSalaryRegistration(registrationID: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingSalaryRegistration(registrationID))
    dispatch(loadingSwipeStatus())
    return delSalaryRegistration(registrationID)
      .then(() => {
        dispatch(deletedSalaryRegistration(registrationID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingSalaryRegistration(registrationID, e))
        }
      })
  }
}
