import React, { ReactElement } from 'react'

import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'

type Props = {
  closeModal: () => void
}

export default function LeaveStatisticsExplanation(props: Props): ReactElement | null {
  return (
    <div className="leave-statistics-explanation">
      <p>{t('leave_statistics.explanation.part1')}</p>
      <p>
        {tx('leave_statistics.explanation.part2', {
          contracted_work_days: <strong>{t('leave_statistics.card.contracted_work_days')}</strong>,
        })}
      </p>
      <p>
        {tx('leave_statistics.explanation.part3', {
          work_days: <strong>{t('leave_statistics.card.work_days')}</strong>,
        })}
      </p>
      <p>
        {tx('leave_statistics.explanation.part4', {
          office_days: <strong>{t('leave_statistics.card.office_days')}</strong>,
        })}
      </p>
      <p>
        {tx('leave_statistics.explanation.part5', { days_off: <strong>{t('leave_statistics.card.days_off')}</strong> })}
      </p>
      <p>{t('leave_statistics.explanation.part6')}</p>
      <p>
        <i>{t('leave_statistics.disclaimer')}</i>
      </p>
      <div className="button-container">
        <Button onClick={() => props.closeModal()}>{t('common.close')}</Button>
      </div>
    </div>
  )
}
