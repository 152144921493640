import { VERSION_NUMBER } from '../release'
import { getPlatform } from './device-utils'
import { request, url } from './request-utils'

export async function checkVersion() {
  type VersionResponse = {
    validVersion?: string
  }
  const platform = getPlatform()
  const response = await request<VersionResponse>('GET', url('/v2/versionCheck', { platform, version: VERSION_NUMBER }))
  if (response && response.validVersion) {
    let updateUrl = 'https://employee.salary.dk'
    switch (platform) {
      case 'ios':
        updateUrl = 'https://apps.apple.com/dk/app/salary-dk/id1542667192'
        break
      case 'android':
        updateUrl = 'https://play.google.com/store/apps/details?id=io.ionic.salary'
        break
      default:
        break
    }
    return { validVersion: response.validVersion, updateUrl }
  }
  return null
}
