import React, { ReactElement } from 'react'

import { getProjects } from '../actions/projects'
import {
  createTimeRegistration,
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import TimeRegistrationDisabledComponent from '../components/time-registration/TimeRegistrationDisabled'
import TimeRegistrationTabComponent from '../components/time-registration/TimeRegistrationTab'
import Project from '../model/project'
import TimeRegistration, {
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/company'
import { EmployeeReducer } from '../reducers/employee'
import { ProjectReducer } from '../reducers/projects'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { projectFilter } from '../utils/projects-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  employee: EmployeeReducer
  company: CompanyReducer
  timeRegistrations: TimeRegistrationReducer
  projects: ProjectReducer
}

type Actions = {
  deleteTimeRegistration: (id: string, employeeID: string) => void
  getTimeRegistrations: (
    employeeID: string,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => Promise<TimeRegistration[] | void>
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => Promise<TimeRegistration | void>
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => Promise<TimeRegistration | void>
  getProjects: (companyID: string) => Promise<Project[] | void>
}

function ProjectRegistrations(props: Reducers & Actions & RouteProps): ReactElement | null {
  const employee = props.employee.employee
  const company = props.company.company

  if (!company || !employee || !props.projects.projects.some((p) => projectFilter(p, employee))) {
    return <TimeRegistrationDisabledComponent mode={'Project Hours'} />
  }

  return (
    <TimeRegistrationTabComponent
      mode={'Project Hours'}
      showHistory={props.location.pathname.indexOf('/history') !== -1}
      company={company}
      employee={employee}
      timeRegistrations={props.timeRegistrations}
      projects={props.projects.projects}
      getTimeRegistrations={props.getTimeRegistrations}
      createTimeRegistration={props.createTimeRegistration}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      getProjects={props.getProjects}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    employee: state.employee,
    company: state.company,
    timeRegistrations: state.timeRegistrations,
    projects: state.projects,
  }),
  {
    getTimeRegistrations: getTimeRegistrations,
    createTimeRegistration: createTimeRegistration,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    getProjects: getProjects,
  }
)(ProjectRegistrations)
