import React from 'react'

import { fetchCostCenters } from '../api/cost-centers'
import ActionTypes from '../constants/action-types'
import CostCenter from '../model/costCenter'
import { CostCenterAction } from '../reducers/costCenters'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingCostCenters(companyID: string): CostCenterAction {
  return {
    type: ActionTypes.COST_CENTER_LOADING,
    companyID,
  }
}
function loadedCostCenters(companyID: string, costCenters: CostCenter[], partial = false): CostCenterAction {
  return {
    type: partial ? ActionTypes.COST_CENTER_LOADED_PARTIAL : ActionTypes.COST_CENTER_LOADED,
    costCenters,
    companyID,
  }
}
function failedLoadingCostCenters(companyID: string, error: Error): CostCenterAction {
  return {
    type: ActionTypes.COST_CENTER_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getCostCenters(companyID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>) => {
    if (!offset) {
      dispatch(loadingCostCenters(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchCostCenters(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedCostCenters(companyID, data)),
          (data) => dispatch(loadedCostCenters(companyID, data, true)),
          (offset) => dispatch(getCostCenters(companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCostCenters(companyID, e))
        }
      })
  }
}
