import PaySlip from '../model/paySlip'
import SalaryPeriod from '../model/salaryPeriod'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchPaySlips(employeeID: string, limit: number, offset: number): Promise<RequestResponse<PaySlip[]>> {
  return secureRequest('GET', url('/v2/paySlips', { employeeID, limit, offset }))
}

export function fetchSalaryPeriods(employeeID: string): Promise<RequestResponse<SalaryPeriod[]>> {
  return secureRequest('GET', url('/v2/salaryPeriods', { employeeID }))
}
